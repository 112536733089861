import { user as userUtils } from '../../utils';
import { camUser as camUserActions, sendFunctionkey } from '../';
export default function (e) {
  return (dispatch, getState) => {
    // @todo check if this is possible with react router instead of native windows
    // only activate if on chat tab
    if (window.location.pathname !== '/multi-messenger') {
      return;
    }

    const {
      camUser: { camUsers },
      dialog: { dialogs, processQueue, queue },
    } = getState();

    // check if we have an active user
    if (
      !userUtils.activeUser([
        ...camUsers,
        ...dialogs,
        ...processQueue,
        ...queue,
      ]) ||
      e?.target?.id === 'usernotes'
    ) {
      return;
    }
    // stop default so no other functions are called like f1 for help
    if (e.keyCode >= 112 && e.keyCode <= 119) {
      e.preventDefault();
    }

    switch (e.keyCode) {
      case 38:
        return dispatch(camUserActions.selectlast()); // arrow up
      case 40:
        return dispatch(camUserActions.selectnext()); // arrow down
      case 112:
        return dispatch(sendFunctionkey(0)); // f1
      case 113:
        return dispatch(sendFunctionkey(1)); // f2
      case 114:
        return dispatch(sendFunctionkey(2)); // f3
      case 115:
        return dispatch(sendFunctionkey(3)); // f4
      case 116:
        return dispatch(sendFunctionkey(4)); // f5
      case 117:
        return dispatch(sendFunctionkey(5)); // f6
      case 118:
        return dispatch(sendFunctionkey(6)); // f7
      case 119:
        return dispatch(sendFunctionkey(7)); // f8
      default:
        return;
    }
  };
}
