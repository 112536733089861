import { connect } from 'react-redux';

import {
  openOverlay,
  spinner,
  updateImages,
  uploadFile,
} from '../../../../actions';
import ProfilePictures from './ProfilePictures';

const mapStateToProps = (state) => ({
  user: state.user,
  isMobile: state.browser.isMobile,
  uploadError: state.upload.error,
  uploadResult: state.upload.result,
  uploadIndicator: state.upload.indicator,
});

const mapDispatchToProps = {
  updateImages,
  openOverlay,
  uploadFile,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePictures);
