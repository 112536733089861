import './ProfilePictures.scss';

import useAxios from 'axios-hooks';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { lStorage } from '../../../../services/storage';
import { uuid } from '../../../../utils';
import Select from '../../../Forms/Select/Select';
import Uploader from '../../../Uploader';
import ImageItem from '../../../Uploader/ImageItem/ImageItem';
import { defaultProfilePictureOptions } from './ProfilePictures.functions';

function ProfilePictures({
  updateImages = () => {},
  isMobile,
  user = {},
  openOverlay = () => {},
  uploadError = null,
  uploadResult = null,
  uploadIndicator = '',
  uploadFile,
  spinner = () => {},
}) {
  const intl = useIntl();
  const isWideScreen = useMediaQuery('(min-width: 1600px)');
  const helpContent = {
    fsk12: 'PROFILE_PICTURES_LABEL_NON_NUDE_DESC',
    fsk16: 'PROFILE_PICTURES_LABEL_SOFTCORE_DESC',
    fsk18: 'PROFILE_PICTURES_LABEL_HARDCORE_DESC',
  };

  const getHelpContent = (fsk) =>
    !isWideScreen && helpContent[fsk] ? (
      <span
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: helpContent[fsk] }),
        }}
      />
    ) : null;

  const indicator = 'profilepictures';

  const [, defaultPictureRequest] = useAxios(
    {
      url: '/user/images/default',
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const uploadFormData12 = useMemo(() => new FormData(), []);
  const uploadFormData16 = useMemo(() => new FormData(), []);
  const uploadFormData18 = useMemo(() => new FormData(), []);

  uploadFormData12.append('fsk[]', 'fsk12');
  uploadFormData16.append('fsk[]', 'fsk16');
  uploadFormData18.append('fsk[]', 'fsk18');

  const [imageError, setImageError] = useState({
    fsk12: '',
    fsk16: '',
    fsk18: '',
  });

  const [activeFsk, setActiveFsk] = useState(null);
  const [fsk18Shown, setFsk18Shown] = useState(!!lStorage.getItem('fsk18'));
  const [defaultPictureError, setDefaultPictureError] = useState(null);

  function setDefaultProfilePicture(value) {
    spinner(true);
    defaultPictureRequest({ data: { fsk: value } })
      .then(() => {
        const newImages = [...user.public.images];
        newImages.forEach((image) => {
          if (image.fsk === value) {
            image.default = true;
          } else {
            image.default = false;
          }
        });
        updateImages(newImages);
        setDefaultPictureError(null);
        spinner(false);
      })
      .catch((error) => {
        if (error.response.data) {
          if (error.response.status === 500) {
            setDefaultPictureError(
              intl.formatMessage({ id: error.response.data.translatedError })
            );
          } else {
            setDefaultPictureError(
              error.response.data.errors?.length
                ? intl.formatMessage({ id: error.response.data.errors[0] })
                : intl.formatMessage({ id: 'ERROR_NETWORK_ERROR' })
            );
          }
        } else {
          setDefaultPictureError(
            intl.formatMessage({ id: 'ERROR_NETWORK_ERROR' })
          );
        }
        spinner(false);
      });
  }

  const openCam = (fsk) => {
    setActiveFsk(fsk);
    const stamp = uuid();
    const uploadFormData =
      fsk === 'fsk12'
        ? uploadFormData12
        : fsk === 'fsk16'
        ? uploadFormData16
        : uploadFormData18;

    openOverlay({
      stamp,
      Component: 'Cam',
      props: {
        camstamp: stamp,
        indicator: indicator + fsk,
        uploadPath: '/user/images',
        uploadFormData,
        title: intl.formatMessage({ id: 'SNAPSHOT_PROFILE_PICTURE_TITLE' }),
        minScreenshotWidth: 1080,
        minScreenshotHeight: 1080,
        filename: `snapshot${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        cropping: true,
      },
    });
  };

  useEffect(() => {
    const localIndicator = indicator + activeFsk;
    if (uploadIndicator !== localIndicator) {
      return;
    }

    if (uploadError) {
      setImageError((prevError) => {
        return {
          ...prevError,
          [activeFsk]: intl.formatMessage({ id: 'IMAGE_UPLOAD_ERROR' }),
        };
      });
      return uploadFile('', false, localIndicator);
    }

    if (uploadResult && uploadResult?.data) {
      updateImages(uploadResult.data);
      setImageError({ ...imageError, [activeFsk]: '' });
      return uploadFile('', false, localIndicator);
    }
  }, [
    uploadIndicator,
    uploadResult,
    uploadError,
    uploadFile,
    activeFsk,
    indicator,
    intl,
    imageError,
    updateImages,
  ]);

  return (
    <div className="profile-pictures-wrapper">
      <h1 className="headline">
        <FormattedMessage id="PROFILE_PICTURES_UPLOAD_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="PROFILE_PICTURES_TEXT_1" />
      </p>
      <p>
        <FormattedMessage id="PROFILE_PICTURES_TEXT_2" />
      </p>
      <p>
        <FormattedMessage id="PROFILE_PICTURES_TEXT_3">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      {!isWideScreen && (
        <p>
          <FormattedMessage id="PROFILE_PICTURES_INFO_TEXT_2">
            {(message) => (
              <span dangerouslySetInnerHTML={{ __html: message[0] }} />
            )}
          </FormattedMessage>
        </p>
      )}

      <div className="uploader-wrapper">
        <Uploader
          indicator={indicator + 'fsk12'}
          uploadPath="/user/images"
          uploadFormData={uploadFormData12}
          cropping={true}
          className="profile-picture"
          minDimensions={{ width: 1080, height: 1080 }}
          label="PROFILE_PICTURES_LABEL_NON_NUDE"
          acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png']}
          handleError={(error) => {
            if (error) {
              setImageError({ ...imageError, fsk12: error });
            }
          }}
          imageError={imageError.fsk12}
          hasChildrenWrapper={true}
          dropzoneProps={{ multiple: false }}
          openCam={() => openCam('fsk12')}
          isMobile={isMobile}
          onlineImage={user.public.images.filter(
            (image) => image.fsk === 'fsk12' && image.state === 'enabled'
          )}
          hasChildren={user.public.images?.some(
            (image) => image.state === 'pending' && image.fsk === 'fsk12'
          )}
          helpContent={getHelpContent('fsk12')}
          onChoose={() => setActiveFsk('fsk12')}
        >
          {user.public.images
            .filter((f) => {
              return (
                f.fsk === 'fsk12' &&
                (f.state === 'pending' || f.state === 'disabled')
              );
            })
            .map((f, idx) => (
              <ImageItem
                key={idx}
                src={process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + f.file}
                labelTitle={
                  f.state === 'disabled'
                    ? intl.formatMessage({
                        id: 'LABEL_REJECTED',
                      })
                    : intl.formatMessage({
                        id: 'LABEL_PENDING',
                      })
                }
                labelContent={
                  f.state === 'disabled'
                    ? f.comment
                    : intl.formatMessage({
                        id: 'PROFILE_PICTURES_COMMENT_UNDER_REVIEW',
                      })
                }
                status={f.state}
              />
            ))}
        </Uploader>
      </div>
      <div className="uploader-wrapper">
        <Uploader
          indicator={indicator + 'fsk16'}
          uploadPath="/user/images"
          uploadFormData={uploadFormData16}
          cropping={true}
          minDimensions={{ width: 1080, height: 1080 }}
          label="PROFILE_PICTURES_LABEL_SOFTCORE"
          acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png']}
          handleError={(error) => {
            if (error) {
              setImageError({ ...imageError, fsk16: error });
            }
          }}
          imageError={imageError.fsk16}
          hasChildrenWrapper={true}
          dropzoneProps={{ multiple: false }}
          className="profile-picture"
          openCam={() => openCam('fsk16')}
          isMobile={isMobile}
          onlineImage={user.public.images.filter(
            (image) => image.fsk === 'fsk16' && image.state === 'enabled'
          )}
          hasChildren={user.public.images?.some(
            (image) => image.state === 'pending' && image.fsk === 'fsk16'
          )}
          helpContent={getHelpContent('fsk16')}
          onChoose={() => setActiveFsk('fsk16')}
        >
          {user.public.images
            .filter((f) => {
              return (
                f.fsk === 'fsk16' &&
                (f.state === 'pending' || f.state === 'disabled')
              );
            })
            .map((f, idx) => (
              <ImageItem
                key={idx}
                src={process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + f.file}
                labelTitle={
                  f.state === 'disabled'
                    ? intl.formatMessage({
                        id: 'LABEL_REJECTED',
                      })
                    : intl.formatMessage({
                        id: 'LABEL_PENDING',
                      })
                }
                labelContent={
                  f.state === 'disabled'
                    ? f.comment
                    : intl.formatMessage({
                        id: 'PROFILE_PICTURES_COMMENT_UNDER_REVIEW',
                      })
                }
                status={f.state}
              />
            ))}
        </Uploader>
      </div>
      <div className="uploader-wrapper">
        <Uploader
          indicator={indicator + 'fsk18'}
          uploadPath="/user/images"
          uploadFormData={uploadFormData18}
          cropping={true}
          minDimensions={{ width: 1080, height: 1080 }}
          label="PROFILE_PICTURES_LABEL_HARDCORE"
          acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png']}
          handleError={(error) => {
            if (error) {
              setImageError({ ...imageError, fsk18: error });
            }
          }}
          imageError={imageError.fsk18}
          hasChildrenWrapper={true}
          className="profile-picture"
          dropzoneProps={{ multiple: false }}
          openCam={() => openCam('fsk18')}
          isMobile={isMobile}
          onlineImage={user.public.images.filter(
            (image) => image.fsk === 'fsk18' && image.state === 'enabled'
          )}
          hasChildren={user.public.images?.some(
            (image) => image.state === 'pending' && image.fsk === 'fsk18'
          )}
          helpContent={getHelpContent('fsk18')}
          uploadingNotAvaialable={
            !fsk18Shown && !user.public.images.some((f) => f.fsk === 'fsk18')
          }
          updateUploadingVisibility={setFsk18Shown}
          onChoose={() => setActiveFsk('fsk18')}
        >
          {user.public.images
            .filter((f) => {
              return (
                f.fsk === 'fsk18' &&
                (f.state === 'pending' || f.state === 'disabled')
              );
            })
            .map((f, idx) => (
              <ImageItem
                key={idx}
                src={process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + f.file}
                labelTitle={
                  f.state === 'disabled'
                    ? intl.formatMessage({
                        id: 'LABEL_REJECTED',
                      })
                    : intl.formatMessage({
                        id: 'LABEL_PENDING',
                      })
                }
                labelContent={
                  f.state === 'disabled'
                    ? f.comment
                    : intl.formatMessage({
                        id: 'PROFILE_PICTURES_COMMENT_UNDER_REVIEW',
                      })
                }
                status={f.state}
              />
            ))}
        </Uploader>
      </div>
      {defaultProfilePictureOptions(user.public.images).length !== 0 && (
        <div className="default-profile-picture-wrapper">
          <label>
            <FormattedMessage id="DEFAULT_PROFILE_PICTURE_LABEL" />
          </label>
          <Select
            options={defaultProfilePictureOptions(user.public.images)}
            onChange={(event) => setDefaultProfilePicture(event.target.value)}
            value={
              user.public.images?.find((image) => image.default)?.fsk || 'fsk12'
            }
          />
        </div>
      )}
      {defaultPictureError && (
        <div className="default-picture-error">{defaultPictureError}</div>
      )}
    </div>
  );
}

export default ProfilePictures;

