import React from 'react';
import { useIntl } from 'react-intl';

import Spinner from './Spinner';

function UploadSpinner({ active = false, percent, ...rest }) {
  const intl = useIntl();

  return active ? (
    <div className="spinner-modal">
      <Spinner {...rest} />
      {percent && (
        <div className="percent-wrapper">
          {intl.formatMessage({
            id: 'UPLOADING',
          }) + ` ${percent}`}
          %
        </div>
      )}
      <div className="background" />
    </div>
  ) : null;
}

export default UploadSpinner;
