import '../../Chat.scss';

import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import WaveSurfer from 'wavesurfer.js';

import pauseIcon from '../../../../../assets/img/svg/pause.svg';
import playIcon from '../../../../../assets/img/svg/play.svg';
import transcriptIcon from '../../../../../assets/img/svg/transcript.svg';
import {
  CHAT_LAYOUT_MULTI,
  CHAT_LAYOUT_SINGLE,
  MESSAGE_TYPE_SENDER,
  MESSAGE_TYPE_USER,
} from '../../../../../constants';
import { getAudioBlob } from '../../../../../utils/audio';
import sonusApi from '../../../../../utils/sonusapi';
import Button from '../../../../Button/Button';
import ChatTime from '../../Time';
import MessageLoading from '../MessageLoading';
import MessageResend from '../MessageResend';

function ChatMessageAudio({
  path,
  id,
  message,
  type,
  layout,
  sonusError,
  playVoicemessage,
  stopVoicemessage,
  voicemessagePlaying,
  textVoicemessage,
  transcriptionTexts,
  loading,
  errorLog,
  chatuser = {},
  infiniteScrollingLoading,
}) {
  const intl = useIntl();

  const [timer, setTimer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [fullLengthTime, setFullLengthTime] = useState(null);
  const [displayTime, setDisplayTime] = useState('');
  const [conversionText, setConversionText] = useState({});
  const [waveSurfer, setWaveSurfer] = useState(null);
  const [loadingTranscript, setLoadingTranscript] = useState(false);
  const [wsEventsSet, setWsEventsSet] = useState(false);

  const messageWave = useRef();
  const messageAudio = useRef();
  const audioprocess = useRef();
  const play = useRef();
  const pause = useRef();
  const finish = useRef();
  const decode = useRef();
  const wserror = useRef();

  const waveColor =
    type === MESSAGE_TYPE_USER
      ? 'rgba(255, 255, 255, 0.7)'
      : 'rgba(54, 69, 86, 0.7)';

  const progressColor = type === MESSAGE_TYPE_USER ? 'white' : '#0084ff';

  const userIdClass = `user-${message?.userId}`;
  const cssclass = message?.cssclass || '';

  const cssClasses = classNames('chat-message-inner', 'has-voice', {
    [userIdClass]: message?.userId,
    [cssclass]: cssclass !== '',
  });

  const outerCssClasses = classNames('chat-message', {
    'type-user': type === MESSAGE_TYPE_USER,
    'type-sender': type === MESSAGE_TYPE_SENDER,
  });

  const msgCssClasses = classNames('msg');

  const onClick = async () => {
    try {
      setLoadingTranscript(true);

      const result = await sonusApi.get(`/voicemessages/${id}/convert`);

      setLoadingTranscript(false);
      setConversionText({ [id]: result?.data?.text });
      textVoicemessage({ id, text: result?.data?.text });
    } catch (sonusConvertError) {
      setLoadingTranscript(false);
      const matches = sonusConvertError.message.match(/(\d{3})/g);
      const errorNumber = matches?.length
        ? matches[0]
        : sonusConvertError?.message || '';
      sonusError(errorNumber);
    }
  };
  useEffect(() => {
    if (!transcriptionTexts[id] || conversionText[id] || !id) return;

    setConversionText({ [id]: transcriptionTexts[id] });
  }, [conversionText, transcriptionTexts, id]);

  useEffect(() => {
    if (!waveSurfer || !waveSurfer.isPlaying()) return;

    if (
      voicemessagePlaying !== null &&
      voicemessagePlaying !== waveSurfer.vid
    ) {
      waveSurfer.isPlaying() && waveSurfer.pause();
      stopVoicemessage(waveSurfer.vid);
    }
  }, [voicemessagePlaying, waveSurfer, stopVoicemessage]);

  const logError = useCallback(
    (error, location) => {
      const errorObject = {
        location,
        error: error?.message || 'unknown_error',
        message: error?.message || 'no_message_available',
      };
      let data = { audioid: id };
      if (chatuser) {
        data = {
          user_name: chatuser?.nickname,
          user_whitelabelShort: chatuser?.portals?.whitelabelShort,
          user_whitelabelLong: chatuser?.portals?.whitelabelLong,
          user_userid: chatuser?.userId,
        };
      }
      errorObject.data = data;
      errorLog(errorObject);
    },
    [chatuser, errorLog, id]
  );

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status

    const fetchDataAndCreateWaveSurfer = async (vid) => {
      if (!path) return;

      try {
        if (
          (waveSurfer && waveSurfer.vid === vid) ||
          !messageWave.current ||
          !messageAudio.current ||
          infiniteScrollingLoading
        ) {
          return;
        }

        let blob = getAudioBlob(vid);

        if (!blob) {
          const response = await fetch(path);

          if (!isMounted) return;
          const audioArrayBuffer = await response.arrayBuffer();

          blob = new Blob([audioArrayBuffer], {
            type: 'audio/mpeg',
          });

          if (!isMounted) return;
        }

        const audioUrl = URL.createObjectURL(blob);
        messageAudio.current.src = audioUrl;

        setWsEventsSet(false);

        const ws = new WaveSurfer({
          container: messageWave?.current,
          waveColor,
          progressColor,
          height: 30,
          barWidth: 4,
          barRadius: 4,
          media: messageAudio.current,
          responsive: true,
          cursorWidth: 0,
        });

        ws.vid = vid; // don't delete this, we need this hack
        audioprocess.current = (currentTime) => {
          if (!messageWave?.current) return;
          setTimer(Math.floor(currentTime));
        };

        ws.on('audioprocess', audioprocess.current);

        decode.current = (duration) => {
          if (messageWave?.current) setFullLengthTime(Math.floor(duration));
        };

        ws.on('decode', decode.current);

        play.current = () => {
          if (!messageWave?.current) return;
          setIsPlaying(true);
        };

        ws.on('play', play.current);

        pause.current = () => {
          if (!messageWave?.current) return;
          setIsPlaying(false);
          stopVoicemessage(vid);
        };

        ws.on('pause', pause.current);

        finish.current = () => {
          if (!messageWave?.current) return;
          setIsPlaying(false);
          stopVoicemessage(vid);
        };

        ws.on('finish', finish.current);

        wserror.current = (error) => {
          logError(error, 'wavesurferError1');
        };
        ws.on('error', wserror.current);

        setWsEventsSet(true);
        setWaveSurfer(ws);
      } catch (error) {
        console.error('Error fetching audio data:', error);
        logError(error, 'wavesurferError2');
      }
    };

    fetchDataAndCreateWaveSurfer(id);

    return () => {
      isMounted = false;

      if (waveSurfer) {
        if (audioprocess.current) {
          waveSurfer.un('audioprocess', audioprocess.current);
        }

        if (decode.current) {
          waveSurfer.un('decode', decode.current);
        }

        if (play.current) {
          waveSurfer.un('play', play.current);
        }

        if (pause.current) {
          waveSurfer.un('pause', pause.current);
        }

        if (finish.current) {
          waveSurfer.un('finish', finish.current);
        }

        if (wserror.current) {
          waveSurfer.un('error', wserror.current);
        }
        waveSurfer.destroy();
      }
    };
  }, [
    path,
    waveSurfer,
    waveColor,
    progressColor,
    id,
    playVoicemessage,
    stopVoicemessage,
    logError,
    infiniteScrollingLoading,
  ]);

  useEffect(() => {
    if (
      audioprocess.current &&
      decode.current &&
      wsEventsSet &&
      messageWave.current
    ) {
      let displayTime = String(timer || timer === 0 ? timer : fullLengthTime);

      if (displayTime === 'null') return setDisplayTime('');

      if (displayTime.length > 1) {
        displayTime = '0:' + displayTime;
      } else if (!displayTime.length) {
        displayTime = '';
      } else {
        displayTime = '0:0' + displayTime;
      }

      setDisplayTime(displayTime);
    }
  }, [timer, fullLengthTime, wsEventsSet]);

  useEffect(() => {
    if (!waveSurfer) return false;

    const isPlaying = waveSurfer.isPlaying();

    if (isPlaying && voicemessagePlaying !== id) waveSurfer.pause();

    if (!isPlaying && voicemessagePlaying === id) waveSurfer.play();
  }, [waveSurfer, voicemessagePlaying, id]);

  const playSound = () => {
    if (!waveSurfer) return false;
    waveSurfer.isPlaying() ? stopVoicemessage(id) : playVoicemessage(id);
  };

  const voiceMessageClasses = classNames('message', 'voice-message', {
    expired: !path,
  });

  const playButtonClasses = classNames('btn-play', {
    active: isPlaying,
  });

  return (
    <div className={outerCssClasses}>
      <div className={cssClasses}>
        {layout === CHAT_LAYOUT_SINGLE && <ChatTime time={message.time} />}
        <div className={msgCssClasses}>
          <span className={voiceMessageClasses}>
            {path && !infiniteScrollingLoading ? (
              <>
                <div className="voice-message-inner">
                  {!loading ? (
                    <Button
                      disabled={!waveSurfer}
                      className={playButtonClasses}
                      title="Play"
                      onClick={playSound}
                      intlTranslate={false}
                      icon={isPlaying ? pauseIcon : playIcon}
                      variant="icon-only"
                    />
                  ) : (
                    <MessageLoading loading={loading} />
                  )}

                  <span className="duration">{displayTime}</span>
                  <div ref={messageWave} className="wavesurfer">
                    <audio ref={messageAudio} controls={false} />
                  </div>
                  {type === MESSAGE_TYPE_USER ? (
                    <div className="transcription-wrapper">
                      {!loadingTranscript && !conversionText[id] ? (
                        <Button
                          className="btn-transcript"
                          title="Transcript"
                          onClick={onClick}
                          intlTranslate={false}
                          icon={transcriptIcon}
                          variant="icon-only"
                        />
                      ) : loadingTranscript ? (
                        <>
                          <span className="converting">
                            {loadingTranscript
                              ? intl.formatMessage({
                                  id: 'AUDIO_IS_CONVERTING',
                                })
                              : ''}
                          </span>
                          <MessageLoading loading={true} />
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                {type === MESSAGE_TYPE_USER && conversionText[id] ? (
                  <p className="transcripted">{conversionText[id]}</p>
                ) : null}
              </>
            ) : (
              <div className="voice-message-inner">
                <Button
                  className="btn-play"
                  title="Play"
                  disabled={true}
                  intlTranslate={false}
                  icon={playIcon}
                  variant="icon-only"
                />
                <span>
                  {intl.formatMessage({
                    id: 'VOICE_MESSAGE_NO_LONGER_AVAILABLE',
                  })}
                </span>
              </div>
            )}
          </span>
        </div>
        <MessageResend intl={intl} message={message} />
      </div>
      {layout === CHAT_LAYOUT_MULTI && (
        <div>
          <ChatTime time={message.time} />
        </div>
      )}
    </div>
  );
}

export default ChatMessageAudio;
