import {
  DIARY_ENTRY_DELETE,
  FRIEND_DELETE,
  FRIEND_FRIEND_REQUEST_ALREADY_SENT,
  FRIEND_FRIEND_REQUEST_ANSWER,
  FRIEND_FRIEND_REQUEST_DENIED,
  FRIEND_FRIEND_REQUEST_REMOVE_DENIED,
  FRIEND_FRIEND_REQUEST_SEND,
  IGNORE_USER,
  MANAGEMENT_CAM_LOGIN,
  MANAGEMENT_CAM_LOGOUT,
  MANAGEMENT_CONNECT,
  MANAGEMENT_EVENTS_BROADCAST_MESSAGE,
  MANAGEMENT_EVENTS_CONNECTION_DISCONNECT,
  MANAGEMENT_EVENTS_USER_REMOVE,
  MANAGEMENT_ORIGINS_SWITCH,
  MANAGEMENT_USER_FAVORITE,
  MANAGEMENT_USER_KICK,
} from '../../actions/actions';
import {
  ALERT_ACTION_ANSWER_FRIEND_REQUEST,
  ALERT_ACTION_DELETE_DENIED_FRIEND,
  ALERT_ACTION_DELETE_DIARY_ENTRY,
  ALERT_ACTION_DELETE_FRIEND,
  ALERT_ACTION_IGNORE_USER,
  ALERT_ACTION_SEND_FRIEND_REQUEST,
  ALERT_LEVEL_ERROR,
  ALERT_LEVEL_INFO,
  ALERT_LEVEL_NONE,
  ALERT_TYPE_CLOSE,
  ALERT_TYPE_CONFIRM,
  ALERT_TYPE_DELETE_DENIED_REQUEST,
  ALERT_TYPE_EXCLUSIVE,
  ALERT_TYPE_EXCLUSIVE_SENDER,
  ALERT_TYPE_FADING,
  ALERT_TYPE_FRIEND_REQUEST,
  ALERT_TYPE_SEND_FRIEND_REQUEST,
} from '../../constants';
import { SERVER_ERROR } from '../../errors';
import { hashAlert, uuid } from '../../utils';

const initialState = [];

const reduce = (state = initialState, action) => {
  switch (action.type) {
    // management actions
    case MANAGEMENT_EVENTS_BROADCAST_MESSAGE:
      if (!action.payload) {
        return state;
      }
      return [
        ...state,
        {
          stamp: action.meta.stamp,
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_INFO,
          scope: 'livecam',
          message: {
            message: action.payload,
          },
        },
      ];
    case MANAGEMENT_EVENTS_CONNECTION_DISCONNECT:
      //remove exclusive popups
      return state.filter(
        (alert) =>
          [ALERT_TYPE_EXCLUSIVE, ALERT_TYPE_EXCLUSIVE_SENDER].indexOf(
            alert.type
          ) < 0
      );
    case MANAGEMENT_ORIGINS_SWITCH:
      if (action.error) {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_ERROR,
            scope: 'livecam',
            message: {
              translate: true,
              id: 'alert.switching',
            },
          },
        ];
      }
      return state;
    case MANAGEMENT_CONNECT:
      if (action.error) {
        let message = {
          translate: true,
          id: 'alert.chatError',
          values: { message: action.payload && action.payload.message },
        };
        if (!action.meta || !action.meta.silentReconnect) {
          message = {
            id: 'alert.failedCamLogin',
            translate: true,
          };
        }
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_ERROR,
            scope: 'livecam',
            message,
          },
        ];
      }
      return state;
    case MANAGEMENT_CAM_LOGOUT:
      if (
        !action.meta ||
        !action.meta.start ||
        !action.payload ||
        !action.payload.kickMessage
      ) {
        return state;
      }
      if (action.payload.translate) {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_INFO,
            scope: 'livecam',
            message: {
              id: action.payload.kickMessage,
              translate: true,
            },
          },
        ];
      }
      return [
        ...state,
        {
          stamp: action.meta.stamp,
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_INFO,
          scope: 'livecam',
          message: {
            message: action.payload.kickMessage,
          },
        },
      ];
    case MANAGEMENT_CAM_LOGIN: {
      if (action.meta.end) {
        return state.filter((alert) =>
          action.meta ? alert.stamp !== action.meta.stamp : true
        );
      }
      if (action.meta.start) {
        return state;
        // keep this until the camMessenger is completly tested
        // return [
        //   ...state,
        //   {
        //     stamp: action.meta.stamp,
        //     type: ALERT_TYPE_CAM_LOADING,
        //     level: ALERT_LEVEL_INFO,
        //     modal: true,
        //     loading: true,
        //     hideClose: true,
        //     scope: 'livecam',
        //     message: {
        //       translate: true,
        //       id: 'alert.testCam',
        //       values: {
        //         suffix: '',
        //       },
        //     },
        //   },
        // ];
      }
      if (!action.error) {
        if (action.payload?.toycontrolTestResult?.code === 507) {
          const alert = {
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_ERROR,
            scope: 'livecam',
            message: {
              headlineId: 'ERROR',
              translate: true,
              id: 'TOYCONTROL_APP_OFFLINE',
            },
            stamp: uuid(),
          };
          if (
            state.filter((a) => {
              return hashAlert(alert) === hashAlert(a);
            }).length > 0
          ) {
            return state;
          }
          return [...state, alert];
        }
        return state;
      }
      const newState = state.filter((alert) =>
        action.meta ? alert.stamp !== action.meta.stamp : true
      );
      if (
        action.meta.errorStatus &&
        [6, 11, 20, 21, 22, 26, 33, 34, 35, 36, 37, 38].indexOf(
          action.meta.errorStatus
        ) >= 0
      ) {
        const errorAlert = {
          stamp: action.meta.errorStamp,
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          scope: 'livecam',
          message: {
            translate: true,
            id: `alert.camLoginError${action.meta.errorStatus}`,
          },
        };
        if (action.meta.errorStatus === 6) {
          errorAlert.message.id = 'login.reload';
          errorAlert.modal = true;
        }
        return [...newState, errorAlert];
      }
      if (action.error && action.payload.message !== SERVER_ERROR) {
        return [
          ...newState,
          {
            stamp: action.meta.errorStamp,
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_ERROR,
            scope: 'livecam',
            message: {
              message: action.payload.message,
            },
          },
        ];
      }
      return [
        ...newState,
        {
          stamp: action.meta.errorStamp,
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          scope: 'livecam',
          message: {
            translate: true,
            id: 'error',
          },
        },
      ];
    }
    case MANAGEMENT_USER_KICK:
      if (action.meta && action.meta.start) {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CONFIRM,
            level: ALERT_LEVEL_NONE,
            userId: action.payload.userId,
            scope: 'livecam',
            message: {
              headlineId: 'alert.areYouSure',
              translate: true,
              id: 'alert.kickUser',
            },
          },
        ];
      }
      return state;
    case IGNORE_USER:
      const index = state.findIndex(
        (alert) => alert.alertAction === ALERT_ACTION_IGNORE_USER
      );
      if (index !== -1) return state;
      if (action.meta && action.meta.start) {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CONFIRM,
            level: ALERT_LEVEL_NONE,
            alertAction: ALERT_ACTION_IGNORE_USER,
            userId: action.payload.userId,
            scope: 'livecam',
            message: {
              headlineId: 'alert.areYouSure',
              translate: true,
              id: 'alert.ignoreUser',
            },
          },
        ];
      }
      return state;
    case MANAGEMENT_EVENTS_USER_REMOVE:
      return state.filter(
        (a) => !a.userId || a.userId !== action.payload.userId
      );
    case MANAGEMENT_USER_FAVORITE:
      if (action.error) {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_ERROR,
            scope: 'livecam',
            message: {
              translate: true,
              id: 'alert.favoriteError',
            },
          },
        ];
      }
      if (action.meta.showAlert) {
        let messageId = action.meta.remove
          ? 'alert.favoriteRemoveSuccess'
          : 'alert.favoriteAddSuccess';
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_FADING,
            level: ALERT_LEVEL_INFO,
            fadingAlertType: 'success',
            scope: 'messenger',
            message: {
              translate: true,
              id: messageId,
            },
          },
        ];
      }
      return state;
    case FRIEND_FRIEND_REQUEST_SEND:
      if (action.meta && action.meta.start) {
        const index = state.findIndex(
          (alert) => alert.alertAction === ALERT_ACTION_SEND_FRIEND_REQUEST
        );
        if (index !== -1) return state;
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_SEND_FRIEND_REQUEST,
            level: ALERT_LEVEL_NONE,
            userId: action.payload.clientProductId
              ? `${action.payload.clientProductId}@${action.payload.clientCustomerId}`
              : action.payload.userId,
            alertAction: ALERT_ACTION_SEND_FRIEND_REQUEST,
            scope: 'livecam',
            message: {
              headlineId: 'alert.becomeFriends',
              translate: true,
              id: 'alert.sendFriendRequest',
              values: {
                name: action.payload.name,
              },
            },
          },
        ];
      }
      if (action.meta?.showAlert) {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_FADING,
            level: ALERT_LEVEL_INFO,
            fadingAlertType: 'success',
            scope: 'messenger',
            message: {
              translate: true,
              id: 'alert.friendRequestSentSuccess',
            },
          },
        ];
      }
      return state;
    case FRIEND_FRIEND_REQUEST_ANSWER:
      if (action.meta && action.meta.start) {
        const index = state.findIndex(
          (alert) => alert.alertAction === ALERT_ACTION_ANSWER_FRIEND_REQUEST
        );
        if (index !== -1) return state;
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_FRIEND_REQUEST,
            level: ALERT_LEVEL_NONE,
            scope: 'general',
            alertAction: ALERT_ACTION_ANSWER_FRIEND_REQUEST,
            message: {
              headlineId: 'alert.becomeFriends',
              translate: true,
              id: 'alert.answerFriendRequest',
              values: {
                name: action.payload.nickname,
              },
            },
          },
        ];
      }
      if (action.payload.friendRequestType === 'confirmed') {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_FADING,
            level: ALERT_LEVEL_INFO,
            fadingAlertType: 'success',
            scope: 'general',
            message: {
              translate: true,
              id: 'alert.isFriend',
              values: {
                name: action.payload.nickname,
              },
            },
          },
        ];
      }
      return state;
    case DIARY_ENTRY_DELETE:
      if (action.meta && action.meta.start) {
        const index = state.findIndex(
          (alert) => alert.alertAction === ALERT_ACTION_DELETE_DIARY_ENTRY
        );
        if (index !== -1) return state;
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CONFIRM,
            level: ALERT_LEVEL_NONE,
            scope: 'general',
            alertAction: ALERT_ACTION_DELETE_DIARY_ENTRY,
            message: {
              headlineId: 'alert.areYouSure',
              translate: true,
              id: 'alert.deleteDiaryEntry',
              values: {
                name: action.payload.name,
              },
            },
          },
        ];
      }
      return state;
    case FRIEND_DELETE:
      if (action.meta && action.meta.start) {
        const index = state.findIndex(
          (alert) => alert.alertAction === ALERT_ACTION_DELETE_FRIEND
        );
        if (index !== -1) return state;
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CONFIRM,
            level: ALERT_LEVEL_NONE,
            scope: 'general',
            alertAction: ALERT_ACTION_DELETE_FRIEND,
            message: {
              headlineId: 'alert.areYouSure',
              translate: true,
              id: 'alert.deleteFriend',
              values: {
                name: action.payload.name,
              },
            },
          },
        ];
      }
      return state;
    case FRIEND_FRIEND_REQUEST_REMOVE_DENIED:
      if (action.meta && action.meta.start) {
        const index = state.findIndex(
          (alert) => alert.alertAction === ALERT_ACTION_DELETE_DENIED_FRIEND
        );
        if (index !== -1) return state;
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_DELETE_DENIED_REQUEST,
            level: ALERT_LEVEL_NONE,
            scope: 'general',
            alertAction: ALERT_ACTION_DELETE_DENIED_FRIEND,
            message: {
              headlineId: 'alert.areYouSure',
              translate: true,
              id: 'alert.deleteDeniedRequest',
              values: {
                name: action.payload.name,
              },
            },
          },
        ];
      }
      return state;
    case FRIEND_FRIEND_REQUEST_ALREADY_SENT:
      return [
        ...state,
        {
          stamp: action.meta.stamp,
          type: ALERT_TYPE_FADING,
          level: ALERT_LEVEL_INFO,
          fadingAlertType: 'error',
          scope: 'messenger',
          message: {
            translate: true,
            id: 'alert.friendRequestAlreadySent',
          },
        },
      ];
    case FRIEND_FRIEND_REQUEST_DENIED:
      return [
        ...state,
        {
          stamp: action.meta.stamp,
          type: ALERT_TYPE_FADING,
          level: ALERT_LEVEL_INFO,
          fadingAlertType: 'error',
          scope: 'messenger',
          message: {
            translate: true,
            id: 'alert.friendRequestDenied',
            values: {
              name: action.payload.name,
            },
          },
        },
      ];
    default:
      return state;
  }
};

export default reduce;
