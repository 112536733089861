import './NumberSelect.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import arrowDownIcon from '../../../assets/img/svg/arrow-down.svg';

function NumberSelect({
  id,
  name,
  options,
  label,
  defaultValue = '',
  defaultValueOption,
  subtextValue,
  disabled,
  inline,
  small,
  ...otherProps
}) {
  const selectOptions = options.map((o, key) => (
    <option key={key} value={o.value}>
      {o.label}
    </option>
  ));

  const wrapperClassName = classNames('select-wrapper', 'form-group', {
    inline,
  });

  const selectClassName = classNames('select-container', {
    disabled: disabled,
    small,
  });

  const labelClassName = classNames('select-label', {
    disabled: disabled,
  });

  return (
    <div className={wrapperClassName}>
      {label ? (
        <label htmlFor={id || name} className={labelClassName}>
          <FormattedMessage id={label} />
        </label>
      ) : null}
      <div className={selectClassName}>
        <select
          id={id || name}
          name={id || name}
          {...otherProps}
          className="small"
          disabled={disabled}
        >
          {options.length > 0 ? (
            selectOptions
          ) : (
            <option value="" disabled>
              {defaultValueOption}
            </option>
          )}
        </select>

        <ReactSVG src={arrowDownIcon} className="arrow-down-icon" />
      </div>
      {subtextValue && <div className="subtext-value">{subtextValue}</div>}
    </div>
  );
}

export default NumberSelect;

