import './VoiceRecorderControl.scss';
import '../Control.scss';

import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';

import deleteIcon from '../../../../../assets/img/svg/delete.svg';
import mic from '../../../../../assets/img/svg/mic.svg';
import pauseIcon from '../../../../../assets/img/svg/pause.svg';
import playIcon from '../../../../../assets/img/svg/play.svg';
import { MAX_VOICE_RECORDING_TIME_SEC } from '../../../../../constants';
import { createRecorder } from '../../../../../services/vmsgRecorder';
import api from '../../../../../utils/sonusapi';
import Button from '../../../../Button/Button';

function VoiceRecorderControl({
  recordingClicked,
  clickRecordingHandler,
  sendVoiceClickedState,
  sendVoiceClicked,
  sendChatMessage,
  setRecording,
  sonusError,
  clearRecordingState,
  playVoicemessage,
  stopVoicemessage,
  voicemessagePlaying,
  loadingVoicemessage,
  sendername,
  errorDisabledMicrophone,
  setRecordingstarted,
}) {
  const intl = useIntl();
  const recRef = useRef();
  const wsPlay = useRef();
  const wsPause = useRef();
  const wsFinish = useRef();
  const messageAudio = useRef();
  const [audioBlob, setAudioBlob] = useState(null);
  const [blobObject, setBlobObject] = useState(null);
  const messageWave = useRef();
  const intervalId = useRef();
  const reactiveWaveRef = useRef();

  const [waveSurfer, setWaveSurfer] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [regPlugin, setRegPlugin] = useState();
  const [isRecording, setIsRecording] = useState(null);
  const [recordingStopped, setRecordingStopped] = useState(false);

  const [isRunning, setIsRunning] = useState(true);
  const [isUploading, setIsUploading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const VID = -1; // create a virtual negative id for this

  useEffect(() => {
    const sendVoice = async () => {
      try {
        loadingVoicemessage(true);
        let file = new File([blobObject], `${sendername}_${Date.now()}.mp3`, {
          type: 'audio/mpeg-3',
        });

        const data = new FormData();
        data.append('file', file, file.name);

        const sonusApiResult = await api.post('/voicemessages', data);

        sendChatMessage('', false, null, sonusApiResult?.data);

        setIsUploading(false);
        setIsRunning(false);
        setBlobObject(null);
        setAudioBlob(null);
        clickRecordingHandler(false);
      } catch (sonusApiError) {
        loadingVoicemessage(false);
        setIsUploading(false);
        const matches = sonusApiError.message.match(/(\d{3})/g);
        const errorNumber = matches?.length
          ? matches[0]
          : sonusApiError?.message || '';

        sonusError(errorNumber);
      } finally {
        clearRecordingState();
      }
    };

    if (sendVoiceClickedState && !isUploading) {
      setIsUploading(true);
      sendVoice();
    }
    sendVoiceClicked(false);
  }, [
    sendVoiceClickedState,
    audioBlob,
    sendVoiceClicked,
    blobObject,
    isUploading,
    seconds,
    sendChatMessage,
    clickRecordingHandler,
    sonusError,
    clearRecordingState,
    sendername,
    loadingVoicemessage,
  ]);

  useEffect(() => {
    if (!audioBlob || waveSurfer || !messageAudio.current) return;
    messageAudio.current.src = audioBlob;

    const ws = WaveSurfer.create({
      container: messageWave?.current,
      waveColor: '#95999d',
      progressColor: '#0084ff',
      media: messageAudio.current,
      barWidth: 4,
      barRadius: 4,
      height: 40,
      responsive: true,
      cursorWidth: 0,
    });

    wsPlay.current = () => {
      setIsPlaying(true);
    };

    ws.on('play', wsPlay.current);

    wsPause.current = () => {
      setIsPlaying(false);
      stopVoicemessage(VID);
    };

    ws.on('pause', wsPause.current);

    wsFinish.current = () => {
      setIsPlaying(false);
      stopVoicemessage(VID);
    };

    ws.on('finish', wsFinish.current);

    setWaveSurfer(ws);
    if (messageWave?.current) {
      if (messageWave.current.childNodes.length === 3) {
        if (messageWave.current.childNodes.item(2))
          messageWave.current.childNodes.item(2).remove();
      }
    }
  }, [audioBlob, isPlaying, waveSurfer, VID, stopVoicemessage]);

  useEffect(() => {
    if (recordingClicked && reactiveWaveRef?.current) {
      const wavesurfer = WaveSurfer.create({
        container: reactiveWaveRef?.current,
        waveColor: '#616D7A',
        barWidth: 4,
        barRadius: 4,
        height: 40,
        responsive: true,
      });

      setRegPlugin(wavesurfer.registerPlugin(RecordPlugin.create()));
    }
  }, [recordingClicked]);

  useEffect(() => {
    if (isRunning && intervalId.current) {
      return;
    } else if (isRunning && !intervalId.current) {
      intervalId.current = setInterval(() => {
        setSeconds((prevSeconds) => (prevSeconds + 1) % 60);
      }, 1000);
    } else {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    return () => clearInterval(intervalId.current);
  }, [isRunning, intervalId]);

  const stopRecording = useCallback(async () => {
    setRecordingStopped(true);
    setIsRunning(false);
    setIsRecording(false);
    setRecording(false);

    try {
      const blob = await recRef.current?.stopRecording();
      setBlobObject(blob);
      setAudioBlob(URL.createObjectURL(blob));

      if (regPlugin) {
        regPlugin.stopRecording();
        regPlugin.destroy();
      }
    } catch (error) {
      navigator.permissions.query({ name: 'microphone' }).then((result) => {
        if (result.state === 'denied') {
          errorDisabledMicrophone();
          setRecordingstarted(false);
          clickRecordingHandler(false);
        } else {
          sonusError(1);
        }
      });
    }
  }, [
    regPlugin,
    setIsRecording,
    setRecording,
    sonusError,
    errorDisabledMicrophone,
    clickRecordingHandler,
    setRecordingstarted,
  ]);

  useEffect(() => {
    if (isRunning && recordingClicked === false) {
      stopRecording();
    }
  }, [isRunning, recordingClicked, stopRecording]);

  const recording = useCallback(
    async function recording() {
      recRef.current = createRecorder();
      try {
        await recRef.current.initAudio();
        await recRef.current.initWorker();
        recRef.current.startRecording();
      } catch (error) {
        stopRecording();
        if (error.message === 'Permission denied') {
          errorDisabledMicrophone();
        } else {
          sonusError(2);
        }
      }
    },
    [stopRecording, sonusError, errorDisabledMicrophone]
  );

  useEffect(() => {
    if (recordingClicked && !isRecording && !recordingStopped) {
      setIsRecording(true);
      setRecording(true);
      recording();
    } else if (recordingClicked && isRecording) {
      regPlugin?.startRecording();
    }
  }, [
    recordingClicked,
    regPlugin,
    isRecording,
    stopRecording,
    setIsRecording,
    setRecording,
    recordingStopped,
    recording,
  ]);

  useEffect(() => {
    if (seconds >= MAX_VOICE_RECORDING_TIME_SEC) {
      stopRecording();
      clickRecordingHandler(false);
    }
  }, [seconds, stopRecording, clickRecordingHandler]);

  useEffect(() => {
    if (!waveSurfer) return false;

    const isPlaying = waveSurfer.isPlaying();

    if (isPlaying && voicemessagePlaying !== VID) waveSurfer.pause();

    if (!isPlaying && voicemessagePlaying === VID) waveSurfer.play();
  }, [waveSurfer, voicemessagePlaying, VID]);

  const deleteRecording = () => {
    setSeconds(0);
    setAudioBlob(null);
    clickRecordingHandler(false);
    clearRecordingState();
  };

  const onPlayClick = useCallback(() => {
    if (!waveSurfer) return;

    if (waveSurfer.isPlaying()) {
      stopVoicemessage(VID);
    } else {
      playVoicemessage(VID);
    }
  }, [waveSurfer, stopVoicemessage, playVoicemessage, VID]);

  const playButtonClasses = classNames('btn-play', {
    active: isPlaying,
  });

  return (
    <>
      <div className="voice-message">
        {!isRecording ? (
          <div className="button-wrapper play">
            <Button
              className={playButtonClasses}
              title={intl.formatMessage({ id: 'AUDIO_BUTTON_PLAY' })}
              onClick={onPlayClick}
              intlTranslate={false}
              disabled={!audioBlob}
              icon={isPlaying ? pauseIcon : playIcon}
              variant="icon-only"
            />
          </div>
        ) : null}

        {isRecording ? (
          <ReactSVG src={mic} wrapper="span" className="recording" />
        ) : null}
        <span className="duration">
          0:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
        {audioBlob && (
          <div ref={messageWave} className="wavesurfer">
            <audio ref={messageAudio} controls={false} />
          </div>
        )}
        {!audioBlob && <div ref={reactiveWaveRef} className="wavesurfer"></div>}
      </div>
      {!isRecording ? (
        <div className="button-wrapper delete">
          <Button
            className="btn-delete"
            title={intl.formatMessage({ id: 'AUDIO_BUTTON_DELETE' })}
            onClick={deleteRecording}
            disabled={!audioBlob}
            id="deleteRecording"
            intlTranslate={false}
            icon={deleteIcon}
            variant="icon-only"
          />
        </div>
      ) : null}
    </>
  );
}

export default VoiceRecorderControl;

