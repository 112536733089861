import './AttachmentsTable.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import redIcon from '../../../assets/img/svg/cancel.svg';
import greenIcon from '../../../assets/img/svg/check-circle-outline.svg';
import deleteIcon from '../../../assets/img/svg/delete.svg';
import { uuid } from '../../../utils';
import Button from '../../Button/Button';
import DateItem from '../../Tables/TableItems/DateItem';
import FileSizeItem from '../../Tables/TableItems/FileSizeItem';
import AttachmentItem from '../../ThumbnailItem/AttachmentItem/AttachmentItem';

function AttachmentsTable({
  files,
  isMobile,
  openOverlay,
  imageBaseUrl,
  language,
  askDeleteAttachment,
  deleteFileId,
  deleteFileStamp,
  deleteFileConfirmed,
  deleteAttachment,
  attachmentDeleteNotAllowed,
  spinner,
  checkIcebreaker,
}) {
  const intl = useIntl();
  const [deleteConfig, setDeleteConfig] = useState({});
  const tableClass = classNames('attachments-table noHover', {
    'is-mobile': isMobile,
  });
  const [icebreakerIds, setIcebreakerIds] = useState([]);

  const [{ data: icebreakerData }, getIcebreaker] = useAxios(
    {
      url: '/user/icebreakers',
    },
    { manual: true }
  );

  useEffect(() => {
    if (!checkIcebreaker) {
      return;
    }
    getIcebreaker();
  }, [getIcebreaker, checkIcebreaker]);
  useEffect(() => {
    if (!icebreakerData || !checkIcebreaker) return;
    setIcebreakerIds(icebreakerData.map((d) => d.attachment_id));
  }, [icebreakerData, checkIcebreaker]);

  const openDocument = (file) => {
    if (!file?.name || !file?.file) {
      return;
    }
    const stamp = uuid();
    const fileSource = imageBaseUrl + file.file;
    if (file.type === 'image/jpeg') {
      spinner(true);
      openOverlay({
        stamp,
        Component: 'Document',
        props: {
          openDocumentStamp: stamp,
          title: file.name,
          fileSource,
        },
      });
      spinner(false);
    } else {
      window.open(fileSource, '_blank');
    }
  };

  useEffect(() => {
    if (
      deleteFileId === deleteConfig?.id &&
      deleteFileConfirmed === true &&
      deleteFileStamp === deleteConfig.stamp
    ) {
      deleteAttachment(deleteFileId);
      setDeleteConfig({});
    }
  }, [
    deleteFileId,
    deleteFileConfirmed,
    deleteFileStamp,
    deleteConfig,
    deleteAttachment,
  ]);

  return files.length > 0 ? (
    <table className={tableClass}>
      <thead>
        <tr>
          <th className="preview">
            {intl.formatMessage({ id: 'ATTACHMENT_TABLE_HEADER_PREVIEW' })}
          </th>
          <th className="status" />
          <th className="name">
            {intl.formatMessage({ id: 'ATTACHMENT_TABLE_HEADER_NAME' })}
          </th>
          {!isMobile && (
            <th className="size">
              {intl.formatMessage({ id: 'ATTACHMENT_TABLE_HEADER_SIZE' })}
            </th>
          )}
          <th className="type">
            {intl.formatMessage({ id: 'ATTACHMENT_TABLE_HEADER_TYPE' })}
          </th>
          {!isMobile && (
            <th className="date">
              {intl.formatMessage({ id: 'ATTACHMENT_TABLE_HEADER_UPLOADTIME' })}
            </th>
          )}
          <th className="delete" />
        </tr>
      </thead>
      <tbody>
        {files.map((file, index) => {
          const denied = file?.status === 'denied';
          const status = denied ? redIcon : greenIcon;
          const statusClasses = classNames('status-icon', {
            'icon-red': denied,
            'icon-green': !denied,
          });
          return (
            <tr key={index}>
              <td className="preview">
                <AttachmentItem
                  file={file.thumbnail}
                  type={file.type}
                  value={file}
                  selectAttachment={openDocument}
                />
              </td>
              <td className="status" onClick={() => openDocument(file)}>
                <ReactSVG
                  src={status}
                  wrapper="span"
                  className={statusClasses}
                />
              </td>
              {denied && isMobile ? (
                <td
                  className="name break-word"
                  colSpan={2}
                  onClick={() => openDocument(file)}
                >
                  <span className="filename">{file?.name}</span>
                  {denied && (
                    <span className="file-denied">
                      {intl.formatMessage({ id: 'ATTACHMENT_DENIED' })}
                    </span>
                  )}
                </td>
              ) : (
                <td
                  className="name break-word"
                  onClick={() => openDocument(file)}
                >
                  <span className="filename">{file?.name}</span>
                </td>
              )}
              {!isMobile && !denied ? (
                <td className="size" onClick={() => openDocument(file)}>
                  <FileSizeItem sizeInBytes={file?.size} />
                </td>
              ) : !isMobile && denied ? (
                <td
                  className="size"
                  colSpan={3}
                  onClick={() => openDocument(file)}
                >
                  <span className="file-denied break-word">
                    {intl.formatMessage({ id: 'ATTACHMENT_DENIED' })}
                  </span>
                </td>
              ) : null}
              {!denied && (
                <td className="type" onClick={() => openDocument(file)}>
                  {file.type}
                </td>
              )}
              {!isMobile && !denied && (
                <td className="date" onClick={() => openDocument(file)}>
                  <DateItem
                    data={file.created}
                    dateFormat={
                      language === 'de'
                        ? 'DD.MM.YYYY - HH:mm'
                        : 'MM/DD/YYYY - h:mm a'
                    }
                  />
                </td>
              )}
              <td className="delete">
                <Button
                  intlTranslate={false}
                  icon={deleteIcon}
                  variant="icon-only"
                  className="delete-icon"
                  onClick={() => {
                    const stamp = uuid();
                    if (icebreakerIds.includes(file.id)) {
                      attachmentDeleteNotAllowed({ stamp });
                      return;
                    }
                    setDeleteConfig({ stamp, id: file?.id });
                    askDeleteAttachment({ id: file?.id, stamp });
                  }}
                  disabled={
                    deleteFileId && deleteFileStamp && !deleteFileConfirmed
                  }
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <div className="attachments-empty">
      {intl.formatMessage({ id: 'ATTACHMENTS_NO_FILES' })}
    </div>
  );
}

export default AttachmentsTable;

