import { SMILEYOVERLAY_TOGGLE } from '../actions';
import { toggleChatMenu } from '..';

export default function () {
  return (dispatch, getState) => {
    const {
      chat: { chatMenuOpen },
    } = getState();

    if (chatMenuOpen) {
      dispatch(toggleChatMenu());
    }

    dispatch({ type: SMILEYOVERLAY_TOGGLE });
  };
}
