import { REHYDRATE } from 'redux-persist';

import { REHYDRATION_COMPLETE } from '../actions/actions';

const initialState = {
  rehydrated: false,
};

const persist = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATION_COMPLETE:
      return { ...state, rehydrated: false };
    case REHYDRATE:
      return { ...state, rehydrated: true };
    default:
      return state;
  }
};

export default persist;
