import { MESSAGE_TYPE_SENDER, MESSAGE_TYPE_USER } from '../constants';
import api from './api';
import { addAudioBlob } from './audio';
import sonusApi from './sonusapi';
const create = async (
  payload,
  partner,
  messagingId,
  activeCamUser,
  attachmentUser,
  sendername,
  isSelected
) => {
  const msg = { ...payload };
  msg.type =
    msg.sender === messagingId ? MESSAGE_TYPE_SENDER : MESSAGE_TYPE_USER;
  msg.userId = partner;
  msg.message = msg.body;
  msg.color = msg.userColor = activeCamUser.color || '';
  msg.payload = msg.payload || {};

  if (activeCamUser?.type) {
    msg.userId = activeCamUser.userId;
    msg.from =
      activeCamUser.partner === msg.sender ? activeCamUser.name : sendername;

    msg.to = msg.from === activeCamUser.name ? sendername : activeCamUser.name;

    if (!msg?.payload?.lc_id) {
      msg.payload.lc_id = -1;
    }
  }

  if (msg?.payload?.type === 'payttachment') {
    const id = msg.payload?.payttachment?.id || msg.payload?.payttachment_id;

    if (!id) {
      return;
    }
    try {
      const response = await api.get(
        `user/payttachments/${id}/check/${partner}`
      );
      msg.payload.payttachment = {
        ...msg.payload.payttachment,
        thumbnail: response?.data?.thumbnail,
        bought: response?.data?.bought,
        file: response?.data?.file,
        price: response?.data?.price,
      };
    } catch (error) {
      console.log({ error });
    }
  }
  if (msg?.attachment && msg.type === MESSAGE_TYPE_USER) {
    if (
      attachmentUser?.portals?.allowAttachments === true &&
      attachmentUser?.portals?.cdnPath !== ''
    ) {
      msg.attachment.cdnPath = attachmentUser.portals.cdnPath;
    }
  }

  if (msg?.payload?.type === 'voice' && msg?.payload?.id && isSelected) {
    try {
      const result = await sonusApi.get(`/voicemessages/${msg.payload.id}`);
      msg.payload.voiceData = result?.data;

      if (result?.data?.voicemessage_id) {
        msg.payload.voiceData.id = result?.data?.voicemessage_id;
      }

      if (msg.payload?.voiceData?.path && msg.payload.voiceData?.id) {
        const response = await fetch(msg.payload.voiceData.path);
        const audioArrayBuffer = await response.arrayBuffer();
        const blob = new Blob([audioArrayBuffer], {
          type: 'audio/mpeg',
        });

        addAudioBlob(blob, msg.payload.voiceData.id);
      }
    } catch (sonusGetVoiceMessageError) {
      console.log({ sonusGetVoiceMessageError });
    }
  }
  return msg;
};

export default { create };

