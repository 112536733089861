import { uuid } from '../../utils';
import userUtils from '../../utils/user';
import { IGNORE_USER } from '../actions';
import { removeDialog } from '../dialog';
import { addIgnoredIdsUser } from '..';
import { closeAlert, management } from '..';
import { camUser as camUserActions } from '..';
export default (ignore, stamp, hasUser, id) => (dispatch, getState) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs, processQueue, queue },
  } = getState();
  const checkedId = id?.includes('-') ? id.replace('-', '@') : id;
  const activeUser = id
    ? userUtils.get(
        [...camUsers, ...dialogs, ...processQueue, ...queue],
        checkedId
      )
    : userUtils.activeUser([
        ...camUsers,
        ...dialogs,
        ...processQueue,
        ...queue,
      ]);
  if (!activeUser || userUtils.isAdmin(activeUser)) {
    return;
  }
  const userId = activeUser.userId;
  const dialogId = activeUser.clientCustomerId
    ? `${activeUser.clientProductId}-${activeUser.clientCustomerId}`
    : activeUser.partner;

  if (!ignore) {
    return dispatch({
      type: IGNORE_USER,
      payload: activeUser,
      meta: {
        start: true,
        stamp: uuid(),
        hasUser: hasUser,
      },
    });
  }
  dispatch(closeAlert(stamp));

  // Kick only if it is the user in the livecam session, otherwise just ignore
  if (hasUser) {
    dispatch(management.kickUser(true));
  }

  dispatch({
    type: IGNORE_USER,
    payload: {
      userId,
    },
    meta: {
      lastUser: dialogs.length === 1,
    },
  });
  dispatch(removeDialog(dialogId));
  dispatch(addIgnoredIdsUser([dialogId]));
  activeUser.active && dispatch(camUserActions.selectnext());
};

