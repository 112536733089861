import { connect } from 'react-redux';

import {
  changeLayoutChat,
  sortDialogs,
  toggleUserlistMenu,
} from '../../../../actions';
import UserListMenu from './UserListMenu';

const mapStateToProps = (state) => ({
  open: state.settings.userListMenuOpen,
  layout: state.settings.chatlayout,
  enableChatLayout: state.sender.feature.powersender,
  isMobile: state.view.isMobile,
  activeSort: state.dialog.sort,
});

const mapDispatchToProps = {
  toggleUserlistMenu,
  changeLayoutChat,
  sortDialogs,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListMenu);
