import { connect } from 'react-redux';

import {
  openOverlay,
  spinner,
  updateActiveGallery,
  uploadFile,
} from '../../../../actions';
import EditDiary from './EditDiary';

const mapStateToProps = (state) => ({
  activeDiary: state.galleries.active,
  uploadIndicator: state.upload.indicator,
  uploadResult: state.upload.result,
  uploadError: state.upload.error,
  published: !!state.galleries.active?.published,
  uploadPercent: state.upload.percent,
  spinneractive: state.spinner.active,
  ...state.view,
});

const mapDispatchToProps = {
  updateActiveGallery,
  spinner,
  uploadFile,
  openOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDiary);

