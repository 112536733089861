import './UserGame.scss';

import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import React, { createElement } from 'react';
import { useIntl } from 'react-intl';
import { ResizableBox } from 'react-resizable';

import closeIcon from '../../../assets/img/svg/close.svg';
import Button from '../../Button/Button';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import Overlay from './Overlay';
import Stats from './Stats';
import Time from './Time';
import Turn from './Turn';

function UserGame({
  id,
  enableResizable,
  componentUrl,
  noUser = false,
  usergame,
  lang,
  mediumStreamingTabWidth,
  onlyGame = false,
  toggleUsergameView,
  gameStatus,
}) {
  const [game, setGame] = useState();
  const [error, setError] = useState();
  const minConstraint = 280;
  const maxConstraint = 480;
  const [height, setHeight] = useState(maxConstraint);
  const [gameStyle, setGameStyle] = useState({});
  const intl = useIntl();
  const [, tokenRequest] = useAxios(
    {
      url: `/user/games/${id}/token`,
    },
    { manual: true }
  );
  const onResize = (event, { element, size }) => {
    const height = parseInt(size.height, 10);
    setHeight(height);
    setGameStyle({ height });
  };

  useEffect(() => {
    setGame(null);
    setError(null);
    if (noUser || !usergame) {
      return;
    }
    const loadGame = async () => {
      try {
        const {
          data: { token },
        } = await tokenRequest();
        const { name } = await import(/* webpackIgnore: true */ componentUrl);
        const options = {
          token,
          id: 'game',
          lang: lang,
        };
        const gameElement = createElement(
          name,
          options,
          onlyGame ? null : (
            <div slot="overlay" className="overlay-wrapper">
              <Overlay />
            </div>
          )
        );
        setGame(gameElement);
      } catch (err) {
        console.log('Game could not be loaded', { err });
        setError(err);
      }
    };
    loadGame();
  }, [
    id,
    componentUrl,
    noUser,
    lang,
    setGame,
    usergame,
    tokenRequest,
    onlyGame,
  ]);

  if (noUser || !usergame) {
    return null;
  }

  const gameElement = game ? (
    game
  ) : error ? (
    <div className="game-error">
      {intl.formatMessage({ id: 'GAME_LOADING_ERROR' })}
    </div>
  ) : (
    <SpinnerComponent />
  );
  return onlyGame ? (
    <>{gameElement}</>
  ) : (
    <div className="user-tab-games" style={gameStyle}>
      {!enableResizable ? (
        <>
          <div className="game-wrapper">
            <div className="inner">
              <Turn />
              <Time />
              {gameStatus === 'decided' && (
                <Button
                  intlTranslate={false}
                  icon={closeIcon}
                  variant="icon-only"
                  className="delete"
                  onClick={toggleUsergameView}
                />
              )}
            </div>
            {gameElement}
            <div className="inner game-stats">
              <Stats />
            </div>
          </div>
        </>
      ) : (
        <ResizableBox
          minConstraints={[Infinity, minConstraint]}
          maxConstraints={[Infinity, maxConstraint]}
          height={height}
          width={mediumStreamingTabWidth}
          axis="y"
          onResize={onResize}
        >
          <div className="game-wrapper">
            {gameElement}
            <div className="inner game-info">
              <Turn />
              {gameStatus === 'decided' && (
                <Button
                  intlTranslate={false}
                  icon={closeIcon}
                  variant="icon-only"
                  className="delete"
                  onClick={toggleUsergameView}
                />
              )}
              <Time />
              <Stats />
            </div>
          </div>
        </ResizableBox>
      )}
    </div>
  );
}

export default UserGame;
