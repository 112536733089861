import { BANDWIDTH_CHECK, BANDWIDTH_CHECK_ABORT } from '../actions/actions';
import { CHECKING, INACTIVE, TIMEOUT_IN_MINUTES } from '../constants/bandwidth';
import { TIMEOUT } from '../errors';

const initialState = {
  check: INACTIVE,
  timeout: TIMEOUT_IN_MINUTES * 60 * 1000,
  percent: 0,
  currentBandwidth: 0,
  averageBandwidth: 0,
  checkLengths: [32, 32, 512, 512, 512, 512],
  step: 0,
  length: 0,
  error: false,
  abortCheck: false,
};

const bandwidth = (state = initialState, action) => {
  switch (action.type) {
    case BANDWIDTH_CHECK:
      if (
        action.error &&
        action.payload &&
        action.payload.message === TIMEOUT
      ) {
        return Object.assign({}, initialState, { error: true });
      }
      if (action.meta && action.meta.start) {
        return Object.assign({}, state, {
          check: CHECKING,
          abortCheck: false,
        });
      }
      if (action.meta && action.meta.step && action.payload) {
        const {
          meta: { step },
          payload: { endTime, startTime, bufferLength },
        } = action;
        const difference = (endTime.getTime() - startTime.getTime()) / 1000;
        const currentBandwidth = Math.round(
          (bufferLength * 8) / difference / 1000
        );
        const percent = Math.floor((step / state.checkLengths.length) * 100);
        const averageBandwidth = Math.round(
          currentBandwidth * (1 / step) +
            (state.averageBandwidth * (step - 1)) / step
        );
        return Object.assign({}, state, {
          currentBandwidth,
          averageBandwidth,
          percent,
          step,
          length: action.meta.length,
        });
      }
      return Object.assign({}, state, {
        check: INACTIVE,
        step: 0,
        length: 0,
        abortCheck: false,
      });
    case BANDWIDTH_CHECK_ABORT:
      return Object.assign({}, state, {
        check: INACTIVE,
        step: 0,
        length: 0,
        abortCheck: true,
      });
    default:
      return state;
  }
};

export default bandwidth;
