import { connect } from 'react-redux';

import { user as userUtils } from '../../../../utils';
import UserProfileTurnover from './UserProfileTurnover';

const mapStateToProps = (state) => {
  const activeUser = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);
  if (
    activeUser?.profile?.turnover &&
    !activeUser?.flags?.includes('is_support')
  ) {
    return {
      ...activeUser?.profile?.turnover,
      largeLoaded: activeUser?.profile?.info?.largeLoaded,
    };
  }
  return {};
};

export default connect(mapStateToProps)(UserProfileTurnover);
