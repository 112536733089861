export default {
  RELATIONSHIPS_HEADLINE: `Relationships – Friends`,
  RELATIONSHIPS_CONTENT: `Here you will find an overview of all your friend requests, existing friendships and rejected requests. You can view and edit them under the menu item {link}. We recommend that you always contact them first before accepting a request without having had any contact with the customer first.`,
  RELATIONSHIPS_CONTENT_LINK: `Relationships`,
  RELATIONSHIPS_CONTENT_1: `When you are online, new friend requests will be displayed directly in your {link} under "Activities". There you can react quickly, write to the customer and make new contacts.`,
  RELATIONSHIPS_CONTENT_1_LINK: `Multi Messenger`,
  RELATIONSHIPS_CONTENT_2: `Active chats with your friends can be found in the Multi Messenger under the filter "Friends".`,
  RELATIONSHIPS_CONTENT_3: `Older chats are automatically removed from your Multi Messenger so that you only see the current chats with your contacts. If you want to revive an older chat with one of your friends, you can find the user under the menu item {link} and write to him at any time and and thereby bring them back into your Multi Messenger.`,
  RELATIONSHIPS_CONTENT_4: `By clicking on the menu (three dots on his profile picture), you have the option to send him a message, confirm the friendship or end it. This way, you'll always be in control of your contacts and stay in touch with them at all times.`,
  RELATIONSHIPS_CONTENT_5: `To effectively manage your contacts, they are divided into the following categories:`,
  RELATIONSHIPS_CONTENT_6: `
  <ul>
    <li>Active friends</li>
    <li>Requests</li>
    <li>My requests</li>
    <li>Denied</li>
  </ul>`,
};
