import { connect } from 'react-redux';

import { changeUsercolor } from '../../../actions';
import UserColorCheckbox from './UserColorCheckbox';

const mapStateToProps = (state, ownProps) => ({
  id: ownProps.id,
  enabled: state.settings.usercolor,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (active) => {
    dispatch(changeUsercolor(active));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserColorCheckbox);
