import './Menu.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import menuIcon from '../../../../../../assets/img/svg/menu-dots.svg';
import useOutsideClick from '../../../../../../hooks/useOutsideClick';
import { spinnerRequest } from '../../../../../../shared/spinnerRequest';
import Button from '../../../../../Button/Button';

function Menu({ item, deleteFmotd, editFmotd, spinner }) {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuIconRef = useRef();
  const menuRef = useRef();
  const intl = useIntl();

  const [, deleteFmotdCall] = useAxios(
    { url: `/fmd/${item?.id}`, method: 'DELETE' },
    { manual: true }
  );

  const deleteFmotdClicked = useCallback(async () => {
    if (!item?.id) return;
    try {
      await spinnerRequest({ request: deleteFmotdCall, spinner });
      deleteFmotd(item?.id);
    } catch (err) {
      console.log(err);
    }
  }, [item?.id, deleteFmotdCall, deleteFmotd, spinner]);

  const menuClassNames = classNames('btn-fmotd', {
    open: menuVisible,
  });

  useOutsideClick(
    menuRef,
    () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    },
    menuIconRef
  );
  return (
    <div className="fmotd-menu">
      <Button
        intlTranslate={false}
        icon={menuIcon}
        innerRef={menuIconRef}
        variant="icon-only"
        className={menuClassNames}
        onClick={() => {
          if (!menuVisible) {
            setMenuVisible(true);
          } else {
            setMenuVisible(false);
          }
        }}
      />

      {menuVisible && (
        <div className="sub-menu" ref={menuRef}>
          <div>
            <div
              className="fmotd-menu-item"
              onClick={() => {
                editFmotd(item);
                setMenuVisible(false);
              }}
            >
              {intl.formatMessage({
                id: 'FMOTD_MENU_EDIT',
              })}
            </div>
            <div
              className="fmotd-menu-item"
              onClick={() => {
                deleteFmotdClicked();
                setMenuVisible(false);
              }}
            >
              {intl.formatMessage({
                id: 'FMOTD_MENU_DELETE',
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Menu;
