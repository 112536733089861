import { useEffect } from 'react';

const useOutsideClick = (ref, callback, additionalRef) => {
  const handleClick = (e) => {
    if (ref?.current?.contains && !ref.current.contains(e.target)) {
      if (
        additionalRef?.current?.contains &&
        additionalRef.current.contains(e.target)
      ) {
        return;
      }
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;

