import '../../Chat.scss';

import Spinner from '../../../../Spinner/Spinner';

function MessageLoading({ loading }) {
  return loading ? (
    <div className="loadingSpinner">
      <Spinner />
    </div>
  ) : null;
}

export default MessageLoading;

