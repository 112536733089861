import { uuid } from '../../utils';
import api from '../../utils/api';
import userUtils from '../../utils/user';
import { MANAGEMENT_USER_FAVORITE } from '../actions';
export default (id) => async (dispatch, getState) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs, processQueue, queue },
  } = getState();

  const activeUser = userUtils.activeUser([
    ...camUsers,
    ...dialogs,
    ...processQueue,
    ...queue,
  ]);
  if (!activeUser || userUtils.isAdmin(activeUser)) {
    return;
  }

  const userId = activeUser.clientCustomerId
    ? `${activeUser.clientProductId}@${activeUser.clientCustomerId}`
    : activeUser.userId;

  dispatch({
    type: MANAGEMENT_USER_FAVORITE,
    payload: activeUser,
    meta: {
      start: true,
    },
  });

  try {
    if (!activeUser?.flags?.includes('favorite')) {
      await api.put(`/user/favorites/${userId}`);
    } else {
      await api.delete(`/user/favorites/${userId}`);
    }
    return dispatch({
      type: MANAGEMENT_USER_FAVORITE,
      payload: activeUser,
      meta: {
        remove: activeUser.flags.includes('favorite'),
        showAlert: true,
        stamp: uuid(),
      },
    });
  } catch (error) {
    return dispatch({
      type: MANAGEMENT_USER_FAVORITE,
      payload: activeUser,
      error: true,
      meta: {
        stamp: uuid(),
      },
    });
  }
};

