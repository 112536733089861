export default {
  PHOTOS_INFO_1: `<span class="bold">Create a new gallery</span><br />You can start directly with the "Create new gallery" button. To upload an image, you can simply drag and drop it into the white area with a blue border. Or you click the + to select a file from your computer or smartphone.`,
  PHOTOS_INFO_1_1: `You can also upload multiple images in a ZIP archive. These are automatically entered in the gallery. All pictures must be in JPG format!`,
  PHOTOS_INFO_2: `<span class="bold">Sort photos in a gallery</span><br />Simply drag and drop existing images into the desired sorting.`,
  PHOTOS_INFO_3: `<span class="bold">Add or delete pictures</span><br />You can add more photos to a gallery at any time. You can also delete unchecked images.`,

  PHOTO_GALLERY_REQUIREMENTS_LIST: `<ul>
      <li>at least 3 photos in jpg, jpeg or png format</li>
      <li>Gallery title</li>
      <li>a short description</li>
      <li>a preview image</li>
      <li>at least 1 category</li>
      <li>Actors' document(s)</li>
    </ul>`,

  PHOTOS: `photos`,
  PHOTO_PRICE_ADDITIONAL_LABEL_1: `2 Coins are €0.20`,
  PHOTO_PRICE_ADDITIONAL_LABEL_2: `Minimum: 1, Max: 50 Coins`,
  PHOTO_COINS_PER_PHOTO: `per photo`,
  COINS_PER_PHOTO: `Coins per photo`,
  PHOTO_GALLERY_MENU_ADD_IMAGES: `Add images`,
  PHOTO_GALLERY_MENU_USE_AS_PREVIEW: `Use it as a preview image`,

  PHOTO_IS_CONVERTING: `The image is converting`,
};
