export default {
  ROUTE_MY_RELATIONSHIPS_HEADLINE: `Check and change the status of your relationships with users`,

  RELATIONSHIP_HEADLINE_IGNORE: `Ignored users`,
  RELATIONSHIP_ITEM_MENU_UNIGNORE: `No longer ignore`,
  RELATIONSHIP_LABEL_NO_IGNORED_USERS: `No user is ignored.`,

  RELATIONSHIP_HEADLINE_FAVORITES: `My favorites`,
  RELATIONSHIP_ITEM_MENU_REMOVE_FAVORITE: `Remove favorite`,
  RELATIONSHIP_ITEM_MENU_WRITE_A_MESSAGE: `Write a message`,
  RELATIONSHIP_ITEM_MENU_END_FRIENDSHIP: `End friendship`,
  RELATIONSHIP_ITEM_MENU_CONFIRM_DENY: `Confirm / Deny`,
  RELATIONSHIP_ITEM_MENU_CANCEL_REQUEST: `Cancel the request`,
  RELATIONSHIP_ITEM_MENU_REMOVE_REQUEST: `Remove`,

  RELATIONSHIP_LABEL_NO_FRIENDS: `You don't have any friends yet.`,
  RELATIONSHIP_LABEL_NO_REQUESTS: `There's no request at the moment.`,
  RELATIONSHIP_LABEL_NO_DENIED_REQUESTS: `You haven't denied any requests.`,

  RELATIONSHIP_LABEL_NO_FAVORITES: `You don´t have any favorites yet.`,

  RELATIONSHIPS_INFO_HEADLINE: `The meaning of each relationship`,
  RELATIONSHIPS_INFO_1: `<span class='bold'>Friends</span><br />Your friends are visible to everyone in the communities.`,
  RELATIONSHIPS_INFO_2: `<span class='bold'>Requests</span><br />Before you answer a friend request, you should see if you've already had contact with the user. If not, ask him directly why he wants to be your friend.`,
  RELATIONSHIPS_INFO_3: `<span class='bold'>Favorites</span><br />Note users who send you a lot of virtual gifts or who otherwise spend a lot of coins with you. This way you always have an eye on your top customers and don’t forget to contact them regularly. Favorites can only be seen by yourself, they remain hidden in the community.`,
  RELATIONSHIPS_INFO_4: `<span class='bold'>Ignored</span><br />Users ignored by you can no longer contact you. Please use this function with care.`,

  PAGE: `Page`,
  PAGE_OF: `of`,

  RELATIONSHIP_ITEM_MENU_SHOW_IMAGE: 'Show profile picture',
  RELATIONSHIP_ITEM_MENU_HIDE_IMAGE: 'Hide profile picture',
};

