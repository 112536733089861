import { connect } from 'react-redux';

import { spinner } from '../../../../actions';
import Payouts from './Payouts';

const mapStateToProps = (state) => ({
  isSubmenuShown: state.view.sidebar,
});

const mapDispatchToProps = { spinner };

export default connect(mapStateToProps, mapDispatchToProps)(Payouts);
