import './TooltipIcon.scss';

import classNames from 'classnames';
import { useRef, useState } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick';
import Button from '../Button/Button';

function TooltipIcon({
  tooltip,
  icon,
  btnClassName = 'help-btn',
  position = 'center',
}) {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const tooltipRef = useRef();
  const tooltipIconRef = useRef();

  const tooltipTextClass = classNames('tooltip-text', {
    'tooltip-left': position === 'left',
    'tooltip-right': position === 'right',
    'tooltip-visible': tooltipVisible,
  });
  useOutsideClick(
    tooltipRef,
    () => {
      if (tooltipVisible) {
        setTooltipVisible(false);
      }
    },
    tooltipIconRef
  );
  return (
    <span className="tooltip">
      <Button
        intlTranslate={false}
        icon={icon}
        className={btnClassName}
        variant="icon-only"
        onClick={(event) => {
          setTooltipVisible(!tooltipVisible);
          event.stopPropagation();
        }}
        innerRef={tooltipIconRef}
      />
      <span ref={tooltipRef} className={tooltipTextClass}>
        {tooltip}
      </span>
    </span>
  );
}

export default TooltipIcon;
