import { connect } from 'react-redux';

import {
  openOverlay,
  spinner,
  userUpdateDocuments as updateDocuments,
  uploadFile,
} from '../../../actions';
import Documents from './Documents';

const mapStateToProps = (state) => ({
  isMobile: state.browser.isMobile,
  user: state.user,
  uploadError: state.upload.error,
  uploadResult: state.upload.result,
  uploadIndicator: state.upload.indicator,
});

const mapDispatchToProps = {
  updateDocuments,
  openOverlay,
  uploadFile,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
