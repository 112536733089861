import { connect } from 'react-redux';

import { openOverlay, spinner } from '../../../actions';
import { contestUtils } from '../../../utils';
import Dashboard from './Dashboard';

const mapDispatchToProps = { spinner, openOverlay };
const mapStateToProps = (state) => ({
  hasSummerContest: contestUtils.hasActiveOrFinishedSummerContest(state),
  user: state.user,
  isMobile: state.view.isMobile,
  hasMobileOverlay: !!state.overlays.find(
    (overlay) => overlay?.Component === 'DashboardMobileOverlay'
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
