import comments from './comments';
import diary from './diary';
import fmotd from './fmotd';
import photos from './photos';
import videos from './videos';

export default {
  ...photos,
  ...videos,
  ...diary,
  ...comments,
  ...fmotd,

  ROUTE_MY_CONTENT_HEADLINE: `Manage your photo galleries, videos and comments here`,
  CREATE_NEW_GALLERY: `Create a new gallery`,
  FSK_CASUAL_LABEL: `Casual`,
  FSK_SOFT_LABEL: `Soft`,
  FSK_HARD_LABEL: `Hard`,

  STARTING_GALLERY_INFO_HEADLINE_1: `Show yourself from your best side!`,
  STARTING_GALLERY_INFO_TEXT_1: `The first visual impression plays an important role in making contact! This is not only the case in real life on the street, at a party or in a club. Of course, community users would also like to see who they can have fun with in the livecam.
  It is therefore all the more important for you to address your interests and your personal attitude and also visually, to arouse curiosity.`,
  STARTING_GALLERY_INFO_TEXT_2: `At LiveCreator you can also let the community have a part in your life in the form of pictures and videos and thus show more of yourself. This is a practical and worthwhile addition to the livecam.`,
  STARTING_GALLERY_INFO_TEXT_3: `The community will be more attentive to you, you can actually arouse curiosity and convince users to visit your livecam more quickly.`,
  STARTING_GALLERY_INFO_TEXT_4: `You can publish both paid and free content. This enables you to offer targeted teasers to arouse interest in paid content and your livecam, and to earn money with your photos and videos in addition to the livecam.`,
  HOW_TO_VIDEO: `Watch our "How to - Video"`,

  WHAT_OPTIONS_DO_I_HAVE: `What options do I have here?`,
  GALLERY_INFO_1: `<span class="bold">Gallery sorting</span><br />First you will find the latest gallery. You can change the sorting with the icon or search for a gallery title.`,
  GALLERY_INFO_2: `<span class="bold">Preview image upload and change</span><br />Change or upload a new preview image.`,
  GALLERY_INFO_3: `<span class="bold">Edit gallery title and description</span><br />Edit the title and description of your galleries.`,
  GALLERY_INFO_4: `<span class="bold">Set gallery price and categories</span><br />Manage price and category assignments`,
  GALLERY_INFO_5: `<span class="bold">Read and edit comments</span><br />Track and edit user comments`,
  GALLERY_INFO_6: `<span class="bold">Track and change gallery status</span><br />Keep an eye on your galleries and their status:`,
  GALLERY_INFO_6_1: `Only visible to you`,
  GALLERY_INFO_6_2: `Support is checking your photos and settings`,
  GALLERY_INFO_6_3: `The gallery will be published at the specified time`,
  GALLERY_INFO_6_4: `Visible to all community users`,
  GALLERY_INFO_6_5: `Only visible to you`,
  GALLERY_INFO_6_6: `Support blocked this gallery`,
  GALLERY_INFO_6_7: `Your action is required!`,

  UNNAMED_GALLERY: `Unnamed gallery`,
  TIMES_SOLD: `times sold`,
  NOT_SOLD_YET: `Not sold yet`,
  COMMENTS: `Comments`,

  GALLERY_MENU_UPLOAD_PREVIEW: `Upload a preview image`,
  GALLERY_MENU_UPLOAD_NEW_PREVIEW: `Upload a new preview image`,
  GALLERY_MENU_EDIT_TEXTS: `Edit texts`,
  GALLERY_MENU_SETTINGS: `Gallery settings`,
  GALLERY_MENU_DELETE: `Delete`,
  GALLERY_MENU_PUBLISH: `Publish the gallery`,
  GALLERY_MENU_ARCHIVE: `Archive the gallery`,

  GALLERY_TITLE_DESCRIPTION: `Gallery title and description`,
  TITLE_LABEL: `Title:`,
  DESCRIPTION_LABEL: `Description:`,
  MIN_32_CHARS: `min 32 characters`,

  GALLERY_PRICE: `Price`,
  GALLERY_PRICE_ERROR: `Please enter a valid price`,
  FREE_CONTENT: `Free content:`,
  GALLERY_ASSIGNED_CATEGORIES: `Assigned categories`,
  GALLERY_CATEGORY: `Category`,
  GALLERY_CATEGORY_1: `Category 1:`,
  GALLERY_CATEGORY_2: `Category 2:`,
  GALLERY_CATEGORY_3: `Category 3:`,
  GALLERY_CATEGORY_4: `Category 4:`,
  GALLERY_CATEGORY_5: `Category 5:`,

  LABEL_PREVIEW: `Preview`,
  PUBLISH_NOW: `Publish now`,
  CANCEL_SCHEDULE: `Cancel schedule`,
  GALLERY_WILL_BE_PUBLISHED_LABEL: `The gallery will be published on`,
  GALLERY_CATEGORY_WARNING: `IMPORTANT: We recommend galleries to users that match their interests and preferences. Please select the categories appropriately and carefully!`,
  SET_SCHEDULE: `Set schedule`,
  SAVE_SCHEDULE: `Save schedule`,
  PUBLISH_DATE: `Publish date`,

  GALLERY_ACTORS_DOCUMENTS: `Actors' documents`,
  GALLERY_ACTORS_DOCUMENTS_TEXT: `Since October 2021, we have had to prove that the actors are of legal age for every published gallery. For this, it is necessary that you provide a current ID for each gallery for each performing actor.`,
  GALLERY_ACTORS_DOCUMENTS_LINK: `Manage my documents`,
  GALLERY_ACTORS_DOCUMENTS_CHECKBOX_1: `I am displayed in this gallery. The standard document is used.`,
  GALLERY_ACTORS_DOCUMENTS_CHECKBOX_2: `At least one other actor can be seen. I assign the relevant documents to the gallery.`,
  GALLERY_ACTORS_HEADLINE: `The following actors can be seen in this gallery:`,
  GALLERY_ASSIGN_DOCUMENT: `Assign document:`,
  GALLERY_STANDARD_DOCUMENT: `Standard document`,
  GALLERY_ACTOR_DOCUMENT: `Actor document`,

  GALLERY_THUMBNAIL_MESSAGE: `Your new preview image will be checked by support. It will be visible here once it has been approved.`,

  COMMENT_WRITES_ON: `writes on`,
  GALLERY_IMAGE_LABEL: `Gallery photo`,

  GALLERY_REQUIREMENTS: `In order to publish the gallery, the following is required:`,
  GALLERY_REJECT_REASONS: `The gallery could not be published because of the following reasons:`,

  GALLERY_UPLOAD_NEW_PREVIEW: `Upload a new preview image`,
  GALLERY_OR_SELECT_NEW_IMAGE: ` or select a photo from the gallery`,

  reject_corrupt: `The conversion of the video failed. Please upload it again.`,
  reject_duplicated: `The content has already been published or is available in other galleries.`,
  reject_expensive: `The price is unreasonably high. Please set it again.`,
  reject_illegal: `The content is illegal or violates the terms and conditions. Please delete!`,
  reject_muted: `The video has no sound.`,
  reject_quality: `The quality of the content is not sufficient.`,
  reject_short: `The video is too short. It must be at least 60 seconds long.`,
  reject_stolen: `The content shown has been stolen or downloaded from the Internet.`,
  reject_undocumented: `An ID document is missing for at least one actor.`,
  reject_similar: `The pictures are too similar or always show the same thing.`,
  reject_freefsk: `A free gallery must not contain hardcore content.`,

  jerkoff: `Jerk Off`,
  dildo: `Dildo`,
  analsex: `Anal Sex`,
  cumshot: `Cumshot`,
  asian: `Asian`,
  car: `Car`,
  bathroom: `Bathroom`,
  bananas: `Bananas`,
  bdsm: `BDSM`,
  hairy: `Hairy`,
  legs: `Legs`,
  bra: `Bra`,
  bikini: `Bikini`,
  picturecumshot: `Picture insemination`,
  bisexual: `Bisexual`,
  bizarr: `Bizarre`,
  blowjob: `Blowjob`,
  blondes: `Blondes`,
  glasses: `Glasses`,
  brunette: `Brunette`,
  office: `Office`,
  transsexual: `Transsexual`,
  deepthroat: `Deepthroat`,
  doctor: `Doctor`,
  dominance: `Dominance`,
  plumper: `Plumper`,
  outdoor: `Outdoor`,
  threesome: `Threesome`,
  wrapped: `Wrapped`,
  lubricate: `Oiled`,
  european: `European`,
  exhibitionist: `Exhibitionist`,
  finger: `Fingers`,
  fisting: `Fisting`,
  fitness: `Fitness`,
  bottles: `Bottles`,
  feetsex: `Feetsex`,
  feet: `Feet`,
  gangbang: `Gangbang`,
  tiedup: `Tied Up`,
  openlegs: `Open Legs`,
  bigtits: `Big Tits`,
  bigcocks: `Big Cocks`,
  groupsex: `Group Sex`,
  gum: `Gum`,
  bracelets: `Handcuffs`,
  highheels: `High Heels`,
  butt: `Butt`,
  balls: `Balls`,
  hangingtits: `Hanging Tits`,
  panty: `Panty`,
  rubber: `Rubber`,
  jeans: `Jeans`,
  smalltits: `Small Tits`,
  clit: `Clit`,
  nurse: `Nurse`,
  shorthair: `Shorthaired`,
  kitchen: `Kitchen`,
  kissing: `Kissing`,
  longhair: `Longhaired`,
  latex: `Latex`,
  latinas: `Latinas`,
  leather: `Leather`,
  lesbian: `Lesbian`,
  machines: `Machines`,
  mask: `Mask`,
  massage: `Massage`,
  masturbation: `Masturbation`,
  chubby: `Chubby`,
  pussy_licking: `Pussy Licking`,
  pussies: `Pussies`,
  close_up: `Closeup`,
  food: `Food`,
  goldenshower: `Golden Shower`,
  normal: `Normal`,
  topless: `Topless`,
  orgy: `Orgy`,
  posing: `Posing`,
  pissing: `Pissing`,
  piercing: `Piercing`,
  shave: `Shave`,
  shaved: `Shaved`,
  smoking: `Smoking`,
  redhead: `Redhead`,
  skirt: `Skirt`,
  vulvar: `Labia`,
  slim: `Slim`,
  schoolgirls: `Schoolgirls`,
  pregnantwomen: `Pregnant Women`,
  cock: `Cock`,
  black_haired: `Black-haired`,
  solarium: `Solarium`,
  tangaslip: `Thong Panties`,
  partlyshaved: `Partly Shaved`,
  tits: `Tits`,
  toys: `Toys`,
  uniform: `Uniform`,
  upskirt: `Up-skirt`,
  lingerie: `Lingerie`,
  jerk: `Jerk`,
  diapers: `Diapers`,
  vixens: `Vixens`,
  toes: `Toes`,
  plaits: `Plaits`,
  tongue: `Tongue`,
  cumshots: `Cumshots`,
  teens: `Teens`,
  amateur: `Amateur`,
  asiangirls: `Asian Girls`,
  thaigirls: `Thai Girls`,
  teen: `Teen`,
  kiss: `Kiss`,
  licking: `Licking`,
  homosexual: `Homosexual`,
  gay: `Gay`,
  scat: `Scat`,
  bondage: `Bondage`,
  fetish: `Fetish`,
  spanking: `Spanking`,
  nylons: `Nylons`,
  interracial: `Interracial`,
  blacksex: `Black Sex`,
  cross_dresser: `Cross-Dresser`,
  ligation: `Ligation`,
  bears: `Bears`,
  blacks: `Blacks`,
  submissive: `Submissive`,
  enema: `Enema`,
  hardcore: `Hardcore`,
  smallcocks: `Small Cocks`,
  lycra: `Lycra`,
  latinos: `Latinos`,
  men: `Men`,
  masochism: `Masochism`,
  punks: `Punks`,
  giantcocks: `Giant Cocks`,
  rimming: `Rimming`,
  sm: `SM`,
  softcore: `Softcore`,
  sportsgear: `Sportsgear`,
  nylonsold: `Pantyhose`,
  sperm: `Sperm`,
  transvestite: `Transvestite`,
  tattoos: `Tattoos`,
  forest: `Forest`,
  waxgames: `Wax Games`,
  squirt: `Squirt`,
  public: `Public`,
  creampie: `Creampie`,
  milf: `MILF`,
  oral: `Oral`,
  plug: `Plug`,
  lace: `Lace`,
  roleplay: `Roleplay`,
  dirtytalk: `Dirty Talk`,
  voyeur: `Voyeur`,
  grannies: `Grannies`,
  balloon: `Balloon`,
  selfies: `Selfies`,
  handjob: `Handjob`,
  students: `Students`,
  strip: `Strip`,
};
