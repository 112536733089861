import '../Chat.scss';

import classNames from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import fourScoreIcon from '../../../../assets/img/4score/4score.svg';
import attachmentIcon from '../../../../assets/img/svg/attachment.svg';
import chevronIcon from '../../../../assets/img/svg/chevron-single-right.svg';
import kissIcon from '../../../../assets/img/svg/kiss.svg';
import matchIcon from '../../../../assets/img/svg/match.svg';
import movieIcon from '../../../../assets/img/svg/movie.svg';
import payttachmentIcon from '../../../../assets/img/svg/payttachment.svg';
import {
  CHAT_LAYOUT_MULTI,
  CHAT_LAYOUT_SINGLE,
  MESSAGE_TYPE_RESEND,
  MESSAGE_TYPE_SENDER,
  MESSAGE_TYPE_SENDER_2ALL,
  MESSAGE_TYPE_SYSTEM,
  MESSAGE_TYPE_USER,
} from '../../../../constants';
import { uuid } from '../../../../utils';
import api from '../../../../utils/api';
import axiosInstance from '../../../../utils/api';
import fileUtils from '../../../../utils/file';
import Button from '../../../Button/Button';
import SmileyParser from '../Smiley/SmileyParser';
import ChatTime from '../Time';
import ChatMessageAudio from './Audio';
import MessageLoading from './MessageLoading';
import MessageResend from './MessageResend';

class ChatMessage extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.layout !== this.props.layout ||
      nextProps.color !== this.props.color ||
      nextProps.showSendername !== this.props.showSendername ||
      nextProps.message !== this.props.message ||
      nextProps.highlight !== this.props.highlight ||
      nextProps.type !== this.props.type ||
      nextProps.id !== this.props.id ||
      nextProps.safeMode !== this.props.safeMode ||
      nextProps.livecam !== this.props.livecam
    );
  }

  deleteDialog = async () => {
    try {
      this.props.spinner(true);
      await api.delete(`/user/dialogs`, {
        data: {
          users: [this.props?.selectedUser?.id],
        },
      });

      await this.props.clearDialog(this.props?.selectedUser?.id);
      this.props.selectFirstDialog();
    } catch (error) {
      console.error('Error deleting dialog:', error);
    } finally {
      this.props.spinner(false);
    }
  };

  unIgnoreDialog = async () => {
    axiosInstance
      .delete(`/user/ignored/${this.props?.selectedUser?.partner}`)
      .then((response) => {
        this.props.unignoreIdUser(this.props?.selectedUser?.partner);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  resendMessage = (message) => {
    this.props.sendChatMessage(message.payload);
  };

  render() {
    const {
      message,
      intl,
      highlight = false,
      type,
      layout,
      color,
      showSendername,
      openOverlay,
      safeMode,
      sexIcons,
      regards,
      spinner,
      sonusError,
      deleteDialog,
      livecam,
      isIgnored,
      loading = false,
      attachments,
      payttachments,
    } = this.props;

    const hasAttachment =
      message?.attachment?.status === 'ok' ||
      (message?.attachment_id && (message?.payload?.resendPayload || loading));
    const hasRemovedAttachment = ['deleted', 'locked'].includes(
      message?.attachment?.status
    );
    const hasVoice = !!message?.payload?.voiceData?.id;
    const hasPayttachment = message?.payload?.type === 'payttachment';
    const thumbnail = message?.payload?.payttachment?.thumbnail;
    const translated = message?.payload?.utm_id && type === MESSAGE_TYPE_USER;
    const cssClasses =
      deleteDialog || isIgnored
        ? 'chat-message-inner'
        : classNames('chat-message-inner', `user-${message.userId}`, {
            highlight: highlight,
            [color]: color && type === MESSAGE_TYPE_USER,
            [message?.cssclass]: message?.cssclass && message?.cssclass !== '',
            'has-voice': hasVoice,
          });
    const attachmentContentCssClass = classNames('attachment-content', {
      empty: hasRemovedAttachment,
    });
    const fromToCssClasses = classNames('from-to', {
      [color]:
        color && type === MESSAGE_TYPE_SENDER && layout === CHAT_LAYOUT_SINGLE,
    });

    const outerCssClasses = classNames('chat-message', {
      'type-user': type === MESSAGE_TYPE_USER,
      'type-sender':
        type === MESSAGE_TYPE_SENDER || type === MESSAGE_TYPE_RESEND,
      'type-sender-2all': type === MESSAGE_TYPE_SENDER_2ALL,
      'type-system': type === MESSAGE_TYPE_SYSTEM || deleteDialog || isIgnored,
    });

    const displayMessage = message?.translate
      ? intl.formatMessage({ id: message.id }, message.values)
      : message?.message;

    const dialogMessageType = message?.payload?.type;
    const isGameMessage =
      typeof dialogMessageType === 'string' &&
      dialogMessageType.startsWith('game.');
    const isSenderGameMessage = type === MESSAGE_TYPE_SENDER && isGameMessage;
    const messageSexIconFiltered =
      dialogMessageType === 'sexicon'
        ? sexIcons.filter((icon) => icon.id === message.payload.id)
        : null;
    const isSexIconFsk12 =
      messageSexIconFiltered?.length &&
      messageSexIconFiltered[0]?.fsk === 'fsk12';

    const messageRegardFiltered =
      dialogMessageType === 'regard'
        ? regards.filter((icon) => icon.img_big === message.payload.image)
        : null;

    const isRegardFsk12 =
      messageRegardFiltered?.length &&
      messageRegardFiltered[0]?.fsk === 'fsk12';

    const msgCssClasses = classNames('msg', {
      'has-attachment': hasAttachment || hasRemovedAttachment,
      'has-regard': dialogMessageType === 'regard',
      'msg-translated': translated,
    });

    const doNotShowFromTo =
      !showSendername &&
      layout === CHAT_LAYOUT_MULTI &&
      type === MESSAGE_TYPE_SENDER;

    let msgPart1 = '';
    let msgPart2 = '';
    let separator = null;

    if ((hasAttachment || hasPayttachment) && message.payload?.resendPayload) {
      if (message.attachment_id) {
        message.attachment = attachments.find(
          (item) => item.id === message.attachment_id
        );
        message.attachment.url = message.attachment?.file;
      } else if (message.payload.payttachment?.id) {
        message.payload.payttachment = payttachments.find(
          (item) => item.id === message.payload.payttachment.id
        );
      }
    }

    const file = message?.payload?.payttachment?.file;

    if (isSenderGameMessage) {
      msgPart1 = 'System: ';
    } else {
      if (type === MESSAGE_TYPE_SENDER && !showSendername) {
        msgPart1 += intl.formatMessage({ id: 'I' });
      }

      if (
        (type !== MESSAGE_TYPE_SENDER || showSendername) &&
        !message?.noRemoteDialog &&
        message?.from
      ) {
        msgPart1 +=
          type !== MESSAGE_TYPE_SYSTEM ? message?.from : message?.from + ':';
      }

      if (layout === CHAT_LAYOUT_SINGLE && type !== MESSAGE_TYPE_SYSTEM) {
        separator = <ReactSVG src={chevronIcon} wrapper="span" />;
      }

      if (
        (showSendername ||
          type === MESSAGE_TYPE_SENDER ||
          type === MESSAGE_TYPE_SENDER_2ALL) &&
        layout !== CHAT_LAYOUT_MULTI &&
        type !== MESSAGE_TYPE_SYSTEM
      ) {
        msgPart2 += message?.to;
      }

      if (!showSendername && type === MESSAGE_TYPE_USER) {
        msgPart2 += intl.formatMessage({ id: 'me' });
      }
    }
    const openImage = (title, fileSource) => {
      spinner(true);
      const stamp = uuid();
      openOverlay({
        stamp,
        Component: 'Document',
        props: {
          openDocumentStamp: stamp,
          title: title,
          fileSource: fileSource,
        },
      });
      spinner(false);
    };
    return (
      <div className={outerCssClasses}>
        <div className={cssClasses}>
          {layout === CHAT_LAYOUT_SINGLE && (
            <>
              <ChatTime time={message.time} />
            </>
          )}
          <div className={msgCssClasses}>
            {!doNotShowFromTo && !deleteDialog && (
              <span className={fromToCssClasses}>
                <span>{msgPart1}</span>
                {separator}
                <span>{msgPart2}</span>
              </span>
            )}
            {dialogMessageType === 'sexicon' ? (
              <span className="message special sexicon">
                {safeMode && !isSexIconFsk12 ? null : (
                  <img
                    src={
                      message?.payload?.image &&
                      process.env.REACT_APP_IMAGE_URL_1 +
                        message?.payload?.image
                    }
                    alt=""
                  />
                )}
                <SmileyParser {...this.props} text={message?.payload?.text} />
              </span>
            ) : dialogMessageType === 'voice' ? (
              <ChatMessageAudio
                {...message?.payload?.voiceData}
                sonusError={sonusError}
                type={message?.type}
              />
            ) : dialogMessageType === 'regard' ? (
              <span className="message special regard">
                <span className="intro small">
                  <span>{intl.formatMessage({ id: 'chat.regard' })}</span>
                  <span className="price">
                    {intl.formatMessage(
                      { id: 'chat.regardCoins' },
                      { coins: message?.payload?.price }
                    )}
                  </span>
                </span>
                {safeMode && !isRegardFsk12 ? null : (
                  <img
                    src={
                      message?.payload?.image &&
                      process.env.REACT_APP_IMAGE_URL_1 +
                        message?.payload?.image
                    }
                    alt=""
                  />
                )}
                <SmileyParser {...this.props} text={displayMessage} />
              </span>
            ) : dialogMessageType === 'kiss' ? (
              <span className="message special kissU">
                <span>{intl.formatMessage({ id: 'chat.kiss' })}</span>
                <ReactSVG src={kissIcon} className="icon" wrapper="span" />
              </span>
            ) : isGameMessage ? (
              <span className="message special game-message">
                <ReactSVG src={fourScoreIcon} className="icon" wrapper="span" />
                <span>
                  {intl.formatMessage(
                    {
                      id: `chat.${dialogMessageType}${
                        layout === CHAT_LAYOUT_SINGLE ? '.single' : ''
                      }`,
                    },
                    { user: message.to }
                  )}
                </span>
              </span>
            ) : dialogMessageType === 'match' ? (
              <span className="message special match">
                <span>{intl.formatMessage({ id: 'chat.match' })}</span>
                <ReactSVG src={matchIcon} className="icon" wrapper="span" />
              </span>
            ) : hasAttachment || hasRemovedAttachment ? (
              <span className="message special attachment">
                <SmileyParser
                  cssClass="text"
                  {...this.props}
                  text={displayMessage}
                />
                <span className="attachment-content-wrapper">
                  <MessageLoading loading={loading} />
                  {safeMode || !hasAttachment || loading ? null : (
                    <div
                      className="attachment-thumbnail"
                      style={{
                        backgroundImage: `url('${
                          (message.attachment.cdnPath ||
                            process.env.REACT_APP_IMAGE_URL_2) +
                          message.attachment?.url
                        }')`,
                      }}
                      onClick={(evt) => {
                        if (fileUtils.isImage(message.attachment.url)) {
                          evt.preventDefault();
                          openImage(
                            message.attachment.name,
                            (message.attachment.cdnPath ||
                              process.env.REACT_APP_IMAGE_URL_2) +
                              message.attachment.url
                          );
                        }
                      }}
                    />
                  )}

                  <span className={attachmentContentCssClass}>
                    {hasAttachment ? (
                      <>
                        <p className="small">
                          {intl.formatMessage({ id: 'MESSAGE_ATTACHMENT' })}
                        </p>
                        {!loading && (
                          <a
                            className="small"
                            href={
                              (message.attachment.cdnPath ||
                                process.env.REACT_APP_IMAGE_URL_2) +
                              message.attachment?.url
                            }
                            rel="noreferrer"
                            target="_blank"
                            onClick={(evt) => {
                              if (fileUtils.isImage(message.attachment.url)) {
                                evt.preventDefault();
                                openImage(
                                  message.attachment.name,
                                  (message.attachment.cdnPath ||
                                    process.env.REACT_APP_IMAGE_URL_2) +
                                    message.attachment.url
                                );
                              }
                            }}
                          >
                            <ReactSVG
                              src={attachmentIcon}
                              className="icon"
                              wrapper="span"
                            />
                            <span className="filename">
                              {message.attachment.name}
                            </span>
                          </a>
                        )}
                      </>
                    ) : hasRemovedAttachment ? (
                      <span className="small">
                        <ReactSVG
                          src={attachmentIcon}
                          className="icon"
                          wrapper="span"
                        />
                        {intl.formatMessage({
                          id: 'MESSAGE_ATTACHMENT_NOT_AVAILABLE',
                        })}
                      </span>
                    ) : null}
                  </span>
                </span>
                <MessageResend intl={intl} message={message} />
              </span>
            ) : hasPayttachment ? (
              <span className="message special payttachment">
                <span className="intro small">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl
                        .formatMessage({
                          id: loading
                            ? 'chat.payttachment.loading'
                            : 'chat.payttachment',
                        })
                        .replace(
                          '{price}',
                          message.payload?.payttachment?.price
                        ),
                    }}
                  />
                </span>
                <span className="payttachment-content-wrapper">
                  <MessageLoading loading={loading} />
                  {safeMode || loading ? null : message.payload?.payttachment
                      ?.type === 'movie' ? (
                    <div
                      className="payttachment-thumbnail movie"
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + file
                        )
                      }
                    >
                      <ReactSVG
                        src={movieIcon}
                        className="icon"
                        wrapper="span"
                      />
                    </div>
                  ) : (
                    <div
                      className="payttachment-thumbnail"
                      style={{
                        backgroundImage: `url('${
                          process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES +
                          thumbnail
                        }')`,
                      }}
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + file
                        )
                      }
                    />
                  )}
                  <span className="payttachment-content">
                    {!loading && (
                      <a
                        className="small"
                        href={
                          process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + file
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        <ReactSVG
                          src={payttachmentIcon}
                          className="icon"
                          wrapper="span"
                        />
                        <span className="filename">
                          {message.payload?.payttachment?.name}
                        </span>
                      </a>
                    )}

                    {message.payload?.payttachment?.bought ? (
                      <span className="message green">
                        {intl.formatMessage({ id: 'FILE_SOLD' })}
                      </span>
                    ) : (
                      <span className="message">
                        {intl.formatMessage({ id: 'FILE_NOT_SOLD' })}
                      </span>
                    )}
                  </span>
                </span>
                <MessageResend intl={intl} message={message} />
              </span>
            ) : type !== MESSAGE_TYPE_SYSTEM && !deleteDialog && !isIgnored ? (
              <>
                <div>
                  <div>
                    <SmileyParser {...this.props} text={displayMessage} />
                  </div>

                  <MessageResend intl={intl} message={message} />
                </div>
              </>
            ) : type !== MESSAGE_TYPE_SYSTEM &&
              !deleteDialog &&
              isIgnored &&
              this.props.unignoreLayout !== CHAT_LAYOUT_MULTI ? (
              <>
                <span className="unignore-div-single">
                  <p className="p-system">System:</p>
                  <p>
                    {intl.formatMessage(
                      {
                        id: 'userIgnored',
                      },
                      { nickname: this.props?.selectedUser?.name }
                    )}
                    {!livecam && intl.formatMessage({ id: 'userUnignore' })}
                  </p>

                  <div className="unignore-buttons-div">
                    <Button
                      label={'alert.unIgnore'}
                      onClick={this.unIgnoreDialog}
                      className="unignore-btn"
                      variant="success"
                    />
                  </div>
                </span>
              </>
            ) : deleteDialog ? (
              <div className="message delete-message ">
                <p>
                  {intl.formatMessage(
                    {
                      id:
                        this.props?.state === 'ignored'
                          ? 'userStateIgnored'
                          : 'userStateDeleted',
                    },
                    { nickname: this.props?.selectedUser?.name }
                  )}
                  {!livecam && intl.formatMessage({ id: 'deleteDialog' })}
                </p>
                {!livecam && (
                  <Button
                    label={'deleteDialogButton'}
                    onClick={this.deleteDialog}
                    className="delete-btn"
                    variant="success"
                  />
                )}
              </div>
            ) : isIgnored ? (
              <div className="unignore-div">
                <p>
                  {intl.formatMessage(
                    {
                      id: 'userIgnored',
                    },
                    { nickname: this.props?.selectedUser?.name }
                  )}
                  {!livecam && intl.formatMessage({ id: 'userUnignore' })}
                </p>

                <div className="unignore-buttons-div">
                  <Button
                    label={'alert.unIgnore'}
                    onClick={this.unIgnoreDialog}
                    className="unignore-btn"
                    variant="success"
                  />
                  {!livecam && (
                    <Button
                      label={'alert.delete'}
                      onClick={this.deleteDialog}
                      className="delete-btn"
                      variant="success"
                    />
                  )}
                </div>
              </div>
            ) : (
              <span className="message">{displayMessage}</span>
            )}
            {translated && (
              <span className="translated">
                ({intl.formatMessage({ id: 'chat.translated' })})
              </span>
            )}
          </div>
        </div>
        {layout === CHAT_LAYOUT_MULTI && (
          <div>
            {(message.payload?.ibid || message.payload?.type === 'match') && (
              <span className="icebreaker-label">
                {intl.formatMessage({ id: 'chat.icebreaker' })} |{' '}
              </span>
            )}
            {message.payload?.animation && (
              <span className="icebreaker-label">
                {intl.formatMessage({ id: 'chat.datingAnimation' })} |{' '}
              </span>
            )}
            <ChatTime time={message.time} />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(ChatMessage);
