import postHydrate from '../../utils/persist/postHydrate';
import { REHYDRATION_COMPLETE } from '../actions';
export default () => {
  return (dispatch, getState) => {
    dispatch({
      type: REHYDRATION_COMPLETE,
    });
    postHydrate(getState, dispatch);
  };
};
