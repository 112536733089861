import { connect } from 'react-redux';

import { changeRoomTypes, toggleToycontrolMenu } from '../../../../actions';
import messagesFilter from '../../../../actions/camUser/messagesFilter';
import {
  getCookieHintData,
  isLivecamCreator,
  roomType as roomTypeUtils,
  user as userUtils,
} from '../../../../utils';
import SubMenu from './SubMenu';

const mapStateToProps = (state) => {
  const {
    sender: {
      roomTypes,
      askExclusiveRoomtypeConfirmed,
      askExclusiveRoomtypeAlert,
      toycontrol,
    },
    camUser: { camUsers },
    dialog,
    camUserFilter,
    view: { isMobile },
    camUserFilter: { messagesFilter: filter },
  } = state;
  const activityCount = Object.keys(camUserFilter.activities).reduce(function (
    previous,
    key
  ) {
    return previous + camUserFilter.activities[key].length;
  },
  0);
  return {
    ...state.streaming,
    exclusive: roomTypeUtils.hasActivatedShowTypeExclusive(roomTypes),
    voyeur: roomTypeUtils.hasActivatedShowTypeVoyeur(roomTypes),
    normal: roomTypeUtils.hasActivatedShowTypeNormal(roomTypes),
    hasUser: userUtils.visibleUser([...camUsers, ...dialog.dialogs]).length > 0,
    exclusiveEnabled: roomTypeUtils.isExclusiveEnabled(state.sender),
    voyeurEnabled: roomTypeUtils.hasVoyeurRoomType(state.sender?.roomTypes),
    askExclusiveRoomtypeConfirmed,
    askExclusiveRoomtypeAlert,
    dialog,
    camUserFilter,
    activityCount:
      activityCount === 0 ? '' : activityCount <= 9 ? activityCount : '9+',
    isMobile,
    filter,
    toycontrolActive: !!toycontrol?.active,
    toycontrolMenuOpen: state.settings.toycontrolMenuOpen,
    toys: toycontrol?.connected?.toys || {},
    toycontrolEnabled:
      state.user?.account?.toy_control === 'enabled' &&
      state.user?.account?.livecam === 'true',
    activeToy: state.sender?.toycontrol?.activeToy,
    isLivecam: isLivecamCreator(state.user),
    isIcebreakerEnabled: userUtils.isIcebreakerEnabled(state.user),
    isIcebreakerAllowed: userUtils.isIcebreakerAllowed(state.user),
    isGameBlocked: userUtils.gamesBlocked(state),
    showCookieHint:
      state.browser.thirdPartyCookiesTested &&
      !state.browser.thirdPartyCookiesAllowed,
    showLivecamErrorHint: state.sender.tokenLoginError,
    cookieHintData: getCookieHintData(state.intl.locale),
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeRoomTypes: (status) => {
    console.log('RoomTypes changed');
    dispatch(changeRoomTypes(status));
  },
  messagesFilter: (filter) => dispatch(messagesFilter(filter)),
  toggleToycontrolMenu: () => dispatch(toggleToycontrolMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubMenu);
