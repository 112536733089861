export default {
  RELATIONSHIPS_HEADLINE: `Beziehungen – Freunde`,
  RELATIONSHIPS_CONTENT: `Hier findest Du eine Übersicht all Deiner Freundschaftsanfragen, bestehenden Freundschaften und abgelehnten Anfragen. Du kannst diese unter dem Menüpunkt {link} einsehen und bearbeiten. Wir empfehlen Dir, stets zuerst Kontakt aufzunehmen, bevor Du eine Anfrage annimmst, ohne vorher mit dem Kunden Kontakt gehabt zu haben.`,
  RELATIONSHIPS_CONTENT_LINK: `Beziehungen`,
  RELATIONSHIPS_CONTENT_1: `Wenn Du online bist, werden neue Freundschaftsanfragen direkt in Deinem {link} unter “Aktivitäten” angezeigt. Dort kannst Du schnell reagieren, den Kunden anschreiben und neue Kontakte knüpfen.`,
  RELATIONSHIPS_CONTENT_1_LINK: `Multi Messenger`,
  RELATIONSHIPS_CONTENT_2: `Aktive Chats mit Deinen Freunden sind im Multi Messenger unter dem Filter “Freunde” zu finden. `,
  RELATIONSHIPS_CONTENT_3: `Ältere Chats werden automatisch aus Deinem Multi Messenger entfernt, damit Du nur die aktuellen Chats mit Deinen Kontakten siehst. Möchtest Du einen älteren Chat mit einem Deiner Freunde wieder aufleben lassen, findest Du diesen User unter dem Menüpunkt {link} und kannst ihn jederzeit anschreiben und dadurch wieder aktiv in Deinen Multi Messenger holen.`,
  RELATIONSHIPS_CONTENT_4: `Durch einen Klick auf das Menü (drei Punkte auf seinem Profilbild) hast Du die Option, ihm eine Nachricht zu senden, die Freundschaft zu bestätigen oder zu beenden. Auf diese Weise behältst Du stets die Kontrolle über Deine Kontakte und bleibst jederzeit mit ihnen in Verbindung.`,
  RELATIONSHIPS_CONTENT_5: `Um Deine Kontakte effektiv zu managen, sind diese in folgende Kategorien unterteilt:`,
  RELATIONSHIPS_CONTENT_6: `
  <ul>
    <li>Bestätigte Freunde</li>
    <li>Anfragen</li>
    <li>Meine Anfragen</li>
    <li>Abgelehnt</li>
  </ul>`,
};
