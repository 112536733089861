import { connect } from 'react-redux';

import {
  camUser,
  changeLayoutChat,
  fetchAllMessages,
  incrementPageChat,
  openOverlay,
  removeDialog,
  resetNewMessages,
  sendChatMessage,
  sonusError,
  spinner,
  unignoreIdUser,
} from '../../../actions';
import {
  ACTIVITY_TYPE_PROFILE_VISIT,
  CHAT_LAYOUT_MULTI,
  CHAT_LAYOUT_SINGLE,
  MESSAGE_TYPE_SYSTEM,
} from '../../../constants';
import { user as userUtils } from '../../../utils';
import Chat from './Chat';

const mapStateToProps = (state) => {
  const {
    chat,
    sender: { runToy },
    settings: { chatlayout, usercolor, showSendername },
    smiley: { items: smileys, smileyDictionary, globalPattern },
    camUser: { camUsers },
    dialog: { dialogs },
    view: { isMobile, cam: camOpen, usergame },
    alert,
    preferences: {
      fontsize: { selected: fontsize },
    },
    user: {
      account: { nickname },
    },
    payttachments: { files: payttachments },
    attachments: { files: attachments },
  } = state;

  const safeMode = state.preferences.safeMode;
  const sexIcons = state.chat?.fskLevels?.sexIcons;
  const regards = state.chat?.fskLevels?.regards;

  const activeCamUser =
    camUsers.find((u) => u.userId === chat.selectedUser) || {};
  let isMessageUser = chat.isMessageUser;
  if (
    userUtils.isDialogUser(activeCamUser?.type) &&
    chatlayout === CHAT_LAYOUT_MULTI
  ) {
    isMessageUser = true;
  }

  const layout = isMobile
    ? CHAT_LAYOUT_MULTI
    : (chat.selectedUser && !chat.isMessageUser && chatlayout) ||
      CHAT_LAYOUT_MULTI;

  const user = { ...(userUtils.activeUser([...camUsers, ...dialogs]) || {}) };

  const dialogmessages = [...chat.dialogmessages];
  const messages = [...chat.messages];
  const isDialog = user.type === 'dialog.fetched';
  if (!isDialog && !user?.partner && !!userUtils.hasPartner(user)) {
    user.partner = userUtils.getPartner(user);
  }
  const hasNoDialogGameInvite = !dialogmessages.find((d) => d.gameInvitation);
  const hasNoCamUserGameInvite = !messages.find((d) => {
    if (layout === CHAT_LAYOUT_SINGLE) {
      return d.gameInvitation && d.userId === user.partner;
    }
    return d.gameInvitation;
  });
  const hasGameInvite = user?.flags?.includes('gameInvitation');

  if (
    hasGameInvite &&
    ((isDialog && hasNoDialogGameInvite) ||
      (!isDialog && user?.partner && hasNoCamUserGameInvite))
  ) {
    (isDialog || layout === CHAT_LAYOUT_MULTI ? dialogmessages : messages).push(
      {
        gameInvitation: true,
        userId: user.partner,
      }
    );
  }

  const isIgnored = state?.ignored?.ignoredIds?.includes(user?.partner);
  if (
    !userUtils.isVisibleLivecamUser(user) &&
    user?.flags?.includes('noRemoteDialog') &&
    !dialogmessages.find((d) => d.noRemoteDialog)
  ) {
    const type = userUtils.getHighestActivityState(state, user);
    if (type) {
      const id =
        type === ACTIVITY_TYPE_PROFILE_VISIT &&
        state.user?.account?.icebreaker !== 'true'
          ? 'ACTIVITY_TYPE_PROFILE_VISIT_NON_ICEBREAKER'
          : type;
      dialogmessages.push({
        id,
        translate: true,
        noRemoteDialog: true,
        userId: user.userId,
        type: MESSAGE_TYPE_SYSTEM,
        time: Math.floor(Date.now() / 1000),
      });
    }
  }

  return {
    ...chat,
    dialogmessages,
    messages,
    layout,
    fontsize,
    usercolor,
    smileys,
    showSendername,
    smileyDictionary,
    globalPattern,
    isMobile,
    usergame,
    camOpen,
    isMessageUser,
    alert,
    user,
    nickname,
    payttachments,
    attachments,
    safeMode,
    runToy,
    sexIcons,
    regards,
    camUsers,
    livecam: userUtils.isVisibleLivecamUser(user),
    isIgnored,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeLayoutChat: (value) => dispatch(changeLayoutChat(value)),
  navLeft: () => dispatch(camUser.selectlast()),
  navRight: () => dispatch(camUser.selectnext()),
  openOverlay: (payload) => dispatch(openOverlay(payload)),
  spinner: (payload) => dispatch(spinner(payload)),
  sonusError: (payload) => dispatch(sonusError(payload)),
  fetchAllMessages: (partner) => dispatch(fetchAllMessages(partner)),
  resetNewMessages: () => dispatch(resetNewMessages()),
  incrementPageChat: () => dispatch(incrementPageChat()),
  clearDialog: (payload) => dispatch(removeDialog(payload)),
  selectFirstDialog: () => dispatch(camUser.selectfirst()),
  unignoreIdUser: (id) => dispatch(unignoreIdUser(id)),
  sendChatMessage: (payload) => dispatch(sendChatMessage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
