import api from './api';

const portals = {
  id_2: { id: 2, whitelabelShort: 'AC', whitelabelLong: 'AmateurCommunity' },
  id_10: { id: 10, whitelabelShort: 'MGD', whitelabelLong: 'Mega Dating' },
  id_11: {
    id: 11,
    whitelabelShort: 'SPC',
    whitelabelLong: 'Sexpartner Community',
  },
  id_13: { id: 13, whitelabelShort: 'LCP', whitelabelLong: 'Livecampartner' },
  id_14: { id: 14, whitelabelShort: 'FUN', whitelabelLong: 'FUN Community' },
  id_15: { id: 15, whitelabelShort: 'ERO', whitelabelLong: 'Erocommunity' },
  id_16: { id: 16, whitelabelShort: 'MGC', whitelabelLong: 'Mega Community' },
  id_17: { id: 17, whitelabelShort: 'AMX', whitelabelLong: 'AmateurX' },
  id_18: { id: 18, whitelabelShort: 'MCA', whitelabelLong: 'mCams' },
  id_19: {
    id: 19,
    whitelabelShort: 'STC',
    whitelabelLong: 'Sextreffen Community',
  },
  id_20: { id: 20, whitelabelShort: 'SXC', whitelabelLong: 'Sexx Community' },
  id_21: {
    id: 21,
    whitelabelShort: 'SKT',
    whitelabelLong: 'Sexkontakte Treffpunkt',
  },
  id_22: { id: 22, whitelabelShort: 'SDC', whitelabelLong: 'Sexdateclub' },
  id_23: {
    id: 23,
    whitelabelShort: 'DST',
    whitelabelLong: 'DeinSextreffpunkt',
  },
  id_24: { id: 24, whitelabelShort: 'SDO', whitelabelLong: 'SexDatesOnly' },
  id_25: { id: 25, whitelabelShort: 'AMF', whitelabelLong: 'AmateurFans' },
  id_26: { id: 26, whitelabelShort: 'AFO', whitelabelLong: 'AmateurFansOnly' },
  id_28: { id: 28, whitelabelShort: 'FHB', whitelabelLong: 'Fikhub' },
};

const extractPortal = (id) => portals[`id_${id}`];
const addPortal = (id, portal) => (portals[`id_${id}`] = portal);

export async function getPortal(id) {
  if (!id) {
    return { id, whitelabelShort: 'DATE', whitelabelLong: 'Dating' };
  }
  const portalFromArray = extractPortal(id);
  if (portalFromArray) return portalFromArray;
  try {
    const response = await api.get(`/portal/${id}`);
    const portal = { ...response.data, id };
    addPortal(id, portal);
    return portal;
  } catch {
    console.log('error, portals cannot be fetched');
  }
}

export async function addPortals(items) {
  if (items.length > 0) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const portalId = item.id.split('-')[0];
      if (!item?.portals) {
        const portals = await getPortal(portalId);
        items[i].portals = portals;
      }
    }
  }
  return items;
}

