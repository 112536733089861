import {
  ALERT_CANCEL,
  ALERT_OK,
  DIARY_ENTRY_DELETE,
  GALLERY_ACTIVE_UPDATE,
} from '../actions/actions';

const initialState = {
  askDeleteDiaryEntryConfirmed: undefined,
  askDeleteDiaryEntryConfirmedAlert: undefined,
  askDeleteDiaryEntryId: undefined,
  list: [],
  active: null,
};

function galleriesReducer(state = initialState, action) {
  switch (action.type) {
    case GALLERY_ACTIVE_UPDATE:
      if (action.payload.type === 'weblog' && action.payload.delete === true) {
        if (!state.list.find((g) => g.id === action.payload.id)) return state;

        return {
          ...state,
          list: state.list.filter((g) => g.id !== action.payload.id),
          active:
            state.active?.id === action.payload.id
              ? initialState.active
              : state.active,
        };
      }
      const newState = {
        ...state,
        list: state.list
          .map((g) => {
            if (
              action.payload.type === 'weblog' &&
              g.type === 'weblog' &&
              g.id === action.payload.id
            ) {
              return action.payload;
            }
            return g;
          })
          .filter((g) => {
            if (action.payload.id !== 'unsaved' && g.id === 'unsaved') {
              return false;
            }
            return true;
          }),
        active: action.payload,
      };
      if (
        action.payload.type === 'weblog' &&
        !newState.list.find((g) => g.id === action.payload.id)
      ) {
        newState.list.push(action.payload);
      }
      return newState;
    case DIARY_ENTRY_DELETE:
      if (action.meta && action.meta.start) {
        return {
          ...state,
          askDeleteDiaryEntryConfirmedAlert: action.meta.stamp,
          askDeleteDiaryEntryId: action.payload.id,
        };
      } else {
        return {
          ...state,
          askDeleteDiaryEntryConfirmed: undefined,
          askDeleteDiaryEntryConfirmedAlert: undefined,
          askDeleteDiaryEntryId: undefined,
        };
      }
    case ALERT_CANCEL: {
      if (action.payload.stamp === state.askDeleteDiaryEntryConfirmedAlert) {
        return {
          ...state,
          askDeleteDiaryEntryConfirmed: undefined,
          askDeleteDiaryEntryConfirmedAlert: undefined,
          askDeleteDiaryEntryId: undefined,
        };
      }
      return state;
    }
    case ALERT_OK: {
      if (action.payload.stamp === state.askDeleteDiaryEntryConfirmedAlert) {
        return {
          ...state,
          askDeleteDiaryEntryConfirmed: true,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

export default galleriesReducer;

