import api from '../../utils/api';
import { FONTSIZE_CHANGE } from '../actions';
export default (payload) => async (dispatch, getState) => {
  if (isNaN(payload)) return;

  const { preferences } = getState();

  const data = {
    ...preferences,
    fontsize: {
      possible: preferences.fontsize.possible,
      selected: parseInt(payload, 10),
    },
  };

  try {
    await api.put('/user/preferences', { data });

    dispatch({
      type: FONTSIZE_CHANGE,
      payload: data.fontsize.selected,
    });
  } catch (error) {
    console.log({ error });
  }
};
