import { user as userUtils } from '../../utils';
import api from '../../utils/api';
import { getPortal } from '../../utils/portals';
import {
  firstDialogDisplayedLog,
  sendDialogloadLog,
  camUser as userActions,
} from '../';
import { ADD_DIALOG } from '../actions';
import { logErrorDialog, queue } from './';

const hasEmptyQueue = (getState) =>
  getState().dialog.processQueue.length === 0 &&
  getState().dialog.queue.length === 0;

export default (dialogPayload) => async (dispatch, getState) => {
  if (!dialogPayload?.partner || !getState().user?.account?.id) {
    return;
  }
  if (getState().ignored.ignoredIds.includes(dialogPayload.partner)) {
    return;
  }
  const dialogProductIdArray = dialogPayload.partner.split('@');
  const productId =
    dialogProductIdArray?.length === 2
      ? parseInt(dialogProductIdArray[0])
      : null;
  const id = dialogPayload.partner;
  let profileMinimal = dialogPayload.profile;
  let portals = {};
  if (!getState().user.isLoggedIn) {
    return dispatch({
      type: ADD_DIALOG,
      error: true,
      payload: dialogPayload,
    });
  }
  const {
    user: {
      account: { id: creatorId },
    },
  } = getState();
  try {
    const response = await api.get(`/profile/${id}/minimal`, {
      skipAttachingAuthToken: true,
    });
    if (!response?.data?.info?.nickname) {
      return dispatch({
        type: ADD_DIALOG,
        error: true,
        payload: dialogPayload,
      });
    }
    const nickname = dialogPayload.flags.includes('is_support')
      ? userUtils.getAdminName(response.data.info.nickname)
      : response.data.info.nickname;
    profileMinimal.info.nickname = nickname;
    if (productId) {
      try {
        portals = await getPortal(productId);
        profileMinimal.info.whitelabelLong = portals.whitelabelLong;
        profileMinimal.info.whitelabelShort = portals.whitelabelShort;
      } catch (err) {
        console.log('portal not found');
      }
    }
    const { user } = getState();
    if (!user?.isLoggedIn || user?.account?.id !== creatorId) {
      return;
    }

    const payload = {
      partner: dialogPayload.partner,
      profile: profileMinimal,
      name: profileMinimal?.info?.nickname,
      visible: true,
      portals,
    };

    dispatch({
      type: ADD_DIALOG,
      payload,
    });
    if (hasEmptyQueue(getState)) {
      dispatch(logErrorDialog());
    }
    const {
      dialog: { dialogs: dialogList, processQueue, queue },
      camUser: { camUsers: camUsersList },
    } = getState();
    const allDialogs = [...dialogList, ...processQueue, ...queue];
    if (
      (payload?.done === true || hasEmptyQueue(getState)) &&
      !userUtils.hasActiveUser(allDialogs)
    ) {
      const firstVisibleUser = userUtils.firstVisibleUser([
        ...dialogList,
        ...camUsersList,
      ]);

      if (firstVisibleUser) {
        dispatch(userActions.select(firstVisibleUser.userId, false, true));
      }
    }
    if (
      getState().dialog?.dialogs?.length === 1 &&
      !getState().log.firstDialogLogged
    ) {
      dispatch(firstDialogDisplayedLog());
    }
    if (hasEmptyQueue(getState) && !getState().log.dialogloadLogged) {
      dispatch(sendDialogloadLog());
    }
  } catch (error) {
    dispatch({
      type: ADD_DIALOG,
      error: true,
      payload: { ...dialogPayload, error },
    });
    const errorName = error?.message;
    if (
      ['timeout of 0ms exceeded', 'network error'].includes(
        errorName.toLowerCase()
      ) &&
      (!dialogPayload.errorCount || dialogPayload.errorCount < 3)
    ) {
      dispatch(
        queue.addElement({
          ...dialogPayload,
          error: undefined,
          errorCount: !dialogPayload.errorCount
            ? 1
            : dialogPayload.errorCount + 1,
        })
      );
    }
    if (hasEmptyQueue(getState)) {
      dispatch(logErrorDialog());
    }

    console.log({ getProfileError: error });
  }
};
