import './PublicDetails.scss';

import useAxios from 'axios-hooks';
import { Field, useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useButtonChange from '../../../../hooks/useButtonChange';
import { spinnerRequest } from '../../../../shared/spinnerRequest';
import ButtonFieldWithErrors from '../../../Forms/ButtonFieldWithErrors';
import Checkbox from '../../../Forms/Checkbox/Checkbox';
import CountriesSelect from '../../../Forms/CountriesSelect';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import Input from '../../../Forms/Input/Input';
import MultipleFields from '../../../Forms/MultipleFields';
import RequestSelect from '../../../Forms/RequestSelect';
import Select from '../../../Forms/Select/Select';
import { getGenderOptions } from '../../Onboarding/Steps/ProfileDetails/ProfileDetails.functions';
import {
  experienceLabels,
  generateDropdownOptions,
  lookingForLabels,
  preferenceLabels,
} from './PublicDetails.functions';

function PublicDetails({
  update = () => {},
  languages = [],
  spinner = () => {},
  user,
  doNotShow,
  doNotShowState,
}) {
  const intl = useIntl();
  const [{ data: attributesData }] = useAxios({
    url: '/data/attributes',
  });

  const [, publicDataRequest] = useAxios(
    {
      url: '/user/public',
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const genderOptions = getGenderOptions(intl);

  const handleCheckboxEvent = (event, name) => {
    let newValues;
    if (values[name].includes(event.target.value)) {
      const index = values[name].findIndex(
        (item) => item === event.target.value
      );
      newValues = [
        ...values[name].slice(0, index),
        ...values[name].slice(index + 1),
      ];
      setFieldValue([name], newValues);
    } else {
      newValues = values[name].concat([event.target.value]);
      setFieldValue([name], newValues);
    }

    spinnerRequest({
      request: publicDataRequest,
      spinner,
      payload: {
        data: { [name]: newValues },
      },
    }).then((response) => {
      update({
        public: response.data,
      });
    });
  };
  const baseChangeOpts = {
    updateField: 'public',
    requestUrl: '/user/public',
    update,
    spinner,
  };
  const updateOptions = {
    zip: useButtonChange({
      ...baseChangeOpts,
      name: 'zip',
    }),
    height: useButtonChange({
      ...baseChangeOpts,
      name: 'height',
    }),
    weight: useButtonChange({
      ...baseChangeOpts,
      name: 'weight',
    }),
    country_code: useButtonChange({
      ...baseChangeOpts,
      name: 'country_code',
    }),
    ethnicity: useButtonChange({
      ...baseChangeOpts,
      name: 'ethnicity',
    }),
    marital: useButtonChange({
      ...baseChangeOpts,
      name: 'marital',
    }),
    tattoos: useButtonChange({
      ...baseChangeOpts,
      name: 'tattoos',
    }),
    piercings: useButtonChange({
      ...baseChangeOpts,
      name: 'piercings',
    }),
    glasses: useButtonChange({
      ...baseChangeOpts,
      name: 'glasses',
    }),
    eye_color: useButtonChange({
      ...baseChangeOpts,
      name: 'eye_color',
    }),
    hair_color: useButtonChange({
      ...baseChangeOpts,
      name: 'hair_color',
    }),
    hair_length: useButtonChange({
      ...baseChangeOpts,
      name: 'hair_length',
    }),
    body: useButtonChange({
      ...baseChangeOpts,
      name: 'body',
    }),
    body_hair: useButtonChange({
      ...baseChangeOpts,
      name: 'body_hair',
    }),
    pubic_hair: useButtonChange({
      ...baseChangeOpts,
      name: 'pubic_hair',
    }),
    smoking: useButtonChange({
      ...baseChangeOpts,
      name: 'smoking',
    }),
    bra_size: useButtonChange({
      ...baseChangeOpts,
      name: 'bra_size',
    }),
  };
  const doNotShowInHandler = useCallback(
    (value) => {
      let payload = {};
      if (value) {
        payload.country_code = value;
        payload.do_not_show_in = [];
      } else {
        payload.do_not_show_in = user.public.do_not_show_in.length
          ? []
          : [user.public.country_code];
      }

      spinnerRequest({
        request: publicDataRequest,
        spinner,
        payload: {
          data: payload,
        },
      }).then((response) => {
        update({
          public: { ...response.data },
        });
        if (payload.country_code) {
          setFieldValue('country_code', payload.country_code);
        }
      });
    },
    [
      publicDataRequest,
      spinner,
      setFieldValue,
      update,
      user.public.country_code,
      user.public.do_not_show_in,
    ]
  );

  useEffect(() => {
    if (doNotShowState) {
      doNotShowInHandler();
      doNotShow(true);
    }
  }, [doNotShow, doNotShowState, doNotShowInHandler]);

  const handleDoNotShowInFilter = () => {
    if (!!user.public.do_not_show_in.length) {
      doNotShowInHandler();
    } else {
      doNotShow();
    }
  };

  const countrySelectHandler = (value) => {
    if (value) {
      doNotShowInHandler(value);
    }
  };

  return (
    <div className="public-details-wrapper">
      <h2 className="headline">
        <FormattedMessage id="PUBLIC_DETAILS_HEADLINE_1" />
      </h2>
      <p>
        <FormattedMessage id="PUBLIC_DETAILS_TEXT_1" />
      </p>

      <div className="data-columns">
        <div className="data-left">
          <div className="group-container">
            <MultipleFields
              fields={[{ name: 'zip', id: 'zip', label: 'FIELD_ZIP' }]}
              commonLabel="PUBLIC_DETAILS_FIELD_ZIP_AREA"
              commonId={'zip'}
              updateOptions={updateOptions['zip']}
              invalidDataLabel="INVALID_ZIP"
            />
            <FieldWithErrors
              as={CountriesSelect}
              asSelect={RequestSelect}
              name="country_code"
              label="FIELD_COUNTRY"
              updateOptions={updateOptions['country_code']}
              intlTranslate={false}
              outsideChangeHandler={countrySelectHandler}
            />
            <div className="form-group">
              <span className="column" />
              <Checkbox
                name="do_not_show_in"
                label="DO_NOT_SHOW_IN_FILTER"
                value={!!user.public.do_not_show_in.length}
                checked={!!user.public.do_not_show_in.length}
                onChange={handleDoNotShowInFilter}
              />
            </div>
          </div>
        </div>
        <div className="data-right" />
      </div>

      <div className="data-columns">
        <div className="data-left">
          <h2 className="headline">
            <FormattedMessage id="PUBLIC_DETAILS_HEADLINE_2" />
          </h2>
          <FieldWithErrors
            as={Select}
            name="native_language"
            options={languages}
            label="PUBLIC_DETAILS_FIELD_NATIVE_LANGUAGE"
            disabled={true}
            intlTranslate={false}
          />
        </div>
        <div className="data-right" />
      </div>

      <div className="data-full">
        <div className="group-container">
          <div className="form-group more-languages">
            <label>
              <FormattedMessage id="FIELDS_LANGUAGES" />
            </label>
            <p className="column2">
              <FormattedMessage id="MORE_LANGUAGES_DESCRIPTION" />
            </p>
          </div>
          <div className="form-group">
            <div className="column" />
            <div className="column2 additional-languages-list">
              {languages.map((l) => (
                <Field
                  key={l.value}
                  as={Checkbox}
                  id={l.value}
                  value={l.value}
                  htmlFor={l.value}
                  label={l.label}
                  checked={values.additional_languages.includes(l.value)}
                  name="additional_languages"
                  intlTranslate={false}
                  disabled={
                    values.native_language === l.value ||
                    (values.additional_languages.length >= 3 &&
                      !values.additional_languages.includes(l.value))
                  }
                  onChange={(event) => {
                    setFieldTouched('additional_languages', true);
                    handleCheckboxEvent(event, 'additional_languages');
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <h2 className="headline">
        <FormattedMessage id="PUBLIC_DETAILS_HEADLINE_3" />
      </h2>
      <div className="data-columns">
        <div className="data-left">
          <div className="group-container">
            <FieldWithErrors
              as={Input}
              name="age"
              label="PUBLIC_DETAILS_FIELD_AGE"
              disabled={true}
            />
            <FieldWithErrors
              as={Select}
              name="gender"
              options={genderOptions}
              label="FIELD_GENDER"
              disabled={true}
              intlTranslate={false}
            />
            <ButtonFieldWithErrors
              as={Input}
              name="height"
              type="number"
              label="PUBLIC_DETAILS_FIELD_HEIGHT"
              updateOptions={updateOptions['height']}
              additionalLabel={'cm'}
              invalidDataLabel="INVALID_HEIGHT"
              small={true}
            />
            <ButtonFieldWithErrors
              as={Input}
              name="weight"
              type="number"
              label="PUBLIC_DETAILS_FIELD_WEIGHT"
              updateOptions={updateOptions['weight']}
              additionalLabel={'kg'}
              invalidDataLabel="INVALID_WEIGHT"
              small={true}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="ethnicity"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.ethnicity)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_TYPE"
              updateOptions={updateOptions['ethnicity']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="marital"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.marital)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_MARITAL_STATUS"
              updateOptions={updateOptions['marital']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="tattoos"
              options={
                attributesData
                  ? generateDropdownOptions(
                      attributesData.tattoos,
                      'tattoo_and_piercings'
                    )
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_TATTOOS"
              updateOptions={updateOptions['tattoos']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="piercings"
              options={
                attributesData
                  ? generateDropdownOptions(
                      attributesData.piercings,
                      'tattoo_and_piercings'
                    )
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_PIERCINGS"
              updateOptions={updateOptions['piercings']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="glasses"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.glasses)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_GLASSES"
              updateOptions={updateOptions['glasses']}
            />
          </div>
        </div>
        <div className="data-right">
          <div className="group-container">
            <FieldWithErrors
              as={RequestSelect}
              name="eye_color"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.eye_color)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_EYE_COLOR"
              updateOptions={updateOptions['eye_color']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="hair_color"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.hair_color)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_HAIR_COLOR"
              updateOptions={updateOptions['hair_color']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="hair_length"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.hair_length)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_HAIR_LENGTH"
              updateOptions={updateOptions['hair_length']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="body"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.body, 'figure')
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_FIGURE"
              updateOptions={updateOptions['body']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="body_hair"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.body_hair)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_BODY_HAIR"
              updateOptions={updateOptions['body_hair']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="pubic_hair"
              options={
                attributesData
                  ? generateDropdownOptions(
                      attributesData.pubic_hair,
                      'pubic_hair'
                    )
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_PUBIC_HAIR"
              updateOptions={updateOptions['pubic_hair']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="bra_size"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.bra_size)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_CUP_SIZE"
              updateOptions={updateOptions['bra_size']}
            />
            <FieldWithErrors
              as={RequestSelect}
              name="smoking"
              options={
                attributesData
                  ? generateDropdownOptions(attributesData.smoking)
                  : []
              }
              label="PUBLIC_DETAILS_FIELD_SMOKING"
              updateOptions={updateOptions['smoking']}
            />
          </div>
        </div>
      </div>

      <h2 className="headline">
        <FormattedMessage id="PUBLIC_DETAILS_HEADLINE_4" />
      </h2>
      <div className="data-full">
        <div className="form-group looking-for-wrapper">
          <label>
            <FormattedMessage id="PUBLIC_DETAILS_LOOKING_FOR_LABEL" />
          </label>
          <div className="column2">
            {lookingForLabels.map((l) => (
              <Field
                key={l.value}
                as={Checkbox}
                id={l.value}
                value={l.value}
                htmlFor={l.value}
                label={l.label}
                checked={values.looking_for.includes(l.value)}
                name="looking_for"
                onChange={(event) => {
                  setFieldTouched('looking_for', true);
                  handleCheckboxEvent(event, 'looking_for');
                }}
              />
            ))}
          </div>
        </div>

        <div className="form-group experience-wrapper">
          <label>
            <FormattedMessage id="PUBLIC_DETAILS_EXPERIENCE_LABEL" />
          </label>
          <div className="column2">
            {experienceLabels.map((l) => (
              <Field
                key={l.value}
                as={Checkbox}
                id={l.value}
                value={l.value}
                htmlFor={l.value}
                label={l.label}
                checked={values.experience.includes(l.value)}
                name="experience"
                onChange={(event) => {
                  setFieldTouched('experience', true);
                  handleCheckboxEvent(event, 'experience');
                }}
              />
            ))}
          </div>
        </div>

        <div className="form-group preference-wrapper">
          <label>
            <FormattedMessage id="PUBLIC_DETAILS_PREFERENCE_LABEL" />
          </label>
          <div className="column2">
            {preferenceLabels.map((l) => (
              <Field
                key={l.value}
                as={Checkbox}
                id={l.value}
                value={l.value}
                htmlFor={l.value}
                label={l.label}
                checked={values.preference.includes(l.value)}
                name="preference"
                onChange={(event) => {
                  setFieldTouched('preference', true);
                  handleCheckboxEvent(event, 'preference');
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublicDetails;

