export default {
  PROFILE_IMAGE_HEADLINE: `What should a profile picture look like?`,
  PROFILE_IMAGE_CONTENT_1: `The top rule is: take your time to make your "perfect" profile picture. A "snapshot" or a quick selfie will usually not meet your own expectations.`,
  PROFILE_IMAGE_CONTENT_2: `A profile picture should show you the way you feel comfortable. You don't have to do  anything just because of your customers. You can decide for yourself what and how much you want to show. If you don't dare to show your face in your profile picture, that's perfectly fine. You can show your face in the picture cropped, covered with your hair or hidden with a mask so that you are not immediately recognizable.`,
  PROFILE_IMAGE_CONTENT_STEP_1: `Clean up`,
  PROFILE_IMAGE_CONTENT_STEP_1_CONTENT: `When taking photos, make sure that the area that will be in the picture is tidy. Hide pictures, photographs, toys of your offspring or the like, otherwise you will quickly reveal more than you might want.`,
  PROFILE_IMAGE_CONTENT_STEP_2: `Lighting`,
  PROFILE_IMAGE_CONTENT_STEP_2_CONTENT: `A window or too bright a light in the background darkens your picture. Light from the front makes contours appear flatter and is usually not very flattering. Lateral light is ideal, as it emphasizes your contours and gives your picture depth. If you create your pictures alone, use a tripod.`,
  PROFILE_IMAGE_CONTENT_STEP_2_2_CONTENT: `A ring light with a tripod is even better. This offers the advantage that you are well lit and your pictures do not blur. In addition to good illumination, it flatters your eyes by creating a circle of light in your pupil and making your eyes shine more brightly. A ring light usually produces enough light to ensure a good quality of your image.`,
  PROFILE_IMAGE_CONTENT_STEP_3: `Sharpness`,
  PROFILE_IMAGE_CONTENT_STEP_3_CONTENT: `Always pay attention to the autofocus of the camera or cell phone. It is ideal to set the autofocus to a fixed position beforehand, preferably your face or your eyes directly, so that the camera does not accidentally focus on another area in the picture that is closer to the lens.`,
  PROFILE_IMAGE_CONTENT_STEP_4: `Format`,
  PROFILE_IMAGE_CONTENT_STEP_4_CONTENT: `If possible, always create your profile picture in landscape format and position yourself in the middle of the picture.`,
  PROFILE_IMAGE_HEADLINE_2: `Info on the correct age rating`,
  PROFILE_IMAGE_CONTENT_3: `For presentation in different areas and media, all images must be assigned to a specific age rating.`,
  PROFILE_IMAGE_CONTENT_4: `There are the following distinctions:`,
  PROFILE_IMAGE_CONTENT_5: `<span class="bold">Non nude</span><br />
This is "a picture from everyday life". You should be fully clothed and the photo should not be an erotic motif. You should be recognizable in the picture.`,
  PROFILE_IMAGE_CONTENT_6: `<span class="bold">Softcore</span><br />
Here you can show a little more skin. Bikini or lingerie, the little black dress with a cleavage or topless is allowed. But no primary sexual characteristics should be visible.`,
  PROFILE_IMAGE_CONTENT_7: `<span class="bold">Hardcore</span><br />
This picture is not a must, and you decide for yourself whether you want to publish a corresponding photo of yourself. If you want to upload one, then the private parts should be visible, but no pure close-ups or sexual acts.`,
  PROFILE_IMAGE_CONTENT_TIPP: `<span class="bold">Tip:</span> Under the pictures you can choose which of your profile pictures should be displayed in your profile by default.`,
};
