import { FormattedMessage } from 'react-intl';

import Spinner from '../../../Spinner/Spinner';
import UserProfileTurnoverItem from './UserProfileTurnoverItem';

function UserProfileTurnover({
  total,
  cams,
  livecam_tips,
  messages,
  payttachments,
  regards,
  sexicons,
  videos,
  pictures,
  games,
  largeLoaded,
  voice_messages,
}) {
  if (!largeLoaded) {
    return null;
  }
  return (
    <div className="area user-turnover">
      <div className="turnover">
        <span>
          <FormattedMessage id="user.turnover" />
        </span>
        <span className="turnover-summary">
          {typeof total !== 'number' ? (
            <Spinner size={20} thickness={2} />
          ) : (
            total
          )}
        </span>
      </div>
      {typeof total !== 'number' ? null : (
        <table className="noHover">
          <tbody>
            <UserProfileTurnoverItem
              label="turnover.cam"
              value={cams}
              display={true}
            />
            <UserProfileTurnoverItem
              label="turnover.cam.tip"
              value={livecam_tips}
            />
            <UserProfileTurnoverItem label="turnover.chat" value={messages} />
            <UserProfileTurnoverItem
              label="turnover.premium.attachments"
              value={payttachments}
            />
            <UserProfileTurnoverItem label="turnover.regards" value={regards} />
            <UserProfileTurnoverItem
              label="turnover.sexicons"
              value={sexicons}
            />
            <UserProfileTurnoverItem label="turnover.video" value={videos} />
            <UserProfileTurnoverItem label="turnover.photo" value={pictures} />
            <UserProfileTurnoverItem label="turnover.games" value={games} />
            <UserProfileTurnoverItem
              label="turnover.voice.messages"
              value={voice_messages}
            />
          </tbody>
        </table>
      )}
    </div>
  );
}

export default UserProfileTurnover;
