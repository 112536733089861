import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// import sortAZIcon from '../../../../../assets/img/svg/sort-a-z.svg';
// import sortZAIcon from '../../../../../assets/img/svg/sort-z-a.svg';
import { PAGINATION_PAGE_SIZE } from '../../../../../constants';
import { spinnerRequest } from '../../../../../shared/spinnerRequest';
import { addPortals } from '../../../../../utils/portals';
import Pagination from '../../../../Pagination/Pagination';
import ThumbnailItem from '../../../../ThumbnailItem';

function MyRequests({ spinner }) {
  const [items, setItems] = useState([]);
  // const [myRequestsSearch, setMyRequestsSearch] = useState('');
  // const [sortingDirection, setSortingDirection] = useState('asc');
  const initialItems = useRef(null);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [{ loading: friendsDataLoading }, friendsDataRequest] = useAxios(
    {
      url: `/user/friends/self?o=${offset}&l=${PAGINATION_PAGE_SIZE}`,
    },
    { manual: true }
  );

  const getFriendsData = useCallback(() => {
    spinnerRequest({
      request: friendsDataRequest,
      spinner,
    })
      .then(async (response) => {
        setTotalItems(response.data?.total);
        const data = await addPortals([...response.data?.data]);
        const sortedItems = data.sort(
          (a, b) => new Date(b.date) - new Date(a.date)
        );
        initialItems.current = [...sortedItems];
        setItems([...sortedItems]);
      })
      .catch(() => {});
  }, [friendsDataRequest, spinner]);

  useEffect(() => {
    getFriendsData();
  }, [getFriendsData]);

  const updateList = useCallback(
    (id) => {
      if (!id) return;
      if (offset >= totalItems - 1) {
        setOffset(offset - PAGINATION_PAGE_SIZE);
        setPageNumber(pageNumber - 1);
        return;
      }
      getFriendsData();
    },
    [getFriendsData, offset, pageNumber, totalItems]
  );

  // SEARCH TO BE ADDED BACK LATER

  // const searchFriendsHandler = (value) => {
  //   const filteredItems = initialItems.current.filter((item) => {
  //     if (value === '') {
  //       return true;
  //     }
  //     return item.nickname.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setItems(filteredItems);
  //   setMyRequestsSearch(value);
  // };

  // const clearSearchInput = () => {
  //   setMyRequestsSearch('');
  //   searchFriendsHandler('');
  // };

  // const sortFriends = () => {
  //   const sortedItems = [...initialItems.current];
  //   if (sortingDirection === 'desc') {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname > b.nickname) {
  //         return -1;
  //       } else if (b.nickname > a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('asc');
  //   } else {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname < b.nickname) {
  //         return -1;
  //       } else if (b.nickname < a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('desc');
  //   }
  //   setItems(sortedItems);
  //   initialItems.current = [...sortedItems];
  // };

  return !!initialItems.current?.length ? (
    <>
      {/*<div className="searchbar">
         <SearchInput
          id={'search_friends'}
          name={'search_friends'}
          placeholder={`${intl.formatMessage({
            id: 'SEARCH',
          })}`}
          value={myRequestsSearch}
          className="search-friends-input"
          onChange={(event) => searchFriendsHandler(event.target.value)}
          clearInput={clearSearchInput}
        />
        <ReactSVG
          onClick={() => sortFriends()}
          src={sortingDirection === 'asc' ? sortAZIcon : sortZAIcon}
          className={'sort-icon'}
          wrapper="span"
        />
      </div>*/}
      <div className="relationships-list-wrapper">
        {items.map((item, index) => (
          <ThumbnailItem
            item={item}
            type="relationship"
            key={`${item}-${index}`}
            relationshipType="friends"
            relationshipMenuType="my-requests"
            updateList={updateList}
          />
        ))}
      </div>
      <Pagination
        offset={offset}
        totalItems={totalItems}
        pageNumber={pageNumber}
        setOffset={setOffset}
        setPageNumber={setPageNumber}
      />
    </>
  ) : (
    !friendsDataLoading && (
      <div className="no-items">
        <FormattedMessage id="RELATIONSHIP_LABEL_NO_REQUESTS" />
      </div>
    )
  );
}

export default MyRequests;

