export default {
  HEADLINE_4SCORE: `JETZT NEU: Community Game 4SCORE`,
  CONTENT_1_4SCORE: `Eine unterhaltsame Zeit mit Deinen Kunden verbringen und dabei noch extra Coins verdienen? Dann entdecke unser neues Feature: <span class="bold">Community Game 4Score</span>!`,
  CONTENT_2_4SCORE: `<span class="bold">Spiel-Klassiker in Echtzeit</span><br />Jeder kennt den Spiele-Klassiker 4gewinnt! Wer schafft es zuerst, vier Spielsteine in einer Reihe zu haben? Spiele in Echtzeit mit Deinen Kunden und gewinne, unabhängig vom Ausgang garantierte Coins!`,
  CONTENT_3_4SCORE: `<span class="bold">Spaßige Interaktion mit Mehrwert</span><br />Mit 4Score bieten wir Dir nicht nur ein Spiel, sondern eine einzigartige Möglichkeit, die Interaktion mit Deinen Kunden spielend und einfach zu intensivieren! Indem Du spielst und dabei mit Deinen Kunden chattest, baust Du eine stärkere Bindung auf und steigerst zeitgleich Deinen Messageumsatz.`,
  CONTENT_3_1_4SCORE: `Apropos Mehrwert: 4Score ist auch eine perfekte Ergänzung für eine persönliche Livecam-Session mit Deinen Kunden. Vielleicht sogar mit einer besonderen Interaktion abhängig vom Erfolg im Spiel?!`,
  CONTENT_4_4SCORE: `<span class="bold">Transparenter Ablauf</span><br />Kunden können Dich einladen, wenn Du online bist, und 25 Coins werden für Dich reserviert. Nach Annahme der Einladung beginnt das Spiel, und die erhöhte Interaktion führt zu mehr Einnahmen!`,
  CONTENT_5_4SCORE: `<span class="bold">Faires Vergütungsmodell</span><br />Für jedes Spiel erhältst Du garantiert 25 Coins, sobald das Spiel erfolgreich beendet wurde, egal, ob Du gewinnst oder verlierst!`,
  CONTENT_6_4SCORE: `<span class="bold">Überblick & Kontrolle</span><br />Behalte stets den Überblick Deiner laufenden Spiele, vergangene Partien und mögliche Einladungen! Ein eigenes Symbol zeigt Dir den Status jedes Spiels und in einer neuen Rubrik siehst Du alle Spielverläufe!`,
  CONTENT_7_4SCORE: `<span class="bold">Flexibilität & Mobilität</span><br />Egal, wo Du bist, mit 4Score auf LiveCreator bist Du immer spielbereit! Du kannst überall spielen und Dich gleichzeitig im Messenger unterhalten, denn alle Game-Features in LiveCreator sind selbstverständlich auch in mobiler Version verfügbar.`,
  CONTENT_8_4SCORE: `<span class="bold">Zukunftsvision</span><br />Dies ist erst der Anfang! Bei Erfolg von 4Score sind neue Features, weitere Spiele, Rankings, Seasons mit Prämien und Specials für Creator geplant!`,
  CONTENT_8_1_4SCORE: `Sei dabei und bring Deine Interaktion mit Kunden auf das nächste Level!`,

  HEADLINE_DETAIL_4SCORE: `Und so funktioniert es im Detail`,
  CONTENT_1_DETAIL_4SCORE: `Der Kunde kann Dich über Dein Profil zu einem Spiel einladen, aber nur wenn Du gerade online bist. Im Moment der Einladung wird der Preis in Höhe von <span class="bold">25 Coins</span> sofort für Dich reserviert. Du erhältst dann auf LiveCreator eine Einladung im Multi Messenger.`,
  CONTENT_2_DETAIL_4SCORE: `Du hast jetzt die Möglichkeit, die Einladung anzunehmen. Sobald geschehen wird zufällig ausgewählt, wer von Euch beiden anfängt. Nun könnt Ihr Eure Züge abwechselnd machen, wobei Euch dafür jeweils <span class="bold">5 Minuten</span> zur Verfügung stehen. Sollte diese Zeit verstreichen, gibt es einen „Timeout“. Sobald ein Gewinner feststeht oder niemand mehr einen Zug machen kann, ist das Spiel beendet.`,
  CONTENT_3_DETAIL_4SCORE: `Wenn es Dir gerade nicht passt, kannst Du die Einladung des Kunden auch ablehnen.`,

  LIST_HEADLINE_1_DETAIL_4SCORE: `Die Vergütung`,
  LIST_CONTENT_1_DETAIL_4SCORE: `Ein Spiel kostet den Kunden 25 Coins. Dieser Preis ist nicht variabel, wie andere Optionen in LiveCreator.`,
  LIST_CONTENT_1_1_DETAIL_4SCORE: `In folgenden Fällen werden dem Kunden die Coins zurückerstattet:<br />
  <ul>
    <li>Du lehnst die Einladung ab</li>
    <li>Die Einladung läuft in ein Timeout</li>
    <li>Es passiert ein Timeout, während Du am Zug bist</li>
  </ul>`,
  LIST_CONTENT_1_2_DETAIL_4SCORE: `In folgenden Fällen werden Dir die Coins als Umsatz gutgeschrieben:<br />
  <ul>
    <li>Das Spiel wird erfolgreich beendet, unabhängig davon, ob Du gewinnst oder verlierst, oder ob es in einem Unentschieden endet</li>
    <li>Es passiert ein Timeout, während der Kunde am Zug ist</li>
  </ul>`,
  LIST_HEADLINE_2_DETAIL_4SCORE: `Weitere Infos`,
  LIST_CONTENT_2_DETAIL_4SCORE: `Du kannst keine Kunden zu Spielen einladen. Natürlich kannst Du im Chat auf die Spiele hinweisen und Dein Gegenüber dazu animieren, ein Spiel mit Dir zu starten.`,
  LIST_CONTENT_2_1_DETAIL_4SCORE: `In der Userliste des {link} wird Dir immer angezeigt, mit wem Du gerade spielst und welches Spiel auf Deinen Zug wartet.`,
  LIST_CONTENT_2_2_DETAIL_4SCORE: `Du kannst Dir bei jedem Kunden das letzte gemeinsame Spiel anschauen und findest dort auch eine kleine Statistik über alle Spiele mit ihm und Deine Anzahl der Siege.`,
  LIST_CONTENT_2_3_DETAIL_4SCORE: `Jeder Kunde, der sich mit Dir in einem Spiel befindet, wird mit einem neuen Symbol im Multi Messenger gekennzeichnet. Die Farbe des Symbols gibt Aufschluss darüber, in welchem Status sich das Spiel befindet:`,
  LIST_ICON_INVITATION_4SCORE: `Du wurdest von einem Kunden eingeladen`,
  LIST_ICON_RUNNING_4SCORE: `Hier findet gerade ein Spiel statt, der Kunde ist am Zug`,
  LIST_ICON_TURN_4SCORE: `Du bist dran, der Kunde wartet auf Deinen Zug`,
  LIST_CONTENT_2_4_DETAIL_4SCORE: `Es gibt eine neue Rubrik unter {link}, in der Du die letzten 30 Spielverläufe aller Kunden einsehen kannst.`,
  LINK_TEXT_STATS_4SCORE: `Meine Statistik - Game 4Score`,
  LIST_CONTENT_2_5_DETAIL_4SCORE: `Dating-Kunden können nicht mit Dir spielen, weil es auf diesen Portalen die Community-Games nicht gibt.`,
  LIST_CONTENT_2_6_DETAIL_4SCORE: `Du kannst Dich auch dafür entscheiden, generell keine Community Games anzubieten. Gehe dafür bitte in Deine {link}.`,
};

