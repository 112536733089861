import './LivecamOverview.scss';

import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import useTranslateTableTitles from '../../../../hooks/useTranslateTableTitles';
import { spinnerRequest } from '../../../../shared/spinnerRequest';
import LivecamOverviewTable from '../../../Tables/LivecamOverviewTable/LivecamOverviewTable';
import SkeletonTable from '../../../Tables/SkeletonTable/SkeletonTable';
import {
  fullLivecamOverviewTableTitles,
  mediumLivecamOverviewTableTitles,
  mobileLivecamOverviewTableTitles,
} from './LivecamOverview.functions';

function LivecamOverview({ user, isSubmenuShown, spinner = () => {} }) {
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const isMediumScreen =
    useMediaQuery('(min-width: 1024px) and (max-width: 1599px)') &&
    isSubmenuShown;

  const titles = isMobileScreen
    ? mobileLivecamOverviewTableTitles
    : isMediumScreen
    ? mediumLivecamOverviewTableTitles
    : fullLivecamOverviewTableTitles;

  const translatedTitles = useTranslateTableTitles(titles);

  const [
    { data: livecamOverviewData, loading: livecamOverviewLoading },
    livecamOverviewRequest,
  ] = useAxios(
    {
      url: '/user/studio/livecam',
    },
    { manual: true }
  );

  useEffect(() => {
    spinnerRequest({
      request: livecamOverviewRequest,
      spinner,
    }).catch(() => {});
  }, [livecamOverviewRequest, spinner]);

  return (
    <div className="livecam-overview-wrapper">
      <h1 className="headline">
        <FormattedMessage id="LIVECAM_OVERVIEW_HEADLINE" />
      </h1>
      {(!livecamOverviewData || !livecamOverviewData.length) &&
        !livecamOverviewLoading && (
          <div className="no-data-label headline">
            <FormattedMessage id="NO_DATA" />
          </div>
        )}
      {livecamOverviewData && !!livecamOverviewData.length && (
        <div className="livecam-overview-table">
          <LivecamOverviewTable
            items={livecamOverviewData}
            dateFormat={
              user.account?.language === 'de'
                ? 'DD MMM, YYYY - HH:mm'
                : 'MMM DD, YYYY - h:mm a'
            }
            translatedTitles={translatedTitles}
          />
        </div>
      )}
      {livecamOverviewLoading && (
        <SkeletonTable translatedTitles={translatedTitles} />
      )}
    </div>
  );
}

export default LivecamOverview;

