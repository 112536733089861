import { PERSIST_BASE_KEY } from '../../constants';
import filter from './filter';
import { whitelist } from './lists';
import stateReconciler from './stateReconciler';
import storageCreator from './storage';

export default {
  key: PERSIST_BASE_KEY,
  storage: storageCreator.storage(true),
  whitelist,
  stateReconciler,
  timeout: null,
  transforms: [filter],
};
