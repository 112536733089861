import {
  audio,
  camUser,
  management,
  sendNotification,
  sendSystemChatMessage,
} from '../../../../actions';
import {
  roomType as roomTypeUtils,
  upgradetimer,
  user as userUtils,
} from '../../../../utils';
import { getPortal } from '../../../../utils/portals';
import {
  MANAGEMENT_EVENTS_STREAM_START,
  MANAGEMENT_EVENTS_USER_ADD,
} from '../../../actions';
import { duplicateName, newDialog } from '../../../dialog';

export default (userInfo) => async (dispatch, getState) => {
  const {
    cam: { selectedMicrophone },
    useraudio: { running },
    sender: { roomTypes },
    camUser: { camUsers },
    dialog: { dialogs, processQueue, queue },
  } = getState();
  try {
    const portals = await getPortal(userInfo.clientProductId);
    userInfo.portalName = portals?.whitelabelLong;
    userInfo.portalShortName = portals?.whitelabelShort;
  } catch (error) {
    console.log({ getPortalError: error });
  }
  const isEvent = roomTypeUtils.isEvent(roomTypes);
  const isVoyeur = userUtils.isVoyeur(userInfo);
  upgradetimer.handleUpgradeTimer(userInfo, false, getState, dispatch);

  if (!isVoyeur) {
    const combinedList = [...camUsers, ...dialogs, ...processQueue, ...queue];

    const dialogIds = combinedList
      .filter((d) => !!d.partner)
      .map((d) => d.partner);

    const type = userUtils.getType(userInfo?.origin, userInfo?.systemId);
    const isNewDialog =
      userInfo.clientProductId &&
      userInfo.clientCustomerId &&
      userUtils.isDialogUser(type) &&
      dialogIds.findIndex(
        (id) =>
          id === `${userInfo.clientProductId}@${userInfo.clientCustomerId}`
      ) < 0;

    if (isNewDialog) {
      await dispatch(
        newDialog({
          id: `${userInfo.clientProductId}@${userInfo.clientCustomerId}`,
          userInfo,
        })
      );
    }
    const duplicateIndex = combinedList.findIndex(
      (item) =>
        item.name?.toLowerCase() === userInfo.name?.toLowerCase() &&
        item.partner !==
          `${userInfo.clientProductId}@${userInfo.clientCustomerId}`
    );

    if (duplicateIndex !== -1) {
      userInfo.duplicateName = true;
      dispatch(duplicateName(userInfo.name));
    }
  }

  const params = {
    state: getState(),
    type: MANAGEMENT_EVENTS_USER_ADD,
    payload: userInfo,
  };

  if (selectedMicrophone && selectedMicrophone.value && !running) {
    dispatch(management.startSenderAudio(userInfo.userId));
  }

  let id = 'systemMessage.userJoined';

  if (!isVoyeur && !isEvent) {
    dispatch(sendSystemChatMessage({ id, values: { user: userInfo.name } }));
    if (userInfo.translate) {
      dispatch(
        sendSystemChatMessage({
          id: 'systemMessage.userJoinedTranslate',
          userId: userInfo.userId,
        })
      );
    }
    dispatch(audio.enterShow(userInfo));
  }
  if (isEvent) {
    dispatch({
      type: MANAGEMENT_EVENTS_STREAM_START,
      payload: userInfo,
    });
  }

  const action = userUtils.createAddEventAction(params);
  dispatch(action);

  if (
    action?.meta?.nextActiveUser?.userId &&
    action.meta.nextActiveUser.userId !== 'system'
  ) {
    dispatch(camUser.select(action?.meta?.nextActiveUser?.userId));
  }
  if (!isVoyeur && !isEvent) {
    dispatch(
      sendNotification({ body: { id, values: { user: userInfo.name } } })
    );
  }
};
