import './ButtonFieldWithErrors.scss';

import classNames from 'classnames';
import { Field, useField, useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import checkedIcon from '../../../assets/img/svg/check.svg';
import closeIcon from '../../../assets/img/svg/close.svg';
import InputWithLabel from '../InputWithLabel/InputWithLabel';

function ButtonFieldWithErrors({
  id,
  name,
  as = InputWithLabel,
  markAsRequired,
  children,
  disabled,
  textarea,
  updateOptions,
  width,
  small,
  additionalLabel,
  invalidDataLabel,
  subtextLabel,
  outsideAdditionalLabels,
  blurTimeout,
  showRemainingChars = false,
  ...otherProps
}) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const [, meta, helper] = useField(name);
  const [initialValue, setInitialValue] = useState('');
  const [buttonsVisible, setButtonsVisibility] = useState(false);
  const [keepButtonsVisible, setKeepButtonsVisible] = useState(false);

  const intl = useIntl();
  const hasError = meta.error && meta.touched;
  const { change, isDataLoading, dataRequestError, action } = updateOptions;

  const className = classNames('button-field-error-message-wrapper', {
    [`${id || name}-wrapper`]: id || name,
    'has-error': (hasError || dataRequestError) && !buttonsVisible,
    disabled: disabled,
    'textarea-wrapper': textarea,
    'static-width': width,
    small,
  });

  const fieldClassName = classNames({
    small,
  });

  const errorClassName = classNames('error-message', {
    small,
  });

  function handleBlur(event) {
    if (!keepButtonsVisible) {
      setButtonsVisibility(false);
      if (!hasError) {
        if (!isDataLoading && values[name] !== initialValue) {
          let data = {
            [name]: event.target.value,
          };

          // this timeout is a hack especially for the diary and should be fixed
          // if you enter title or text into a diary item and click in large screen directly
          // on an other item in the left list, the click does not work without this timeout
          // do no set this property if your not 100% forced to do this
          if (!isNaN(blurTimeout)) {
            setTimeout(() => change(data), blurTimeout);
          } else {
            change(data);
          }
        }
      }
      helper.setTouched(true);
    }
  }

  function handleButtonClick(type) {
    if (type === 'reject') {
      setFieldValue(name, initialValue);
      helper.setTouched(false);
    } else {
      if (!isDataLoading && values[name] !== initialValue && !hasError) {
        let data = {
          [name]: values[name],
        };
        change(data);
      }
      helper.setTouched(true);
    }
    setButtonsVisibility(false);
  }

  function handleFocus() {
    if (!hasError) {
      setInitialValue(meta.value);
    }
    setButtonsVisibility(true);
  }

  function handleAutocomplete(event) {
    if (event.target.hasAttribute('autocompleted')) {
      if (!hasError) {
        if (!isDataLoading && event.target.value !== initialValue) {
          let data = {
            [name]: event.target.value,
          };
          change(data);
        }
      }
    }
  }

  return (
    <InputWithLabel
      id={id}
      name={name}
      markAsRequired={markAsRequired}
      {...otherProps}
    >
      <div className={className}>
        {children ? (
          children
        ) : (
          <div className="field-wrapper">
            <Field
              as={as}
              id={id}
              name={name}
              {...otherProps}
              onBlur={(event) => handleBlur(event)}
              onFocus={() => handleFocus()}
              onChange={(event) => {
                setFieldTouched(name, true);
                setFieldValue(name, event.target.value);
                handleAutocomplete(event);
              }}
              className={fieldClassName}
              disabled={disabled}
            />
            {additionalLabel && !outsideAdditionalLabels && (
              <span className="additional-labels">
                <span className="additional-label">{additionalLabel}</span>
                <span className="subtext-label">{subtextLabel}</span>
              </span>
            )}
            {showRemainingChars && (
              <p className="textarea-length-indicator">
                {intl.formatMessage({ id: 'REMAINING_CHARS' })}:{' '}
                {otherProps['maxLength'] - values[name]?.length}
              </p>
            )}
          </div>
        )}
        {buttonsVisible && !action && (
          <div
            className="input-action-buttons"
            style={
              (width && { width, right: 'unset' }) ||
              (small && { width: '80px', right: 'unset' })
            }
          >
            <div className="action-buttons-wrapper">
              <div
                className="action-button confirm-button"
                // onMouseDown and onMouseUp need to be used in order to keep the buttons visible when the blur event
                // of the input field fires. If only onClick is used then the buttons will disappear after the blur effect
                // since the order of events is onMouseDown -> onBlur -> onMouseUp -> onClick
                onMouseDown={() => {
                  setKeepButtonsVisible(true);
                }}
                onMouseUp={() => {
                  setKeepButtonsVisible(false);
                }}
                onClick={() => {
                  handleButtonClick('confirm');
                }}
              >
                <ReactSVG
                  src={checkedIcon}
                  wrapper="span"
                  className="confirm-icon"
                />
              </div>
              <div
                className="action-button reject-button"
                onMouseDown={() => {
                  setKeepButtonsVisible(true);
                }}
                onMouseUp={() => {
                  setKeepButtonsVisible(false);
                }}
                onClick={() => {
                  handleButtonClick('reject');
                }}
              >
                <ReactSVG
                  src={closeIcon}
                  wrapper="span"
                  className="reject-icon"
                />
              </div>
            </div>
          </div>
        )}
        {(hasError || dataRequestError) && !buttonsVisible && (
          <div className={errorClassName}>
            {dataRequestError?.response?.data?.errors && invalidDataLabel
              ? intl.formatMessage({ id: invalidDataLabel })
              : meta?.error
              ? meta.error
              : intl.formatMessage({ id: 'ERROR_NETWORK_ERROR' })}
          </div>
        )}
      </div>
    </InputWithLabel>
  );
}

export default ButtonFieldWithErrors;

