import { connect } from 'react-redux';

import {
  errorLog,
  playVoicemessage,
  stopVoicemessage,
  textVoicemessage,
} from '../../../../../actions';
import Audio from './Audio';

const mapStateToProps = (state) => ({
  voicemessagePlaying: state.voicemessage.playing,
  transcriptionTexts: state.voicemessage.texts,
  chatuser: state.dialog.dialogs.find(
    (u) => u.partner === state.chat.selectedUser
  ),
});

const mapDispatchToProps = {
  playVoicemessage,
  stopVoicemessage,
  textVoicemessage,
  errorLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Audio);
