import './Spinner.scss';

import React from 'react';

function Spinner({
  size = 80,
  thickness = 8,
  variant = 'primary',
  margin = '8px',
}) {
  return (
    <div style={{ width: size, height: size, margin }} className="spinner">
      <div
        style={{ borderWidth: thickness, width: size, height: size }}
        className={variant}
      />
      <div
        style={{ borderWidth: thickness, width: size, height: size }}
        className={variant}
      />
      <div
        style={{ borderWidth: thickness, width: size, height: size }}
        className={variant}
      />
      <div
        style={{ borderWidth: thickness, width: size, height: size }}
        className={variant}
      />
    </div>
  );
}

export default Spinner;

