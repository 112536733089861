import './Newsletter.scss';

import useAxios from 'axios-hooks';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { NEWSLETTER_PAGINATION_SIZE } from '../../../constants';
import useMediaQuery from '../../../hooks/useMediaQuery';
import api from '../../../utils/api';
import Button from '../../Button/Button';
import PageSection from '../../PageSection/PageSection';
import Pagination from '../../Pagination/Pagination';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import DateItem from '../../Tables/TableItems/DateItem';
import NewsletterIframe from '../NewsletterIframe/NewsletterIframe';

function Newsletter({ nickname }) {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const [newsletters, setNewsletters] = useState([]);
  const [data, setData] = useState();
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [initialNewsletterLoading, setInitialNewsletterLoading] =
    useState(false);

  const [{ loading }, newsletterReq] = useAxios(
    {
      url: `/user/newsletter?l=${NEWSLETTER_PAGINATION_SIZE}&o=${offset}`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );
  const [, newsletterTotalReq] = useAxios(
    {
      url: `/user/newsletter/total`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  const openNewsletter = useCallback(
    async (id) => {
      try {
        setInitialNewsletterLoading(true);
        const response = await api.get(`/user/newsletter/${id}`);
        const htmlNickname = response.data.html.replaceAll(
          '{nickname}',
          nickname
        );
        const htmlTags = htmlNickname.replaceAll(
          'href="#',
          'href="about:srcdoc#'
        );
        setData({ ...response.data, html: htmlTags });
        setInitialNewsletterLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [nickname]
  );

  useEffect(() => {
    newsletterTotalReq().then((response) => {
      setTotalItems(response.data?.total);
    });
    newsletterReq().then((response) => {
      setNewsletters(response.data);
      const filteredNewsletters = response.data.filter(
        (letter) => letter.test === false
      );
      if (filteredNewsletters.length && isLargeScreen) {
        openNewsletter(filteredNewsletters[0].id);
      }
    });
  }, [newsletterReq, openNewsletter, newsletterTotalReq, isLargeScreen]);

  return loading || initialNewsletterLoading ? (
    <SpinnerComponent />
  ) : (
    <>
      {isLargeScreen ? (
        <PageSection
          title={<FormattedMessage id="HEADER_PAGE_NEWSLETTER" />}
          rightColumnTitle={true}
          className="newsletter-container"
          rightColumn={
            <div className="iframe">
              <NewsletterIframe data={data} />
            </div>
          }
        >
          <div className="newsletter-list">
            <h1 className="headline">
              <FormattedMessage id="HEADER_TITLE_NEWSLETTER" />
            </h1>
            {newsletters.length ? (
              newsletters.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`list-item-wrapper ${
                      data?.id === item?.id ? 'active' : ''
                    }
                  ${item.test ? 'test' : ''}
                `}
                  >
                    <div
                      className="newsletter-item"
                      onClick={() => openNewsletter(item.id)}
                    >
                      <DateItem data={item.date} className="date-item" />
                      <span className="subject-item">{item.subject}</span>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>
                <FormattedMessage id="ERROR_NO_NEWS_LETTER" />
              </p>
            )}
          </div>
          <>
            <Pagination
              offset={offset}
              totalItems={totalItems}
              pageNumber={pageNumber}
              setOffset={setOffset}
              setPageNumber={setPageNumber}
              paginationSize={NEWSLETTER_PAGINATION_SIZE}
            />
          </>
        </PageSection>
      ) : (
        <PageSection
          className="newsletter-container"
          title={<FormattedMessage id="HEADER_PAGE_NEWSLETTER" />}
        >
          {!data ? (
            <div className="newsletter-list">
              <h1 className="headline">
                <FormattedMessage id="HEADER_TITLE_NEWSLETTER" />
              </h1>
              {newsletters.length ? (
                newsletters.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={`list-item-wrapper ${
                        data?.id === item?.id ? 'active' : ''
                      }
              ${item.test ? 'test' : ''}
            `}
                    >
                      <div
                        className="newsletter-item"
                        onClick={() => openNewsletter(item.id)}
                      >
                        <DateItem data={item.date} className="date-item" />
                        <span className="subject-item">{item.subject}</span>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>
                  <FormattedMessage id="ERROR_NO_NEWS_LETTER" />
                </p>
              )}
              <>
                <Pagination
                  offset={offset}
                  totalItems={totalItems}
                  pageNumber={pageNumber}
                  setOffset={setOffset}
                  setPageNumber={setPageNumber}
                  paginationSize={NEWSLETTER_PAGINATION_SIZE}
                />
              </>
            </div>
          ) : (
            <>
              <div className="iframe-wrapper">
                <NewsletterIframe data={data} />
              </div>
              <div className="button-wrapper">
                <Button label="BUTTON_BACK" onClick={() => setData(null)} />
              </div>
            </>
          )}
        </PageSection>
      )}
    </>
  );
}

export default Newsletter;

