import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import arrowDownIcon from '../../../assets/img/svg/arrow-down.svg';

function RequestSelect({
  id,
  name,
  options,
  defaultValue = '',
  defaultValueOption,
  intlTranslate,
  updateOptions,
  existingData,
  index,
  outsideChangeHandler,
  disabled,
  ...otherProps
}) {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();
  const { change, isDataLoading } = updateOptions;

  const selectOptions = options.map((o, i) => {
    const translatedLabel =
      intlTranslate === false ? o.label : intl.formatMessage({ id: o.label });
    return o.label === 'break_line_element' ? (
      <option disabled key="separator-line">
        ─────────────────────
      </option>
    ) : (
      <option value={o.value} key={`${o.value}.${i}`}>
        {translatedLabel}
      </option>
    );
  });

  const selectClassName = classNames('select-container', {
    disabled,
  });

  function handleSelect(event) {
    if (outsideChangeHandler) {
      outsideChangeHandler(event.target.value);
    } else {
      if (!isDataLoading) {
        let newData = event.target.value;
        if (existingData) {
          if (index >= existingData.length) {
            newData = existingData.concat([event.target.value]);
          } else {
            newData = [...existingData];
            newData[index] = event.target.value;
          }
        }

        change({ [name]: newData });
        setFieldValue(name, newData);
      }
    }
  }

  return (
    <div className={selectClassName}>
      <select
        id={id || name}
        name={id || name}
        {...otherProps}
        className={selectClassName}
        onChange={(event) => handleSelect(event)}
        disabled={disabled}
      >
        {options.length > 0 ? (
          selectOptions
        ) : (
          <option value="" disabled>
            {defaultValueOption}
          </option>
        )}
      </select>
      <ReactSVG src={arrowDownIcon} className="arrow-down-icon" />
    </div>
  );
}

export default RequestSelect;

