import comments from './comments';
import diary from './diary';
import fmotd from './fmotd';
import photos from './photos';
import videos from './videos';

export default {
  ...photos,
  ...videos,
  ...diary,
  ...comments,
  ...fmotd,

  ROUTE_MY_CONTENT_HEADLINE: `Erstelle und bearbeite hier Deine Foto- und Videogalerien und lese oder beantworte erhaltene Kommentare`,
  CREATE_NEW_GALLERY: `Neue Galerie erstellen`,
  FSK_CASUAL_LABEL: `Casual`,
  FSK_SOFT_LABEL: `Soft`,
  FSK_HARD_LABEL: `Hard`,

  STARTING_GALLERY_INFO_HEADLINE_1: `Zeige Dich von Deiner besten Seite!`,
  STARTING_GALLERY_INFO_TEXT_1: `Der visuelle Ersteindruck spielt eine wichtige Rolle bei der Kontaktaufnahme! Das ist nicht nur im realen Leben auf der Straße, auf einer Party oder im Club der Fall. Natürlich möchten Community User auch gerne sehen, mit wem sie das Vergnügen in der Livecam haben können. Umso wichtiger ist es für Dich, neben Deinen Interessen und Deiner persönlichen Einstellung, auch optisch anzusprechen und neugierig zu machen.`,
  STARTING_GALLERY_INFO_TEXT_2: `Bei LiveCreator kannst Du die Community auch in Form von Bildern und Videos an Deinem Leben Teil haben lassen und damit mehr von Dir zeigen. Das ist eine praktische und lohnenswerte Ergänzung zur Livecam.`,
  STARTING_GALLERY_INFO_TEXT_3: `Die Community wird damit besser aufmerksam auf Dich, Du kannst konkret die Neugierde wecken und User schneller überzeugen, Deine Livecam zu besuchen.`,
  STARTING_GALLERY_INFO_TEXT_4: `Dabei kannst Du sowohl kostenpflichtige, als auch Gratis Inhalte veröffentlichen. Das ermöglicht Dir gezielt Teaser anzubieten, um das Interesse an kostenpflichtigen Inhalten und Deiner Livecam zu wecken, und zusätzlich zur Livecam mit Deinen Fotos und Videos Geld zu verdienen.`,
  HOW_TO_VIDEO: `Schaue dazu unser „How-to Video“`,

  WHAT_OPTIONS_DO_I_HAVE: `Welche Möglichkeiten habe ich hier?`,
  GALLERY_INFO_1: `<span class="bold">Galerie Sortierung</span><br />Als erstes findest Du die neueste Galerie. Du kannst die Sortierung aber mit dem Icon ändern oder nach einem Galerie Titel suchen.`,
  GALLERY_INFO_2: `<span class="bold">Vorschaubild hochladen und ändern</span><br />Lade ein Vorschaubild hoch oder ersetze ein bereits vorhandenes Vorschaubild.`,
  GALLERY_INFO_3: `<span class="bold">Galerie Titel und Beschreibung editieren</span><br />Bearbeite den Titel und die Beschreibung Deiner Galerien.`,
  GALLERY_INFO_4: `<span class="bold">Galerie Preis und Kategorien einstellen</span><br />Verwalte Preis und Kategorie Zuweisung`,
  GALLERY_INFO_5: `<span class="bold">Kommentare lesen und editieren</span><br />Lese und bearbeite User Kommentare`,
  GALLERY_INFO_6: `<span class="bold">Galerie Status verfolgen und ändern</span><br />Behalte Deine Galerien und deren Status im Blick:`,
  GALLERY_INFO_6_1: `Nur für Dich sichtbar`,
  GALLERY_INFO_6_2: `Der Support prüft Bilder und Einstellungen`,
  GALLERY_INFO_6_3: `Die Galerie wird zum festgelegten Zeitpunkt veröffentlicht`,
  GALLERY_INFO_6_4: `Sichtbar für alle Community User`,
  GALLERY_INFO_6_5: `Nur für Dich sichtbar`,
  GALLERY_INFO_6_6: `Die Galerie wurde vom Support gesperrt`,
  GALLERY_INFO_6_7: `Dein Handeln ist erforderlich!`,

  UNNAMED_GALLERY: `Unbenannte Galerie`,
  TIMES_SOLD: `mal verkauft`,
  NOT_SOLD_YET: `Noch nicht verkauft`,
  COMMENTS: `Kommentare`,

  GALLERY_MENU_UPLOAD_PREVIEW: `Vorschaubild hochladen`,
  GALLERY_MENU_UPLOAD_NEW_PREVIEW: `Neues Vorschaubild hochladen`,
  GALLERY_MENU_EDIT_TEXTS: `Texte bearbeiten`,
  GALLERY_MENU_SETTINGS: `Galerie Einstellungen`,
  GALLERY_MENU_DELETE: `Löschen`,
  GALLERY_MENU_PUBLISH: `Publish the gallery`,
  GALLERY_MENU_ARCHIVE: `Galerie archivieren`,

  GALLERY_TITLE_DESCRIPTION: `Galerie Titel und Beschreibung`,
  TITLE_LABEL: `Titel:`,
  DESCRIPTION_LABEL: `Beschreibung:`,
  MIN_32_CHARS: `mind. 32 Zeichen`,

  GALLERY_PRICE: `Preis`,
  GALLERY_PRICE_ERROR: `Bitte gib einen gültigen Preis ein`,
  FREE_CONTENT: `Gratis Inhalt:`,
  GALLERY_ASSIGNED_CATEGORIES: `Zugewiesene Kategorien`,
  GALLERY_CATEGORY: `Kategorie`,
  GALLERY_CATEGORY_1: `Kategorie 1:`,
  GALLERY_CATEGORY_2: `Kategorie 2:`,
  GALLERY_CATEGORY_3: `Kategorie 3:`,
  GALLERY_CATEGORY_4: `Kategorie 4:`,
  GALLERY_CATEGORY_5: `Kategorie 5:`,

  LABEL_PREVIEW: `Vorschau`,
  PUBLISH_NOW: `Jetzt veröffentlichen`,
  CANCEL_SCHEDULE: `Zeitplan aufheben`,
  GALLERY_WILL_BE_PUBLISHED_LABEL: `Die Galerie wird veröffentlicht am`,
  GALLERY_CATEGORY_WARNING: `WICHTIG: Wir empfehlen Usern Galerien, die zu ihren Interessen und Vorlieben passen. Bitte wähle die Kategorien passend und sorgfältig aus!`,
  SET_SCHEDULE: `Zeitplan festlegen`,
  SAVE_SCHEDULE: `Zeitplan speichern`,
  PUBLISH_DATE: `wird veröffentlicht`,

  GALLERY_ACTORS_DOCUMENTS: `Dokumente der Darsteller`,
  GALLERY_ACTORS_DOCUMENTS_TEXT: `Seit Oktober 2021 müssen wir für jede veröffentlichte Galerie nachweisen, dass die Darsteller volljährig sind. Dafür ist es notwendig, dass Du für jede Galerie pro auftretenden Darsteller einen aktuellen Ausweis hinterlegst.`,
  GALLERY_ACTORS_DOCUMENTS_LINK: `Meine Dokumente verwalten`,

  GALLERY_ACTORS_DOCUMENTS_CHECKBOX_1: `In dieser Galerie bin ich zu sehen. Das Standard-Dokument wird verwendet.`,
  GALLERY_ACTORS_DOCUMENTS_CHECKBOX_2: `Es ist mindestens ein weiterer Darsteller zu sehen. Ich ordne die entsprechenden Dokumente der Galerie zu.`,
  GALLERY_ACTORS_HEADLINE: `Folgende Darsteller sind in dieser Galerie zu sehen:`,
  GALLERY_ASSIGN_DOCUMENT: `Dokument zuordnen:`,
  GALLERY_STANDARD_DOCUMENT: `Standard-Dokument`,
  GALLERY_ACTOR_DOCUMENT: `Darsteller-Dokument`,

  GALLERY_THUMBNAIL_MESSAGE: `Dein neues Vorschaubild wird vom Support geprüft. Es wird hier sichtbar, sobald es genehmigt wurde.`,

  COMMENT_WRITES_ON: `schreibt am`,
  GALLERY_IMAGE_LABEL: `Galerie Foto`,

  GALLERY_REQUIREMENTS: `Um eine Galerie zu veröffentlichen, ist folgendes Voraussetzung:`,
  GALLERY_REJECT_REASONS: `Die Veröffentlichung der Galerie wurde aus folgenden Gründen abgelehnt:`,

  GALLERY_UPLOAD_NEW_PREVIEW: `Lade ein neues Vorschaubild hoch`,
  GALLERY_OR_SELECT_NEW_IMAGE: ` oder wähle ein Bild der Galerie aus`,

  reject_corrupt: `Die Konvertierung des Videos ist fehlgeschlagen. Bitte lade es erneut hoch.`,
  reject_duplicated: `Der Content ist bereits veröffentlicht bzw. in anderen Galerien vorhanden.`,
  reject_expensive: `Der Preis ist unangemessen hoch. Bitte setze ihn neu.`,
  reject_illegal: `Der Content ist illegal oder verstößt gegen die AGB. Bitte löschen!`,
  reject_muted: `Das Video hat keinen Ton.`,
  reject_quality: `Die Qualität des Contents ist nicht ausreichend.`,
  reject_short: `Das Video ist zu kurz. Es muss mindestens eine Länge von 60 Sekunden haben.`,
  reject_stolen: `Der gezeigte Content ist gestohlen oder aus dem Internet heruntergeladen.`,
  reject_undocumented: `Mindestens für einen Darsteller fehlt ein Ausweisdokument`,
  reject_similar: `Die Bilder ähneln sich zu sehr bzw. zeigen immer dasselbe.`,
  reject_freefsk: `Eine Gratis-Galerie darf keinen FSK 18 Content enthalten.`,

  jerkoff: `Wichsen`,
  dildo: `Dildo`,
  analsex: `Analsex`,
  cumshot: `Abspritzen`,
  asian: `Asiatisch`,
  car: `Auto`,
  bathroom: `Badezimmer`,
  bananas: `Bananen`,
  bdsm: `BDSM`,
  hairy: `Behaart`,
  legs: `Beine`,
  bra: `BH`,
  bikini: `Bikini`,
  picturecumshot: `Bildbesamung`,
  bisexual: `Bisexuell`,
  bizarr: `Bizarr`,
  blowjob: `Blowjob`,
  blondes: `Blonde`,
  glasses: `Brille`,
  brunette: `Braunhaarige`,
  office: `Büro`,
  transsexual: `Transsexuell`,
  deepthroat: `Deepthroat`,
  doctor: `Doktor`,
  dominance: `Dominanz`,
  plumper: `Prall`,
  outdoor: `Draußen`,
  threesome: `Dreier`,
  wrapped: `Eingewickelt`,
  lubricate: `Eingeölt`,
  european: `Europäisch`,
  exhibitionist: `Exhibitionist`,
  finger: `Fingern`,
  fisting: `Fisting`,
  fitness: `Fitness`,
  bottles: `Flaschen`,
  feetsex: `Fußsex`,
  feet: `Füße`,
  gangbang: `Gangbang`,
  tiedup: `Gefesselt`,
  openlegs: `Gespreizte Beine`,
  bigtits: `Große Brüste`,
  bigcocks: `Große Schwänze`,
  groupsex: `Gruppensex`,
  gum: `Kaugummi`,
  bracelets: `Handschellen`,
  highheels: `High heels`,
  butt: `Hintern`,
  balls: `Hoden`,
  hangingtits: `Hängetitten`,
  panty: `Höschen`,
  rubber: `Gummi`,
  jeans: `Jeans`,
  smalltits: `Kleine Brüste`,
  clit: `Klitoris`,
  nurse: `Krankenschwester`,
  shorthair: `Kurzhaarig`,
  kitchen: `Küche`,
  kissing: `Küssen`,
  longhair: `Langhaarig`,
  latex: `Latex`,
  latinas: `Latinas`,
  leather: `Leder`,
  lesbian: `Lesben`,
  machines: `Maschinen`,
  mask: `Maske`,
  massage: `Massage`,
  masturbation: `Masturbation`,
  chubby: `Mollige`,
  pussy_licking: `Muschilecken`,
  pussies: `Muschis`,
  close_up: `Nahaufnahme`,
  food: `Nahrungsmittel`,
  goldenshower: `Natursekt`,
  normal: `Normal`,
  topless: `Oben ohne`,
  orgy: `Orgie`,
  posing: `Posing`,
  pissing: `Pissen`,
  piercing: `Piercing`,
  shave: `Rasieren`,
  shaved: `Rasiert`,
  smoking: `Rauchend`,
  redhead: `Rothaarig`,
  skirt: `Röcke`,
  vulvar: `Schamlippen`,
  slim: `Schlank`,
  schoolgirls: `Schulmädchen`,
  pregnantwomen: `Schwangere`,
  cock: `Schwanz`,
  black_haired: `Schwarzhaarig`,
  solarium: `Solarium`,
  tangaslip: `Tanga`,
  partlyshaved: `Teilrasiert`,
  tits: `Titten`,
  toys: `Toys`,
  uniform: `Uniform`,
  upskirt: `Unterm Rock`,
  lingerie: `Unterwäsche`,
  jerk: `Stoßen`,
  diapers: `Windeln`,
  vixens: `Weiber`,
  toes: `Zehen`,
  plaits: `Zöpfe`,
  tongue: `Zunge`,
  cumshots: `Cumshots`,
  teens: `Teens`,
  amateur: `Amateur`,
  asiangirls: `Asiatinnen`,
  thaigirls: `Thai Girls`,
  teen: `Teen`,
  kiss: `Kuss`,
  licking: `Lecken`,
  homosexual: `Homosexuell`,
  gay: `Schwul`,
  scat: `Kaviar`,
  bondage: `Fesselspiele`,
  fetish: `Fetisch`,
  spanking: `Verprügeln`,
  nylons: `Nylons`,
  interracial: `Interracial`,
  blacksex: `Schwarzer Sex`,
  cross_dresser: `Damenwäscheträger`,
  ligation: `Abbinden`,
  bears: `Bears`,
  blacks: `Schwarze`,
  submissive: `Devot`,
  enema: `Klistier, Einlauf`,
  hardcore: `Hardcore`,
  smallcocks: `Kleine Schwänze`,
  lycra: `Lycra`,
  latinos: `Latinos`,
  men: `Männer`,
  masochism: `Masochismus`,
  punks: `Punks`,
  giantcocks: `Riesenschwänze`,
  rimming: `Rimming`,
  sm: `SM`,
  softcore: `Softcore`,
  sportsgear: `Sportsgear`,
  nylonsold: `Strumpfhose`,
  sperm: `Sperma`,
  transvestite: `Transvestit`,
  tattoos: `Tattoos`,
  forest: `Wald`,
  waxgames: `Wachsspiele`,
  squirt: `Squirt`,
  public: `Öffentlich`,
  creampie: `Creampie`,
  milf: `MILF`,
  oral: `Oral`,
  plug: `Plug`,
  lace: `Lack`,
  roleplay: `Rollenspiele`,
  dirtytalk: `Dirty-Talk`,
  voyeur: `Voyeur`,
  grannies: `Omas`,
  balloon: `Luftballon`,
  selfies: `Selfies`,
  handjob: `Handjob`,
  students: `Studentinnen`,
  strip: `Strip`,
};
