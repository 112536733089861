import { user as userUtils } from '../../utils';
import {
  checkFriend,
  fetchAllMessages,
  getGameStats,
  getUserProfile,
  updateDialog,
  usernote,
} from '..';
export default (user, isSameUser = false) =>
  async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }
    let partner = user.partner;
    if (userUtils.isDialogUser(user?.type) && userUtils.hasPartner(user)) {
      partner = userUtils.getPartner(user);
    }

    if (partner === 'system') return;

    if (!userUtils.isAdmin(user)) {
      dispatch(checkFriend(user.userId));
      dispatch(usernote.get(user));
      dispatch(getUserProfile(user));
      dispatch(getGameStats(partner));
    }
    if (
      (getState().chat.selectedUser === partner ||
        (getState().chat.selectedUser === user.userId && partner)) &&
      !isSameUser
    ) {
      await dispatch(updateDialog(partner));
      dispatch(fetchAllMessages(partner, true));
    }
  };
