import './ArticlesExtrasTable.scss';

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import useTranslateTableTitles from '../../../hooks/useTranslateTableTitles';
import {
  fullArticleExtrasTableTitles,
  mediumArticleExtrasTableTitles,
  mobileArticleExtrasTableTitles,
} from '../../Content/Commission/BillingPeriod/BillingPeriod.functions';
import {
  dashboardLargeArticleExtrasTableTitles,
  dashboardMobileArticleExtrasTableTitles,
} from '../../Content/Dashboard/Dashboard.functions';
import DateItem from '../TableItems/DateItem';
import LinkItem from '../TableItems/LinkItem/LinkItem';
import NumberItem from '../TableItems/NumberItem';

function ArticlesExtrasTable({
  items = [],
  results = [],
  precision,
  currency,
  additionalInfo,
  isSubmenuShown,
  location,
  translateName = true,
}) {
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const isMediumScreen =
    useMediaQuery('(min-width: 1024px) and (max-width: 1599px)') &&
    isSubmenuShown;
  const titles =
    location === 'billing-period' || location === 'bill-details'
      ? isMobileScreen
        ? mobileArticleExtrasTableTitles
        : isMediumScreen
        ? mediumArticleExtrasTableTitles
        : fullArticleExtrasTableTitles
      : isMobileScreen
      ? dashboardMobileArticleExtrasTableTitles
      : dashboardLargeArticleExtrasTableTitles;

  const translatedTitles = useTranslateTableTitles(titles);
  const intl = useIntl();
  const history = useHistory();
  const search = useLocation().search;
  const billId = new URLSearchParams(search).get('bill_id');
  function redirectToBillArticles(type) {
    history.push({
      pathname: '/my-account/commission/bill-articles',
      search: `?type=${type}${billId ? '&bill_id=' + billId : ''}`,
    });
  }
  return (
    <>
      <table>
        <thead>
          <tr>
            {translatedTitles.map((title, index) => {
              return (
                <th
                  style={{ width: title.width, textAlign: title.position }}
                  key={`${title}.${index}`}
                >
                  {title.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left">
              <FormattedMessage id="ARTICLES_OTHER" />{' '}
              <span className="gray-text">
                <FormattedMessage id="ARTICLES_SEE_ABOVE" />
              </span>
            </td>
            <td className="text-right" />

            {!isMobileScreen && !isMediumScreen && location !== 'dashboard' ? (
              <td className="text-center" />
            ) : null}

            {!isMobileScreen && !isMediumScreen && location !== 'dashboard' ? (
              <td className="text-left" />
            ) : null}

            {!isMobileScreen && !isMediumScreen && location !== 'dashboard' ? (
              <td className="text-left">
                {intl.formatNumber(additionalInfo.turnover)} Coins
              </td>
            ) : null}

            {!isMobileScreen && !isMediumScreen && location !== 'dashboard' ? (
              <td className="text-left">
                {additionalInfo.share ? (
                  <span>
                    <NumberItem number={additionalInfo.share} /> %
                  </span>
                ) : null}
              </td>
            ) : null}

            <td className="text-right">
              <NumberItem
                number={additionalInfo.premium}
                precision={precision}
                currency={currency}
              />
            </td>
          </tr>
          {items
            .filter(
              (item) =>
                item.display_if_empty ||
                item.coins ||
                item.revenue ||
                item.number
            )
            .map((item, index) => {
              return (
                <tr key={`${item.key}.${index}`}>
                  <td
                    className="text-left"
                    onClick={() => {
                      if (item.display_details) {
                        redirectToBillArticles(item.key);
                      }
                    }}
                  >
                    {item.display_details ? (
                      <LinkItem
                        data={
                          translateName
                            ? intl.formatMessage({ id: item.name })
                            : item.name
                        }
                      />
                    ) : translateName ? (
                      intl.formatMessage({ id: item.name })
                    ) : (
                      item.name
                    )}
                  </td>
                  <td className="text-right">
                    {item.number > 0 ? (
                      <NumberItem number={item.number} />
                    ) : null}
                  </td>

                  {!isMobileScreen &&
                  !isMediumScreen &&
                  location !== 'dashboard' ? (
                    <td className="text-center">
                      {item.first && <DateItem data={item.first} />}
                    </td>
                  ) : null}

                  {!isMobileScreen &&
                  !isMediumScreen &&
                  location !== 'dashboard' ? (
                    <td className="text-left">
                      {item.last && <DateItem data={item.last} />}
                    </td>
                  ) : null}

                  {!isMobileScreen &&
                  !isMediumScreen &&
                  location !== 'dashboard' ? (
                    <td className="text-left">
                      {item.coins > 0 ? (
                        <>
                          <NumberItem number={item.coins} /> Coins
                        </>
                      ) : null}
                    </td>
                  ) : null}

                  {!isMobileScreen &&
                  !isMediumScreen &&
                  location !== 'dashboard' ? (
                    <td className="text-left">
                      {item.percent ? (
                        <span>
                          {item.percent % 1 === 0 ? null : 'Ø '}
                          <NumberItem number={item.percent} precision={1} /> %
                        </span>
                      ) : null}
                    </td>
                  ) : null}

                  <td className="text-right">
                    <NumberItem
                      number={item.revenue}
                      precision={precision}
                      currency={currency}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="results-row">
        <span>{results[0].data}</span>
        <span className="end-result">
          <NumberItem
            number={results[1].data}
            precision={precision}
            currency={currency}
          />
        </span>
      </div>
    </>
  );
}

export default ArticlesExtrasTable;
