export default {
  ROUTE_MY_PROFILE_HEADLINE: `Ändere hier Deine Profilbilder und verwalte Deine öffentlichen Angaben`,
  PROFILE_PICTURES_UPLOAD_HEADLINE: `Upload Bilder`,
  PROFILE_PICTURES_TEXT_1: `Du kannst neben Deinem Profilbild, welches Du bei Deiner Registrierung hochgeladen hast, weitere Bilder für Dein Profil veröffentlichen. Hierbei kann es sich auch um freizügigere und Bilder mit Teilkörperaufnahmen handeln, sofern Du das möchtest.`,
  PROFILE_PICTURES_TEXT_2: `Um ein Bild hochzuladen kannst Du dieses einfach per „Drag and Drop“ in den weißen, blau umrandeten Bereich ziehen. Oder Du klickst das + an um eine Datei von Deinem Computer oder Smartphone auszuwählen. Um ein Bild mit Deiner Webcam aufzunehmen klicke einfach auf das Feld mit der Kamera.`,
  PROFILE_PICTURES_TEXT_3: `Bilder sind im jpg, jpeg oder png Format erlaubt und benötigen eine Auflösung von <span class="bold">mindestens 1080 x 1080 Pixel</span>. Bitte beachte dabei den richtigen FSK Level! Siehe hierzu auch die Info!`,
  PROFILE_PICTURE_POSING_HEADLINE: `Zeige Dich von Deiner besten Seite`,
  PROFILE_PICTURE_POSING: `Sei kreativ und lass Dich von unseren Beispielbildern inspirieren. Wähle ein Motiv von Dir, mit dem Du Dich wohl fühlst und wie Du gern gesehen
wirst.`,
  PROFILE_PICTURE_POSING_NOFACE_HEADLINE: `Du möchtest Dein Gesicht nicht zeigen?`,
  PROFILE_PICTURE_NOFACE_POSING: `Kein Problem! Es gibt viele reizvolle Alternativen für ein ansprechendes Profilbild: Du kannst zum Beispiel eine Maske, Sonnenbrille oder einen großen Hut tragen, um Dein Gesicht zu verbergen. Zeige Dich von hinten und posiere ohne erkennbares Gesicht. Ein gut platzierter Ausschnitt von einem tollen Bild von Dir funktioniert auch wunderbar, ohne Dein Gesicht zu zeigen.`,
  PROFILE_PICTURES_LABEL_NON_NUDE: `FSK12 - Non nude`,
  PROFILE_PICTURES_VIDEO_HEADLINE: `Profilbild Erklärung`,
  PROFILE_PICTURES_LABEL_NON_NUDE_DESC: `
    <ul>
        <li>Vollständig bekleidet</li>
        <li>Kein erotisches Motiv</li>
        <li>Du musst als Person erkennbar sein, also keine reinen Körperteile</li>
    </ul>`,
  PROFILE_PICTURES_LABEL_SOFTCORE: `FSK16 - Softcore`,
  PROFILE_PICTURES_LABEL_SOFTCORE_DESC: `
    <ul>
        <li>Erotisches Motiv</li>
        <li>Leicht bekleidet, Dessous oder ähnliches</li>
        <li>Bikini</li>
        <li>Oben ohne</li>
        <li>Nackt – OHNE sichtbaren Intimbereich</li>
    </ul>`,
  PROFILE_PICTURES_HARDCORE_LABEL_INFO: `Ich möchte auch ein explizites Bild hochladen`,
  PROFILE_PICTURES_LABEL_HARDCORE: `FSK18 - Hardcore`,
  PROFILE_PICTURES_LABEL_HARDCORE_DESC: `
    <ul>
        <li>Sichtbarer Intimbereich</li>
        <li>Sexuelle Handlung</li>
        <li>KEINE reine Nahaufnahme von Körperteilen</li>
    </ul>`,
  PROFILE_PICTURES_COMMENT_UNDER_REVIEW: `In Bearbeitung vom Support.`,
  DEFAULT_PROFILE_PICTURE_LABEL: `Wähle das Profilbild, welches ein User zuerst sieht: `,
  PROFILE_PICTURES_INFO_HEADLINE: `Infos zum richtigen FSK Level`,
  PROFILE_PICTURES_INFO_TEXT_1: `Für die Darstellung in unterschiedlichen Bereichen und Medien, müssen alle Bilder einem bestimmten FSK Level zugeordnet werden.<br />Es gibt folgende Unterscheidungen:`,
  PROFILE_PICTURES_INFO_TEXT_2: `<span class="bold">Wichtig:</span><br />Bilder aus dem Internet sind im Profil, wie überall, verboten! Keine fremden Personen auf den Profilbildern!`,
  PROFILE_PICTURE_CONVERTING_VIDEO: `Dein Video wird konvertiert. Es erscheint hier, sobald der Vorgang abgeschlossen ist.`,

  PROFILE_VIDEO_HEADLINE_1: `Upload Video`,
  PROFILE_VIDEO_TEXT_1: `Neben Deinen Profilbildern kannst Du zusätzlich ein Vorstellungsvideo für Dein Profil hochladen.`,
  PROFILE_VIDEO_TEXT_2: `Um ein Video hochzuladen kannst Du dieses einfach per „Drag and Drop“ in den weißen, blau umrandeten Bereich ziehen. Oder Du klickst das + an um eine Datei von Deinem Computer oder Smartphone auszuwählen. Wir akzeptieren die folgenden Formate: wmv, avi oder mpg4.`,
  PROFILE_VIDEO_INFO_HEADLINE: `Tipps für Dein Video`,
  PROFILE_VIDEO_INFO_TEXT_1: `Mit Deinem persönlichen Profil Video kannst Du Dich in einem kurzen Film präsentieren um das Interesse der User zu wecken.`,
  PROFILE_VIDEO_INFO_TEXT_2: `Das Video wird auch in Deinem Livecam-Profil angezeigt.`,
  PROFILE_VIDEO_INFO_TEXT_3: `<span class='bold'>Beachte bitte:</span>
    <ul>
        <li>Kein FSK18 Inhalt erlaubt!</li>
        <li>Nicht zu lang, maximal 1 Minute genügt.</li>
        <li>Licht und Ton ok? Setze Dich richtig in Szene!</li>
    </ul>`,
  PROFILE_VIDEO_DELETE_LABEL: `Das aktuelle Video entfernen`,
  PROFILE_VIDEO_UPLOAD_HEADLINE: `Upload`,

  PROFILE_TEXT_HEADLINE_1: `Wecke die Neugier Deiner Profilbesucher`,
  PROFILE_TEXT_LABEL_ABOUT_MYSELF: `Was ich über mich sagen kann:`,
  PROFILE_TEXT_LABEL_ABOUT_ME: `Was über mich gesagt wird:`,
  PROFILE_TEXT_LABEL_EROTIC_FANTASY: `Meine erotische Fantasie:`,
  PROFILE_TEXT_INFO_HEADLINE: `Tipps für Deine Profiltexte`,
  PROFILE_TEXT_INFO_TEXT_1: `Dein Profiltext soll in erster Linie das Interesse der User wecken und diese dazu animieren Dich anzuschreiben. Dabei solltest Du dem User schildern, was Du suchst, warum Du in unseren Communities angemeldet bist, was Deine Vorlieben sind oder wie andere Dich einschätzen. Je genauer Du Dich beschreibst, desto mehr kann der User auf Dich eingehen. Sei dabei aber auch ein wenig unverbindlich, denn damit bleibst Du interessant und ein User hat mehr Gründe nachzufragen.`,

  PUBLIC_DETAILS_HEADLINE_1: `Ich komme aus`,
  PUBLIC_DETAILS_TEXT_1: `Hier kannst Du entscheiden, welche Angaben öffentlich angezeigt werden. Diese Angaben haben keinen Einfluss auf Deine offizielle Adresse, die Du unter "Meine Daten" angegeben hast. Bitte beachte, wenn Du hier eine Veränderung vornimmst, kann es bis zu 2 Stunden dauern, bis alle Bereiche in den Communities aktualisiert sind.`,
  PUBLIC_DETAILS_FIELD_ZIP_AREA: `PLZ Bereich:`,

  PUBLIC_DETAILS_HEADLINE_2: `Internationale Einstellungen`,
  PUBLIC_DETAILS_FIELD_NATIVE_LANGUAGE: `Meine Muttersprache:`,
  PUBLIC_DETAILS_HEADLINE_3: `Persönliche Informationen`,
  PUBLIC_DETAILS_FIELD_AGE: `Alter:`,
  PUBLIC_DETAILS_FIELD_HEIGHT: `Größe:`,
  PUBLIC_DETAILS_FIELD_WEIGHT: `Gewicht:`,
  PUBLIC_DETAILS_FIELD_TYPE: `Vom Typ her bin ich:`,
  PUBLIC_DETAILS_FIELD_MARITAL_STATUS: `Beziehungsstatus:`,
  PUBLIC_DETAILS_FIELD_TATTOOS: `Tattoos:`,
  PUBLIC_DETAILS_FIELD_PIERCINGS: `Piercings:`,
  PUBLIC_DETAILS_FIELD_GLASSES: `Brillenträger:`,
  PUBLIC_DETAILS_FIELD_EYE_COLOR: `Augenfarbe:`,
  PUBLIC_DETAILS_FIELD_HAIR_COLOR: `Haarfarbe:`,
  PUBLIC_DETAILS_FIELD_HAIR_LENGTH: `Haarlänge:`,
  PUBLIC_DETAILS_FIELD_FIGURE: `Figur:`,
  PUBLIC_DETAILS_FIELD_BODY_HAIR: `Körperbehaarung:`,
  PUBLIC_DETAILS_FIELD_PUBIC_HAIR: `Schambehaarung:`,
  PUBLIC_DETAILS_FIELD_CUP_SIZE: `Körbchengröße:`,
  PUBLIC_DETAILS_FIELD_SMOKING: `Raucher:`,
  PUBLIC_DETAILS_HEADLINE_4: `Meine Vorlieben`,
  PUBLIC_DETAILS_LOOKING_FOR_LABEL: `Was ich suche, ist`,
  PUBLIC_DETAILS_EXPERIENCE_LABEL: `Was ich erleben will, ist`,
  PUBLIC_DETAILS_PREFERENCE_LABEL: `Was mich antörnt, ist`,
  PUBLIC_DETAILS_INFO_HEADLINE: `Hinweis zu Deinen öffentlichen Angaben`,
  PUBLIC_DETAILS_INFO_TEXT_1: `Alle Informationen, die Du hier angibst, erscheinen auf Deinem Profil, sichtbar für alle Community User.`,
  PUBLIC_DETAILS_INFO_TEXT_2: `Du musst nicht alles über Dich preisgeben. Bedenke jedoch: Je mehr Du angibst, desto besser können User Dich finden und umso reizvoller kannst Du dich innerhalb der Communities hervor heben!`,

  MY_PUBLIC_DETAILS_HEADLINE_1: `Vorschau AmateurX`,
  MY_PUBLIC_DETAILS_HEADLINE_2: `Vorschau FunCommunity`,
  MY_PUBLIC_DETAILS_HEADLINE_3: `Vorschau AmateurFans`,
  LABEL_MY_PROFILE_IN_THIS_VERSION: `Mein Profil in dieser Version`,
  MY_PUBLIC_DETAILS_TEXT_1: `Hier haben wir ein helles Portal, welches sich in die Welt der Social Media einreiht.`,
  MY_PUBLIC_DETAILS_TEXT_2: `Bei FunCommunity handelt es sich um ein Casual Dating Portal im dunkleren Stil.`,
  MY_PUBLIC_DETAILS_TEXT_3: `AmateurFans ist eines unserer neuesten Portale in einem DarkMode Stil.`,
  MY_PUBLIC_DETAILS_INFO_HEADLINE: `Warum gibt es unterschiedliche Versionen meines Profils?`,
  MY_PUBLIC_DETAILS_INFO_TEXT_1: `Mit LiveCreator hast Du den Vorteil, zeitgleich auf 20+ Community-Portalen live zu gehen. Alles, was Du dafür benötigst, ist Dein Creator-Account und eine aussagekräftige Profilbeschreibung mit Profilbild. Sinnvollerweise ergänzt Du Dein Profil noch mit weiterem Content und regelmäßigen Updates.
  </br>
  </br>
  Natürlich sehen die Community-Portale nicht alle gleich aus. Hier gibt es sowohl inhaltlich und thematisch als auch farblich und strukturell große Unterschiede im Erscheinungsbild. Hinsichtlich der unterschiedlichen Designs musst Du aber nicht selber aktiv werden. LiveCreator bettet Deine Profilinhalte immer passend in die jeweiligen Community-Portale ein. So stellen wir immer die beste Version Deines Profils bereit.
  </br>
  </br>
  Du kannst Dir anhand unserer drei Partner-Portale <a href="https://www.AmateurX.com" target="blank">www.AmateurX.com</a>, <a href="https://www.FunCommunity.com" target="blank">www.FunCommunity.com</a> und <a href="https://app.AmateurFans.com" target="blank">www.AmateurFans.com</a> einen beispielhaften Eindruck verschaffen, wie das aussehen kann.`,
};

