import { completeRehydration } from '../';

export default () => (dispatch, getState) => {
  const {
    sender: { isAuthenticated },
    persist: { rehydrated },
  } = getState();
  if (isAuthenticated && rehydrated) {
    dispatch(completeRehydration());
  }
};
