export default {
  PHOTOS_INFO_1: `<span class="bold">Neue Galerie anlegen</span><br />Mit dem Button „Neue Galerie erstellen“ kannst Du direkt starten. Um ein Bild hochzuladen kannst Du dieses einfach per „Drag and Drop“ in den weißen, blau umrandeten Bereich ziehen. Oder Du klickst das + an um eine Datei von Deinem Computer oder Smartphone auszuwählen.`,
  PHOTOS_INFO_1_1: `Du kannst auch mehrere Bilder in einem ZIP-Archiv hochladen. Diese werden automatisch in die Galerie eingepflegt. Alle Bilder müssen im JPG Format sein!`,
  PHOTOS_INFO_2: `<span class="bold">Fotos sortieren</span><br />Bringe Deine Fotos einfach per „Drag and Drop“ in die gewünschte Sortierung.`,
  PHOTOS_INFO_3: `<span class="bold">Bilder hinzufügen oder Löschen</span><br />Du kannst einer Galerie jederzeit weitere Fotos hinzufügen. Ungeprüfte Bilder kannst Du auch wieder löschen.`,

  PHOTO_GALLERY_REQUIREMENTS_LIST: `<ul>
      <li>mindestens 3 Fotos im jpg, jpeg oder png Format</li>
      <li>der Galerie Titel</li>
      <li>eine kurze Beschreibung</li>
      <li>ein Vorschaubild</li>
      <li>mindestens eine Kategorie</li>
      <li>zugewiesene(s) Darsteller-Dokument(e)</li>
    </ul>`,

  PHOTOS: `Fotos`,
  PHOTO_PRICE_ADDITIONAL_LABEL_1: ` 2 Coins entsprechen 0,20 €`,
  PHOTO_PRICE_ADDITIONAL_LABEL_2: `Minimum: 1, Max: 50 Coins`,
  PHOTO_COINS_PER_PHOTO: `pro Foto`,
  COINS_PER_PHOTO: `Coins pro Foto:`,
  PHOTO_GALLERY_MENU_ADD_IMAGES: `Fotos hinzufügen`,
  PHOTO_GALLERY_MENU_USE_AS_PREVIEW: `Als Vorschaubild nutzen`,

  PHOTO_IS_CONVERTING: `Das Bild wird konvertiert`,
};
