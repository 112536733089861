import { gameUtils, user as userUtils } from '../../../../utils';
import { GAME_INVITATION_STATUS } from '../../../actions';

export default (data) => async (dispatch, getState) => {
  try {
    if (userUtils.gamesBlocked(getState())) return;
    const payload = gameUtils.addPartner(data?.payload);
    const {
      camUser: { camUsers },
      chat: { selectedUser },
      dialog: { dialogs, processQueue, queue },
      user: {
        account: { nickname },
      },
    } = getState();
    const allDialogs = [...dialogs, ...processQueue, ...queue];
    const selectedLivecamUser = camUsers.find((u) => u.userId === selectedUser);
    const isSelected = !selectedLivecamUser
      ? selectedUser === payload?.partner
      : userUtils.getPartner(selectedLivecamUser) === payload?.partner;
    const userId = isSelected && selectedUser;
    let user = selectedLivecamUser;
    if (!user) {
      user = allDialogs.find((d) => d.partner === selectedUser);
    }
    dispatch({
      type: GAME_INVITATION_STATUS,
      meta: {
        isSelected,
        userId,
        to: user?.name,
        from: nickname,
      },
      payload,
    });
  } catch (err) {
    console.log(err);
  }
};
