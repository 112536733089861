import './PhotoItem.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import checkIcon from '../../../assets/img/svg/check.svg';
import backIcon from '../../../assets/img/svg/chevron-single-left.svg';
import forwardIcon from '../../../assets/img/svg/chevron-single-right.svg';
import menuIcon from '../../../assets/img/svg/edit.svg';
import noPhoto from '../../../assets/img/svg/no-picture.svg';
import searchIcon from '../../../assets/img/svg/search.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import { uuid } from '../../../utils';
import { checkImage, getImageSource } from '../../Uploader/Uploader.functions';

const statusLabelMap = {
  fsk12: 'FSK_CASUAL_LABEL',
  fsk16: 'FSK_SOFT_LABEL',
  fsk18: 'FSK_HARD_LABEL',
};

function PhotoItem({
  item,
  statusClassName,
  openPictureCropper,
  galleryStatus,
  spinner,
  itemDeleted,
  activeGallery,
  galleryThumbnail,
  openThumbnail,
  setImageError,
  menuVisible,
  setMenuVisible,
  openOverlay,
  thumbnailRejected,
  changeItemPosition,
  isMobile,
  tickOrText,
  photoGalleryDeleteDisabled,
  showMediumOnPreview,
}) {
  const itemPath = item.file
    ? `${item.file.slice(0, -4)}${galleryThumbnail ? '' : '-pw'}.jpg`
    : '';
  const largeItemPath =
    item.large_file && !galleryThumbnail
      ? item.large_file
      : item.file
      ? `${item.file.slice(0, -4)}.jpg`
      : '';

  const intl = useIntl();

  const menuRef = useRef();
  const menuIconRef = useRef();
  const openingDocumentRef = useRef();

  async function openImage() {
    try {
      spinner(true);
      const response = await fetch(
        `${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${largeItemPath}`
      );
      let data = await response.blob();
      let metadata = {
        type: 'image/jpeg',
      };
      let file = new File(
        [data],
        `preview${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        metadata
      );
      const iSource = await checkImage(await getImageSource(file, intl), intl, {
        width: 1024,
        height: 576,
      });
      openPictureCropper(file, iSource);
      spinner(false);
    } catch (error) {
      setImageError(error.message);
    }
  }

  const [, deletePhotoRequest] = useAxios(
    {
      url: `/galleries/${activeGallery?.id}/picture/${item.id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  function deleteImage() {
    spinnerRequest({
      request: deletePhotoRequest,
      spinner,
    })
      .then(() => itemDeleted(item.id))
      .catch(() => {});
  }

  const galleryMenuClass = classNames('sub-menu', {
    'mobile-photo-item': galleryThumbnail,
  });

  const thumbnailClass = classNames('thumbnail-wrapper', {
    'gallery-thumbnail': galleryThumbnail,
  });

  const photoMenuIconClass = classNames('photo-menu', {
    open: menuVisible,
  });

  useOutsideClick(
    menuRef,
    () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    },
    menuIconRef
  );

  const openDocument = (title, fileSource) => {
    openingDocumentRef.current = true;
    spinner(true);
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Document',
      props: {
        openDocumentStamp: stamp,
        title,
        fileSource: showMediumOnPreview
          ? fileSource.replace('-pw', '-medium')
          : fileSource,
      },
    });
    spinner(false);

    openingDocumentRef.current = false;
  };

  return (
    <div className="thumbnail-item" id={`thumbnail-item-${item.id}`}>
      <div className={thumbnailClass}>
        <div className="thumbnail">
          <div
            className="thumbnail-image"
            style={{
              backgroundImage: `${
                itemPath.length !== 0
                  ? `url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${itemPath}')`
                  : `url('${noPhoto}')`
              }`,
            }}
          />
          {item.status === 'confirmed' && isMobile && (
            <>
              <div
                className="swap-back-wrapper"
                onClick={() => changeItemPosition(item.id, 'backward')}
              >
                <ReactSVG
                  src={backIcon}
                  className={'swap-icon'}
                  wrapper="span"
                />
              </div>{' '}
              <div
                className="swap-forward-wrapper"
                onClick={() => changeItemPosition(item.id, 'forward')}
              >
                <ReactSVG
                  src={forwardIcon}
                  className={'swap-icon'}
                  wrapper="span"
                />
              </div>
            </>
          )}
          {itemPath.length !== 0 && (
            <div
              className="fullscreen-icon-wrapper"
              onClick={() =>
                !openingDocumentRef.current &&
                openDocument(
                  intl.formatMessage({ id: 'GALLERY_IMAGE_LABEL' }),
                  `${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${largeItemPath}`
                )
              }
            >
              <ReactSVG
                src={searchIcon}
                className={'fullscreen-icon'}
                wrapper="span"
              />
            </div>
          )}
          <div
            className={photoMenuIconClass}
            ref={menuIconRef}
            onClick={() => {
              if (!menuVisible) {
                setMenuVisible(true);
              }
            }}
          >
            <ReactSVG src={menuIcon} className={'menu-icon'} wrapper="span" />
          </div>
          {menuVisible && (
            <div
              className={galleryMenuClass}
              id={`photo-menu${item.id}`}
              ref={menuRef}
            >
              {galleryThumbnail ? (
                <div
                  className="gallery-menu-item"
                  onClick={() => {
                    openThumbnail();
                    setMenuVisible(false);
                  }}
                >
                  {intl.formatMessage({
                    id: 'GALLERY_MENU_UPLOAD_NEW_PREVIEW',
                  })}
                </div>
              ) : (
                <>
                  <div
                    className="gallery-menu-item"
                    onClick={() => {
                      openImage();
                      setMenuVisible(false);
                    }}
                  >
                    {intl.formatMessage({
                      id: 'PHOTO_GALLERY_MENU_USE_AS_PREVIEW',
                    })}
                  </div>
                  {item.status !== 'confirmed' &&
                    !photoGalleryDeleteDisabled && (
                      <div
                        className="gallery-menu-item"
                        onClick={() => {
                          deleteImage();
                          setMenuVisible(false);
                        }}
                      >
                        {intl.formatMessage({ id: 'GALLERY_MENU_DELETE' })}
                      </div>
                    )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {(galleryStatus === 'published' ||
        galleryStatus === 'archived' ||
        galleryStatus === 'blocked') &&
        !galleryThumbnail && (
          <>
            <div className="textbox">
              <div className="textbox-stats">
                <span className="number">{item.sales !== 0 && item.sales}</span>{' '}
                {item.sales
                  ? intl.formatMessage({ id: 'TIMES_SOLD' })
                  : intl.formatMessage({ id: 'NOT_SOLD_YET' })}{' '}
              </div>
            </div>
            {item.status === 'confirmed' && (
              <div className={statusClassName}>
                {tickOrText === 'text' ? (
                  intl.formatMessage({ id: statusLabelMap[item.fsk] })
                ) : (
                  <ReactSVG
                    src={checkIcon}
                    wrapper="span"
                    className="fsk12-badge-icon"
                  />
                )}
              </div>
            )}
          </>
        )}
      {galleryThumbnail && (
        <div className={statusClassName}>
          {' '}
          {thumbnailRejected
            ? intl.formatMessage({ id: 'LABEL_REJECTED' })
            : intl.formatMessage({ id: 'LABEL_PREVIEW' })}
        </div>
      )}
    </div>
  );
}

export default PhotoItem;

