import './Game4Score.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactSVG } from 'react-svg';

import fourScoreIcon from '../../../../assets/img/4score/4score.svg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { getPortal } from '../../../../utils/portals';
import Usergame from '../../../Livecam/UserGame/UserGame';
import Scrollbar from '../../../Scrollbar';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import DateItem from '../../../Tables/TableItems/DateItem';

function Game4Score({ user, lang, openDialog }) {
  const meId = user?.account?.id;
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const [games, setGames] = useState([]);
  const [namesLoaded, setNamesLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(0);
  const [, getProfile] = useAxios(
    {
      url: '/',
    },
    { manual: true }
  );

  const [{ data, loading, error }] = useAxios({
    url: `/user/games/finished?limit=30`,
  });
  useEffect(() => {
    if (loading) return;
    if (error) return console.log({ game4ScoreLoadingError: error });
    (async () => {
      if (data && !namesLoaded) {
        if (data?.length) {
          setGames(data);
          const newData = JSON.parse(JSON.stringify(data));
          for (let i = 0; i < newData.length; i++) {
            try {
              const id = newData[i]?.fromId.replace('-', '@');
              const clientProductId = id.split('@')[0];

              const response = await getProfile(
                {
                  url: `/profile/${id}/minimal`,
                },
                { skipAttachingAuthToken: true }
              );

              let nickname = response?.data?.info?.nickname;
              try {
                const portals = await getPortal(clientProductId);
                nickname += ` | ${portals?.whitelabelShort}`;
              } catch (error) {
                console.log({ getPortalErrorGame4Score: error });
              }
              newData[i].username = nickname;

              if (i === 0) {
                if (newData[i]?.session && newData[i]?.status !== 'timedOut') {
                  setIsOpen(newData[i]?.id);
                }
              }
            } catch (error) {
              console.log({ game4scoreLoadProfileError: error });
            }
          }

          setGames(newData);
          setNamesLoaded(true);
        }
      }
    })();
  }, [loading, data, error, getProfile, namesLoaded]);

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="stats-wrapper game4score-wrapper">
        <Scrollbar visible={isLargeScreen} isLargeScreen={isLargeScreen}>
          <div className="data-wrapper">
            <h1 className="headline">
              <FormattedMessage id="GAME_WON_OR_LOST" />
            </h1>

            {loading ? (
              <SpinnerComponent />
            ) : (
              <div className="games-wrapper">
                {games.map((game) => {
                  if (!game?.id) return null;
                  if (!game?.session) {
                    game.session = {};
                  }
                  const {
                    id,
                    fromId,
                    createdAt,
                    status,
                    coins,
                    session: {
                      winnerId,
                      status: sessionStatus,
                      decisionReason,
                    },
                  } = game;

                  const winner =
                    winnerId &&
                    sessionStatus === 'decided' &&
                    winnerId === meId;
                  const loser =
                    winnerId &&
                    sessionStatus === 'decided' &&
                    winnerId !== meId;
                  const draw = status === 'accepted' && !winnerId;
                  const meTimeout = loser && decisionReason === 'timeout';
                  const userTimeout = winner && decisionReason === 'timeout';
                  const coinsValue =
                    (winner || draw || (loser && !meTimeout)) &&
                    coins !== 0 &&
                    coins;

                  const stateCss = classNames('match-state', {
                    winner,
                    loser,
                    draw,
                    'me-timeout': meTimeout,
                  });

                  const matchCss = classNames('match', {
                    open: parseInt(isOpen, 10) === id,
                    unclickable: status !== 'accepted',
                  });

                  const valueCss = classNames('coin-value', {
                    'no-values': !coinsValue,
                  });

                  return (
                    <div
                      key={id}
                      className={matchCss}
                      onClick={() => {
                        if (status !== 'accepted') {
                          return false;
                        }
                        if (parseInt(isOpen, 10) === id) {
                          return setIsOpen(0);
                        }
                        setIsOpen(id);
                      }}
                    >
                      <div className="text-box">
                        <ReactSVG
                          src={fourScoreIcon}
                          className="icon"
                          wrapper="span"
                        />
                        <div className="inner">
                          <div className="user-info">
                            <span className="match-date">
                              {status === 'accepted'
                                ? intl.formatMessage({ id: 'GAME_MATCH_FROM' })
                                : intl.formatMessage({
                                    id: 'GAME_INVITATION_FROM',
                                  })}{' '}
                              <DateItem data={createdAt} />
                              <span>
                                {status === 'accepted'
                                  ? intl.formatMessage({
                                      id: 'GAME_MATCH_WITH',
                                    })
                                  : null}
                              </span>
                            </span>
                            <span className="match-user">
                              <Link
                                onClick={() => openDialog(fromId)}
                                to="/multi-messenger"
                              >
                                {game.username}
                              </Link>
                            </span>
                          </div>
                          <div className={stateCss}>
                            {status === 'accepted'
                              ? winnerId && sessionStatus === 'decided'
                                ? winner && userTimeout
                                  ? intl.formatMessage({
                                      id: 'GAME_MATCH_WINNER_TIMEOUT',
                                    })
                                  : winner
                                  ? intl.formatMessage({
                                      id: 'GAME_MATCH_WINNER',
                                    })
                                  : loser && !meTimeout
                                  ? intl.formatMessage({
                                      id: 'GAME_MATCH_LOSER',
                                    })
                                  : intl.formatMessage({
                                      id: 'GAME_MATCH_TIMEOUT_REFUND',
                                    })
                                : intl.formatMessage({ id: 'GAME_MATCH_DRAW' })
                              : status === 'timedOut'
                              ? intl.formatMessage({
                                  id: 'GAME_INVITATION_TIMEOUT_REFUND',
                                })
                              : ''}
                          </div>
                          <div className={valueCss}>
                            {coinsValue && `${coinsValue} Coins`}
                          </div>
                        </div>
                      </div>
                      <div className="game-box">
                        <Usergame
                          id={game.id}
                          enableResizable={false}
                          componentUrl={
                            process.env.REACT_APP_GAME_URL +
                            game.game.webComponentLoaderPath
                          }
                          usergame={true}
                          lang={lang}
                          onlyGame={true}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </Scrollbar>
        <Scrollbar visible={isLargeScreen} hasBoxShadow={true}>
          <div className="info-wrapper">
            <h2 className="headline">
              <FormattedMessage id="STATS_INFO_HEADER" />
            </h2>
            <p>
              <FormattedMessage id="STATS_GAME" />
            </p>
            <p>
              <FormattedMessage id="STATS_GAME_1" />
            </p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}
export default Game4Score;
