import api from '../../utils/api';
import { spinner, updateFilePercent } from '../';
import { UPLOAD_FILE } from '../actions';
export default function (path, data, indicator, disableSpinner) {
  return async (dispatch) => {
    let config = {
      onUploadProgress: function (progressEvent) {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(updateFilePercent(percentCompleted));
      },
    };

    if (!data && indicator) {
      dispatch({
        type: UPLOAD_FILE,
        meta: {
          end: true,
          indicator,
        },
      });
      return dispatch(spinner(false));
    }

    if (!disableSpinner) {
      dispatch(spinner(true));
    }

    try {
      const result = await api.post(path, data, config);
      updateFilePercent(null);
      dispatch({
        type: UPLOAD_FILE,
        payload: result,
        meta: { indicator },
      });
      dispatch(spinner(false));
      return result;
    } catch (error) {
      dispatch({
        type: UPLOAD_FILE,
        error: true,
        payload: error,
        meta: { indicator },
      });
    }
    dispatch(spinner(false));
  };
}
