// Locales need to be loaded before they can be used.
// If additional languages are used in the future, they need to be loaded here to be used inside dayjs
// English does not need loading since it is loaded by default
import 'dayjs/locale/de';

import dayjs from 'dayjs';

import { PERSIST_BASE_KEY } from '../../constants';
import { default as locales } from '../../locale';
import api from '../../utils/api';
import crypt from '../../utils/persist/crypt';
import updateVersion from '../socketService/events/version/update';
import {
  addOptions,
  getAttachment,
  getContests,
  getCountries,
  getGames,
  getLanguages,
  getPayttachment,
  getPreferences,
  getRegards,
  getSexIcons,
  socketService,
  timerFriendRequest,
  tokenLoginSender,
  updateIntl,
  updateKpi,
  userLoggedIn,
  userLogout,
  userUpdate,
} from '..';

export default (userId) => async (dispatch, getState) => {
  try {
    if (!getState().user?.access_token) return;
    const firstaccess_token = getState().user?.access_token;
    const accountResponse = await api.get(`/user/account`);

    if (!getState().user?.access_token || !accountResponse?.data) return;
    const accountData = accountResponse.data;
    const username = accountData.nickname;
    const livecam = accountData.livecam;
    const state = accountData.state;

    const privateResponse = await api.get(`/user/private`);

    if (!getState().user?.access_token || !privateResponse?.data) return;
    const privateData = privateResponse.data;

    const publicResponse = await api.get(`/user/public`);

    if (
      !getState().user?.access_token ||
      getState().user?.access_token !== firstaccess_token ||
      !publicResponse?.data
    )
      return;

    const publicData = publicResponse.data;
    accountData.showLivecamTip = state === 'confirmed' && livecam !== 'true';
    if (livecam === 'true' && !localStorage.getItem('livecam')) {
      localStorage.setItem('livecam', 'true');
    } else if (livecam === 'false' && localStorage.getItem('livecam')) {
      localStorage.removeItem('livecam', 'true');
    }
    if (!accountData?.scopes?.includes('livecam')) {
      accountData.livecam = 'false';
    }

    if (
      [
        'onboarding',
        'double_optin_needed',
        'review_needed',
        'pending',
        'confirmed',
        'unconfirmed',
      ].includes(state)
    ) {
      const response = await api.get('/user/options');

      if (
        !getState().user?.access_token ||
        getState().user?.access_token !== firstaccess_token ||
        !publicResponse?.data
      )
        return;
      dispatch(addOptions(response.data));
    }

    dispatch(
      userUpdate({
        account: accountData,
        private: privateData,
        public: publicData,
      })
    );

    dispatch(
      updateIntl({
        locale: accountData.language,
        messages: locales[accountData.language],
      })
    );

    dayjs.locale(accountData.language);

    crypt.init(PERSIST_BASE_KEY + username.toLowerCase());
    dispatch(userLoggedIn(accountData, userId));
    dispatch(getCountries());
    dispatch(getLanguages());
    dispatch(getPreferences());

    if (state === 'confirmed' || state === 'unconfirmed') {
      dispatch(updateKpi());
      dispatch(timerFriendRequest.start());
    }

    if (state === 'confirmed') {
      dispatch(getPayttachment());
      dispatch(getAttachment());
      dispatch(getSexIcons());
      dispatch(getRegards());
      dispatch(getContests());
      await dispatch(getGames());
      dispatch(socketService.connect());
    }

    dispatch(updateVersion(null, true));

    if (livecam !== 'true') {
      return;
    }
    dispatch(tokenLoginSender(username));
  } catch (err) {
    console.log({ getUserDataErr: err });
    dispatch(userLogout(true));
  }
};
