import {
  FONTSIZE_CHANGE,
  PREFERENCES_ADD,
  SAFE_MODE_CHANGE,
  USER_LOGOUT,
} from '../actions/actions';
import { DEFAULT_CHAT_FONT_SIZE, POSSIBLE_CHAT_FONT_SIZES } from '../constants';

const initialState = {
  fontsize: {
    possible: POSSIBLE_CHAT_FONT_SIZES,
    selected: DEFAULT_CHAT_FONT_SIZE,
  },
  loaded: false,
};

function preferences(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT:
      return initialState;
    case FONTSIZE_CHANGE:
      return {
        ...state,
        fontsize: {
          ...initialState.fontsize,
          selected: action.payload,
        },
        loaded: true,
      };
    case PREFERENCES_ADD:
      return {
        ...initialState,
        ...action.payload,
      };
    case SAFE_MODE_CHANGE: {
      return {
        ...state,
        safeMode: action.payload,
      };
    }
    default:
      return state;
  }
}

export default preferences;
