import './AttachmentItem.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import movieIcon from '../../../assets/img/svg/movie.svg';
import noPictureIcon from '../../../assets/img/svg/no-picture.svg';

function AttachmentItem({
  selectAttachment,
  value,
  label,
  file,
  selectedAttachment,
  type,
  disabled,
}) {
  const [imageExists, setImageExists] = useState(false);

  const classes = classNames('attachment-thumbnail-wrapper', {
    selected: selectedAttachment && selectedAttachment === value,
  });

  const attachmentThumbnailClasses = classNames('attachment-thumbnail', {
    disabled: disabled,
    unclickable: !selectAttachment,
  });

  const attachmentLabelClasses = classNames('attachment-label', {
    disabled: disabled,
    unclickable: !selectAttachment,
  });

  useEffect(() => {
    if (type.includes('video')) return;
    const checkImage = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${file}`
        );
        if (response.status === 200) {
          setImageExists(true);
        }
      } catch (error) {}
    };
    checkImage();
  }, [file, type]);

  return (
    <div
      className={classes}
      onClick={() =>
        !disabled && selectAttachment && selectAttachment(value, label)
      }
    >
      {type.includes('video') ? (
        <div className={`${attachmentThumbnailClasses} movie`}>
          <ReactSVG src={movieIcon} wrapper="span" className="icon" />
        </div>
      ) : imageExists ? (
        <div
          className={attachmentThumbnailClasses}
          style={{
            backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${file}')`,
          }}
        />
      ) : (
        <div className={`${attachmentThumbnailClasses} no-image`}>
          <ReactSVG src={noPictureIcon} wrapper="span" className="icon" />
        </div>
      )}
      {label && <div className={`${attachmentLabelClasses}`}>{label}</div>}
    </div>
  );
}

export default AttachmentItem;

