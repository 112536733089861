export default {
  ROUTE_PERSONAL_DETAILS_HEADLINE: `Here you can change or update your personal and account information`,
  ACCOUNT_DETAILS_HEADLINE: `You are registered with these details`,
  FIELD_CURRENT_PASSWORD: `Current password:`,
  FIELD_NEW_PASSWORD: `New password:`,
  FIELD_REPEAT_PASSWORD: `Repeat password:`,

  PERSONAL_DATA: `Private personal data`,
  FIELD_STREET_ADDRESS: `Street address:`,
  FIELD_STREET_ADDRESS2: `Street continue:`,
  FIELD_ZIP_AND_CITY: `Zip & City:`,
  FIELD_LIVINGCOUNTRY: `Home country:`,
  FIELD_ZIP_CODE: `Zip Code`,
  FIELD_CONTACT: `Contact`,
  FIELD_PHONE: `Phone:`,
  FIELD_SKYPE: `Skype:`,
  PERSONAL_DETAILS_FOR_BUSINESS: `For businesses <span class="subtext">(optional)</span>`,
  FIELD_COMPANY: `Company name:`,
  FIELD_VAT: `VAT number:`,

  CHANGE_ADDRESS: 'Change address',
  CHANGE_ADDRESS_TEXT: `If your address has changed, you can enter your new address details here.
After you have confirmed your entry with 'Change address', it will be checked by the support.
The address details you provided during registration will apply until the check has been completed successfully.`,
  CHANGE_ADDRESS_BACK: 'Back',
  ADDRESS_DECLINED: 'Address declined',
  ADDRESS_PENDING_TITLE: `Address pending`,
  ADDRESS_PENDING_1: `Our support team is currently reviewing the new information you provided to ensure everything is accurate.

To view the details of your new address, please click  `,
  ADDRESS_PENDING_2: 'here.',
  CHANGE_ADDRESS_ZIP: 'Zip:',
  CHANGE_ADDRESS_CITY: 'City:',

  ADDRESS_ERROR: `There has been an error submitting the request. Please try again.`,

  CHANGE_ADDRESS_STREET_TOO_LONG: `The street name must be at most 64 characters long`,
  CHANGE_ADDRESS_STREET2_TOO_LONG: `The Street continue text must be at most 64 characters long`,
  CHANGE_ADDRESS_ZIP_TOO_LONG: `The zip address must be at most 12 characters long`,
  CHANGE_ADDRESS_CITY_TOO_LONG: `The city name must be at most 64 characters long`,
  CHANGE_ADDRESS_VALIDATION_ERROR: 'These special characters are not supported',

  PERSONAL_DATA_ADDRESS_HINT: `If you would like to change your address information, {link}.`,
  PERSONAL_DATA_ADDRESS_HINT_LINK: `please contact support`,

  PERSONAL_DATA_INFO_HEADLINE: `Why and what do we need your data for?`,
  PERSONAL_DATA_INFO_CONTENT_1: `So that we can ensure that you are of legal age and that you have actually registered as a creator, we compare your details with the authentication documents you have uploaded.`,
  PERSONAL_DATA_INFO_CONTENT_2: `Of course, we also need this data for the payment of your commissions. In accordance with the Money Laundering Act, the recipient must also be clearly legitimized.`,
  PERSONAL_DATA_INFO_CONTENT_3: `All data you provide is of course subject to privacy policy.`,

  BANK_DETAILS_HEADLINE: `Payout recepient`,
  BANK_DETAILS_TEXT_1: `If the payout recipient differs from the owner of the Creator account, the account holder's personal details must be provided here. In addition, a copy of the valid identity card or passport with registration certificate must be uploaded.`,
  BANK_DETAILS_TEXT_2: `If this data is not available, no payment can be made.`,
  BANK_DETAILS_ADDRESS_ERROR: `The address details are still missing!`,
  BANK_DETAILS_HEADLINE_2: `Bank details payout recipient`,
  BANK_DETAILS_MINIMUM_PAYOUT: `The minimum payout amount is {minimum_payout} EUR.`,
  BANK_DETAILS_PAYOUT_NO_COST: `There are no additional costs for the transaction.`,
  BANK_DETAILS_PAYOUT_COST: `We will pay via TransferWise in your local currency, which will incur additional transaction costs depending on your country of origin. You can see the potential costs at TransferWise. If you want to avoid these costs, please deposit a SEPA account with us.`,
  BANK_DETAILS_PAYOUT_NONE: `The minimum payout amount depends on the account type.`,
  SEPA_ACCOUNT: `SEPA Account`,
  NONE_SEPA: `Non-SEPA Account`,
  PAXUM: `Paxum`,
  ACCOUNT_DETAILS_LATER: `I will submit my bank details later`,
  FIELD_ACCOUNT_HOLDER: `Account holder:`,
  FIELD_IBAN: `IBAN:`,
  FIELD_BIC: `BIC/SWIFT:`,
  FIELD_BANK_PAX_EMAIL: `Email:`,
  FIELD_BANK_NAME: `Name o/t Bank:`,
  FIELD_BANK_ADDRESS: `Bank address:`,
  FIELD_BANK_CITY: `City o/t bank:`,
  FIELD_BANK_COUNTRY: `Country o/t bank:`,

  BANKHOLDER_DOCUMENT_MISSING: `Please upload the ID or passport of the payout recipient!`,
  BANKHOLDER_DOCUMENT_PENDING: `Your documents are currently being examined. Please be patient, thank you very much.`,
  BANKHOLDER_DOCUMENT_REJECTED: `The uploaded ID was rejected. If you have any questions, please contact the support.`,
  BANKHOLDER_DOCUMENT_CONFIRMED: `The ID of the payout recipient has been verified! Thank you.`,
};

