import { uuid } from '../../../utils';
import userUtils from '../../../utils/user';
import { FRIEND_FRIEND_REQUEST_SEND } from '../../actions';
import { updateListOwnRequests } from '..';
export default (requestSent) => async (dispatch, getState) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs, processQueue, queue },
    friends: { ownRequests },
  } = getState();
  const activeUser = userUtils.activeUser([
    ...camUsers,
    ...dialogs,
    ...processQueue,
    ...queue,
  ]);
  if (!activeUser || userUtils.isAdmin(activeUser)) {
    return;
  }

  if (requestSent) {
    const updatedRequests = [
      ...ownRequests,
      { id: activeUser.userId, nickname: activeUser.name },
    ];
    dispatch(updateListOwnRequests(updatedRequests));
    return dispatch({
      type: FRIEND_FRIEND_REQUEST_SEND,
      payload: activeUser,
      meta: {
        showAlert: true,
        stamp: uuid(),
      },
    });
  }

  return dispatch({
    type: FRIEND_FRIEND_REQUEST_SEND,
    payload: activeUser,
    meta: {
      start: true,
      stamp: uuid(),
    },
  });
};

