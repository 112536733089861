import dayjs from 'dayjs';

export const dashboardLinks = {
  affiliate: [
    {
      title: 'DASHBOARD_AFFILIATE_TITLE_1',
      content: 'DASHBOARD_AFFILIATE_CONTENT_1',
      path: '/wiki/moneymakers#creatorReferral',
    },
    {
      title: 'DASHBOARD_AFFILIATE_TITLE_2',
      content: 'DASHBOARD_AFFILIATE_CONTENT_2',
      path: '/wiki/moneymakers#userAdvertising',
    },
  ],
  important: [
    {
      title: 'DASHBOARD_LINK_TITLE_1',
      content: 'DASHBOARD_LINK_CONTENT_1',
      path: '/my-account/settings/prices',
      needsLivecam: true,
    },
    {
      title: 'DASHBOARD_LINK_TITLE_5',
      content: 'DASHBOARD_LINK_CONTENT_5',
      path: '/my-account/settings/livecam',
      needsLivecam: true,
    },
    {
      title: 'DASHBOARD_LINK_TITLE_2',
      content: 'DASHBOARD_LINK_CONTENT_2',
      path: '/my-account/my-profile/profile-pictures',
    },
    {
      title: 'DASHBOARD_LINK_TITLE_3',
      content: 'DASHBOARD_LINK_CONTENT_3',
      path: '/moneymakers/attachments',
      needsConfirmed: true,
    },
    {
      title: 'DASHBOARD_LINK_TITLE_4',
      content: 'DASHBOARD_LINK_CONTENT_4',
      path: '/my-content/photos/my-photos',
      needsConfirmed: true,
    },
  ],
};

export const dashboardLargeArticleCoinsTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '50%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '30%', position: 'right' },
  { title: 'ARTICLE_COINS_TABLE_TURNOVER', width: '20%', position: 'right' },
];

export const dashboardMobileArticleCoinsTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '35%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '35%', position: 'center' },
  { title: 'ARTICLE_COINS_TABLE_TURNOVER', width: '30%', position: 'right' },
];

export const dashboardLargeArticleExtrasTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '50%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '30%', position: 'right' },
  { title: 'ARTICLE_COINS_TABLE_PREMIUM', width: '20%', position: 'right' },
];

export const dashboardMobileArticleExtrasTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '40%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '30%', position: 'right' },
  { title: 'ARTICLE_COINS_TABLE_PREMIUM', width: '30%', position: 'right' },
];

export function getProgressData(data) {
  let currentItem;

  const modelsData = Object.values(data.provision_model).sort(
    (a, b) => a.position - b.position
  );
  modelsData.forEach((item, index) => {
    if (
      item?.next_coins !== undefined &&
      modelsData[index + 1]?.next_coins === undefined
    ) {
      currentItem = { ...item };
    } else if (index === 0 && item?.next_coins === undefined) {
      currentItem = { ...item, next_percent: 100 };
    }
  });

  return {
    item: currentItem,
    model: data.affected_model,
    rate: data.affected_rate,
    start: data.period_start,
    end: data.period_end,
  };
}

export function getDateProgressData(startDate) {
  const date1 = dayjs(startDate);
  const date2 = dayjs();
  const difference = date2.diff(date1, 'day');
  const percent = (difference / 15) * 100;
  return percent;
}

