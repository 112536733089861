export default {
  ROUTE_DOCUMENTS_HEADLINE: `Verwalte hier Deine Dokumente`,
  UPLOAD_DOCUMENTS: `Dokumente Upload`,
  DOCUMENTS_EXISTS: `Deine Dokumente`,
  DOCUMENTS_TEXT_1: `Um ein Bild des Dokuments hochzuladen kannst Du dieses einfach per „Drag and Drop“ in den weißen, blau umrandeten Bereich ziehen. Oder Du klickst das + an um eine Datei von Deinem Computer oder Smartphone auszuwählen. Um ein Bild des Dokuments mit Deiner Webcam aufzunehmen klicke einfach auf das Feld mit der Kamera.`,
  DOCUMENTS_TEXT_2: `Du kannst Deine Ausweisdokumente als Kopie kennzeichnen. Es müssen dabei aber immer alle Daten und das Ausweisbild deutlich erkennbar sein!`,
  DOCUMENTS_TEXT_3: `Achte bitte darauf, dass alle Dokumente aktuell und nicht abgelaufen sind! Ein Führerschein ist kein gültiges Legitimationsdokument!`,
  DOCUMENTS_TEXT_4: `Lade bitte beide Seiten des Legitimationsdokuments hoch!`,
  DOCUMENT_UPLOADER_LABEL: `Neues Dokument:`,
  DOCUMENTS_NAME: `Name`,
  DOCUMENTS_STATUS: `Status`,
  DOCUMENTS_UPLOAD_TIME: `Upload Datum`,
  UPLOAD_DOCUMENTS_CONTENT_1: `<span class="bold">Hinweis:</span> Alle Deine Angaben und Dokumente unterliegen selbstverständlich dem Datenschutz.`,
  FILENAME: `Dateiname:`,
  DOCUMENTS_TYPE: `Art`,
  DOCUMENTS_AMATEUR: `Legitimation`,
  DOCUMENTS_BANKHOLDER: `Auszahlung`,
  DOCUMENTS_AVCHECK: `Altersverifikation`,
};

