import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import arrowDownIcon from '../../../assets/img/svg/arrow-down.svg';

function Select({
  id,
  name,
  options,
  defaultValue = '',
  disabled,
  defaultValueOption,
  intlTranslate,
  breakLineIndex,
  ...otherProps
}) {
  const intl = useIntl();

  const selectOptions = options.map((o, i) => {
    const translatedOption = o.translate
      ? intl.formatMessage({ id: o.label })
      : intlTranslate === false
      ? o.label
      : intl.formatMessage({ id: o.label });
    return o.label === 'break_line_element' ? (
      <option disabled key="separator-line">
        ─────────────────────
      </option>
    ) : (
      <option value={o.value} key={`${o.value}.${i}`} disabled={o.disabled}>
        {translatedOption}
      </option>
    );
  });

  const selectClassName = classNames('select-container', {
    disabled,
  });

  return (
    <div className={selectClassName}>
      <select
        id={id || name}
        name={id || name}
        {...otherProps}
        disabled={disabled}
      >
        {options.length > 0 ? (
          selectOptions
        ) : (
          <option value="" disabled>
            {defaultValueOption}
          </option>
        )}
      </select>

      <ReactSVG src={arrowDownIcon} className="arrow-down-icon" />
    </div>
  );
}

export default Select;

