import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { sound } from '../../../actions';
import AudioVolume from './AudioVolume';

const mapStateToProps = (state) => ({
  volumes: state.sounds.volumes,
  volume: state.sounds.volume,
});
const mapDispatchToProps = (dispatch) => ({
  onChange: (e) => dispatch(sound.updateVolume(e.target.value)),
});
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(AudioVolume)
);
