import './UserTab.scss';

import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import menuDotsSVG from '../../../../assets/img/svg/menu-dots.svg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useWindowResize from '../../../../hooks/useWindowResize';
import Button from '../../../Button/Button';
import UserGame from '../../../Livecam/UserGame';
import Stats from '../../../Livecam/UserGame/Stats';
import UserNote from '../../../Livecam/UserNote';
import UserProfile from '../../../Livecam/UserProfile';
import UserTabMenu from './UserTabMenuContainer';

function UserTab({
  toggleUserMenu,
  toggleUsernoteView,
  toggleUserinfoView,
  toggleUsergameView,
  toggleUsergamestatView,
  usernote,
  userinfo,
  usergame,
  usergamestats,
  open,
  menuIsActivated,
  isMobile,
  cam,
  hasUser,
  showGame,
  largeScreenStreaming,
  isIgnored,
}) {
  const isMediumScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1599px)'
  );
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const windowResize = useWindowResize();
  const [userTabStyle, setUserTabStyle] = useState({
    height: 300,
  });
  const innerHeight = window.innerHeight;
  const mobileUserTabStyle = {
    height: '100%',
  };
  useEffect(() => {
    if (!isLargeScreen) {
      return;
    }

    // Height of the whole window
    const windowHeight = innerHeight;
    // The height of the user tab is set to fill the rest of the height
    const userTabHeight = parseInt(windowHeight - largeScreenStreaming.height);

    if (userTabHeight === userTabStyle.height) {
      return;
    }

    setUserTabStyle({ height: userTabHeight });
  }, [
    windowResize,
    cam,
    largeScreenStreaming,
    isLargeScreen,
    userTabStyle,
    isMobile,
    innerHeight,
  ]);

  if (!userinfo && !usernote && !(usergame && isLargeScreen) && !usergamestats)
    return null;

  const infoHeaderClasses = classNames(
    'user-tab-content-header-info',
    'header-menu-item',
    'userinfo',
    {
      active: userinfo,
    }
  );
  const notesHeaderClasses = classNames(
    'user-tab-content-header-info',
    'header-menu-item',
    'usernote',
    {
      active: usernote,
    }
  );
  const gamesHeaderClasses = classNames(
    'user-tab-content-header-info',
    'header-menu-item',
    'usergame',
    {
      active: (usergame && !isMediumScreen) || (usergamestats && isMobile),
    }
  );
  const menuButtonCss = classNames({ active: open });
  return (
    <div
      className="user-tab"
      style={
        isLargeScreen
          ? userTabStyle
          : isMediumScreen
          ? null
          : mobileUserTabStyle
      }
    >
      {(isMediumScreen || (!isMediumScreen && !cam)) && (
        <div className="top-bar" id="top-bar">
          <div className="user-control">
            <Button
              className={menuButtonCss}
              icon={menuDotsSVG}
              intlTranslate={false}
              variant="icon-only"
              renumerateIRIElements={false}
              onClick={toggleUserMenu}
              disabled={!hasUser || !menuIsActivated || isIgnored}
            />
            <UserTabMenu />
          </div>
        </div>
      )}
      <div className="content">
        <div className="user-tab-content header-menu">
          <div className="user-tab-content-header">
            <div
              onClick={() => !userinfo && toggleUserinfoView()}
              className={infoHeaderClasses}
            >
              <FormattedMessage id="btn.user.info" />
            </div>
            <div
              onClick={() => !usernote && toggleUsernoteView()}
              className={notesHeaderClasses}
            >
              <FormattedMessage id="btn.user.notes" />
            </div>
            {showGame && isLargeScreen && (
              <div
                onClick={() => !usergame && toggleUsergameView()}
                className={gamesHeaderClasses}
              >
                <FormattedMessage id="btn.user.connect4" />
              </div>
            )}
            {showGame && isMobile && (
              <div
                onClick={() => !usergame && toggleUsergamestatView()}
                className={gamesHeaderClasses}
              >
                <FormattedMessage id="btn.user.connect4" />
              </div>
            )}
          </div>
          {((cam && !isMediumScreen) || isMobile) && (
            <div className="user-control">
              <Button
                className={menuButtonCss}
                icon={menuDotsSVG}
                intlTranslate={false}
                variant="icon-only"
                renumerateIRIElements={false}
                onClick={toggleUserMenu}
                disabled={!hasUser || !menuIsActivated || isIgnored}
              />
              <UserTabMenu />
            </div>
          )}
        </div>
        {hasUser ? (
          <>
            {userinfo && <UserProfile />}
            {usernote && <UserNote />}
            {usergame && isLargeScreen && <UserGame />}
            {usergamestats && isMobile && <Stats />}
          </>
        ) : (
          <div className="no-user-label">
            <FormattedMessage id="label.noUserSelected" />
          </div>
        )}
      </div>
    </div>
  );
}

export default UserTab;
