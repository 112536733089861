import { lStorage, sStorage } from '../services/storage';

const valuesToKeep = [
  'rememberMe',
  'mhp',
  'sorting',
  'toyId',
  'messengerOpen',
  'activitiesOpen',
  'featuresOpen',
  'motto',
];
const clear = function () {
  const values = {};
  valuesToKeep.forEach((key) => {
    let value = lStorage.getItem(key);
    if (value) {
      values[key] = value;
    }
  });
  lStorage.clear();
  for (const key in values) {
    lStorage.setItem(key, values[key]);
  }
  sStorage.clear();
};

export default {
  clear,
};
