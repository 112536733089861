import './UploadDocuments.scss';

import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { uuid } from '../../../../../utils';
import FieldErrorWrapper from '../../../../Forms/FieldErrorWrapper/FieldErrorWrapper';
import StepperContext from '../../../../Stepper/StepperContext';
import DocumentsTable from '../../../../Tables/DocumentsTable';
import Uploader from '../../../../Uploader';
import FileApi from './FileApi';

function UploadDocuments({
  update = () => {},
  updateDocuments = () => {},
  user = {},
  isMobile,
  openOverlay = () => {},
  uploadResult = null,
  uploadIndicator = '',
  uploadError = null,
  uploadFile = () => {},
  spinner = () => {},
}) {
  const indicator = 'uploaddocuments';
  const [documentsError, setDocumentsError] = useState(null);
  const { values, setFieldValue } = useFormikContext();
  const {
    currentItemIndex,
    nextItemIndex,
    setCurrentItemIndex,
    markCurrentItemAsValid,
    markCurrentItemAsInvalid,
    sendingToReview,
    setSendingToReview,
    items,
  } = useContext(StepperContext);
  const intl = useIntl();
  useEffect(() => {
    if (sendingToReview !== 'pending') {
      return;
    }
    setSendingToReview('ready');
  }, [sendingToReview, setSendingToReview]);

  useEffect(() => {
    if (
      (!nextItemIndex && nextItemIndex !== 0) ||
      currentItemIndex === nextItemIndex
    ) {
      return;
    }

    setCurrentItemIndex(nextItemIndex);
  }, [currentItemIndex, nextItemIndex, setCurrentItemIndex]);

  const onReviewUpdate = useCallback(() => {
    const updatedReviewState = _.union(
      user.account.review_state_changed_fields,
      ['documents']
    );
    update({
      account: {
        ...user.account,
        review_state_changed_fields: updatedReviewState,
      },
    });
  }, [update, user]);

  const openCam = () => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Cam',
      props: {
        camstamp: stamp,
        indicator,
        uploadPath: '/user/documents',
        title: 'Document picture - take snapshot',
        minScreenshotWidth: 1600,
        filename: `snapshot${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        outsideFormik: true,
      },
    });
  };

  useEffect(() => {
    if (user.account.state === 'review_needed') {
      const documents = items.find((i) => i.path === '/upload-documents');
      if (!documents) return; // should not happen

      if (
        !documents.isError &&
        !values.documents.find((d) => d.state === 'pending')
      ) {
        markCurrentItemAsInvalid();
      }
    }
  }, [markCurrentItemAsInvalid, user, values, items]);

  useEffect(() => {
    if (uploadIndicator !== indicator) {
      return;
    }
    if (uploadError) {
      const msg = uploadError.message || uploadError;
      setDocumentsError(msg);
      if (!values.images.length) {
        markCurrentItemAsInvalid();
      }
      return uploadFile('', false, indicator);
    }
    if (uploadResult && uploadResult?.data) {
      setFieldValue('documents', uploadResult.data);
      updateDocuments(uploadResult.data);
      setDocumentsError(null);
      if (user.account.state === 'review_needed') {
        onReviewUpdate();
        if (
          (values.documents.length &&
            values.documents.find(
              (documents) =>
                documents.comment === '' && documents.state === 'pending'
            )) ||
          (!values.documents.length && uploadResult.data)
        ) {
          markCurrentItemAsValid();
        }
      } else {
        markCurrentItemAsValid();
      }
      return uploadFile('', false, indicator);
    }
  }, [
    uploadResult,
    uploadError,
    uploadIndicator,
    indicator,
    markCurrentItemAsInvalid,
    values,
    setFieldValue,
    markCurrentItemAsValid,
    user,
    update,
    uploadFile,
    onReviewUpdate,
    updateDocuments,
  ]);

  const openDocument = (title, fileSource) => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Document',
      props: {
        openDocumentStamp: stamp,
        title: title,
        fileSource: fileSource,
      },
    });
  };
  return (
    <div className="content-inner upload-documents-step">
      <h1 className="step-headline">
        <FormattedMessage id="UPLOAD_DOCUMENTS" />
      </h1>
      <div className="content-grid">
        <div className="grid-col">
          <p>
            <FormattedMessage id="UPLOAD_DOCUMENTS_SUB" />
          </p>
          <p className="bold">
            <FormattedMessage id="UPLOAD_DOCUMENTS_CONTENT_3" />
          </p>
          <FieldErrorWrapper
            name="documents"
            error={documentsError}
            noGrid={true}
          >
            <Uploader
              indicator={indicator}
              uploadPath="/user/documents"
              label="DOCUMENT_UPLOADER_LABEL"
              acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png', '.pdf']}
              openCam={openCam}
              isMobile={isMobile}
              handleError={setDocumentsError}
              className="documents"
            >
              {user.private.documents
                .filter((document) => document.state === 'pending')
                .map((f, idx) => (
                  <FileApi
                    key={idx}
                    file={f}
                    isDeleting={spinner}
                    reviewFileDeleted={() => onReviewUpdate()}
                    spinner={spinner}
                  />
                ))}
            </Uploader>
          </FieldErrorWrapper>
        </div>

        {user.account.state === 'review_needed' &&
        values.documents.some((document) => document.state !== 'pending') ? (
          <div className="grid-col">
            <div className="documents-status">
              <p>{intl.formatMessage({ id: 'DOCUMENTS_EXISTS' })}</p>
              <div className="feedback-table">
                <DocumentsTable
                  items={user.private.documents.filter(
                    (document) => document.state !== 'pending'
                  )}
                  spinner={spinner}
                  openDocument={openDocument}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>

      <div className="bottom">
        <hr />
        <p>
          <FormattedMessage id="UPLOAD_DOCUMENTS_CONTENT_1">
            {(message) => (
              <span dangerouslySetInnerHTML={{ __html: message[0] }} />
            )}
          </FormattedMessage>
        </p>
        <p className="no-margin">
          <FormattedMessage id="UPLOAD_DOCUMENTS_CONTENT_2" />
        </p>
      </div>
    </div>
  );
}

export default UploadDocuments;
