import api from '../../utils/api';
import { SAFE_MODE_CHANGE } from '../actions';
export default () => async (dispatch, getState) => {
  const { preferences } = getState();
  const safeModeValue = !preferences?.safeMode;

  const data = {
    ...preferences,
    safeMode: safeModeValue,
  };

  try {
    await api.put('/user/preferences', { data });

    dispatch({
      type: SAFE_MODE_CHANGE,
      payload: safeModeValue,
    });
  } catch (error) {
    console.log({ error });
  }
};

