import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

/**
 * stateReconciler
 * merges the state coming from the store into the existing (original) state
 * then calls redux persist autoMergeLevel2
 *
 * @param  {Object} inboundState={}
 * @param  {Object} originalState
 * @param  {Object} reducedState
 * @param  {Object} _ref
 */
export default (inboundState = {}, originalState, reducedState, _ref) => {
  return autoMergeLevel2(inboundState, originalState, reducedState, _ref);
};
