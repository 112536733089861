export const fullArticleCoinsTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '26%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '13%', position: 'right' },
  { title: 'ARTICLE_COINS_TABLE_FIRST_ON', width: '13%', position: 'center' },
  { title: 'ARTICLE_COINS_TABLE_LAST_ON', width: '13%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_TURNOVER', width: '35%', position: 'right' },
];

export const mediumArticleCoinsTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '50%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '30%', position: 'right' },
  { title: 'ARTICLE_COINS_TABLE_TURNOVER', width: '20%', position: 'right' },
];

export const mobileArticleCoinsTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '35%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '35%', position: 'center' },
  { title: 'ARTICLE_COINS_TABLE_TURNOVER', width: '30%', position: 'right' },
];

export const fullArticleExtrasTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '26%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '13%', position: 'right' },
  { title: 'ARTICLE_COINS_TABLE_FIRST_ON', width: '13%', position: 'center' },
  { title: 'ARTICLE_COINS_TABLE_LAST_ON', width: '13%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_TURNOVER', width: '13%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_SHARE', width: '10%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_PREMIUM', width: '12%', position: 'right' },
];

export const mediumArticleExtrasTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '50%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '30%', position: 'right' },
  { title: 'ARTICLE_COINS_TABLE_PREMIUM', width: '20%', position: 'right' },
];

export const mobileArticleExtrasTableTitles = [
  { title: 'ARTICLE_COINS_TABLE_ARTICLE', width: '30%', position: 'left' },
  { title: 'ARTICLE_COINS_TABLE_NUMBER', width: '40%', position: 'right' },
  { title: 'ARTICLE_COINS_TABLE_PREMIUM', width: '30%', position: 'right' },
];

export const fullModelsTableTitles = [
  { title: 'MODELS_TABLE_MODEL', width: '15%', position: 'left' },
  { title: 'MODELS_TABLE_COMMISSION_RATE', width: '15%', position: 'center' },
  {
    title: 'MODELS_TABLE_MINIMUM_TURNOVER',
    width: '15%',
    padding: '0px',
    position: 'right',
    hideInBillDetails: true,
  },
  {
    title: 'MODELS_TABLE_PROGRESS',
    width: '40%',
    position: 'center',
    hideInBillDetails: true,
  },
  { title: 'MODELS_TABLE_YOUR_COMMISSION', width: '15%', position: 'right' },
];

export const billDetailsModelsTableTitles = [
  { title: 'MODELS_TABLE_MODEL', width: '10%', position: 'left' },
  { title: 'MODELS_TABLE_COMMISSION_RATE', width: '15%', position: 'center' },
  {
    title: 'MODELS_TABLE_PROGRESS',
    width: '65%',
    position: 'center',
    hideInBillDetails: true,
  },
  { title: 'MODELS_TABLE_YOUR_COMMISSION', width: '10%', position: 'right' },
];

export const mediumModelsTableTitles = [
  { title: 'MODELS_TABLE_MODEL', width: '12%', position: 'left' },
  { title: 'MODELS_TABLE_COMMISSION_RATE', width: '17%', position: 'center' },
  {
    title: 'MODELS_TABLE_PROGRESS',
    width: '54%',
    position: 'center',
    hideInBillDetails: true,
  },
  { title: 'MODELS_TABLE_YOUR_COMMISSION', width: '17%', position: 'right' },
];

export const mobileModelsTableTitles = [
  { title: 'MODELS_TABLE_MODEL', width: '20%', position: 'left' },
  { title: 'MODELS_TABLE_COMMISSION_RATE', width: '20%', position: 'center' },
  {
    title: 'MODELS_TABLE_PROGRESS',
    width: '40%',
    position: 'center',
    hideInBillDetails: true,
  },
  { title: 'MODELS_TABLE_YOUR_COMMISSION', width: '20%', position: 'right' },
];

export const euPrices = [
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 250, label: 250 },
  { value: 500, label: 500 },
  { value: 1000, label: 1000 },
  { value: 2000, label: 2000 },
  { value: 10000, label: 10000 },
  { value: 99999, label: 99999 },
];

export const extPrices = [
  { value: 250, label: 250 },
  { value: 500, label: 500 },
  { value: 1000, label: 1000 },
  { value: 2000, label: 2000 },
  { value: 10000, label: 10000 },
  { value: 99999, label: 99999 },
];

export function getModelsItems(data) {
  let modelsData = Object.keys(data)
    .map((key) => {
      return {
        ...data[key],
        key: key,
      };
    })
    .sort((a, b) => a.position - b.position);

  if (modelsData.length === 1) {
    modelsData[0].status = 'current';
  } else {
    modelsData.forEach((item, index) => {
      if (item?.next_coins !== undefined) {
        item.status = 'enabled';
      } else if (
        (index === 0 && modelsData[index]?.next_coins === undefined) ||
        modelsData[index - 1]?.next_coins !== undefined
      ) {
        item.status = 'current';
      } else {
        item.status = 'disabled';
      }
    });
  }

  return modelsData;
}
