import {
  DIALOG_QUEUE_ADD_ELEMENT,
  DIALOG_QUEUE_ADD_ELEMENTS,
  DIALOG_QUEUE_REMOVE_ELEMENT,
  DIALOG_QUEUE_RESET,
} from '../actions';
import { dialogFetched } from '..';

let timer;
const MAX_PROCESS_QUEUE_SIZE = 100;
const CHUNK_SIZE = 30;
const INTERVAL = 100;
const addElements = (payload) => (dispatch) => {
  dispatch({
    type: DIALOG_QUEUE_ADD_ELEMENTS,
    payload,
  });
  dispatch(removeElement());
};
const addElement = (payload) => (dispatch) => {
  dispatch({
    type: DIALOG_QUEUE_ADD_ELEMENT,
    payload,
  });
  dispatch(removeElement());
};

const removeElement = () => (dispatch, getState) => {
  if (timer) {
    return;
  }
  timer = setInterval(() => {
    for (let i = 0; i < CHUNK_SIZE; i++) {
      const { queue, processQueue } = getState().dialog;
      if (processQueue.length > MAX_PROCESS_QUEUE_SIZE) return;
      if (queue.length === 0) return dispatch(clear());

      const payload = queue[0];
      dispatch({
        type: DIALOG_QUEUE_REMOVE_ELEMENT,
        payload,
      });

      if (getState().dialog.queue.length === 0) {
        dispatch(clear());
      }

      dispatch(dialogFetched(payload));
    }
  }, INTERVAL);
};

const clear = () => (dispatch) => {
  dispatch({
    type: DIALOG_QUEUE_RESET,
  });
  clearInterval(timer);
  timer = null;
};
export default {
  addElement,
  addElements,
  removeElement,
  clear,
};
