import './Sedcard.scss';

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import completeSedcardDE from '../../../../../assets/img/wiki/sedcard/complete_sedcard_de.jpg';
import completeSedcardEN from '../../../../../assets/img/wiki/sedcard/complete_sedcard_en.jpg';
import moreBtnDE from '../../../../../assets/img/wiki/sedcard/more_btn_de.jpg';
import moreBtnEN from '../../../../../assets/img/wiki/sedcard/more_btn_en.jpg';
import notesDE from '../../../../../assets/img/wiki/sedcard/notes_de.jpg';
import notesEN from '../../../../../assets/img/wiki/sedcard/notes_en.jpg';
import personalDE from '../../../../../assets/img/wiki/sedcard/personal_de.jpg';
import personalEN from '../../../../../assets/img/wiki/sedcard/personal_en.jpg';
import revenueDE from '../../../../../assets/img/wiki/sedcard/revenue_de.jpg';
import revenueEN from '../../../../../assets/img/wiki/sedcard/revenue_en.jpg';
import sedcardDE from '../../../../../assets/img/wiki/sedcard/sedcard_de.jpg';
import sedcardEN from '../../../../../assets/img/wiki/sedcard/sedcard_en.jpg';
import BackButton from '../../../../BackButton';

function Relationships({ locale, unconfirmed, onBackButtonClicked }) {
  const intl = useIntl();
  return (
    <div className="wiki-content sedcard-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="SEDCARD_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage
          id="SEDCARD_INTRO"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="SEDCARD_MULTIMESSENGER" />
            ) : (
              <Link to="/multi-messenger">
                <FormattedMessage id="SEDCARD_MULTIMESSENGER" />
              </Link>
            ),
          }}
        />
      </p>
      <div className="image-wrapper complete-sedcard-img">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={completeSedcardEN}
            alt="Sedcard"
          />
        ) : (
          <img
            className="img-responsive"
            src={completeSedcardDE}
            alt="Sedcard"
          />
        )}
      </div>
      <h2 className="headline">
        <FormattedMessage id="SEDCARD_NOTES_HEADER" />
      </h2>
      <p>
        <FormattedMessage id="SEDCARD_NOTES" />
      </p>
      <div className="image-wrapper complete-sedcard-img">
        {locale === 'en' ? (
          <img className="img-responsive" src={notesEN} alt="Sedcard" />
        ) : (
          <img className="img-responsive" src={notesDE} alt="Sedcard" />
        )}
      </div>
      <h2 className="headline">
        <FormattedMessage id="SEDCARD_USERINFO_HEADER" />
      </h2>

      <p>
        <FormattedMessage id="SEDCARD_USERINFO" />
      </p>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="SEDCARD_PROFILE_PICTURE_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="SEDCARD_PROFILE_PICTURE" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="SEDCARD_NICKNAME_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="SEDCARD_NICKNAME" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="SEDCARD_PORTAL_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="SEDCARD_PORTAL" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="SEDCARD_DATA_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="SEDCARD_DATA" />
          </p>
        </div>
      </div>
      <div className="image-wrapper sedcard-img">
        {locale === 'en' ? (
          <img className="img-responsive" src={sedcardEN} alt="Sedcard" />
        ) : (
          <img className="img-responsive" src={sedcardDE} alt="Sedcard" />
        )}
      </div>
      <h3 className="headline">
        <FormattedMessage id="SEDCARD_MORE" />
      </h3>
      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={moreBtnEN}
            alt={intl.formatMessage({ id: 'SEDCARD_MORE' })}
          />
        ) : (
          <img
            className="img-responsive"
            src={moreBtnDE}
            alt={intl.formatMessage({ id: 'SEDCARD_MORE' })}
          />
        )}
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="SEDCARD_PERSONAL_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="SEDCARD_PERSONAL" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="SEDCARD_PREFERENCES_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="SEDCARD_PREFERENCES" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="SEDCARD_ABOUT_ME_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="SEDCARD_ABOUT_ME" />
          </p>
        </div>
      </div>
      <div className="image-wrapper sedcard-img">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={personalEN}
            alt={intl.formatMessage({ id: 'SEDCARD_PERSONAL_HEADER' })}
          />
        ) : (
          <img
            className="img-responsive"
            src={personalDE}
            alt={intl.formatMessage({ id: 'SEDCARD_PERSONAL_HEADER' })}
          />
        )}
      </div>
      <h2 className="headline">
        <FormattedMessage id="SEDCARD_REVENUE_HEADER" />
      </h2>

      <p>
        <FormattedMessage id="SEDCARD_REVENUE" />
      </p>
      <ul>
        <li>
          <FormattedMessage id="article_livecams" />
        </li>
        <li>
          <FormattedMessage id="article_message" />
        </li>
        <li>
          <FormattedMessage id="article_payttachment" />
        </li>
        <li>
          <FormattedMessage id="article_regard" />
        </li>
        <li>
          <FormattedMessage id="article_movies" />
        </li>
        <li>
          <FormattedMessage id="article_pictures" />
        </li>
        <li>
          <FormattedMessage id="article_voicemessage" />
        </li>
        <li>
          <FormattedMessage id="article_game" />
        </li>
      </ul>
      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={revenueEN}
            alt={intl.formatMessage({ id: 'SEDCARD_REVENUE_HEADER' })}
          />
        ) : (
          <img
            className="img-responsive"
            src={revenueDE}
            alt={intl.formatMessage({ id: 'SEDCARD_REVENUE_HEADER' })}
          />
        )}
      </div>
    </div>
  );
}
export default Relationships;

