import { connect } from 'react-redux';

import {
  openOverlay,
  spinner,
  userUpdateBankholderDocument as updateBankholderDocument,
  userUpdateDocuments as updateDocuments,
  uploadFile,
} from '../../../../../actions';
import BankholderDocument from './BankholderDocument';

const mapDispatchToProps = {
  spinner,
  openOverlay,
  uploadFile,
  updateBankholderDocument,
  updateDocuments,
};

const mapStateToProps = (state) => ({
  bankholder_document: state.user.private?.bankholder_document,
  bank_holder: `${state.user.private?.bank_forename} ${state.user.private?.bank_surname}`,
  isMobile: state.browser.isMobile,
  uploadError: state.upload.error,
  uploadResult: state.upload.result,
  uploadIndicator: state.upload.indicator,
  documents: state.user.private?.documents,
});

export default connect(mapStateToProps, mapDispatchToProps)(BankholderDocument);
