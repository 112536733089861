import { connect } from 'react-redux';

import { ignoreUser, management, toggleUserMenu } from '../../../../actions';
import { user as userUtils } from '../../../../utils';
import UserTabMenu from './UserTabMenu';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
  } = state;
  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  const isAdmin = activeUser?.flags?.includes('is_support');
  const isLivecamUser =
    activeUser && userUtils.isVisibleLivecamUser(activeUser);
  return {
    username: (userUtils.activeUser([...camUsers, ...dialogs]) || {}).name,
    open: state.settings.userMenuOpen,
    hasUser: !!activeUser,
    isLivecamUser,
    disableIgnore: isAdmin || userUtils.checkDeleteOrIgnored(activeUser),
  };
};

const mapDispatchToProps = (dispatch) => ({
  kickUser: () => dispatch(management.kickUser(false)),
  ignoreUser: (hasUser) => dispatch(ignoreUser(false, null, hasUser)),
  toggleUserMenu: () => dispatch(toggleUserMenu()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserTabMenu);
