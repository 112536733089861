import { connect } from 'react-redux';

import { openDialog } from '../../../../../actions/dialog';
import MyComments from './MyComments';

const mapStateToProps = (state) => ({
  user: state.user,
  isMobile: state.view.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (id) => dispatch(openDialog(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyComments);

