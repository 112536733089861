export default {
  PROFILE_HEADLINE: `Dein Profil, Dein Aushängeschild`,
  PROFILE_INTRO: `{link} kannst Du zu jeder Zeit vervollständigen oder ergänzen. Diese Informationen müssen nicht zwingend der Realität entsprechen, z.B. Deine Postleitzahl. Mach den User neugierig auf Dich und setze Dich so in Szene, dass der User Dich unbedingt kennenlernen möchte. Dein Profil ist der erste Kontakt, den der User mit Dir hat.`,
  PROFILE_INTRO_LINK: `Dein Profil`,
  PROFILE_HEADLINE_2: `Die Bereiche unterteilen sich in:`,
  PROFILE_CONTENT_STEP_1: `Profilbilder`,
  PROFILE_CONTENT_STEP_1_CONTENT: `Deine Profilbilder sind die Visitenkarte Deines Profils. Wähle schöne Fotos, in guter Qualität. {link}`,
  PROFILE_CONTENT_STEP_1_CONTENT_LINK: `Hier findest Du detaillierte Informationen.`,
  PROFILE_CONTENT_STEP_1_1_CONTENT: `Wir bieten die Möglichkeit, Fotos in FSK 12 (Non nude), FSK 16 (Softcore) und FSK 18 (Hardcore) hochzuladen. Natürlich ist es nicht zwingend, Dich in jedem FSK Level mit einem Foto zu zeigen. In der Infobox findest Du eine Übersicht, was die einzelnen Bereiche auszeichnet.`,
  PROFILE_CONTENT_STEP_1_2_CONTENT: `<span class="bold">Hinweis:</span> Fotos lassen sich im Anschluss nicht löschen. Du kannst sie aber jederzeit durch ein anderes Foto ersetzen.`,

  PROFILE_CONTENT_STEP_2: `Profilvideo`,
  PROFILE_CONTENT_STEP_2_CONTENT: `Mit einem persönlichen Profil Video kannst Du Dich in einem kurzen Film präsentieren. Mimik, Gestik und Deine Stimme sind bestens geeignet das Interesse der User zu wecken.`,
  PROFILE_CONTENT_STEP_2_1_CONTENT: `Hinweise:`,
  PROFILE_CONTENT_STEP_2_2_CONTENT: `
  <ul>
    <li>Es ist kein FSK 18 Inhalt erlaubt</li>
    <li>Das Video sollte nicht zu lang sein, max. 1 Minute genügt</li>
    <li>Licht und Ton ok? Setze Dich richtig in Szene!</li>
  </ul>`,

  PROFILE_CONTENT_STEP_3: `Profiltext`,
  PROFILE_CONTENT_STEP_3_CONTENT: `Dein Profiltext soll dem User einen ersten Eindruck von Dir geben und ihn dazu animieren Dich anzuschreiben. Schildere in diesem Text, wer Du bist, was Du suchst oder was Du vielleicht noch erleben möchtest. Beschreibe Deine Interessen, Hobbys oder Vorlieben. Je genauer Du Dich beschreibst, desto mehr kann der User auf Dich eingehen. Sei dabei aber auch ein wenig unverbindlich, denn damit bleibst Du interessant und ein User hat mehr Gründe nachzufragen.`,

  PROFILE_CONTENT_STEP_4: `Öffentliche Angaben`,
  PROFILE_CONTENT_STEP_4_CONTENT: `Alle Informationen, die Du hier angibst, erscheinen auf Deinem öffentlichen Profil und sind damit sichtbar für alle User.`,
  PROFILE_CONTENT_STEP_4_1_CONTENT: `Du musst nicht alles über Dich preisgeben. Bedenke jedoch: Je mehr Du angibst, desto besser können User Dich finden und umso reizvoller kannst Du Dich hervorheben.`,

  PROFILE_CONTENT_STEP_5: `Mein Profil anschauen`,
  PROFILE_CONTENT_STEP_5_CONTENT: `Dein Profil wird zeitgleich auf 20+ Community-Portalen zu sehen sein. Sinnvollerweise ergänzt Du Dein Profil noch mit weiterem Content und regelmäßigen Updates.`,
  PROFILE_CONTENT_STEP_5_1_CONTENT: `Natürlich sehen die Community-Portale nicht alle gleich aus. Hier gibt es sowohl inhaltlich und thematisch als auch farblich und strukturell große Unterschiede im Erscheinungsbild.`,
  PROFILE_CONTENT_STEP_5_2_CONTENT: `Du kannst Dir anhand unserer drei Partner-Portale einen beispielhaften Eindruck verschaffen wie Dein Profil aussehen kann.`,
};
