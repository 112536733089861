export function defaultProfilePictureOptions(images) {
  const possibleOptions = {
    fsk12: 'PROFILE_PICTURES_LABEL_NON_NUDE',
    fsk16: 'PROFILE_PICTURES_LABEL_SOFTCORE',
    fsk18: 'PROFILE_PICTURES_LABEL_HARDCORE',
  };

  let options = [];

  for (const [key, value] of Object.entries(possibleOptions)) {
    const enabledImage = images.find(
      (image) => image.fsk === key && image.state === 'enabled'
    );
    if (enabledImage) {
      options.push({ value: key, label: value });
    }
  }

  return options;
}
