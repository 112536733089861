import { getTimestamp } from '../../utils';
import { LOG_FIRST_DIALOG_DISPLAYED } from '../actions';

export default function () {
  return {
    type: LOG_FIRST_DIALOG_DISPLAYED,
    payload: {
      firstDialogLoadedTime: getTimestamp(),
    },
  };
}
