import { connect } from 'react-redux';

import { contestUtils } from '../../../utils';
import Wiki from './Wiki';

const mapStateToProps = (state) => ({
  hasContest: contestUtils.hasSummerContest(state),
});

export default connect(mapStateToProps)(Wiki);
