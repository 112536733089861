import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import BackButton from '../../../../BackButton';

function Relationships({ unconfirmed, onBackButtonClicked }) {
  return (
    <div className="wiki-content relationship-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="RELATIONSHIPS_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage
          id="RELATIONSHIPS_CONTENT"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="RELATIONSHIPS_CONTENT_LINK" />
            ) : (
              <Link to="/my-relationships/friends/active-friends">
                <FormattedMessage id="RELATIONSHIPS_CONTENT_LINK" />
              </Link>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage
          id="RELATIONSHIPS_CONTENT_1"
          values={{
            link: (
              <Link to="/wiki/general#multiMessenger">
                <FormattedMessage id="RELATIONSHIPS_CONTENT_1_LINK" />
              </Link>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage id="RELATIONSHIPS_CONTENT_2" />
      </p>
      <p>
        <FormattedMessage
          id="RELATIONSHIPS_CONTENT_3"
          values={{
            link: unconfirmed ? (
              <FormattedMessage id="RELATIONSHIPS_CONTENT_LINK" />
            ) : (
              <Link to="/my-relationships/friends/active-friends">
                <FormattedMessage id="RELATIONSHIPS_CONTENT_LINK" />
              </Link>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage id="RELATIONSHIPS_CONTENT_4" />
      </p>
      <p className="list-headline">
        <FormattedMessage id="RELATIONSHIPS_CONTENT_5" />
      </p>
      <p>
        <FormattedMessage id="RELATIONSHIPS_CONTENT_6">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
    </div>
  );
}
export default Relationships;
