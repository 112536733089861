import { connect } from 'react-redux';

import {
  openOverlay,
  userUpdate as update,
  updateImages,
  uploadFile,
} from '../../../../../actions';
import ProfilePicture from './ProfilePicture';

const mapStateToProps = (state) => ({
  user: state.user,
  isMobile: state.browser.isMobile,
  uploadError: state.upload.error,
  uploadResult: state.upload.result,
  uploadIndicator: state.upload.indicator,
});

const mapDispatchToProps = {
  updateImages,
  update,
  openOverlay,
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture);
