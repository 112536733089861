export default {
  SEDCARD_HEADLINE: `User Infocard`,
  SEDCARD_INTRO: `In the {link}, you will find all the information that the user has filled out on his profile in the right column. This information can be helpful to ask the user initial questions or to address certain characteristics.`,
  SEDCARD_MULTIMESSENGER: `Multi Messenger`,
  SEDCARD_NOTES_HEADER: `Notes`,
  SEDCARD_NOTES: `As soon as you are in contact with the user and he reveals further information about himself, you can enter it under the "Notes" tab. This way, you always have them at hand for later messages.`,
  SEDCARD_USERINFO_HEADER: `User Info`,
  SEDCARD_USERINFO: `In the User Info you can see the following information:`,
  SEDCARD_PROFILE_PICTURE_HEADER: `Customer's profile picture`,
  SEDCARD_PROFILE_PICTURE: `You can enlarge the profile picture by clicking on the magnifying glass next to the picture.`,
  SEDCARD_NICKNAME_HEADER: `Nickname`,
  SEDCARD_NICKNAME: `The customer has a pseudonym as a name, just like you.`,
  SEDCARD_PORTAL_HEADER: `Portal`,
  SEDCARD_PORTAL: `Information about which platform the customer comes from.`,
  SEDCARD_DATA_HEADER: `Important data`,
  SEDCARD_DATA: `Age, gender, origin, language`,
  SEDCARD_MORE: `More (blue button)`,
  SEDCARD_PERSONAL_HEADER: `Personal`,
  SEDCARD_PERSONAL: `Figure, tattoos, marital status`,
  SEDCARD_PREFERENCES_HEADER: `Preferences`,
  SEDCARD_PREFERENCES: `What turns me on, what else do I want to experience`,
  SEDCARD_ABOUT_ME_HEADER: `Preferences`,
  SEDCARD_ABOUT_ME: `What I say about myself`,
  SEDCARD_REVENUE_HEADER: `Turnovers`,
  SEDCARD_REVENUE: `Here you will find an overview of sales broken down by different categories. This gives you an overview of how the revenue was made up with the customer. This way, you can easily see if the customer is mainly coming to your live cam, sending you messages, buying premium file attachments, or viewing your photo and video galleries.`,
};
