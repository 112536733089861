import { getTimestamp } from '../../utils';
import log from '../../utils/logApi';
import { LOG_DIALOGLOAD_SEND } from '../actions';

export default () => (dispatch, getState) => {
  if (!getState().user?.isLoggedIn) return;
  const {
    log: {
      dialogCount,
      favoriteCount,
      friendCount,
      onlineCount,
      userLoggedInTime,
      socketServiceConnectedTime,
      fetchDialogStartTime,
      dialogFetchedTime,
      onlineStateFetchedTime,
      favoriteFetchedTime,
      favoriteAddedTime,
      ignoredFetchedTime,
      ignoredAddedTime,
      friendFetchedTime,
      friendAddedTime,
      flagsAddedTime,
      firstDialogLoadedTime,
      ignoredCount,
    },
    user: {
      account: { id: userId, nickname: sendername, language, state },
    },
  } = getState();
  const dialogLoadedTime = getTimestamp();
  const socketServiceConnectedDuration =
    socketServiceConnectedTime - userLoggedInTime;
  const fetchDialogStartDuration =
    fetchDialogStartTime - socketServiceConnectedTime;
  const dialogFetchedDuration = dialogFetchedTime - fetchDialogStartTime;
  const onlineStateFetchedDuration = onlineStateFetchedTime - dialogFetchedTime;
  const friendsFetchedDuration = friendFetchedTime - onlineStateFetchedTime;
  const friendsAddedDuration = friendAddedTime - friendFetchedTime;
  const favoriteFetchedDuration = favoriteFetchedTime - friendAddedTime;
  const favoriteAddedDuration = favoriteAddedTime - favoriteFetchedTime;
  const ignoredFetchedDuration = ignoredFetchedTime - friendAddedTime;
  const ignoredAddedDuration = ignoredAddedTime - ignoredFetchedTime;
  const flagsAddedDuration = flagsAddedTime - ignoredAddedTime;
  const firstDialogLoadedDuration = firstDialogLoadedTime - flagsAddedTime;
  const dialogLoadedDuration = dialogLoadedTime - firstDialogLoadedTime;
  const dialogLoadedDurationTotal = dialogLoadedTime - userLoggedInTime;
  const firstDialogLoadedTotal = firstDialogLoadedTime - userLoggedInTime;
  const logObject = {
    location: 'dialogPerformance',
    message: 'performance',
    userId,
    sendername,
    language,
    state,
    level: 'info',
    time: new Date(),
    data: {
      dialogLoadedDuration,
      socketServiceConnectedDuration,
      fetchDialogStartDuration,
      dialogFetchedDuration,
      onlineStateFetchedDuration,
      favoriteFetchedDuration,
      favoriteAddedDuration,
      friendsFetchedDuration,
      friendsAddedDuration,
      ignoredFetchedDuration,
      ignoredAddedDuration,
      flagsAddedDuration,
      firstDialogLoadedDuration,
      dialogLoadedDurationTotal,
      firstDialogLoadedTotal,
      dialogCount,
      favoriteCount,
      friendCount,
      onlineCount,
      ignoredCount,
    },
  };
  log(logObject);
  dispatch({
    type: LOG_DIALOGLOAD_SEND,
    payload: {
      dialogLoadedTime,
      dialogLoadedDuration,
      socketServiceConnectedDuration,
      fetchDialogStartDuration,
      dialogFetchedDuration,
      onlineStateFetchedDuration,
      favoriteFetchedDuration,
      favoriteAddedDuration,
      friendsFetchedDuration,
      friendsAddedDuration,
      ignoredFetchedDuration,
      ignoredAddedDuration,
      flagsAddedDuration,
      firstDialogLoadedDuration,
      dialogLoadedDurationTotal,
      firstDialogLoadedTotal,
    },
  });
};
