import { connect } from 'react-redux';

import { contestUtils } from '../../../../utils';
import Contest from './Contest';

const mapStateToProps = (state) => ({
  isMobile: state.view.isMobile,
  locale: state.intl.locale,
  contests: state.contests,
  summerContest: contestUtils.getSummerContest(state),
  showRanking: contestUtils.hasActiveOrFinishedSummerContest(state),
});

export default connect(mapStateToProps)(Contest);

