import './RelationshipItem.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';

import menuIcon from '../../../assets/img/svg/menu-dots.svg';
import userHiddenIcon from '../../../assets/img/svg/user-hidden.svg';
import missingPhotoIcon from '../../../assets/img/svg/user.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';

function RelationshipItem({
  item,
  menuVisible,
  setMenuVisible,
  relationshipType,
  relationshipMenuType,
  updateList,
  openDialog,
  answerFriendRequest,
  removeFavoriteUser,
  removeFriend,
  ownRequests,
  updateListOwnRequests,
  friendsError,
  removeDeniedFriendRequest,
  hasFriendsScope,
  temporaryUpdateList,
  safeMode,
  unignoreIdUser,
}) {
  const intl = useIntl();
  const menuRef = useRef();
  const menuIconRef = useRef();
  const history = useHistory();
  const galleryMenuClass = classNames('sub-menu');

  const galleryMenuIconClass = classNames('gallery-menu', {
    open: menuVisible,
  });

  const galleryMenuItem = classNames('gallery-menu-item', {
    disabled: !hasFriendsScope,
  });

  const [, deleteRelationshipRequest] = useAxios(
    {
      url: `/user/${relationshipType}/${item.id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  useOutsideClick(
    menuRef,
    () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    },
    menuIconRef
  );

  const deleteRelationship = () => {
    if (relationshipType === 'friends' && !hasFriendsScope) return;
    if (relationshipMenuType === 'active-friends') {
      removeFriend(item.id, item.nickname);
      return;
    }
    if (relationshipType === 'favorites') {
      temporaryUpdateList(item.id);
    }
    if (relationshipType === 'ignored') {
      unignoreIdUser(item.id);
    }

    deleteRelationshipRequest()
      .then(() => {
        updateList(item.id);
        if (relationshipMenuType === 'my-requests') {
          const updatedRequests = ownRequests.filter(
            (request) =>
              request.id !== item.id && request.id !== item.id.replace('-', '@')
          );
          updateListOwnRequests(updatedRequests);
        }
        if (relationshipType === 'favorites') {
          removeFavoriteUser(item.id);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 409) {
          if (relationshipMenuType === 'my-requests') {
            updateList(item.id);
            friendsError();
          }
        }
        if (relationshipType === 'favorites') {
          updateList(item.id);
        }
      })
      .finally(() => {
        setMenuVisible(false);
      });
  };

  const redirectToMessengerHandler = (id) => {
    openDialog(id);
    history.push('/multi-messenger');
  };

  const thumbnailClass = classNames('thumbnail', {
    ignored: relationshipType === 'ignored',
  });

  const thumbnailItemClass = classNames('thumbnail-item', {
    hidden: item.hidden,
  });

  const [hideProfilePicture, setHideProfilePicture] = useState(safeMode);

  const safeProfilePicture =
    item.profile_image_fsk === 'fsk12' ||
    !item.profile_image ||
    !item.profile_image_fsk;

  const [visibilityText, setVisibilityText] = useState(
    'RELATIONSHIP_ITEM_MENU_SHOW_IMAGE'
  );

  const safeModeMenuItem = () => {
    return (
      !safeProfilePicture &&
      safeMode && (
        <div
          className={galleryMenuItem}
          onClick={(event) => {
            visibilityOfProfilePicture(event);
          }}
        >
          {intl.formatMessage({
            id: visibilityText,
          })}
        </div>
      )
    );
  };

  function visibilityOfProfilePicture(event) {
    setHideProfilePicture(!hideProfilePicture);
    setMenuVisible(false);
    event.stopPropagation();
    const newText =
      visibilityText === 'RELATIONSHIP_ITEM_MENU_SHOW_IMAGE'
        ? 'RELATIONSHIP_ITEM_MENU_HIDE_IMAGE'
        : 'RELATIONSHIP_ITEM_MENU_SHOW_IMAGE';
    setVisibilityText(newText);
  }

  return (
    <div className={thumbnailItemClass}>
      <div className="thumbnail-wrapper">
        <div
          className={thumbnailClass}
          onClick={(event) => {
            if (
              event.target.id === 'menu-dots' ||
              event.target.parentElement?.id === 'menu-dots' ||
              event.target.id.includes('gallery-menu-icon')
            ) {
              return;
            }
            if (relationshipType === 'favorites') {
              redirectToMessengerHandler(item.id);
              event.stopPropagation();
              return;
            }

            if (relationshipType === 'friends') {
              redirectToMessengerHandler(item.id);
              event.stopPropagation();
            }
          }}
        >
          {item.profile_image ? (
            hideProfilePicture ? (
              safeProfilePicture ? (
                <div
                  className="thumbnail-image"
                  style={{
                    backgroundImage: `url('${
                      item.profile_image?.includes('https://')
                        ? item.profile_image
                        : process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES +
                          item.profile_image
                    }')`,
                  }}
                ></div>
              ) : (
                <div className={`missing-thumbnail-icon-wrapper `}>
                  <ReactSVG src={userHiddenIcon} wrapper="span" />
                </div>
              )
            ) : (
              <div
                className="thumbnail-image"
                style={{
                  backgroundImage: `url('${
                    item.profile_image?.includes('https://')
                      ? item.profile_image
                      : process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES +
                        item.profile_image
                  }')`,
                }}
              ></div>
            )
          ) : (
            <div className="missing-thumbnail-icon-wrapper">
              <ReactSVG
                src={missingPhotoIcon}
                className={'missing-thumbnail-icon'}
                wrapper="span"
              />
            </div>
          )}
          <div
            className={galleryMenuIconClass}
            ref={menuIconRef}
            onClick={() => {
              if (!menuVisible) {
                setMenuVisible(true);
              }
            }}
            id={`gallery-menu-icon-${item.id || item.user}`}
          >
            <ReactSVG src={menuIcon} className={'menu-icon'} wrapper="span" />
          </div>
          {menuVisible && (
            <div
              className={galleryMenuClass}
              id={`gallery-menu${item.id || item.user}`}
              ref={menuRef}
            >
              {relationshipMenuType === 'ignored' && (
                <>
                  <div
                    className="gallery-menu-item"
                    onClick={(event) => {
                      deleteRelationship();
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_UNIGNORE',
                    })}
                  </div>
                  {safeModeMenuItem()}
                </>
              )}
              {relationshipMenuType === 'favorites' && (
                <>
                  <div
                    className="gallery-menu-item"
                    onClick={(event) => {
                      redirectToMessengerHandler(item.id, false);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_WRITE_A_MESSAGE',
                    })}
                  </div>
                  <div
                    className="gallery-menu-item"
                    onClick={(event) => {
                      deleteRelationship();
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_REMOVE_FAVORITE',
                    })}
                  </div>
                  {safeModeMenuItem()}
                </>
              )}
              {relationshipMenuType === 'active-friends' && (
                <>
                  <div
                    className="gallery-menu-item"
                    onClick={(event) => {
                      redirectToMessengerHandler(item.id);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_WRITE_A_MESSAGE',
                    })}
                  </div>
                  <div
                    className={galleryMenuItem}
                    onClick={(event) => {
                      deleteRelationship();
                      setMenuVisible(false);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_END_FRIENDSHIP',
                    })}
                  </div>
                  {safeModeMenuItem()}
                </>
              )}
              {relationshipMenuType === 'friend-requests' && (
                <>
                  <div
                    className="gallery-menu-item"
                    onClick={(event) => {
                      redirectToMessengerHandler(item.id);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_WRITE_A_MESSAGE',
                    })}
                  </div>
                  <div
                    className={galleryMenuItem}
                    onClick={(event) => {
                      if (!hasFriendsScope) return;
                      answerFriendRequest(
                        false,
                        item.id.replace('-', '@'),
                        item.nickname
                      );
                      setMenuVisible(false);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_CONFIRM_DENY',
                    })}
                  </div>
                  {safeModeMenuItem()}
                </>
              )}
              {relationshipMenuType === 'my-requests' && (
                <>
                  <div
                    className="gallery-menu-item"
                    onClick={(event) => {
                      redirectToMessengerHandler(item.id);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_WRITE_A_MESSAGE',
                    })}
                  </div>
                  <div
                    className={galleryMenuItem}
                    onClick={(event) => {
                      deleteRelationship();
                      setMenuVisible(false);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_CANCEL_REQUEST',
                    })}
                  </div>
                  {safeModeMenuItem()}
                </>
              )}
              {relationshipMenuType === 'denied-requests' && (
                <>
                  <div
                    className="gallery-menu-item"
                    onClick={(event) => {
                      redirectToMessengerHandler(item.id);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_WRITE_A_MESSAGE',
                    })}
                  </div>
                  <div
                    className={galleryMenuItem}
                    onClick={(event) => {
                      removeDeniedFriendRequest(item.id, item.nickname);
                      setMenuVisible(false);
                      event.stopPropagation();
                    }}
                  >
                    {intl.formatMessage({
                      id: 'RELATIONSHIP_ITEM_MENU_REMOVE_REQUEST',
                    })}
                  </div>
                  {safeModeMenuItem()}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="textbox">
        <span>{item.nickname || item}</span>{' '}
        {item.portals?.whitelabelShort
          ? `| ${item.portals?.whitelabelShort}`
          : ''}
      </div>
    </div>
  );
}

export default RelationshipItem;

