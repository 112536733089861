import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import greenIcon from '../../../../assets/img/svg/check-circle-outline.svg';
import menuIcon from '../../../../assets/img/svg/menu-dots.svg';
import noPictureIcon from '../../../../assets/img/svg/no-picture.svg';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { spinnerRequest } from '../../../../shared/spinnerRequest';
import Button from '../../../Button/Button';
import DateItem from '../../../Tables/TableItems/DateItem';
import { getValidationScheme } from '../Diary.functions';

function DiaryItem({
  published,
  date,
  image,
  type,
  title,
  id,
  spinner,
  updateActiveGallery,
  editing,
  item,
  deleteDiaryEntry,
}) {
  const intl = useIntl();
  const history = useHistory();
  const [menuVisible, setMenuVisible] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const menuIconRef = useRef();
  const menuRef = useRef();
  const validationSchema = getValidationScheme(intl);

  const [, publishDiaryData] = useAxios(
    {
      url: `/user/diary/${id}/publish`,
      method: 'POST',
    },
    { manual: true }
  );

  const [, unpublishDiaryData] = useAxios(
    {
      url: `/user/diary/${id}/publish`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const openDiaryEntry = (id) => {
    history.push({
      pathname: `/my-content/diary/edit/${id}`,
    });
  };

  function publishEntry() {
    const request = published ? unpublishDiaryData : publishDiaryData;
    spinnerRequest({
      request,
      spinner,
    })
      .then((result) => {
        if (result.status === 200 || result.status === 204) {
          updateActiveGallery({ ...item, published: !published });
        }
      })
      .catch((error) => {
        console.log({ publishDiaryEntryError: error });
      });
  }

  useOutsideClick(
    menuRef,
    () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    },
    menuIconRef
  );

  const menuClassNames = classNames('btn-diary', {
    open: menuVisible,
    editing,
  });

  const publishTextId = published
    ? 'DIARY_MENU_UNPUBLISH'
    : 'DIARY_MENU_PUBLISH';

  useEffect(() => {
    (async () => {
      let isValid = false;
      try {
        isValid = await validationSchema.validate(item);
      } catch (error) {
        isValid = false;
      }
      setIsValid(!!isValid);
    })();
  }, [item, validationSchema]);

  const editableCheck = type === 'weblog';

  const handleClickEdit = (id) => {
    if (editableCheck) {
      openDiaryEntry(id);
    }
  };

  const rowClass = classNames({
    'new-entry': !date,
    'editablediary-item': editableCheck,
  });

  return (
    <tr className={rowClass}>
      <td className="status" onClick={() => handleClickEdit(id)}>
        {published && (
          <ReactSVG
            src={greenIcon}
            wrapper="span"
            className="status-icon icon-green"
          />
        )}
      </td>
      <td className="date" onClick={() => handleClickEdit(id)}>
        {date ? (
          <DateItem data={date} />
        ) : (
          intl.formatMessage({ id: 'DIARY_NEW_ENTRY' })
        )}
      </td>
      <td className="preview" onClick={() => handleClickEdit(id)}>
        <div className="diary-thumbnail-wrapper">
          {image ? (
            <div
              className="diary-thumbnail"
              style={{
                backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${image}')`,
              }}
            />
          ) : (
            <div className="diary-thumbnail no-image">
              <ReactSVG src={noPictureIcon} wrapper="span" className="icon" />
            </div>
          )}
        </div>
      </td>
      <td className="type" onClick={() => handleClickEdit(id)}>
        {type === 'weblog'
          ? intl.formatMessage({ id: 'diary_weblog' })
          : intl.formatMessage({ id: 'diary_automatic_entry' })}
      </td>
      <td className="title" onClick={() => handleClickEdit(id)}>
        <span className="label">
          {type === 'profile_image'
            ? intl.formatMessage({ id: 'diary_profile_image' })
            : title}
        </span>
      </td>
      <td className="options">
        {type === 'weblog' && (
          <Button
            intlTranslate={false}
            icon={menuIcon}
            innerRef={menuIconRef}
            variant="icon-only"
            className={menuClassNames}
            onClick={() => {
              if (!menuVisible) {
                setMenuVisible(true);
              } else {
                setMenuVisible(false);
              }
            }}
          />
        )}

        {menuVisible && (
          <div className="sub-menu" id={`diary-menu${id}`} ref={menuRef}>
            <div>
              <div
                className="diary-menu-item"
                onClick={() => {
                  openDiaryEntry(id);
                  setMenuVisible(false);
                }}
              >
                {intl.formatMessage({
                  id: 'DIARY_MENU_EDIT',
                })}
              </div>
              {isValid && (
                <div
                  className="diary-menu-item"
                  onClick={() => {
                    publishEntry();
                    setMenuVisible(false);
                  }}
                >
                  {intl.formatMessage({
                    id: publishTextId,
                  })}
                </div>
              )}
              <div
                className="diary-menu-item"
                onClick={() => {
                  deleteDiaryEntry(id);
                  setMenuVisible(false);
                }}
              >
                {intl.formatMessage({
                  id: 'DIARY_MENU_DELETE',
                })}
              </div>
            </div>
          </div>
        )}
      </td>
    </tr>
  );
}

export default DiaryItem;
