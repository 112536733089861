import {
  ADD_CAM,
  ADD_DIALOG,
  ALERT_CLOSE,
  ALERT_VERSION_UPDATE_MANDATORY,
  ALERT_VERSION_UPDATE_OPTIONAL,
  ATTACHMENT_DELETE,
  ATTACHMENT_DELETE_ASK,
  ATTACHMENT_DELETE_NOT_ALLOWED,
  BANDWIDTH_CHECK,
  BROWSER_NOT_ALLOWED,
  CAM_LOADING_END,
  CAM_LOADING_START,
  CAM_PERMISSION_REQUEST_FAILED,
  CHANGE_CAM,
  CHAT_ATTACHEMENT_ERROR,
  COOKIE,
  DIARY_ENTRY_DELETE,
  DOCUMENT_ERROR,
  DO_NOT_SHOW_IN,
  EXCLUSIVE_ASK,
  EXCLUSIVE_ASK_SENDER,
  EXCLUSIVE_ASK_TICK,
  FETCH_ALL_MESSAGES,
  FRIEND_DELETE,
  FRIEND_ERROR,
  FRIEND_FRIEND_REQUEST_ALREADY_SENT,
  FRIEND_FRIEND_REQUEST_ANSWER,
  FRIEND_FRIEND_REQUEST_DENIED,
  FRIEND_FRIEND_REQUEST_REMOVE_DENIED,
  FRIEND_FRIEND_REQUEST_SEND,
  GAME_INVITATION_ACCEPT,
  GAME_INVITATION_DECLINE,
  IGNORE_USER,
  MANAGEMENT_CAM_LOGIN,
  MANAGEMENT_CAM_LOGOUT,
  MANAGEMENT_CONNECT,
  MANAGEMENT_EVENTS_BROADCAST_MESSAGE,
  MANAGEMENT_EVENTS_CONNECTION_DISCONNECT,
  MANAGEMENT_EVENTS_USER_REMOVE,
  MANAGEMENT_ORIGINS_SWITCH,
  MANAGEMENT_USER_FAVORITE,
  MANAGEMENT_USER_KICK,
  MESSAGE_CHAT_SEND,
  MICROPHONE_DISABLED_ERROR,
  NEW_DIALOG,
  ORIGIN_TOGGLE,
  RESET_CAM,
  ROOM_TYPES_CHANGE,
  SENDER_LOGIN,
  SENDER_LOGOUT,
  SHOW_TYPE_EXPIRED,
  SONUS_ERROR,
  SOUND_PLAY_CONFIRM,
  STREAM_EVENTS_ERROR,
  STREAM_LOW_FPS,
  STREAM_PUBLISH,
  TEST_CAM,
  TOKEN_LOGIN_ERROR,
  TOYCONTROL_APP_OFFLINE,
  UPGRADE_START,
  USER_LOGOUT_FORCED,
  USER_SHOW_LOGOUT_ALERT,
  VERIFF_ERROR,
} from '../../actions/actions';
import {
  ALERT_ACTION_DO_NOT_SHOW_IN,
  ALERT_ACTION_UNIGNORE_USER,
  ALERT_LEVEL_ERROR,
  ALERT_LEVEL_INFO,
  ALERT_LEVEL_NONE,
  ALERT_TYPE_CLOSE,
  ALERT_TYPE_CONFIRM,
  ALERT_TYPE_VERSION_UPDATE,
  CONTACT_TOO_OLD,
  IGNORED_MESSAGE,
  IGNORING_MESSAGE,
  INVALID_MESSAGE,
  MESSAGE_LIMIT_ERROR,
  MESSAGE_LIMIT_NO_DIALOG,
  NO_EXISTING_CONVERSATION,
  NO_EXISTING_CONVERSATION_GAME_TIMEOUT,
  OFFLINE_DAYS_LIMIT,
  PAYTTACHMENTS_DISABLED,
  PAYTTACHMENTS_DISABLED_FOR_USER,
  PAYTTACHMENT_ALREADY_RECEIVED_ERROR,
  PAYTTACHMENT_NOT_FOUND,
} from '../../constants';
import { hashAlert, uuid } from '../../utils';
import cam from './cam';
import chat from './chat';
import general from './general';
import management from './management';
import roomAndShowTypes from './roomAndShowType';
import sender from './sender';
import settings from './settings';
import stream from './stream';

const initialState = [];
const alert = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_ATTACHEMENT_ERROR:
      return chat(state, action);
    // general alert actions
    case ALERT_CLOSE:
    case SOUND_PLAY_CONFIRM:
    case BROWSER_NOT_ALLOWED:
    case ADD_DIALOG:
    case NEW_DIALOG:
    case ATTACHMENT_DELETE_NOT_ALLOWED:
    case FRIEND_ERROR:
    case TOKEN_LOGIN_ERROR:
    case USER_SHOW_LOGOUT_ALERT:
      return general(state, action);
    // sender login/logout
    case SENDER_LOGIN:
    case SENDER_LOGOUT:
      return sender(state, action);
    // cam actions
    case CHANGE_CAM:
    case ADD_CAM:
    case RESET_CAM:
    case TEST_CAM:
    case CAM_PERMISSION_REQUEST_FAILED:
    case CAM_LOADING_START:
    case CAM_LOADING_END:
      return cam(state, action);
    // settings actions
    case BANDWIDTH_CHECK:
    case ORIGIN_TOGGLE:
    case ROOM_TYPES_CHANGE:
      return settings(state, action);
    // management actions
    case MANAGEMENT_EVENTS_BROADCAST_MESSAGE:
    case MANAGEMENT_EVENTS_CONNECTION_DISCONNECT:
    case MANAGEMENT_ORIGINS_SWITCH:
    case MANAGEMENT_CAM_LOGIN:
    case MANAGEMENT_CONNECT:
    case MANAGEMENT_CAM_LOGOUT:
    case MANAGEMENT_USER_KICK:
    case IGNORE_USER:
    case MANAGEMENT_EVENTS_USER_REMOVE:
    case MANAGEMENT_USER_FAVORITE:
    case FRIEND_FRIEND_REQUEST_SEND:
    case FRIEND_FRIEND_REQUEST_ANSWER:
    case FRIEND_DELETE:
    case FRIEND_FRIEND_REQUEST_ALREADY_SENT:
    case FRIEND_FRIEND_REQUEST_DENIED:
    case FRIEND_FRIEND_REQUEST_REMOVE_DENIED:
    case DIARY_ENTRY_DELETE:
      return management(state, action);
    // stream actions
    case STREAM_PUBLISH:
    case STREAM_EVENTS_ERROR:
    case STREAM_LOW_FPS:
      return stream(state, action);
    // room/showtype actions
    case EXCLUSIVE_ASK:
    case EXCLUSIVE_ASK_TICK:
    case EXCLUSIVE_ASK_SENDER:
    case SHOW_TYPE_EXPIRED:
    case UPGRADE_START:
      return roomAndShowTypes(state, action);
    case COOKIE:
      if (action.payload.thirdPartyCookiesAllowed === false && action.meta) {
        const { modal, id, links, scope, headlineId } = action.meta;
        let alert = {
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          modal,
          scope,
          message: {
            translate: true,
            id,
            links,
            headlineId,
          },
          stamp: uuid(),
        };

        // make alert unique
        if (
          state.filter((a) => {
            return hashAlert(alert) === hashAlert(a);
          }).length > 0
        ) {
          return state;
        }

        return [...state, alert];
      }
      return state;

    case USER_LOGOUT_FORCED: {
      const links = {
        link_support: '/support',
      };
      let alert = {
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        modal: false,
        scope: 'general',
        persistAfterLogout: true,
        message: {
          translate: true,
          links,
          id: 'ALERT_FORCED_LOGOUT',
          headlineId: 'ERROR',
        },
        stamp: uuid(),
      };

      // make alert unique
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }

    case DOCUMENT_ERROR: {
      let alert = {
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        modal: false,
        scope: 'general',
        persistAfterLogout: true,
        message: {
          translate: true,
          id: 'ALERT_DOCUMENT',
          headlineId: 'ERROR',
        },
        stamp: uuid(),
      };

      // make alert unique
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }
    case FETCH_ALL_MESSAGES: {
      if (!action?.error) {
        return state;
      }
      let alert = {
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        modal: false,
        scope: 'general',
        persistAfterLogout: true,
        message: {
          translate: true,
          id: 'alert.allMessages',
          headlineId: 'ERROR',
        },
        stamp: uuid(),
      };

      // make alert unique
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }
    case MESSAGE_CHAT_SEND: {
      if (action.error && action.payload !== IGNORED_MESSAGE) {
        let message = {
          translate: true,
          headlineId: 'ERROR',
        };

        if (action.payload === MESSAGE_LIMIT_ERROR) {
          message.id = 'alert.messageLimit';
        } else if (
          action?.payload?.includes &&
          action?.payload?.includes(OFFLINE_DAYS_LIMIT)
        ) {
          message.id = 'alert.dayLimit';
        } else if (action.payload === PAYTTACHMENT_ALREADY_RECEIVED_ERROR) {
          message.id = 'alert.payttachmentAlreadyReceived';
        } else if (action.payload === PAYTTACHMENTS_DISABLED) {
          message.id = 'alert.payttachmentsDisabled';
        } else if (action.payload === PAYTTACHMENTS_DISABLED_FOR_USER) {
          message.id = 'alert.payttachmentsDisabledForUser';
        } else if (action.payload === PAYTTACHMENT_NOT_FOUND) {
          message.id = 'alert.payttachmentNotFound';
        } else if (action.payload.includes(CONTACT_TOO_OLD)) {
          message.id = 'alert.contactTooOld';
        } else if (action.payload === NO_EXISTING_CONVERSATION) {
          message.id = 'alert.noExistingConversation';
        } else if (action.payload === NO_EXISTING_CONVERSATION_GAME_TIMEOUT) {
          message.id = 'alert.noExistingConversationGameTimeout';
        } else if (action.payload === INVALID_MESSAGE) {
          message.id = 'alert.invalidMessage';
        } else if (action.payload === IGNORING_MESSAGE) {
          return [
            ...state,
            {
              stamp: uuid(),
              type: ALERT_TYPE_CONFIRM,
              level: ALERT_LEVEL_NONE,
              alertAction: ALERT_ACTION_UNIGNORE_USER,
              scope: 'livecam',
              message: {
                headlineId: 'alert.ignoring',
                translate: true,
                id: 'alert.unignoreUser',
              },
            },
          ];
        } else if (action.payload === MESSAGE_LIMIT_NO_DIALOG) {
          message.id = 'alert.noDialog';
        } else {
          return state;
        }

        let alert = {
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          modal: false,
          scope: 'livecam',
          message,
          stamp: uuid(),
        };

        // make alert unique
        if (
          state.filter((a) => {
            return hashAlert(alert) === hashAlert(a);
          }).length > 0
        ) {
          return state;
        }
        return [...state, alert];
      }
      return state;
    }
    case ATTACHMENT_DELETE_ASK: {
      if (action.payload.id && action.payload.stamp) {
        // make alert unique
        const alert = {
          type: ALERT_TYPE_CONFIRM,
          level: ALERT_LEVEL_NONE,
          modal: true,
          scope: 'general',
          message: {
            headlineId: 'alert.areYouSure',
            translate: true,
            id: 'DELETE_ATTACHMENT',
          },
          stamp: action.payload.stamp,
        };
        if (
          state.filter((a) => {
            return hashAlert(alert) === hashAlert(a);
          }).length > 0
        ) {
          return state;
        }
        return [...state, alert];
      }
      return state;
    }
    case ATTACHMENT_DELETE: {
      if (action.error) {
        const alert = {
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          scope: 'general',
          message: {
            headlineId: 'ERROR',
            translate: true,
            id: 'DELETE_ATTACHMENT_FAILED',
          },
          stamp: uuid(),
        };
        if (
          state.filter((a) => {
            return hashAlert(alert) === hashAlert(a);
          }).length > 0
        ) {
          return state;
        }
        return [...state, alert];
      }
      return state;
    }
    case TOYCONTROL_APP_OFFLINE: {
      const alert = {
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        scope: 'livecam',
        message: {
          headlineId: 'ERROR',
          translate: true,
          id: 'TOYCONTROL_APP_OFFLINE',
        },
        stamp: uuid(),
      };
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }
    case GAME_INVITATION_ACCEPT: {
      if (!action.error) {
        return state;
      }
      const alert = {
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        scope: 'general',
        message: {
          headlineId: 'ERROR',
          translate: true,
          id: 'GAME_ACCEPT_ERROR',
        },
        stamp: uuid(),
      };
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }
    case GAME_INVITATION_DECLINE: {
      if (!action.error) {
        return state;
      }
      const alert = {
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        scope: 'general',
        message: {
          headlineId: 'ERROR',
          translate: true,
          id: 'GAME_DECLINE_ERROR',
        },
        stamp: uuid(),
      };
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }
    case ALERT_VERSION_UPDATE_MANDATORY:
    case ALERT_VERSION_UPDATE_OPTIONAL: {
      const alert = {
        type: ALERT_TYPE_VERSION_UPDATE,
        level: ALERT_LEVEL_INFO,
        scope: 'general',
        mandatory: action.type === ALERT_VERSION_UPDATE_MANDATORY,
        stamp: action.meta?.stamp,
      };
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }
    case DO_NOT_SHOW_IN:
      if (action.meta && action.meta.start) {
        const index = state.findIndex(
          (alert) => alert.alertAction === ALERT_ACTION_DO_NOT_SHOW_IN
        );
        if (index !== -1) return state;
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CONFIRM,
            level: ALERT_LEVEL_NONE,
            alertAction: ALERT_ACTION_DO_NOT_SHOW_IN,
            scope: 'general',
            modal: true,
            message: {
              headlineId: 'alert.areYouSure',
              translate: true,
              id: 'alert.doNotShowIn',
            },
          },
        ];
      }
      return state;
    case VERIFF_ERROR: {
      const alert = {
        stamp: action.meta.stamp,
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        scope: 'general',
        message: {
          headlineId: 'ERROR_TITLE',
          translate: true,
          id: 'alert.veriffError',
        },
      };
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }
    case SONUS_ERROR: {
      const id = action.meta.token ? 'SONUS_TOKEN_ERROR' : 'SONUS_ERROR';
      const alert = {
        stamp: action.meta.stamp,
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        scope: action.meta.token ? 'general' : 'livecam',
        modal: true,
        message: {
          headlineId: 'ERROR_TITLE',
          translate: true,
          id,
          values: { number: action.payload.number },
        },
      };
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }

      return [...state, alert];
    }
    case MICROPHONE_DISABLED_ERROR: {
      const alert = {
        stamp: action.meta.stamp,
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        scope: 'livecam',
        modal: true,
        message: {
          headlineId: 'ERROR_TITLE',
          translate: true,
          id: 'MICROPHONE_DISABLED_ERROR',
        },
      };
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    }
    default:
      return state;
  }
};

export default alert;
