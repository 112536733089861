import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { redirectedSettings } from '../../../actions';
import { STREAMING_IS_STARTING } from '../../../constants';
import Redirect from './Redirect';

export default connect(
  (state) => ({
    redirectSettings: state.sender.askInitCamAlertConfirmed,
    isStreamStarting: state.streaming.step === STREAMING_IS_STARTING,
    redirectStreaming: state.settings.redirectStreaming,
  }),
  (dispatch) => ({
    redirectDone: () => dispatch(redirectedSettings()),
  })
)(withRouter(Redirect));
