import React from 'react';
import { Redirect as RedirectRouter } from 'react-router';
class Redirect extends React.Component {
  componentDidUpdate() {
    if (this.props.redirectSettings) {
      this.props.redirectDone();
    }
  }
  render() {
    const { redirectSettings, location, isStreamStarting, redirectStreaming } =
      this.props;
    const pathSettings = 'my-account/settings/livecam';
    if (redirectSettings && location.pathname !== pathSettings) {
      return <RedirectRouter to={pathSettings} />;
    }
    const pathStreaming = '/multi-messenger';
    if (
      isStreamStarting &&
      location.pathname !== pathStreaming &&
      redirectStreaming
    ) {
      return <RedirectRouter to={pathStreaming} />;
    }
    return null;
  }
}

export default Redirect;
