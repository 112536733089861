export default {
  'livecam.settings.headline':
    'Here you can change or update your livecam settings',
  'streamingOptions.title': 'Stream settings',
  'streamingOptions.cam': 'Webcam',
  'streamingOptions.fps': 'Frames per second',
  'streamingOptions.resolution': 'Resolution',
  'streamingOptions.mic': 'Microphone',
  'streamingOptions.selectCam': 'Select a webcam',
  'streamingOptions.noMic': 'No microphone',
  'cam.noCam':
    'No webcam found. Please plug in a webcam and restart the software.',
  'bandwidthCheck.title': 'Bandwidth check',
  'bandwidthCheck.loading': 'Loading:',
  'bandwidthCheck.current': 'Actual bandwidth:',
  'bandwidthCheck.average': 'Average bandwidth:',
  'check.bandwidth': 'Check bandwidth',
  'bandwidthCheck.check': 'Check bandwidth {suffix}',

  'chatTemplates.title': 'Text-Templates for the Function-Keys',
  'chatTemplates.key': 'Key',
  'chatTemplates.text': 'Text',

  'userColors.title': 'User colors',
  'showSendername.title': 'Display your name',
  'fontSize.title': 'Font size',

  'info.setting.video.title': `Video livecam settings`,
  'info.setting.title': 'The meaning of the settings',
  'info.text.streamSettings':
    '<span class="bold">Important information about the stream settings</span><br />If a webcam or a webcam splitter is installed and its use has been allowed in the browser, it will be displayed in the selection menu. If you have several webcam splitters or webcams, you have the option of selecting one.',
  'info.text_2.streamSettings':
    'Windows users: If there are several applications that access the webcam at the same time, a webcam splitter must be installed, as this is the only way to distribute the video signal.',
  'info.text_3.streamSettings':
    'Here you can also select the video resolution with which the webcam image should be transmitted. The software checks your hardware and software configuration as well as the bandwidth of your Internet connection and uses this to determine the best possible transmission value.',
  'info.text_4.streamSettings':
    'The resolution is given as width x height in pixels, in brackets you can see the aspect ratio of the resolution (4: 3 or 16: 9).',
  'info.text_5.streamSettings':
    'If black bars are displayed on the left / right or top / bottom in the preview image, you have to adjust the video resolution, because the bars reduce the maximum possible video display for the user.',
  'info.text_6.streamSettings':
    'If you have set an aspect ratio of 4: 3 and you can see black bars at the top and bottom, then choose a similar resolution with an aspect ratio of 16: 9',
  'info.text_7.streamSettings':
    'If you have set an aspect ratio of 16: 9 and black bars can be seen on the left and right, then select a similar resolution with an aspect ratio of 4: 3.',
  'info.text_8.streamSettings':
    'Please make sure that NO black bars are visible in the preview image and thus also in the video image transmitted to the user.',
  'info.text_9.streamSettings':
    'In particular after updates and changes to the settings in the splitter software or when switching between e.g. mobile phone / tablet and PC, the settings can be lost and the resolution must be set again.',
  'info.text.sounds':
    '<span class="bold">Notification sounds and their volume</span><br />Choose a notification sound for different situations, which also notifies you acoustically, e.g. when a new message arrives. You can adjust the volume of the sounds in several steps between 50% (half volume) and 300% (three times the volume). The selected volume is equal for all notification sounds.',
  'info.text.fKeys':
    '<span class="bold">What is the use of the list containing F1, F2, etc?</span><br />In this list you can save short texts, which will be sent to the user by pressing the corresponding F-Key. In that way you are able to greet users only by pressing one key. You can also use placeholders to insert the username or the local time.',
  'info.currentTime': 'Current time',
  'info.customerName': 'Username',
  'info.placeholder': 'Placeholder',
  'info.replace': 'will be replaced by',

  'audio.title': 'Sound',
  'audio.volume': 'Volume',
  'audio.connectionLost': 'Connection lost',
  'audio.userLeavesChat': 'User leaves chat',
  'audio.newChatMessage': 'New chat message',
};
