import './ThumbnailItem.scss';

import classNames from 'classnames';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import GalleryItem from './GalleryItem/GalleryItem';
import PhotoItem from './PhotoItem';
import RelationshipItem from './RelationshipItem';
import SpinnerItem from './SpinnerItem/SpinnerItem';
import VideoItem from './VideoItem/VideoItem';

function ThumbnailItem({
  item,
  type,
  openPictureCropper,
  galleryStatus,
  spinner,
  itemDeleted,
  activeGallery,
  galleryThumbnail,
  openThumbnail,
  itemMenuVisible,
  updateMenuVisibility,
  setImageError,
  itemUpdated,
  language,
  relationshipType,
  relationshipMenuType,
  updateList,
  thumbnailRejected,
  data,
  changeItemPosition,
  setDeletionError,
  conversionDetails,
  uploadPercent,
  conversionError,
  temporaryUpdateList,
  tickOrText,
  component,
  videoGalleryDeleteDisabled,
  photoGalleryDeleteDisabled,
  showMediumOnPreview,
}) {
  const history = useHistory();
  const statusClassName = classNames('badge', {
    red:
      item.state === 'blocked' ||
      item.state === 'archived' ||
      item.fsk === 'fsk18' ||
      thumbnailRejected,
    blue:
      item.state === 'unpublished' || (galleryThumbnail && !thumbnailRejected),
    green: item.state === 'published' || item.schedule || item.fsk === 'fsk12',
    orange: item.state === 'pending' || item.fsk === 'fsk16',
  });

  const openGallery = (type, id, target) => {
    const galleryType = history.location.pathname.includes('videos')
      ? 'videos'
      : 'photos';
    history.push({
      pathname: `/my-content/${galleryType}/edit-gallery`,
      search: `?type=${type}&id=${id}&target=${target}`,
    });
  };

  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <>
      {type === 'gallery' && (
        <GalleryItem
          item={item}
          statusClassName={statusClassName}
          openGallery={openGallery}
          itemMenuVisible={itemMenuVisible}
          updateMenuVisibility={updateMenuVisibility}
          spinner={spinner}
          itemDeleted={itemDeleted}
          menuVisible={menuVisible}
          setMenuVisible={setMenuVisible}
          itemUpdated={itemUpdated}
          language={language}
        />
      )}

      {type === 'photo' && (
        <PhotoItem
          item={item}
          statusClassName={statusClassName}
          itemMenuVisible={itemMenuVisible}
          updateMenuVisibility={updateMenuVisibility}
          openPictureCropper={openPictureCropper}
          galleryStatus={galleryStatus}
          spinner={spinner}
          itemDeleted={itemDeleted}
          activeGallery={activeGallery}
          galleryThumbnail={galleryThumbnail}
          openThumbnail={openThumbnail}
          setImageError={setImageError}
          menuVisible={menuVisible}
          setMenuVisible={setMenuVisible}
          thumbnailRejected={thumbnailRejected}
          changeItemPosition={changeItemPosition}
          tickOrText={tickOrText}
          photoGalleryDeleteDisabled={photoGalleryDeleteDisabled}
          showMediumOnPreview={showMediumOnPreview}
        />
      )}

      {type === 'relationship' && (
        <RelationshipItem
          item={item}
          menuVisible={menuVisible}
          setMenuVisible={setMenuVisible}
          relationshipType={relationshipType}
          relationshipMenuType={relationshipMenuType}
          updateList={updateList}
          temporaryUpdateList={temporaryUpdateList}
        />
      )}

      {type === 'video' && (
        <VideoItem
          item={item}
          statusClassName={statusClassName}
          itemMenuVisible={itemMenuVisible}
          updateMenuVisibility={updateMenuVisibility}
          openPictureCropper={openPictureCropper}
          galleryStatus={galleryStatus}
          spinner={spinner}
          itemDeleted={itemDeleted}
          activeGallery={activeGallery}
          galleryThumbnail={galleryThumbnail}
          openThumbnail={openThumbnail}
          setImageError={setImageError}
          menuVisible={menuVisible}
          setMenuVisible={setMenuVisible}
          data={data}
          setDeletionError={setDeletionError}
          videoGalleryDeleteDisabled={videoGalleryDeleteDisabled}
        />
      )}

      {type === 'spinner' && (
        <SpinnerItem
          conversionDetails={conversionDetails}
          uploadPercent={uploadPercent}
          conversionError={conversionError}
          component={component}
        />
      )}
    </>
  );
}

export default ThumbnailItem;

