import { SPINNER } from '../actions/actions';

const initialState = {
  active: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER:
      return { active: action.payload };
    default:
      return state;
  }
};

export default reducer;
