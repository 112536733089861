import { MESSAGE_TYPE_SENDER } from '../../../constants';
import { MESSAGE_SENT } from '../../actions';
import { typingSender } from '../..';
export default (data) => (dispatch, getState) => {
  setTimeout(() => {
    const {
      user: {
        account: { nickname: from },
      },
      dialog: { dialogs, processQueue, queue },
      camUser: { camUsers },
      chat: { selectedUser },
    } = getState();
    const selectedLivecamUser = camUsers.find((u) => u.userId === selectedUser);
    const user = [...dialogs, ...processQueue, ...queue].find(
      (d) => d.partner === data.recipient
    );

    if (!user) {
      return; // just drop it
    }

    const payload = {
      ...data,
      from,
      to: user?.profile?.info?.nickname,
      type: MESSAGE_TYPE_SENDER,
      userId: data.recipient,
      message: data.body,
      userColor: user.color,
    };

    delete payload.body;

    if (payload.payload?.type !== 'payttachment') {
      delete payload.payload;
    }

    dispatch({
      type: MESSAGE_SENT,
      payload,
      meta: {
        selectedLivecamUser: !!selectedLivecamUser,
        selectedUser,
        user,
      },
    });
    if (selectedLivecamUser) {
      dispatch(typingSender(selectedLivecamUser.userId, ''));
    }
  }, 1000);
};
