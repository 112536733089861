import './FunctionKeys.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import useMediaQuery from '../../../hooks/useMediaQuery';

const FunctionKeys = ({ changeFunctionkey, functionkeys }) => {
  const isLargeScreen = useMediaQuery('(min-width: 1920px)');
  return (
    <div className="function-keys">
      <h2 className="headline">
        <FormattedMessage id="chatTemplates.title" />
      </h2>
      {isLargeScreen && (
        <div className="data-columns">
          <div className="form-group">
            <label>
              <FormattedMessage id="chatTemplates.key" />
            </label>
            <span className="extra-label">
              <FormattedMessage id="chatTemplates.text" />
            </span>
          </div>
          <div className="form-group">
            <label>
              <FormattedMessage id="chatTemplates.key" />
            </label>
            <span className="extra-label">
              <FormattedMessage id="chatTemplates.text" />
            </span>
          </div>
        </div>
      )}
      <div className="data-columns">
        {functionkeys.map((key, index) => (
          <div className="form-group" key={index}>
            {' '}
            <label>F{index + 1}</label>{' '}
            <div className="input-wrapper">
              <input
                type="text"
                value={key}
                onChange={changeFunctionkey}
                fkey={index}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FunctionKeys;
