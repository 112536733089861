import './PayttachmentItem.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import coinsIcon from '../../../assets/img/svg/coins.png';
import movieIcon from '../../../assets/img/svg/movie.svg';
import noPictureIcon from '../../../assets/img/svg/no-picture.svg';

function PayttachmentItem({
  payttachment,
  setSelectedPayttachment,
  selected,
  selectedFile,
  payttachmentAlreadySent,
}) {
  const [imageExists, setImageExists] = useState(false);

  const classes = classNames('attachment-thumbnail-wrapper', {
    selected,
  });

  const attachmentThumbnailClasses = classNames('attachment-thumbnail', {
    disabled: selectedFile || payttachmentAlreadySent,
    movie: payttachment.type.includes('movie'),
    'no-image': !imageExists && !payttachment.type.includes('movie'),
  });

  const labelClass = classNames('payttachment-name', {
    disabled: payttachmentAlreadySent,
  });
  useEffect(() => {
    if (!payttachment.thumbnail) {
      return;
    }
    const checkImage = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${payttachment.thumbnail}`
        );
        if (response.status === 200) {
          setImageExists(true);
        }
      } catch (error) {}
    };
    checkImage();
  }, [payttachment.thumbnail]);

  const price = (
    <div className="payttachment-price">
      <span>{payttachment.price}</span>{' '}
      <img className="icon-coins" src={coinsIcon} alt="" />
    </div>
  );

  return (
    <>
      <div
        className={classes}
        onClick={() =>
          !selectedFile &&
          !payttachmentAlreadySent &&
          setSelectedPayttachment(payttachment.id)
        }
      >
        {payttachment.type.includes('movie') ? (
          <div className={attachmentThumbnailClasses}>
            {price}
            <ReactSVG src={movieIcon} wrapper="span" className="icon" />
          </div>
        ) : imageExists ? (
          <div
            className={attachmentThumbnailClasses}
            style={{
              backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${payttachment.thumbnail}')`,
            }}
          >
            {price}
          </div>
        ) : (
          <div className={attachmentThumbnailClasses}>
            {price}
            <ReactSVG src={noPictureIcon} wrapper="span" className="icon" />
          </div>
        )}
        <div className={labelClass}>{payttachment.name}</div>
      </div>
    </>
  );
}

export default PayttachmentItem;

