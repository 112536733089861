import dayjs from 'dayjs';
import React from 'react';

import { isToday } from '../../../../utils/date';

class ChatTime extends React.Component {
  render() {
    let date;
    if (this.props.time) {
      const timestamp =
        this.props.time.toString().length === 10
          ? this.props.time * 1000
          : this.props.time;
      date = new Date(timestamp);
    } else {
      date = this.props.date;
    }
    const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const seconds = (date.getSeconds() < 10 ? '0' : '') + date.getSeconds();
    let displayTime = hours + ':' + minutes + ':' + seconds;

    if (!isToday(date)) {
      const fullDate = dayjs(date).format(
        this.props.language === 'de' ? 'D. MMM YYYY' : 'MMM D, YYYY'
      );
      displayTime = `${fullDate} ${displayTime}`;
    }

    return <span className="chat-time">{displayTime}</span>;
  }
}

export default ChatTime;
