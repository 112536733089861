import { createTransform } from 'redux-persist';

import crypt from './crypt';
import { whitelist } from './lists';

export default createTransform(
  // inbound
  (inboundState, key) => {
    switch (key) {
      case 'bandwidth':
        return {
          percent: inboundState.percent,
          currentBandwidth: inboundState.currentBandwidth,
          averageBandwidth: inboundState.averageBandwidth,
        };
      case 'cam':
        return crypt.run(
          {
            selectedCam: inboundState.selectedCam,
            selectedMicrophone: inboundState.selectedMicrophone,
            selectedResolution: inboundState.selectedResolution,
            resolutions: inboundState.resolutions,
            oldServerResolutions: inboundState.oldServerResolutions,
          },
          true
        );
      case 'sounds':
        return {
          volume: inboundState.volume,
        };
      case 'streaming':
        return crypt.run({ mute: inboundState.mute }, true);
      default:
        return inboundState;
    }
  },
  // outbound
  (outboundState, key) => {
    switch (key) {
      case 'settings': {
        return {
          ...outboundState,
          userMenuOpen: false,
          toycontrolMenuOpen: false,
        };
      }
      case 'cam': {
        const newState = crypt.run(outboundState, false);
        const oldServerResolutions = newState.oldServerResolutions
          ? newState.oldServerResolutions.filter((res) => res.fps === 30)
          : [];
        return {
          ...newState,
          selectedFps: { value: 30 },
          oldServerResolutions,
        };
      }
      case 'streaming':
        return crypt.run(outboundState, false);
      default:
        return outboundState;
    }
  },
  { whitelist }
);
