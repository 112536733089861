import { connect } from 'react-redux';

import {
  openOverlay,
  spinner,
  userUpdate as update,
  updateActiveGallery,
  updateImages,
  uploadFile,
} from '../../../actions';
import EditGallery from './EditGallery';

const mapStateToProps = (state) => ({
  language: state.user.account.language,
  isMobile: state.browser.isMobile,
  uploadError: state.upload.error,
  uploadResult: state.upload.result,
  uploadIndicator: state.upload.indicator,
  activeGallery: state.galleries.active,
  user: state.user,
  uploadPercent: state.upload.percent,
});

const mapDispatchToProps = {
  updateImages,
  update,
  openOverlay,
  uploadFile,
  updateActiveGallery,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGallery);
