import { UPDATE_FILE_PERCENT, UPLOAD_FILE } from '../actions/actions';

const initialState = {
  result: null,
  error: null,
  indicator: '',
  percent: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE:
      if (action.error) {
        return {
          ...initialState,
          error: action.payload,
          indicator: action.meta.indicator,
        };
      }

      if (action.meta.end && action.meta.indicator === state.indicator) {
        return initialState;
      }

      return {
        ...initialState,
        result: action.payload,
        indicator: action.meta.indicator,
      };
    case UPDATE_FILE_PERCENT:
      return {
        ...state,
        percent: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
