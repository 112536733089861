import './ButtonSelect.scss';

import classNames from 'classnames';
import React from 'react';
import { ReactSVG } from 'react-svg';

import arrowDownIcon from '../../../assets/img/svg/arrow-down.svg';
import playIcon from '../../../assets/img/svg/play-video.svg';
import Button from '../../Button/Button';

function ButtonSelect({ selected, onChange, options, buttonClick }) {
  const buttonIconClass = classNames('btn-play', {
    disabled: selected === '',
  });

  return (
    <div className="select-container">
      <select value={selected} onChange={onChange}>
        {options.map((option) => {
          return (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      <ReactSVG
        src={arrowDownIcon}
        className="arrow-down-icon button-select-arrow"
      />
      <Button
        variant="icon-only"
        onClick={() => buttonClick(selected)}
        intlTranslate={false}
        icon={playIcon}
        className={buttonIconClass}
      />
    </div>
  );
}

export default ButtonSelect;

