import { WebRTCPublisher } from '../../../services';
import addAdmin from './admin/add';
import removeAdmin from './admin/remove';
import broadcastMessage from './broadcast/message';
import buzz from './buzz';
import connectedCam2Cam from './cam2cam/connected';
import disableCam2Cam from './cam2cam/disable';
import enableCam2Cam from './cam2cam/enable';
import clientDimensions from './client/dimensions';
import streamingStartedClient from './client/streamingStarted';
import connect from './connection/connect';
import connect_error from './connection/connect/error';
import connect_timeout from './connection/connect/timeout';
import disconnect from './connection/disconnect';
import error from './connection/error';
import addAdminMessage from './message/admin/add';
import addUserMessage from './message/user/add';
import startPrivateAudioSession from './privateAudioSession/start';
import reconnect from './reconnect';
import changeSenderResolution from './resolution/sender/change';
import runToySuccess from './runToySuccess';
import kickSender from './sender/kick';
import loggedOut from './sender/loggedout';
import reconnectSocket from './socket/reconnect';
import prewarmStream from './stream/prewarm';
import streamReconnect from './stream/reconnect';
import startStream from './stream/start';
import stopStream from './stream/stop';
import startStreamInfo from './streaminfo/start';
import stopStreamInfo from './streaminfo/stop';
import tip from './tip';
import toycontrolAppOffline from './toycontrolAppOffline';
import clientAsksUpgrade from './upgrade/asks/client';
import upgradesChanged from './upgrade/changed';
import senderAskUpgradeDenied from './upgrade/denied';
import initUpgrade from './upgrade/init';
import startUpgrade from './upgrade/start';
import addUser from './user/add';
import removeUser from './user/remove';
import notifyTyping from './user/typing';
import wake from './wake';

export default (management) => ({
  startStream,
  stopStream,
  addUser,
  removeUser,
  addAdmin,
  removeAdmin,
  error: error(management),
  disconnect: disconnect(management),
  connect_error,
  connect_timeout,
  loggedOut,
  connect,
  livecamChatMessage: addUserMessage,
  adminChatMessage: addAdminMessage,
  notifyTyping,
  wake,
  enableCam2Cam,
  disableCam2Cam,
  buzz,
  kickSender,
  clientAsksUpgrade,
  initUpgrade: initUpgrade(management),
  startUpgrade,
  senderAskUpgradeDenied,
  startPrivateAudioSession,
  startStreamInfo,
  stopStreamInfo,
  upgradesChanged,
  clientDimensions,
  broadCastMessage: broadcastMessage,
  prewarmStream,
  changeSenderResolution: changeSenderResolution(WebRTCPublisher, management),
  tip,
  streamingStartedClient,
  streamReconnect,
  reconnect: reconnect(management),
  reconnectSocket,
  cam2camConnected: connectedCam2Cam,
  runToySuccess,
  toycontrolAppOffline,
});
