import './SkeletonTable.scss';

import React from 'react';

function SkeletonTable({ translatedTitles }) {
  return (
    <table>
      <thead>
        <tr>
          {translatedTitles.map((title, index) => {
            return (
              <th
                key={`${title}.${index}`}
                style={{ width: title.width, textAlign: title.position }}
                className={title.number && 'text-right'}
              >
                {title.title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        <tr className="full-height" />
      </tbody>
    </table>
  );
}

export default SkeletonTable;

