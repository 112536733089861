import { connect } from 'react-redux';

import { spinner, uploadFile } from '../../../../actions';
import ProfileVideo from './ProfileVideo';

const mapStateToProps = (state) => ({
  isMobile: state.browser.isMobile,
  uploadError: state.upload.error,
  uploadResult: state.upload.result,
  uploadIndicator: state.upload.indicator,
  percent: state.upload.percent,
});

const mapDispatchToProps = {
  spinner,
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileVideo);

