import { PERSIST_SEPERATOR_KEY } from '../../constants';
import sender from '../../services/sender';

let apiOpts;

const apiStorage = {
  init: (opts) => {
    apiOpts = opts;
  },
  setItem: (key, value) => {
    if (typeof apiOpts === 'undefined') {
      throw new Error('apiStorage not initialized calling setItem');
    }
    const { isAuthenticated } = apiOpts.store.getState().sender;
    if (!isAuthenticated) {
      // once logged out, do not store to api
      return Promise.resolve({});
    }
    let dataKey = key.split(PERSIST_SEPERATOR_KEY).pop();
    let storeOpts = Object.assign({}, apiOpts, {
      key: dataKey,
    });
    return sender.store(storeOpts).post({ [dataKey]: JSON.parse(value) });
  },
  getItem: (key) => {
    if (typeof apiOpts === 'undefined') {
      throw new Error('apiStorage not initialized calling getItem');
    }
    let dataKey = key.split(PERSIST_SEPERATOR_KEY).pop();
    let storeOpts = Object.assign({}, apiOpts, {
      key: dataKey,
    });
    return sender.store(storeOpts).get();
  },
  removeItem: () => {
    // there is no removeItem at the api but we need to implement this method
    return Promise.resolve();
  },
};
export default apiStorage;
