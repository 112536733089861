import {
  CAM_CONTAINER_HEIGHT_CHANGE,
  CHAT_LAYOUT_CHANGE,
  FUNCTIONKEY_CHANGE,
  MENU_TOYCONTROL_TOGGLE,
  MENU_USERLIST_TOGGLE,
  MENU_USER_TOGGLE,
  SHOW_SENDERNAME_CHANGE,
  SOUND_ACTIVATE,
  SOUND_RESET,
  SOUND_UPDATE,
  STREAMING_REDIRECT_TOGGLE,
  USERCOLOR_CHANGE,
} from '../actions/actions';
import { GLAS, TERMINAL, TOCK2 } from '../constants/sound';

const resetSounds = {
  lostConnection: {
    type: TERMINAL,
    active: true,
  },
  leaveShow: {
    type: TOCK2,
    active: true,
  },
  newMessage: {
    type: GLAS,
    active: true,
  },
};
const initialState = {
  chatlayout: 'single',
  sounds: {
    lostConnection: Object.assign({}, resetSounds.lostConnection),
    leaveShow: Object.assign({}, resetSounds.leaveShow),
    newMessage: Object.assign({}, resetSounds.newMessage),
  },
  usercolor: true,
  showSendername: true,
  functionkeys: ['', '', '', '', '', '', '', ''],
  camContainerHeight: 21.0,
  redirectStreaming: true,
  userMenuOpen: false,
  toycontrolMenuOpen: false,
  userListMenuOpen: false,
  resetSounds,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case MENU_TOYCONTROL_TOGGLE:
      return { ...state, toycontrolMenuOpen: !state.toycontrolMenuOpen };
    case MENU_USERLIST_TOGGLE:
      return { ...state, userListMenuOpen: !state.userListMenuOpen };
    case MENU_USER_TOGGLE:
      return { ...state, userMenuOpen: !state.userMenuOpen };
    case SOUND_RESET:
      return Object.assign({}, state, {
        sounds: {
          lostConnection: Object.assign({}, resetSounds.lostConnection),
          leaveShow: Object.assign({}, resetSounds.leaveShow),
          newMessage: Object.assign({}, resetSounds.newMessage),
        },
      });
    case SOUND_ACTIVATE:
      if (state.sounds[action.payload.sound]) {
        const newState = Object.assign({}, state);
        newState.sounds[action.payload.sound].active = action.payload.active;
        return newState;
      }
      return state;
    case SOUND_UPDATE:
      if (state.sounds[action.payload.sound]) {
        const newState = Object.assign({}, state);
        newState.sounds[action.payload.sound].type = action.payload.type;
        return newState;
      }
      return state;
    case CHAT_LAYOUT_CHANGE:
      return Object.assign({}, state, {
        chatlayout: action.payload,
      });
    case USERCOLOR_CHANGE:
      return Object.assign({}, state, {
        usercolor: action.payload,
      });
    case SHOW_SENDERNAME_CHANGE:
      return { ...state, showSendername: action.payload };
    case FUNCTIONKEY_CHANGE:
      return Object.assign({}, state, {
        functionkeys: state.functionkeys.map((key, index) =>
          index === action.payload.key ? action.payload.value : key
        ),
      });
    case CAM_CONTAINER_HEIGHT_CHANGE:
      return { ...state, camContainerHeight: parseFloat(action.payload) };
    case STREAMING_REDIRECT_TOGGLE:
      return { ...state, redirectStreaming: !state.redirectStreaming };
    default:
      return state;
  }
};

export default settings;
