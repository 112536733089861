export default {
  ROUTE_DOCUMENTS_HEADLINE: `Upload and manage your documents here`,
  UPLOAD_DOCUMENTS: `Document upload`,
  DOCUMENTS_EXISTS: `Existing documents`,
  DOCUMENTS_TEXT_1: `To upload a document, you can simply drag and drop it into the white area with a blue border. Or you click the + to select a file from your computer or smartphone. To take a picture of your document with your webcam, simply click on the field with the camera.`,
  DOCUMENTS_TEXT_2: `You can mark your identification documents as copies. However, all data and the ID photo must always be clearly visible!`,
  DOCUMENTS_TEXT_3: `Please make sure that all documents are up-to-date and not expired! A driver's license is not a valid identification document!`,
  DOCUMENTS_TEXT_4: `Please upload both sides of your document!`,
  DOCUMENT_UPLOADER_LABEL: `New document:`,
  DOCUMENTS_NAME: `Name`,
  DOCUMENTS_STATUS: `Status`,
  DOCUMENTS_UPLOAD_TIME: `Date of upload`,
  UPLOAD_DOCUMENTS_CONTENT_1: `<span class="bold">Please note:</span> All the data and documents you provide are of course subject to privacy policy.`,
  FILENAME: `Filename:`,
  DOCUMENTS_TYPE: `Type`,
  DOCUMENTS_AMATEUR: `Identification`,
  DOCUMENTS_BANKHOLDER: `Bankholder`,
  DOCUMENTS_AVCHECK: `Age verification`,
};

