import { toggleSmileyOverlay } from '../../';
import { MENU_CHAT_TOGGLE } from '../../actions';
export default function (ignoreSmiley) {
  return (dispatch, getState) => {
    const { overlay } = getState().smiley;
    if (overlay) {
      dispatch(toggleSmileyOverlay());
    }
    dispatch({
      type: MENU_CHAT_TOGGLE,
    });
  };
}
