import localStorage from 'redux-persist/lib/storage';

import { PERSIST_SEPERATOR_KEY } from '../../constants';
import apiStorage from './apiStorage';

let opts = {
  serviceURL: '',
  softwareOrigin: '',
  key: '',
  store: {},
};
apiStorage.init(opts);

const itemKey = (key, reducerKey) => key + PERSIST_SEPERATOR_KEY + reducerKey;
const enhanceKey = (addKey, key) => (addKey ? key + opts.key : key);
// add a storage for every reducer
const storages = {
  cam: localStorage,
  settings: apiStorage,
  bandwidth: apiStorage,
  sounds: apiStorage,
  streaming: localStorage,
};
const storageKeys = Object.keys(storages);

// @todo simplify this as possible
const storage = (addKey) => ({
  // write to all configured storages
  setItem: (key, value) => {
    key = enhanceKey(addKey, key);
    const storagePromises = [];
    const tmpValue = JSON.parse(value);
    // add the Promise for all reducer storages to storagePromises array
    storageKeys.forEach((reducerKey) =>
      storagePromises.push(
        storages[reducerKey].setItem(
          itemKey(key, reducerKey),
          tmpValue[reducerKey]
        )
      )
    );
    return Promise.all(storagePromises);
  },
  // get teim from all configured storages
  getItem: (key) => {
    key = enhanceKey(addKey, key);
    const storagePromises = [];
    // add the Promise for all reducer storages to storagePromises array
    storageKeys.forEach((reducerKey) =>
      storagePromises.push(
        storages[reducerKey].getItem(itemKey(key, reducerKey)).then((value) => {
          return Promise.resolve({
            [reducerKey]:
              reducerKey === 'cam' || reducerKey === 'streaming'
                ? JSON.parse(value)
                : value,
          });
        })
      )
    );
    return Promise.all(storagePromises).then((allReducers) => {
      const state = {};
      allReducers.forEach((reducer) =>
        Object.keys(reducer).forEach(
          (key) => (state[key] = JSON.stringify(reducer[key]))
        )
      );
      return Promise.resolve(JSON.stringify(state));
    });
  },
  removeItem: (key) => {
    key = enhanceKey(addKey, key);
    const storagePromises = [];
    // add the Promise for all reducer storages to storagePromises array
    storageKeys.forEach((reducerKey) =>
      storagePromises.push(
        storages[reducerKey].removeItem(itemKey(key, reducerKey))
      )
    );
    return Promise.all(storagePromises);
  },
});
export default {
  init: function (
    options = { softwareOrigin: '', key: '', serviceURL: '', store: {} }
  ) {
    opts = Object.assign(opts, options); // keep the reference
    return this;
  },
  storage,
};
