import { connect } from 'react-redux';

import { openDialog } from '../../../../actions/dialog';
import ReferredCustomers from './ReferredCustomers';

const mapStateToProps = (state) => ({
  language: state.user.account?.language,
  online: state.onlineStatus.online,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (id) => dispatch(openDialog(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferredCustomers);

