export default {
  'alert.yes': 'Ja',
  'alert.no': 'Nein',
  'alert.confirm': 'Annehmen',
  'alert.deny': 'Ablehnen',
  'alert.decideLater': 'Später entscheiden',
  'alert.unIgnore': 'Nicht länger ignorieren',
  'alert.delete': 'Löschen',

  'browser.notAllowed': `Dein Browser wird für die Verwendung unserer Livecam-Software nicht unterstützt, bitte verwende einen der folgenden:
     {browsers}`,
  'alert.areYouSure': 'Bist Du Dir sicher?',
  'alert.becomeFriends': 'Freundschaftsanfrage',
  'alert.testCam': 'Die Cam wird überprüft {suffix}',
  'alert.switching': 'Achtung! Fehler beim Wechsel. Du wirst ausgeloggt.',
  'alert.camAccess':
    'Auf die Kamera konnte nicht zugegriffen werden. ({error})',
  'alert.camLoginError6':
    'Eine neue Software Version ist verfügbar. Bitte lade die Seite neu.',
  'alert.camLoginError11': 'Die Logindaten sind nicht korrekt.',
  'alert.camLoginError20':
    'Anmeldung nicht möglich. Bitte wende Dich an unseren Support.',
  'alert.camLoginError21':
    'Zur Zeit ist keine Anmeldung möglich. Bitte versuche es später noch einmal!',
  'alert.camLoginError22':
    'Du bist bereits mit diesem Nickname angemeldet. Bitte melde Dich erst ab.',
  'alert.camLoginError26':
    'Zu viele Loginversuche innerhalb 60 Sekunden. Bitte versuche es später noch einmal',
  'alert.camLoginError33':
    'Du hast bereits ein Sendertool auf diesem Gerät laufen. Bitte beende diesen zuerst, bevor Du weiter sendest!',
  'alert.camLoginError38':
    'Deine Lovense Connect App ist offline. Deine Verbindung zu Lovense wurde gelöscht, bitte gehe in die Einstellungen und aktiviere "Lovense Toy" erneut und verbinde danach Deine App im Tab Lovense Toy. Bitte kontrolliere die Internet Verbindung Deines Geräts und achte darauf, dass die App im Vordergrund ist ohne Energiesparmodus und kein Bildschirmschoner an ist.',
  'alert.kickUser': 'Möchtest Du den User wirklich aus der Show kicken?',
  'alert.ignoreUser': 'Möchtest Du den User wirklich ignorieren?',
  'alert.unignoreUser':
    'Du kannst dem User keine Nachrichten schicken, da Du ihn ignorierst. Willst Du ihn nicht länger ignorieren?',
  'alert.exclusiveChangeNotPossible':
    'Ein Wechsel in den Exklusiv Modus ist nicht möglich',
  'alert.noWebcamFound':
    'Keine Webcam konfiguriert. Möchtest Du jetzt eine Cam in den Einstellungen festlegen?',
  'alert.noResolutionFound':
    'Die Kamera Auflösung ist nicht mehr verfügbar. Möchtest Du jetzt Deine Kamera Einstellungen ändern?',
  'alert.kickUserWarning':
    'Beim Wechsel in den Exklusiv Modus werden alle anderen User gekickt',
  'alert.exclusive': 'Exklusiv Show Anfrage',
  'alert.exclusiveTooltip':
    'Eine Exklusiv Show kann nur von einem User zur gleichen Zeit besucht werden. Du kannst nur auf einem Portal exklusiv senden. Den Preis pro Minute musst Du auf jedem Portal einzeln einstellen (Profil -> Livecams). Dieser wird dann automatisch übernommen.',
  'alert.askExclusive': '{username} hat Dich zu einer Exklusiv Show eingeladen',
  'alert.exclusivePrice': '{coins} Coins pro Minute',
  'alert.notEnoughCoins':
    '{username} hat nicht genügend Coins für eine Exklusiv Show',
  'alert.exclusiveAccept': 'Annehmen {tick}',
  'alert.exclusiveDeny': 'Ablehnen',
  'alert.sender.exclusive': 'Exklusiv Show Anfrage',
  'alert.sender.ok': 'OK',
  'alert.sender.cancel': 'Abbrechen',
  'alert.sender.askExclusive': '{username} zu einer Exklusiv Show einladen',
  'alert.bandwidthCheckError':
    'Der Bandbreitencheck wurde nicht abgeschlossen. Bitte starte diesen erneut um die beste Qualität zu erhalten.',
  'alert.notEnoughBandwidth': 'Es ist nicht genug Bandbreite verfügbar.',
  'alert.infoSound': 'Bitte bestätigen, um den Sound abzuspielen',
  'alert.camAbort':
    'Es konnte nicht auf die Cam zugegriffen werden. Fehlernummer {number}',
  'alert.camAbortNoResolutions':
    'Auf die Kamera konnte nicht zugegriffen werden. Bitte überprüfe, ob eine andere Software oder andere Portale auf Deine Kamera zugreifen oder die Cam im Browser geblockt ist. Falls Du Fragen hast, kontaktiere bitte unseren Support.',
  'alert.changeBandwidthError':
    'Die Bandbreite konnte nicht angepasst werden, bitte kontaktiere den Support',
  'alert.streamingError':
    'Verbindung zum Streaming Server verloren ({message})',
  'alert.chatError': 'Verbindung zum Chat Server verloren ({message})',
  'alert.noh264Error':
    'Der H.264 Videocodec ist entweder deaktiviert oder nicht installiert. Bitte aktiviere oder installiere diesen. Falls das nicht möglich ist, kontaktiere den Support.',
  'alert.login.1':
    'CamLogin fehlgeschlagen, Fehlerummer 1. Bitte versuche es erneut oder kontaktiere den Support.',
  'alert.login.2':
    'CamLogin fehlgeschlagen, Fehlerummer 2. Bitte versuche es erneut oder kontaktiere den Support.',
  'alert.login.3':
    'Du wurdest ausgeloggt, da Deine Session ungültig war. Das kann z.B. daran liegen, dass die Software in mehreren Tabs benutzt wird. Bitte öffne die Software nur in einem Tab und versuche es erneut oder kontaktiere den Support.',
  'alert.lowFps':
    'Wir haben Dich ausgeloggt, weil Dein Stream keine Bilder übertragen hat. Bitte stelle sicher, dass Du unser Livecam Tool mit Google Chrome nutzt! Wenn möglich, nutze Chrome nicht für den Stream zu mehreren Portalen. Wenn das Problem weiterhin auftritt, wende Dich bitte an unseren Support!',
  'alert.sessionTimeout':
    'Deine Session ist abgelaufen, bitte logge dich erneut ein.',
  'alert.failedSenderLogin':
    'Login nicht möglich. Versuche es erneut oder kontaktiere den Support.',
  'alert.failedCamLogin':
    'Cam Login nicht möglich. Versuche es erneut oder kontaktiere den Support.',
  'alert.messageLimit':
    'Du kannst nur maximal 5 Nachrichten ohne Antwort des Users versenden',
  'alert.dayLimit':
    'Dieser User ist inaktiv und kann nicht initiativ angeschrieben werden',
  'alert.attachementError': 'Der Anhang konnte nicht geladen werden',
  'alert.dialogFetchError':
    'Die Unterhaltung konnte nicht geladen werden. Bitte versuche es noch einmal.',
  'alert.favoriteError':
    'Beim Aktualisieren des Favoritenstatus ist ein Fehler aufgetreten. Bitte versuche es noch einmal.',
  'alert.friendRequestError':
    'Beim Versenden der Freundesanfrage ist ein Fehler aufgetreten. Bitte versuche es noch einmal.',
  'alert.sendFriendRequest':
    '{name} erhält unmittelbar eine Freundschaftsanfrage von Dir. Sobald diese bestätigt wird, benachrichtigen wir Dich.',
  'alert.answerFriendRequest': '{name} möchte Dein Freund werden!',
  'alert.deleteDiaryEntry': 'Möchtest Du den Tagebucheintrag wirklich löschen?',
  'alert.deleteFriend':
    'Möchtest Du die Freundschaft mit {name} wirklich beenden?',
  'alert.doNotShowIn':
    'Bist Du sicher, dass Du nicht in Deinem Land gezeigt werden willst? Dadurch kann Dir sehr viel Umsatz verloren gehen.',
  'alert.favoriteAddSuccess': 'Dein Favorit wurde gespeichert',
  'alert.favoriteRemoveSuccess': 'Der Favorit wurde entfernt',
  'alert.lowFpsStream': 'Die Übertragung Deines Livecam Streams ist instabil',
  'alert.lowFpsStreamHeadline': 'Frames/s Warnung',
  'alert.friendRequestSentSuccess':
    'Deine Freundschaftsanfrage wurde versendet',
  'alert.isFriend': '{name} ist jetzt Dein Freund',
  'alert.attachmentInUse':
    'Der Anhang kann nicht gelöscht werden, da er noch in einem Icebreaker verwendet wird.',
  'alert.friendRequestAlreadySent': 'Du hast bereits eine Anfrage versendet',
  'alert.button.sendFriendRequest': 'Anfrage versenden',
  'alert.button.cancel': 'Abbrechen',
  'alert.friendRequestDenied': '{name} hat Deine Anfrage abgelehnt.',
  'alert.button.deleteRefusal': 'Ablehnung löschen',
  'alert.deleteDeniedRequest': 'Du hast die Anfrage von {name} abgelehnt.',
  'alert.payttachmentAlreadyReceived':
    'Du hast diese Datei bereits an den User gesendet',
  'alert.payttachmentsDisabled':
    'Kostenpflichtige Dateianhänge wurden für Deinen Account deaktiviert',
  'alert.payttachmentsDisabledForUser':
    'Der User kann keine kostenpflichtige Dateianhänge empfangen',
  'alert.payttachmentNotFound': 'Die Datei wurde auf dem Server nicht gefunden',
  'alert.noDialog': 'Dieser User kann nicht initiativ angeschrieben werden',
  'alert.contactTooOld': 'Du kannst diesen User nicht kontaktieren',
  'alert.noExistingConversation': 'Diesen Dialog gibt es nicht mehr',
  'alert.noExistingConversationGameTimeout':
    'Diesen Dialog gibt es nicht mehr, nachdem Du die Spieleinladung nicht rechtzeitig angenommen hast',
  'alert.invalidMessage':
    'Die Nachricht wird wegen fehlerhaftem Format/Inhalten nicht gesendet',
  'alert.ignoring': 'Du ignorierst diesen User',
  'alert.allMessages': 'Nachrichten konnten nicht geladen werden',
};

