import { connect } from 'react-redux';

import Sedcard from './Sedcard';

const mapStateToProps = (state) => ({
  unconfirmed: state.user?.account?.state === 'unconfirmed',
  locale: state.intl.locale,
});
export default connect(mapStateToProps)(Sedcard);

