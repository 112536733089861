export default {
  SEDCARD_HEADLINE: `User Infocard`,
  SEDCARD_INTRO: `Im {link} findest Du in der rechten Spalte alle Informationen, die der User auf seinem Profil ausgefüllt hat. Diese Informationen können hilfreich sein, um dem User erste Fragen zu stellen oder auf bestimmte Merkmale einzugehen.`,
  SEDCARD_MULTIMESSENGER: `Multi Messenger`,
  SEDCARD_NOTES_HEADER: `Notizen`,
  SEDCARD_NOTES: `Sobald Du mit dem User in Kontakt stehst und er weitere Informationen von sich preisgibt, kannst Du diese unter dem Tab “Notizen” eintragen. Dadurch hast Du sie für spätere Nachrichten immer griffbereit.`,
  SEDCARD_USERINFO_HEADER: `User Info`,
  SEDCARD_USERINFO: `In der User Info kannst Du folgende Informationen einsehen:`,
  SEDCARD_PROFILE_PICTURE_HEADER: `Profilbild des Kunden`,
  SEDCARD_PROFILE_PICTURE: `Du kannst das Profilbild vergrößern, indem Du auf die Lupe neben dem Bild klickst.`,
  SEDCARD_NICKNAME_HEADER: `Nickname`,
  SEDCARD_NICKNAME: `Der Kunde hat genauso wie Du ein Pseudonym als Namen.`,
  SEDCARD_PORTAL_HEADER: `Portal`,
  SEDCARD_PORTAL: `Informationen, von welcher Plattform der Kunde kommt.`,
  SEDCARD_DATA_HEADER: `Wichtige Daten`,
  SEDCARD_DATA: `Alter, Geschlecht, Herkunft, Sprache`,
  SEDCARD_MORE: `Mehr (blauer Button)`,
  SEDCARD_PERSONAL_HEADER: `Persönliches`,
  SEDCARD_PERSONAL: `Figur, Tattoos, Familienstand`,
  SEDCARD_PREFERENCES_HEADER: `Vorlieben `,
  SEDCARD_PREFERENCES: `Was törnt an, was will ich noch erleben`,
  SEDCARD_ABOUT_ME_HEADER: `Über mich`,
  SEDCARD_ABOUT_ME: `Was ich über mich sage`,
  SEDCARD_REVENUE_HEADER: `Umsätze`,
  SEDCARD_REVENUE: `Hier findest Du eine Übersicht der Umsätze nach verschiedenen Kategorien aufgeschlüsselt. Dadurch erhältst Du einen Überblick darüber, wie sich die Einnahmen mit dem Kunden zusammensetzten. So kannst Du leicht erkennen, ob der Kunde hauptsächlich in Deine Livecam kommt, Dir Nachrichten schickt, Premium-Dateianhänge kauft oder Deine Foto und Video Galerien anschaut.`,
};
