import React, { useRef } from 'react';

import closeIcon from '../../../../assets/img/svg/close.svg';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Button from '../../../Button/Button';
import Smiley from './Smiley';

const SmileyOverlay = ({
  smileysTop,
  smileysBottom,
  onClick,
  open,
  toggleSmileyOverlay,
}) => {
  const menuRef = useRef();

  const renderSmileys = (smiley, index) => {
    return <Smiley key={index} onClick={onClick} {...smiley} />;
  };

  useOutsideClick(menuRef, () => {
    if (open) {
      toggleSmileyOverlay();
    }
  });

  return (
    <div className="chat-smiley-overlay" ref={menuRef}>
      <Button
        variant="icon-only"
        type="button"
        icon={closeIcon}
        onClick={() => toggleSmileyOverlay()}
        intlTranslate={false}
        className="close-button"
      />
      <div className="top">{smileysTop.map(renderSmileys)}</div>
      <div className="bottom">{smileysBottom.map(renderSmileys)}</div>
    </div>
  );
};

export default SmileyOverlay;
