import { connect } from 'react-redux';

import Spinner from './UploadSpinner';

const mapStateToProps = (state) => ({
  active: state.spinner.active,
  percent: state.upload.percent,
});

export default connect(mapStateToProps)(Spinner);
