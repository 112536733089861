import './MyRelationships.scss';

import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router';

import { getMyRelationshipsRoutes, routeFunctionsMap } from '../../../routes';
import HeaderMenu from '../../HeaderMenu/HeaderMenu';
import Section from '../../Section/Section';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import MyRelationshipsContext from './MyRelationshipsContext';

function MyRelationships({ user, friendRequests }) {
  const intl = useIntl();
  const [items, setItems] = useState(null);
  const [pathname, setPathname] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [headerTitle, setHeaderTitle] = useState('');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const contextValue = useMemo(() => {
    return { setIsLoading };
  }, []);

  useEffect(() => {
    if (
      history.location.pathname === '/my-relationships' ||
      history.location.pathname === '/my-relationships/friends'
    ) {
      history.push('/my-relationships/friends/active-friends');
    }
  }, [history]);

  useEffect(() => {
    const items = getMyRelationshipsRoutes().filter((item) => !item.notInMenu);
    const pathname = `/${history.location.pathname.split('/')[2]}`;
    const routesFunction = routeFunctionsMap.get(pathname);

    const headerItems = routesFunction
      ? routesFunction(user.account.slave, history.location.pathname)?.filter(
          (item) => {
            if (!item.notInMenu) {
              return true;
            } else if (item.notInMenu === 'current-path') {
              return history.location.pathname.includes(item.path);
            } else {
              return false;
            }
          }
        )
      : null;

    const routes = items
      ? items.map((route, i) => {
          if (history.location.pathname.includes(route.path)) {
            setHeaderTitle(intl.formatMessage({ id: route.headerTitle }));
          }
          const Component = route.component;
          const componentRoutesFunction = routeFunctionsMap.get(route.path);

          return (
            <Route
              key={`/my-relationships${route.path}`}
              path={`/my-relationships${route.path}`}
              component={() => (
                <Component
                  items={
                    componentRoutesFunction
                      ? componentRoutesFunction(user.account.slave)
                      : null
                  }
                />
              )}
            />
          );
        })
      : null;

    routes.push(
      <Redirect key="redirect-to/friends" to="/my-relationships/friends" />
    );
    setItems(headerItems);
    setPathname(pathname);
    setRoutes(routes);
  }, [history.location.pathname, intl, user.account.slave]);
  const shortenFriendRequests = friendRequests > 9 ? '9+' : friendRequests;
  return (
    <div className="page-wrapper">
      <Section title={headerTitle} className="component-section">
        <>
          {items ? (
            <HeaderMenu
              className="header-menu-wrapper"
              defaultPath={`/my-relationships${pathname}`}
              items={items}
              additionalContent={shortenFriendRequests}
            />
          ) : null}
          <div
            className={
              items ? 'component-wrapper' : 'component-wrapper-no-header-menu'
            }
          >
            {isLoading && <SpinnerComponent />}
            <MyRelationshipsContext.Provider value={contextValue}>
              <Switch>{routes}</Switch>
            </MyRelationshipsContext.Provider>
          </div>
        </>
      </Section>
    </div>
  );
}

export default MyRelationships;

