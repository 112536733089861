import account from './account';
import content from './content';
import game from './game';
import home from './home';
import legal from './legal';
import livecam from './livecam';
import menu from './menu';
import moneymakers from './moneymakers';
import relationships from './relationships';
import statistics from './statistics';
import support from './support';
import toycontrol from './toycontrol';
import uploader from './uploader';
import veriff from './veriff';
import wiki from './wiki';

export default {
  ...home,
  ...menu,
  ...legal,
  ...support,
  ...livecam,
  ...account,
  ...uploader,
  ...content,
  ...relationships,
  ...moneymakers,
  ...toycontrol,
  ...wiki,
  ...statistics,
  ...game,
  ...veriff,

  LABEL_ENGLISH: `English`,
  LABEL_DEUTSCH: `Deutsch`,
  LABEL_HERE: `hier`,
  LABEL_CHANGE: `ändern`,
  LABEL_ONLINE: `online`,
  LABEL_REJECTED: `Abgelehnt`,
  LABEL_PENDING: `In Prüfung`,
  LABEL_CONFIRMED: `Freigeschaltet`,
  unpublished: `In Bearbeitung`,
  pending: `In Prüfung`,
  published: `Veröffentlicht`,
  blocked: `Gesperrt`,
  archived: `Archiviert`,
  schedule: `Zeitplan`,
  scheduled: `Geplant`,

  SAVE: `Speichern`,
  CANCEL: 'Abbruch',
  SECONDS_LABEL: `s`,
  WARNING: `Achtung`,
  NOTE: `Hinweis`,
  MAINTENANCE: `Wartung`,
  TIP: `Tipp`,
  YES: `Ja`,
  NO: `Nein`,
  SEARCH: `Suche`,
  CLICK_HERE: `Klicke hier`,
  TRY_AGAIN: `versuche es noch einmal`,
  PLEASE_SELECT: `Bitte wählen...`,
  LOADING: `Lädt...`,

  SUCCESS_TITLE: `Das hat geklappt`,
  ERROR: `Fehler`,

  LABEL_SHOW: `Anzeigen`,

  LINK_SUPPORT: `Support.`,
  ERROR_MESSAGE: `Es ist ein Fehler aufgetreten. Bitte lade die Seite neu.`,
  NO_DATA: `Keine Daten vorhanden`,

  VALIDATION_EMAIL: `Das ist keine gültige E-Mail-Adresse`,
  VALIDATION_REQUIRED_FIELD: `Das ist ein Pflichtfeld`,
  VALIDATION_NICKNAME_TO_SHORT: `Der Nickname muss mindestens 5 Zeichen lang sein`,
  VALIDATION_PASSWORD_TO_SHORT: `Das Passwort muss mindestens 6 Zeichen lang sein`,
  VALIDATION_NICKNAME_TO_LONG: `Der Nickname darf maximal 20 Zeichen lang sein.`,
  VALIDATION_PROFILE_DESCRIPTION_TO_SHORT: `Ein Profiltext muss mindestens 32 Zeichen lang sein`,
  VALIDATION_PROFILE_DESCRIPTION_TO_LONG: `Ein Profiltext darf maximal 500 Zeichen lang sein`,
  VALIDATION_PHOTO_DESCRIPTION_TO_SHORT: `Ein Text muss mindestens 32 Zeichen lang sein`,
  VALIDATION_PHOTO_DESCRIPTION_TO_LONG: `Ein Text darf maximal 1000 Zeichen lang sein`,
  VALIDATION_VIDEO_DESCRIPTION_TO_SHORT: `Ein Text muss mindestens 32 Zeichen lang sein`,
  VALIDATION_VIDEO_DESCRIPTION_TO_LONG: `Ein Text darf maximal 1000 Zeichen lang sein`,
  VALIDATION_DIARY_DESCRIPTION_TO_SHORT: `Ein Text muss mindestens 32 Zeichen lang sein`,
  VALIDATION_DIARY_DESCRIPTION_TO_LONG: `Ein Text darf maximal 3000 Zeichen lang sein`,
  VALIDATION_NICKNAME_NOT_VALID_1: `Der Nickname darf nicht mit VX, sender- oder user- beginnen`,
  VALIDATION_NICKNAME_NOT_VALID_2: `Der Nickname muss mit einem Buchstaben beginnen und kann nur einmal ein - oder _ beinhalten`,
  VALIDATION_FIELD_TO_SHORT: `Der Eintrag muss mindestens 32 Zeichen lang sein`,
  VALIDATION_FIELD_TOO_SHORT_4: `Der Eintrag muss mindestens 4 Zeichen lang sein`,
  VALIDATION_PROFILE_FORENAME_MAX: `Der Vorname darf maximal 64 Zeichen enthalten`,
  VALIDATION_PROFILE_SURENAME_MAX: `Der Nachname darf maximal 64 Zeichen enthalten`,
  VALIDATION_PROFILE_AGE_18: `Du musst mindestens 18 Jahre alt sein um den Service benutzen zu können`,
  VALIDATION_NAME_NOT_CORRECT: `Der Name enthält nicht erlaubte Zeichen`,
  VALIDATION_NO_URLS: `Keine URLs hier erlaubt`,
  VALIDATION_NO_TAGS: `Keine HTML-Tags hier erlaubt`,
  VALIDATION_FILENAME_REQUIRED: `Bitte gib einen Dateinamen an.`,
  VALIDATION_FILENAME_AT_LEAST_5_CHARACTERS: `Der Dateiname muss mindestens 5 Zeichen enthalten.`,
  VALIDATION_FILENAME_AT_MOST_50_CHARACTERS: `Der Dateiname darf maximal 50 Zeichen enthalten.`,
  VALIDATION_FILENAME_ENDING: `Der Dateiname muss mit .jpg enden`,

  NICKNAME_TAKEN: `Der Nickname ist schon vergeben`,
  NICKNAME_ALTERNATIVE: `Unsere Alternativen:`,
  NICKNAME_ERROR: `Fehler. Bitte stelle sicher, dass Deine Verbindung stabil ist und versuche es erneut.`,
  NICKNAME_AVAILABLE: `Der Nickname ist noch verfügbar`,

  PASSWORD_CHANGE_VALIDATION_MESSAGE_LENGTH: `Das Passwort muss mindestens 6 Zeichen lang sein.`,
  PASSWORD_CHANGE_VALIDATION_MESSAGE_REPEAT: `Neues Passwort und Passwort wiederholen müssen identisch sein.`,
  PASSWORD_CHANGE_SUCCESS_LABEL: `Das Passwort wurde geändert`,
  PASSWORD_CHANGE_ERROR_LABEL: `Ein Fehler ist aufgetreten beim Ändern des Passwortes. Bitte versuche es erneut.`,
  PASSWORD_CHANGE_ERROR_CURRENT_PASSWORD: `Falsches Passwort`,
  PASSWORD_CHANGED_LABEL: `Das Passwort wurde erfolgreich geändert`,
  PASSWORD_ERROR_LABEL: `Falsches Passwort`,

  WEBCAM_ERROR_TITLE: `Fehler beim Zugriff auf Deine Webcam`,
  WEBCAM_ERROR_TEXT: `Bitte kontrolliere ob Deine Webcam korrekt angeschlossen ist und stimme dem Zugriff auf die Webcam zu. Du kannst alternativ auch ein Bild hochladen oder den Support kontaktieren.`,

  ERROR_BANK_DETAILS: `Deine Bankdaten sind unvollständig!`,
  ERROR_UPLOAD_DOCUMENT_1: `Fehler beim Upload, ungültiges Dateiformat.`,
  attachments_reached_limit: `Die maximale Anzahl an Attachments wurde erreicht.`,
  attachments_file_is_missing: `Es wurde keine gültige Datei hochgeladen.`,
  unable_to_save: `Die Datei konnte nicht gespeichert werden.`,
  ERROR_UPLOAD_DOCUMENT_2: `Hochladen fehlgeschlagen. Bitte überprüfe Deine Internet Verbindung.`,
  ERROR_INVALID_CREDENTIALS: `Falsche Zugangsdaten. Bitte versuche es erneut.`,
  ERROR_BLOCKED_ACCOUNT: `Dein Account wurde gesperrt. Bitte kontaktiere unseren Support.`,
  DEACTIVATION: `Account gesperrt`,
  ERROR_MALE_ACCOUNT: `Aufgrund von hohen Registrierungszahlen, ist das Onboarding für männliche Creator momentan nicht möglich.`,
  ERROR_REGISTER_GENERAL: `Bitte versuche es erneut.`,
  ERROR_RESET_PASSWORD: `Deine Passwortanfrage ist abgelaufen oder ungültig. Bitte {link}.`,
  ERROR_NETWORK_ERROR: `Netzwerkfehler. Bitte versuche es erneut.`,

  FORGOT_PASSWORD_SUCCESS_MESSAGE: `Bitte folge den Anweisungen aus unserer E-Mail.`,
  RESET_PASSWORD_SUCCESS_MESSAGE: `Dein Passwort wurde geändert`,

  CONFIRMED_EMAIL_SUCCESS_TITLE: `Deine E-Mail-Adresse ist jetzt bestätigt`,
  CONFIRMED_EMAIL_SUCCESS_CONTENT: `{link} um fortzufahren`,
  CONFIRMED_EMAIL_ERROR: `Das hat nicht geklappt`,
  CONFIRMED_EMAIL_ERROR_TITLE: `Beim Bestätigen der E-Mail-Adresse gab es einen Fehler.`,
  CONFIRMED_EMAIL_ERROR_CONTENT: `Bitte versuche es erneut.`,

  UNSUBSCRIBE_NEWSLETTER_ERROR_TITLE: `Beim Laden der Seite gab es einen Fehler.`,
  UNSUBSCRIBE_NEWSLETTER_ERROR_CONTENT: `Bitte versuche es erneut.`,
  UNSUBSCRIBE_NEWSLETTER_TITLE: `Benachrichtigungen & Newsletter`,
  UNSUBSCRIBE_NEWSLETTER_HEADER: `Benachrichtigungen & Newsletter ändern`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_TEXT_1: `Möchtest Du benachrichtigt werden, wenn ein User Dir schreibt während Du offline bist?`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_EMAIL: `E-Mail Benachrichtigungen`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_TEXT_2: `Wir informieren Dich in regelmäßigen Abständen über Neuigkeiten rund um LiveCreator.com und unsere Communities. Außerdem versorgen wir Dich mit nützlichen Tipps, wie Du noch erfolgreicher als Creator werden kannst.`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_NEWSLETTER: `Newsletter abonnieren`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_RECOMMENDED: `(empfohlen)`,

  BANK_ACCOUNT_MISSING_TITLE: `Deine Bankdaten sind nicht komplett!`,
  BANK_ACCOUNT_MISSING_CONTENT: `Es fehlen Bankdaten oder entsprechende Dokumente für die Auszahlung Deiner Provision.`,
  BANK_ACCOUNT_MISSING_SUBCONTENT: `Vervollständige sie bitte hier`,

  EMAIL_UPDATE_ERROR: `Die Änderung der E-Mail ist fehlgeschlagen. Diese E-Mail ist vielleicht schon in Verwendung.`,
  EMAIL_NOT_CONFIRMED: `Noch nicht bestätigt`,
  EMAIL_VERIFY_INSTRUCTION: `Bitte verifiziere Deine Mailadresse um den Zugang zu LiveCreator freizuschalten.`,
  EMAIL_CONFIRMED: `Deine E-Mail-Adresse wurde bestätigt`,

  NEW_VERSION_HEADLINE: `Eine neue Version ist verfügbar.`,
  NEW_VERSION_TEXT_1: `Bitte hier klicken, um die neue Version zu laden.`,
  NEW_VERSION_TEXT_2: `Die Seite lädt automatisch neu nach Ablauf des Countdowns.`,
  NEW_VERSION_RELOAD: `Neu laden`,
  NEW_VERSION_LATER: 'Nicht jetzt updaten',

  NICKNAME_REVIEW_ERROR: `Der Nickname ist ungültig`,
  GENDER_REVIEW_ERROR: `Geschlecht ist ungültig`,
  COUNTRY_CODE_REVIEW_ERROR: `Das Land ist ungültig`,
  ZIP_CODE_INVALID: `Ungültige Postleitzahl`,
  NATIVE_LANGUAGE_REVIEW_ERROR: `Die Muttersprache ist ungültig`,
  FORENAME_REVIEW_ERROR: `Der Vorname ist ungültig`,
  SURNAME_REVIEW_ERROR: `Der Nachname ist ungültig`,
  BIRTHDAY_REVIEW_ERROR: `Der Geburtstag ist ungültig`,

  verify_email_wait: `Die E-Mail konnte nicht gesendet werden. Bitte versuche es noch einmal in {minutes} Minuten`,
  verify_email_error: `Fehler beim Versenden der E-Mail. Bitte versuche es noch einmal`,
  verify_email_already_verified: `Die E-Mail wurde bereits bestätigt`,

  UPLOADER_NO_SOURCE: `Es wurde kein Bild gefunden`,
  UPLOADER_BROKEN_IMAGE: `Das Bild scheint defekt zu sein`,
  UPLOADER_INVALID_DIMENSIONS: `Die Auflösung des Bildes ist zu niedrig. Sie muss mindestens {minWidth} x {minHeight} Pixel betragen.`,
  UPLOADER_TIMEOUT: `Das Hochladen des Bildes dauerte zu lange`,
  UPLOADER_NO_FILE: `Es wurde keine Datei gefunden`,
  UPLOADER_FILE_TIMEOUT: `Das Laden der Datei dauerte zu lange`,

  VALUE_DOES_NOT_MAKE_SENSE: `Sicher?`,

  INVALID_IBAN_LABEL: `Die IBAN ist ungültig`,
  INVALID_BIC_SWIFT_LABEL: `Die BIC/SWIFT ist nicht gültig`,
  INVALID_STREET_ADDRESS: `Ungültige Straße`,
  INVALID_STREET_ADDRESS_2: `Ungültiger Adresszusatz`,
  INVALID_PHONE_NUMBER: `Ungültige Telefonnummer`,
  INVALID_SKYPE: `Ungültiger Skype Account`,
  INVALID_COMPANY_NAME: `Ungültiger Name  des Unternehmens`,
  INVALID_VAT: `Ungültige Steuernummer`,
  INVALID_ZIP_CITY: `Ungültige PLZ oder Ort`,
  INVALID_FORENAME: `Ungültiger Vorname`,
  INVALID_SURNAME: `Ungültiger Nachname`,
  INVALID_BANK_NAME: `Ungültiger Name der Bank`,
  INVALID_BANK_ADDRESS: `Ungültige Adresse der Bank`,
  INVALID_BANK_CITY: `Ungültige Stadt der Bank`,
  INVALID_DESCRIPTION: `Ungültige Beschreibung`,
  INVALID_ABOUT_MYSELF: `Ungültige Beschreibung über mich`,
  INVALID_ABOUT_ME: `Invalid Beschreibung, was über mich gesagt wird`,
  INVALID_EROTIC_FANTASY: `Ungültige erotische Fantasie`,
  INVALID_ZIP: `Ungültige PLZ`,
  INVALID_HEIGHT: `Ungültige Größe`,
  INVALID_WEIGHT: `Ungültiges Gewicht`,
  IMAGE_UPLOAD_ERROR: `Fehler während des Uploads. Bitte versuche es noch einmal`,
  INVALID: `Ungültig`,
  INVALID_DATE_MESSAGE: `Das Datum ist ungültig`,

  SEND_EMAIL_SUCCESS: `Die E-Mail wurde versendet.`,
  CHANGE_EMAIL_SUCCESS: `Die E-Mail-Adresse wurde erfolgreich geändert`,
  image_not_found_or_confirmed: `Das Bild wurde noch nicht hochgeladen oder bestätigt.`,

  PAGE404_NOT_FOUND: `OOPS! Seite nicht gefunden`,
  PAGE404_NOT_FOUND_TEXT: `Es tut uns leid, aber die aufgerufene Seite existiert nicht.`,
  PAGE404_NOT_FOUND_LINK: `Zurück zur Startseite`,

  GALLERY_ERROR_LOADING: `Die Galerie konnte nicht geladen werden. Versuche es noch einmal`,
  GALLERY_ERROR_THUMBNAIL: `Das Vorschaubild wurde abgelehnt.`,
  GALLERY_ERROR_MISSING: `Folgendes fehlt um die Galerie zu veröffentlichen: `,
  GALLERY_ERROR_TITLE: `der Galerie Titel`,
  GALLERY_ERROR_DESCRIPTION: `eine kurze Beschreibung`,
  GALLERY_ERROR_PREVIEW: `ein Vorschaubild`,
  GALLERY_ERROR_CATEGORY: `mindestens eine Kategorie`,
  GALLERY_ERROR_PHOTOS: `mindestens 3 Fotos`,
  GALLERY_ERROR_DOCUMENTS: `zugewiesene(s) Darsteller-Dokument(e)`,
  GALLERY_ERROR_VIDEO: `ein Video`,
  GALLERY_INVALID_PICTURE_TO_DELETE: `Dieses Bild kann nicht gelöscht werden.`,

  GALLERY_PUBLISHED: `Die Galerie wurde erfolgreich veröffentlicht. Sobald sie redaktionell geprüft wurde, geht sie online und steht Deinen Kunden zur Verfügung.`,
  GALLERY_SCHEDULED: `Deine Galerie wird jetzt redaktionell geprüft und zum angegebenen Zeitpunkt freigeschaltet.`,

  SUPPORT_LINK_TEXT: `Support`,
  ALERT_FORCED_LOGOUT:
    'Bitte versuche es später erneut oder kontaktiere unseren',

  NO_SOUND: 'Kein Sound',
  ALERT_DOCUMENT: `Das Dokument wurde nicht gefunden.`,

  ERROR_UPLOAD_FILETYPE:
    'Dieser Dateityp ist nicht erlaubt. Erlaubt sind {typlist}',

  BUTTON_TITLE_ASK_ME_AGAIN: `Inaktiv bleiben`,
  BUTTON_TITLE_ACTIVATE: `Aktivieren`,
  IDLE_MODAL_TITLE: `Du bist inaktiv!`,
  IDLE_MODAL_TEXT_1: `Nach 15 Minuten Inaktivität im Messenger werden keine Icebreaker mehr für Dich verschickt.`,
  IDLE_MODAL_TEXT_2: `Klicke den grünen Button um wieder als verfügbar angezeigt zu werden!`,
  UPLOADING: `Wird hochgeladen`,

  ERROR_DURING_ACTION: `Es ist ein Fehler aufgetreten, bitte probiere noch einmal`,
  ERROR_TITLE: `Es ist ein Fehler aufgetreten`,

  ALERT_USER_LOGOUT:
    'Bist Du sicher, dass Du die Seite verlassen willst? Alle erforderlichen Daten sind vorhanden. Bitte klicke auf "abschließen", um die Registrierung zu beenden.',
  'title.toycontrol': 'Lovense Toy',
  GAME_INVITATION_MESSAGE_HEADLINE: 'Möchtest Du mit mir spielen?',
  'gameinvite.button.no': 'Nein, danke',
  'gameinvite.button.yes': 'Ja, gerne',
  GAME_LOADING_ERROR: 'Das Spiel konnte nicht geladen werden',
  GAME_ACCEPT_ERROR: 'Das Spiel konnte nicht gestartet werden.',
  GAME_DECLINE_ERROR: 'Das Spiel konnte nicht abgelehnt werden.',

  COPIED: 'Kopiert',

  OPEN_VIDEO_OVERLAY: `Video in einem Overlay öffnen`,
  OPEN_WIKI_LINK: `Weitere Informationen im Wiki`,
  VOICE_MESSAGE_NO_LONGER_AVAILABLE: `Die Sprachnachricht ist nicht mehr verfügbar`,
  SONUS_ERROR:
    'Bitte versuche es erneut oder kontaktiere den Support. {number}',
  SONUS_TOKEN_ERROR:
    'Der Versand von Sprachnachrichten ist temporär gestört. Bitte lade die Seite neu und versuche es noch einmal, oder kontaktiere den Support. {number}',
  MICROPHONE_DISABLED_ERROR:
    'Um diese Funktion zu nutzen, aktiviere bitte Dein Mikrofon',
  AUDIO_IS_CONVERTING: 'Konvertieren',
  AUDIO_BUTTON_DELETE: 'Löschen',
  AUDIO_BUTTON_STOP: 'Stopp',
  AUDIO_BUTTON_PLAY: 'Abspielen',
  AUDIO_BUTTON_RECORD: 'Aufnehmen',
};

