import { gameUtils, user as userUtils } from '../../../../utils';
import { GAME_SESSION_STATUS } from '../../../actions';
import { getGameStats } from '../../../games';
import { toggleUsergameView } from '../../..';
export default (data) => (dispatch, getState) => {
  if (userUtils.gamesBlocked(getState())) return;
  const {
    camUser: { camUsers },
    chat: { selectedUser },
    dialog: { dialogs, processQueue, queue },
    user: {
      account: { nickname },
    },
    view: { usergame },
  } = getState();
  const allDialogs = [...dialogs, ...processQueue, ...queue];
  const payload = gameUtils.addPartner(data?.payload);
  const game = gameUtils.getGame(getState(), payload?.partner);
  if (!game) {
    return;
  }
  const wasActiveInvitation = gameUtils.isActiveInvitation(game);
  const selectedLivecamUser = camUsers.find((u) => u.userId === selectedUser);

  const isSelected = !selectedLivecamUser
    ? selectedUser === payload?.partner
    : userUtils.getPartner(selectedLivecamUser) === payload?.partner;
  const userId = isSelected && selectedUser;
  let user = selectedLivecamUser;

  if (!user) {
    user = allDialogs.find((d) => d.partner === selectedUser);
  }
  dispatch({
    type: GAME_SESSION_STATUS,
    meta: {
      isSelected,
      userId,
      to: user?.name,
      from: nickname,
    },
    payload,
  });

  if (
    wasActiveInvitation &&
    payload?.session?.status === 'pending' &&
    !usergame
  ) {
    dispatch(toggleUsergameView());
  }
  if (payload?.session?.status === 'decided') {
    dispatch(getGameStats(payload.partner));
  }
};
