import {
  CONTEST_ACTIVE,
  CONTEST_FINISHED,
  SUMMER_CONTEST_TEMPLATE,
} from '../constants';

const hasSummerContest = (state) =>
  Object.values(state.contests).some(
    (el) => el.template === SUMMER_CONTEST_TEMPLATE
  );
const hasActiveOrFinishedSummerContest = (state) =>
  hasSummerContest(state) &&
  [CONTEST_FINISHED, CONTEST_ACTIVE].includes(getSummerContest(state).state);

const getSummerContest = (state) =>
  Object.values(state.contests).find(
    (el) => el.template === SUMMER_CONTEST_TEMPLATE
  );

export default {
  hasSummerContest,
  getSummerContest,
  hasActiveOrFinishedSummerContest,
};
