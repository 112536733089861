import './VeriffOverlay.scss';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import closeIcon from '../../../assets/img/svg/close.svg';
import Button from '../../Button/Button';
import Scrollbar from '../../Scrollbar';

function VeriffOverlay({
  toggleVeriffFrame,
  toggleVeriffBar,
  closeOverlay,
  stamp,
  showVeriffReminder,
  isMobile,
}) {
  const intl = useIntl();

  const classes = classNames('veriff-overlay', 'overlay-wrapper', {
    'is-mobile': isMobile,
  });
  return (
    <>
      <div className={classes}>
        <Scrollbar visible={true}>
          <div className="overlay-header">
            <h1 className="headline">
              {intl.formatMessage({ id: 'VERIFF_OVERLAY_HEADLINE' })}
            </h1>
          </div>
          <div className="overlay-content">
            <p>{intl.formatMessage({ id: 'VERIFF_OVERLAY_TEXT_1' })}</p>
            <p>{intl.formatMessage({ id: 'VERIFF_OVERLAY_TEXT_2' })}</p>
            <Button
              className={'btn-veriff'}
              label="VERIFF_VERIFY_BUTTON"
              onClick={() => {
                toggleVeriffFrame();
                if (!showVeriffReminder) {
                  toggleVeriffBar();
                }
                closeOverlay(stamp);
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'VERIFF_OVERLAY_TEXT_3',
                }),
              }}
            ></p>
          </div>
        </Scrollbar>
        <Button
          type="button"
          classNamesOnly="close-btn"
          icon={closeIcon}
          onClick={() => {
            if (!showVeriffReminder) {
              toggleVeriffBar();
            }
            closeOverlay(stamp);
          }}
          intlTranslate={false}
        />
      </div>

      <div
        className="background"
        onClick={() => {
          if (!showVeriffReminder) {
            toggleVeriffBar();
          }

          closeOverlay(stamp);
        }}
      />
    </>
  );
}

export default VeriffOverlay;
