import {
  ALERT_ACTION_ANSWER_FRIEND_REQUEST,
  ALERT_ACTION_DELETE_FRIEND,
  ALERT_ACTION_IGNORE_USER,
  ALERT_ACTION_SEND_FRIEND_REQUEST,
  ALERT_ACTION_UNIGNORE_USER,
} from '../../../constants';
import { SMILEYOVERLAY_TOGGLE } from '../../actions';
import {
  closeAlert,
  toggleChatMenu,
  toggleUserMenu,
  toggleUserlistView,
} from '../..';
export default () => async (dispatch, getState) => {
  if (!getState().user.isLoggedIn) {
    return;
  }
  const {
    view: { userlist, isMobile },
    smiley: { overlay: smileyOverlay },
    chat: { chatMenuOpen },
    settings: { userMenuOpen },
    alert,
  } = getState();
  if (smileyOverlay) {
    dispatch({ type: SMILEYOVERLAY_TOGGLE });
  }

  if (chatMenuOpen) {
    dispatch(toggleChatMenu());
  }

  if (userMenuOpen) {
    dispatch(toggleUserMenu());
  }
  alert.forEach((a) => {
    if (
      [
        ALERT_ACTION_SEND_FRIEND_REQUEST,
        ALERT_ACTION_ANSWER_FRIEND_REQUEST,
        ALERT_ACTION_DELETE_FRIEND,
        ALERT_ACTION_IGNORE_USER,
        ALERT_ACTION_UNIGNORE_USER,
      ].includes(a.alertAction)
    ) {
      dispatch(closeAlert(a.stamp));
    }
  });

  if (isMobile && userlist) {
    dispatch(toggleUserlistView());
  }
};
