import './VideoItem.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import menuIcon from '../../../assets/img/svg/edit.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import VideoPlayerItem from '../../VideoPlayerItem/VideoPlayerItem';

function VideoItem({
  item,
  spinner,
  itemDeleted,
  activeGallery,
  galleryThumbnail,
  menuVisible,
  setMenuVisible,
  data,
  setDeletionError,
  videoGalleryDeleteDisabled,
}) {
  const menuRef = useRef();
  const menuIconRef = useRef();
  const [, deleteVideoRequest] = useAxios(
    {
      url: `/galleries/${activeGallery?.id}/movie`,
      method: 'DELETE',
    },
    { manual: true }
  );

  function deleteVideo() {
    spinnerRequest({
      request: deleteVideoRequest,
      spinner,
    })
      .then(() => {
        setDeletionError(null);
        itemDeleted();
      })
      .catch(() => {
        setDeletionError(true);
      });
  }

  const galleryMenuClass = classNames('sub-menu', {
    'mobile-photo-item': galleryThumbnail,
  });

  const thumbnailClass = classNames('thumbnail-wrapper', {
    'gallery-thumbnail': galleryThumbnail,
  });

  const photoMenuIconClass = classNames('photo-menu', {
    open: menuVisible,
  });

  useOutsideClick(
    menuRef,
    () => {
      if (menuVisible) {
        setMenuVisible(false);
      }
    },
    menuIconRef
  );

  return (
    <div className="gallery-item thumbnail-item">
      <div className={thumbnailClass}>
        <div className="thumbnail">
          <div className="thumbnail-image thumbnail-video">
            <VideoPlayerItem data={data} />
          </div>
          {item.status !== 'confirmed' && !videoGalleryDeleteDisabled && (
            <div
              className={photoMenuIconClass}
              ref={menuIconRef}
              onClick={() => {
                if (!menuVisible) {
                  setMenuVisible(true);
                }
              }}
            >
              <ReactSVG src={menuIcon} className={'menu-icon'} wrapper="span" />
            </div>
          )}
          {menuVisible &&
            item.status !== 'confirmed' &&
            !videoGalleryDeleteDisabled && (
              <div
                className={galleryMenuClass}
                id={`photo-menu${item.id}`}
                ref={menuRef}
              >
                <div
                  className="gallery-menu-item"
                  onClick={() => {
                    deleteVideo();
                    setMenuVisible(false);
                  }}
                >
                  <FormattedMessage id="GALLERY_MENU_DELETE" />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default VideoItem;
