import { camUser as userActions } from '../../actions';
import { DIALOG_SELECT } from '../actions';
import { newDialog } from '.';

export default (
    payload,
    moveToTop = true,
    prioritizeCamUser = true,
    fetchImmediately = true
  ) =>
  async (dispatch, getState) => {
    if (!payload?.id) {
      return;
    }
    const {
      dialog: { dialogs, processQueue, queue },
    } = getState();

    const dialogIds = [...dialogs, ...processQueue, ...queue].map(
      (d) => d.partner
    );
    const isNewDialog = dialogIds.indexOf(payload.id) < 0;

    if (isNewDialog) {
      await dispatch(newDialog({ id: payload.id }));
    }
    if (moveToTop) {
      dispatch({
        type: DIALOG_SELECT,
        payload,
      });
    }

    dispatch(
      userActions.select(payload.id, prioritizeCamUser, fetchImmediately)
    );
  };

