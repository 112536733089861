import './Prices.scss';

import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { spinnerRequest } from '../../../../shared/spinnerRequest';
import { isLivecamCreator } from '../../../../utils';
import NumberSelect from '../../../Forms/NumberSelect/NumberSelect';
import { generatePricesList } from './Prices.functions';

function Prices({ spinner = () => {}, user }) {
  const isLivecam = isLivecamCreator(user);

  const [, getPricesDataRequest] = useAxios(
    {
      url: '/user/prices',
    },
    { manual: true }
  );

  const [, postPricesDataRequest] = useAxios(
    {
      url: '/user/prices',
      method: 'PUT',
    },
    { manual: true }
  );

  const [prices, setPrices] = useState(null);

  useEffect(() => {
    spinnerRequest({
      request: getPricesDataRequest,
      spinner,
    })
      .then((response) => {
        setPrices(response.data);
      })
      .catch(() => {});
  }, [getPricesDataRequest, spinner]);

  function changePriceHandler(event) {
    let newPrices = {
      messages: {
        normal: prices?.messages?.normal,
      },
      livecams: {
        normal: {
          current:
            prices.livecams.normal.current === null
              ? prices.livecams.normal.min
              : prices.livecams.normal.current,
        },
      },
    };

    if (prices.livecams.exclusive.status === 'true') {
      newPrices.livecams.exclusive = {
        current:
          prices.livecams.exclusive.current === null
            ? prices.livecams.exclusive.min
            : prices.livecams.exclusive.current,
      };
    }
    if (event.target.name === 'messages') {
      newPrices.messages.normal = parseInt(event.target.value);
    } else {
      newPrices.livecams[event.target.name].current = parseInt(
        event.target.value
      );
    }
    spinnerRequest({
      request: postPricesDataRequest,
      spinner,
      payload: {
        data: newPrices,
      },
    }).then((response) => {
      setPrices(response.data);
    });
  }

  return (
    <div className="prices-wrapper data-columns">
      <div className="data-left">
        <div className="group-container">
          <h1 className="headline">
            <FormattedMessage id="PRICES_MESSAGE_HEADER" />
          </h1>
          <p>
            <FormattedMessage id="PRICES_MESSAGE_TEXT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <NumberSelect
            options={generatePricesList(1, 10, 1)}
            label="PRICES_MESSAGE"
            value={prices?.messages?.normal || prices?.messages || ''}
            subtextValue="Coins"
            name="messages"
            onChange={(event) => changePriceHandler(event)}
          />
          <NumberSelect
            options={generatePricesList(1, 5, 1)}
            label="PRICES_APPS_MESSAGE"
            value={prices?.messages?.apps || ''}
            subtextValue="Coins"
            disabled={true}
            name="apps"
            onChange={(event) => changePriceHandler(event)}
          />
        </div>
      </div>
      {isLivecam && (
        <div className="data-right">
          <h2 className="headline">
            <FormattedMessage id="PRICES_LIVECAM_HEADER" />
          </h2>
          <p>
            <FormattedMessage id="PRICES_LIVECAM_TEXT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <NumberSelect
            options={generatePricesList(
              prices?.livecams.normal.min,
              prices?.livecams.normal.max,
              prices?.livecams.normal.step
            )}
            label="PRICES_NORMAL_SHOW"
            value={prices?.livecams.normal.current || ''}
            subtextValue="Coins"
            name="normal"
            onChange={(event) => changePriceHandler(event)}
          />
          {prices?.livecams.exclusive.status === 'true' && (
            <NumberSelect
              options={generatePricesList(
                prices?.livecams.exclusive.min,
                prices?.livecams.exclusive.max,
                prices?.livecams.exclusive.step
              )}
              label="PRICES_EXCLUSIVE_SHOW"
              value={prices?.livecams.exclusive.current || ''}
              subtextValue="Coins"
              name="exclusive"
              onChange={(event) => changePriceHandler(event)}
            />
          )}
          <NumberSelect
            options={generatePricesList(1, prices?.livecams.normal.max, 1)}
            label="PRICES_VOYEUR_SHOW"
            value={prices?.livecams.voyeur.current || ''}
            subtextValue="Coins"
            disabled={true}
            name="voyeur"
            onChange={(event) => changePriceHandler(event)}
          />
          <NumberSelect
            options={generatePricesList(1, prices?.livecams.normal.max, 1)}
            label="PRICES_DATING_SHOW"
            value={prices?.livecams.dating.current || ''}
            subtextValue="Coins"
            disabled={true}
            name="dating"
            onChange={(event) => changePriceHandler(event)}
          />
        </div>
      )}
    </div>
  );
}

export default Prices;

