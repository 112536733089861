import { useIntl } from 'react-intl';

import useMediaQuery from '../../../../hooks/useMediaQuery';

function UserProfileItem({
  label,
  value,
  prefix = '',
  postfix = '',
  display = false,
  translate = true,
  wrap = false,
  showZero = false,
}) {
  const isSmallMediumScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1399px)'
  );
  const intl = useIntl();
  const displayItem = display || value;
  const item =
    value || (showZero && value === 0)
      ? `${prefix}${value}${postfix}`
      : intl.formatMessage({ id: 'not.specified' });
  return displayItem ? (
    wrap && isSmallMediumScreen ? (
      <>
        <tr className="no-border">
          <td className="bold">{intl.formatMessage({ id: label })}</td>
        </tr>
        <tr>
          <td>
            {translate && value ? intl.formatMessage({ id: item }) : item}
          </td>
        </tr>
      </>
    ) : (
      <tr>
        <td className="bold">{intl.formatMessage({ id: label })}</td>
        <td className="align-right">
          {translate && value ? intl.formatMessage({ id: item }) : item}
        </td>
      </tr>
    )
  ) : null;
}

export default UserProfileItem;
