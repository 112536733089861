import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { usernote } from '../../../actions';
import { user as userUtils } from '../../../utils';
import UserNote from './UserNote';

const mapStateToProps = (state) => {
  const user = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);

  if (!user || userUtils.isAdmin(user)) {
    return {
      noUser: true,
    };
  }
  let activeUserId;
  if (userUtils.hasDatingPartner(user)) {
    activeUserId = userUtils.getDatingPartner(user);
  } else if (!user.partner) {
    activeUserId = userUtils.getPartner(user);
  } else if (user?.partner) {
    activeUserId = user.partner;
  } else {
    return {
      noUser: true,
    };
  }

  if (activeUserId !== state.usernote.userId) {
    return { loading: true };
  }
  return {
    ...state.usernote,
    ...state.view,
    name: user.name,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ change: usernote.update }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(UserNote);
