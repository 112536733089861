import { auth, cookie } from '../../../actions';
import { sender as senderService } from '../../../services';
import Cookie from '../../../services/cookie';
import { lStorage } from '../../../services/storage';
import { getCookieHintData } from '../../../utils';
import api from '../../../utils/api';
import { SENDER_LOGIN, TOKEN_LOGIN_SENDER } from '../../actions';

export default (username) => {
  return async (dispatch, getState) => {
    const {
      user: {
        account: { scopes },
      },
    } = getState();

    const hasLivecamScope = scopes.includes('livecam');

    if (!hasLivecamScope) {
      dispatch({
        type: TOKEN_LOGIN_SENDER,
        error: true,
      });
      return;
    }

    try {
      let token = null;
      const response = await api.get('/user/livecam');
      if (response?.data?.token) {
        token = response.data.token;
      }
      if (username === '' || !token) {
        return;
      }
      const {
        intl: { locale },
        user,
      } = getState();

      const cookieHintData = getCookieHintData(locale, user?.isLoggedIn);

      if (!Cookie.test()) {
        return dispatch(cookie(false, cookieHintData));
      }

      if (!lStorage.test()) {
        return dispatch(cookie(false, cookieHintData));
      }

      dispatch({
        type: TOKEN_LOGIN_SENDER,
        meta: { start: true },
      });

      const data = await senderService.login({
        username,
        token,
        locale,
      });
      if (data.error) {
        return dispatch({
          type: TOKEN_LOGIN_SENDER,
          error: true,
          payload: data,
        });
      }
      const authdata = await senderService.auth();
      if (authdata && authdata.status === 400) {
        dispatch(cookie(false, cookieHintData));
        return dispatch({
          type: TOKEN_LOGIN_SENDER,
          error: true,
        });
      }
      dispatch({
        type: SENDER_LOGIN,
        payload: {
          ...data,
          username,
          origin,
        },
      });
      dispatch(auth.start(data.authInterval));
    } catch (error) {
      dispatch({
        type: TOKEN_LOGIN_SENDER,
        error: true,
      });
    }
  };
};
