import { connect } from 'react-redux';

import { changeShowSendername } from '../../../actions';
import ShowSendernameCheckbox from './ShowSendernameCheckbox';

const mapStateToProps = (state, ownProps) => ({
  id: ownProps.id,
  enabled: state.settings.showSendername,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (active) => {
    dispatch(changeShowSendername(active));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowSendernameCheckbox);
