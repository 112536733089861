export default {
  ROUTE_PERSONAL_DETAILS_HEADLINE: `Hier kannst Du Deine persönlichen Daten und Accountdaten ändern`,
  ACCOUNT_DETAILS_HEADLINE: `Deine bei uns hinterlegten Daten`,
  FIELD_CURRENT_PASSWORD: `Aktuelles Passwort:`,
  FIELD_NEW_PASSWORD: `Neues Passwort:`,
  FIELD_REPEAT_PASSWORD: `Passwort wiederholen:`,

  PERSONAL_DATA: `Private Daten`,
  FIELD_STREET_ADDRESS: `Straße:`,
  FIELD_STREET_ADDRESS2: `Adresszusatz:`,
  FIELD_ZIP_AND_CITY: `PLZ & Ort:`,
  FIELD_LIVINGCOUNTRY: `Land:`,
  FIELD_ZIP_CODE: `Postleitzahl`,
  FIELD_CONTACT: `Kontakt`,
  FIELD_PHONE: `Telefon:`,
  FIELD_SKYPE: `Skype:`,
  PERSONAL_DETAILS_FOR_BUSINESS: `Für Unternehmen <span class="subtext">(optional)</span>`,
  FIELD_COMPANY: `Name des Unternehmens:`,
  FIELD_VAT: `Steuernummer:`,

  CHANGE_ADDRESS: 'Adresse ändern',
  CHANGE_ADDRESS_TEXT: `Sollte sich Deine Anschrift geändert haben, kannst Du hier Deine neuen Adressdaten eingeben.
Nachdem Du Deine Eingabe mit 'Adresse ändern' bestätigt hast, wird diese durch den Support geprüft.
Bis die Prüfung positiv abgeschlossen wurde, gelten die Adressdaten, die Du bei der Registrierung angegeben hast.`,
  CHANGE_ADDRESS_BACK: 'Zurück',
  ADDRESS_DECLINED: 'Adresse abgelehnt',
  ADDRESS_PENDING_TITLE: `Adresse ausstehend`,
  ADDRESS_PENDING_1: `Unser Support-Team überprüft derzeit die von Ihnen bereitgestellten neuen Informationen, um sicherzustellen, dass alles korrekt ist.

Um die Details Ihrer neuen Adresse anzusehen, klicken Sie bitte `,
  ADDRESS_PENDING_2: 'hier.',
  CHANGE_ADDRESS_ZIP: 'PLZ:',
  CHANGE_ADDRESS_CITY: 'Ort:',

  ADDRESS_ERROR: `Es ist ein Fehler beim Versenden aufgetreten. Bitte versuche es noch einmal.`,

  CHANGE_ADDRESS_STREET_TOO_LONG:
    'Der Straßenname darf höchstens 64 Zeichen lang sein',
  CHANGE_ADDRESS_STREET2_TOO_LONG:
    'Der zusätzliche Adresszusatz darf höchstens 64 Zeichen lang sein',
  CHANGE_ADDRESS_ZIP_TOO_LONG:
    'Die Postleitzahl darf höchstens 12 Zeichen lang sein',
  CHANGE_ADDRESS_CITY_TOO_LONG:
    'Der Stadtname darf höchstens 64 Zeichen lang sein',
  CHANGE_ADDRESS_VALIDATION_ERROR: 'Diese Sonderzeichen sind nicht erlaubt',

  PERSONAL_DATA_ADDRESS_HINT: `Falls Du Deine Adressdaten ändern möchtest, {link}.`,
  PERSONAL_DATA_ADDRESS_HINT_LINK: `kontaktiere bitte den Support`,

  PERSONAL_DATA_INFO_HEADLINE: `Warum und wofür benötigen wir Deine Daten?`,
  PERSONAL_DATA_INFO_CONTENT_1: `Damit wir sicherstellen können, dass Du volljährig bist und Deine Anmeldung als Creator auch wirklich von Dir erfolgt ist, gleichen wir Deine Angaben mit den von Dir hochgeladenen Legitimationsdokumenten ab.`,
  PERSONAL_DATA_INFO_CONTENT_2: `Außerdem benötigen wir diese Daten natürlich auch für die Auszahlung Deiner Provisionen. Hierbei muss gemäß Geldwäschegesetz ebenfalls eine eindeutige Legitimation des Empfängers erfolgen.`,
  PERSONAL_DATA_INFO_CONTENT_3: `Alle Deine Angaben unterliegen selbstverständlich dem Datenschutzgesetz.`,

  BANK_DETAILS_HEADLINE: `Auszahlungsempfänger`,
  BANK_DETAILS_TEXT_1: `Sollte der Inhaber des Auszahlungskontos vom Inhaber des Creator-Accounts abweichen, müssen hier die entsprechenden persönlichen Daten des Kontoinhabers angegeben werden. Außerdem muss eine Kopie des gültigen Personalausweises bzw. Reisepasses mit Meldebescheinigung hochgeladen werden.`,
  BANK_DETAILS_TEXT_2: `Liegen diese Daten nicht vor, kann keine Auszahlung erfolgen.`,
  BANK_DETAILS_ADDRESS_ERROR: `Es fehlen noch Adressdaten!`,
  BANK_DETAILS_HEADLINE_2: `Bankdaten des Empfängers`,
  BANK_DETAILS_MINIMUM_PAYOUT: `Der Mindestauszahlungbetrag beträgt {minimum_payout} EUR`,
  BANK_DETAILS_PAYOUT_NO_COST: `Es fallen keine zusätzlichen Transaktionskosten an.`,
  BANK_DETAILS_PAYOUT_COST: `Wir zahlen via TransferWise in Deiner Landeswährung aus, worüber Dir abhängig Deines Heimatlandes zusätzliche Transaktionskosten entstehen. Du kannst die potentiell anfallenden Kosten bei TransferWise einsehen. Möchtest Du diese Kosten vermeiden, hinterlege bei uns bitte ein SEPA Konto.`,
  BANK_DETAILS_PAYOUT_NONE: `Der Mindestauszahlungsbetrag ist abhängig von der Art der Auszahlung.`,
  SEPA_ACCOUNT: `SEPA Konto`,
  NONE_SEPA: `Kein SEPA Konto`,
  PAXUM: `Paxum`,
  ACCOUNT_DETAILS_LATER: `Ich gebe meine Bankdaten später an`,
  FIELD_ACCOUNT_HOLDER: `Kontoinhaber:`,
  FIELD_IBAN: `IBAN:`,
  FIELD_BIC: `BIC/SWIFT:`,
  FIELD_BANK_PAX_EMAIL: `E-Mail:`,
  FIELD_BANK_NAME: `Name der Bank:`,
  FIELD_BANK_ADDRESS: `Adresse der Bank:`,
  FIELD_BANK_CITY: `Stadt der Bank:`,
  FIELD_BANK_COUNTRY: `Land der Bank:`,

  BANKHOLDER_DOCUMENT_MISSING: `Bitte lade den Ausweis oder Reisepass des Auszahlungsempfängers hoch!`,
  BANKHOLDER_DOCUMENT_PENDING: `Es befinden sich entsprechende Dokumente in der Prüfung. Bitte habe etwas Geduld, vielen Dank.`,
  BANKHOLDER_DOCUMENT_REJECTED: `Der hochgeladene Ausweis wurde abgelehnt. Bei Fragen kontaktiere bitte den Support.`,
  BANKHOLDER_DOCUMENT_CONFIRMED: `Der Ausweis des Auszahlungsempfängers wurde verifiziert! Vielen Dank.`,
};

