import { errorLog } from '../';
import { DIALOG_ERROR_LOG } from '../actions';
export default () => (dispatch, getState) => {
  const errorObject = getState().dialog?.errorObject;
  if (Object.keys(errorObject).length > 0 && getState().user?.account?.id) {
    for (let message in errorObject) {
      const logObject = {
        location: 'fetchedDialog',
        message,
        count: errorObject[message],
      };
      dispatch(errorLog(logObject));
    }
    dispatch({
      type: DIALOG_ERROR_LOG,
    });
  }
};
