import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import VideoIcon from '../../../../assets/img/svg/has-video.svg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Button from '../../../Button/Button';
import PageSection from '../../../PageSection/PageSection';
import Attachments from './Attachments';
import CreatorReferral from './CreatorReferral';
import CreatorTags from './CreatorTags';
import Diary from './Diary';
import FirstSteps from './FirstSteps';
import FourScore from './FourScore';
import Galleries from './Galleries';
import Icebreaker from './Icebreaker';
import LivecamFaq from './LivecamFaq';
import LivecamSetting from './LivecamSetting';
import LovenseInstallation from './LovenseInstallation';
import MultiMessenger from './MultiMessenger';
import Profile from './Profile';
import ProfileImage from './ProfileImage';
import Rankings from './Rankings';
import Relationships from './Relationships';
import Sedcard from './Sedcard';
import Settings from './Settings';
import TimeManagement from './TimeManagement';
import UserAdvertising from './UserAdvertising';
import Visibility from './Visibility';
import VoiceMessage from './VoiceMessage';

//sites
const CREATOR_REFERRAL = 'creatorReferral';
const USER_ADVERTISING = 'userAdvertising';
const PROFILE_IMAGE = 'profileImage';
const LIVECAM_SETTING = 'livecamSetting';
const LIVECAM_FAQ = 'livecamFaq';
const FOURSCORE = 'fourScore';
const LOVENSE_INSTALLATION = 'lovenseInstallation';
const FIRST_STEPS = 'firstSteps';
const MULTI_MESSENGER = 'multiMessenger';
const ATTACHMENTS = 'attachments';
const CREATOR_TAGS = 'creatorTags';
const RANKINGS = 'rankings';
const PROFILE = 'profile';
const SETTINGS = 'settings';
const ICEBREAKER = 'icebreaker';
const DIARY = 'diary';
const GALLERIES = 'galleries';
const VOICE_MESSAGE = 'voiceMessage';
const VISIBILITY = 'visibility';
const RELATIONSHIPS = 'relationships';
const TIME_MANAGEMENT = 'timeManagement';
const SEDCARD = 'sedcard';

const sites = [
  CREATOR_REFERRAL,
  USER_ADVERTISING,
  PROFILE_IMAGE,
  LIVECAM_SETTING,
  LIVECAM_FAQ,
  FOURSCORE,
  LOVENSE_INSTALLATION,
  FIRST_STEPS,
  MULTI_MESSENGER,
  ATTACHMENTS,
  CREATOR_TAGS,
  RANKINGS,
  PROFILE,
  SETTINGS,
  ICEBREAKER,
  DIARY,
  GALLERIES,
  VOICE_MESSAGE,
  VISIBILITY,
  RELATIONSHIPS,
  TIME_MANAGEMENT,
  SEDCARD,
];

function Content({ site, onBackButtonClicked }) {
  return (
    <div className="right-column">
      {site === CREATOR_REFERRAL && (
        <CreatorReferral onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === USER_ADVERTISING && (
        <UserAdvertising onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === PROFILE_IMAGE && (
        <ProfileImage onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === LIVECAM_SETTING && (
        <LivecamSetting onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === LIVECAM_FAQ && (
        <LivecamFaq onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === FOURSCORE && (
        <FourScore onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === LOVENSE_INSTALLATION && (
        <LovenseInstallation onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === FIRST_STEPS && (
        <FirstSteps onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === MULTI_MESSENGER && (
        <MultiMessenger onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === ATTACHMENTS && (
        <Attachments onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === CREATOR_TAGS && (
        <CreatorTags onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === RANKINGS && (
        <Rankings onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === PROFILE && (
        <Profile onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === SETTINGS && (
        <Settings onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === ICEBREAKER && (
        <Icebreaker onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === DIARY && <Diary onBackButtonClicked={onBackButtonClicked} />}
      {site === GALLERIES && (
        <Galleries onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === VOICE_MESSAGE && (
        <VoiceMessage onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === VISIBILITY && (
        <Visibility onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === RELATIONSHIPS && (
        <Relationships onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === TIME_MANAGEMENT && (
        <TimeManagement onBackButtonClicked={onBackButtonClicked} />
      )}
      {site === SEDCARD && (
        <Sedcard onBackButtonClicked={onBackButtonClicked} />
      )}
      {onBackButtonClicked ? (
        <Button
          onClick={onBackButtonClicked}
          className="wiki-back-button"
          label="BUTTON_BACK"
        />
      ) : null}
    </div>
  );
}

function MenuButton({ openPage, label, link, site, icon }) {
  const classes = classNames({
    active: link === site,
  });
  return (
    <Button
      type="button"
      className={classes}
      onClick={() => openPage(link)}
      label={label}
      icon={icon}
    />
  );
}
function HowTo() {
  const location = useLocation();
  const history = useHistory();
  const isContentSectionDisplayed = useMediaQuery('(min-width: 1600px)');
  const startPages = useMemo(
    () => ({
      '/wiki/start': FIRST_STEPS,
      '/wiki/general': MULTI_MESSENGER,
      '/wiki/moneymakers': ICEBREAKER,
    }),
    []
  );

  const [site, setSite] = useState(
    isContentSectionDisplayed && startPages[location.pathname]
  );
  const openPage = (page) => {
    history.push({
      hash: page,
    });
  };
  const onBackButtonClicked = () => {
    if (location.hash) {
      history.push({
        hash: '',
      });
    }
  };
  useEffect(() => {
    const hash = location.hash;
    if (hash?.length && sites.includes(hash.substring(1))) {
      return setSite(hash.substring(1));
    }
    if (isContentSectionDisplayed) {
      return history.push({
        hash: startPages[location.pathname],
      });
    }
    return setSite();
  }, [
    location,
    location.hash,
    history,
    isContentSectionDisplayed,
    setSite,
    startPages,
  ]);

  return (
    <PageSection
      rightColumn={<Content site={site} />}
      className="wiki-container"
      containsHeader={true}
      scrollToTop={true}
    >
      {(!isContentSectionDisplayed && !site) || isContentSectionDisplayed ? (
        <>
          <h2 className="headline">
            <FormattedMessage id="WIKI_HOW_TO_HEADER" />
          </h2>

          <div className="wiki-menu">
            {location.pathname === '/wiki/start' && (
              <>
                <MenuButton
                  openPage={openPage}
                  link={FIRST_STEPS}
                  label="WIKI_HOW_TO_MENU_FIRST_STEPS"
                  site={site}
                  icon={VideoIcon}
                />
                <MenuButton
                  openPage={openPage}
                  link={PROFILE_IMAGE}
                  label="WIKI_HOW_TO_MENU_PROFILE_IMAGE"
                  site={site}
                  icon={VideoIcon}
                />
                <MenuButton
                  openPage={openPage}
                  link={LIVECAM_SETTING}
                  label="WIKI_HOW_TO_MENU_LIVECAM_SETTING"
                  site={site}
                  icon={VideoIcon}
                />
              </>
            )}
            {location.pathname === '/wiki/general' && (
              <>
                <MenuButton
                  openPage={openPage}
                  link={MULTI_MESSENGER}
                  label="WIKI_HOW_TO_MENU_MULTI_MESSENGER"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={VOICE_MESSAGE}
                  label="WIKI_HOW_TO_MENU_VOICE_MESSAGE"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={RANKINGS}
                  label="WIKI_HOW_TO_MENU_RANKINGS"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={PROFILE}
                  label="WIKI_HOW_TO_MENU_PROFILE"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={CREATOR_TAGS}
                  label="WIKI_HOW_TO_MENU_CREATOR_TAGS"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={GALLERIES}
                  label="WIKI_HOW_TO_MENU_GALLERIES"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={DIARY}
                  label="WIKI_HOW_TO_MENU_DIARY"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={SETTINGS}
                  label="WIKI_HOW_TO_MENU_SETTINGS"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={LIVECAM_SETTING}
                  label="WIKI_HOW_TO_MENU_LIVECAM_SETTING"
                  site={site}
                  icon={VideoIcon}
                />
                <MenuButton
                  openPage={openPage}
                  link={LIVECAM_FAQ}
                  label="WIKI_HOW_TO_MENU_LIVECAM_FAQ"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={LOVENSE_INSTALLATION}
                  label="WIKI_HOW_TO_MENU_LOVENSE_INSTALLATION"
                  site={site}
                  icon={VideoIcon}
                />
                <MenuButton
                  openPage={openPage}
                  link={VISIBILITY}
                  label="WIKI_HOW_TO_MENU_VISIBILITY"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={RELATIONSHIPS}
                  label="WIKI_HOW_TO_MENU_RELATIONSHIPS"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={TIME_MANAGEMENT}
                  label="WIKI_HOW_TO_MENU_TIME_MANAGEMENT"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={SEDCARD}
                  label="WIKI_HOW_TO_MENU_SEDCARD"
                  site={site}
                />
              </>
            )}
            {location.pathname === '/wiki/moneymakers' && (
              <>
                <MenuButton
                  openPage={openPage}
                  link={ICEBREAKER}
                  label="WIKI_HOW_TO_MENU_ICEBREAKER"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={ATTACHMENTS}
                  label="WIKI_HOW_TO_MENU_ATTACHMENTS"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={FOURSCORE}
                  label="WIKI_HOW_TO_MENU_4SCORE"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={CREATOR_REFERRAL}
                  label="WIKI_HOW_TO_MENU_CREATOR_REFERRAL"
                  site={site}
                />
                <MenuButton
                  openPage={openPage}
                  link={USER_ADVERTISING}
                  label="WIKI_HOW_TO_MENU_USER_ADVERTISING"
                  site={site}
                />
              </>
            )}
          </div>
        </>
      ) : null}
      {!isContentSectionDisplayed && site ? (
        <Content site={site} onBackButtonClicked={onBackButtonClicked} />
      ) : null}
    </PageSection>
  );
}

export default HowTo;

