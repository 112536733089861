import './Stats.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';

import { getStatsRoutes } from '../../../routes';
import HeaderMenu from '../../HeaderMenu/HeaderMenu';
import Section from '../../Section/Section';

function CustomerStats({ user }) {
  const intl = useIntl();

  const [routes, setRoutes] = useState(null);
  const history = useHistory();
  const [headerTitle, setHeaderTitle] = useState('');
  const [items, setItems] = useState(null);
  const [, referredCustomersReq] = useAxios(
    {
      url: '/user/customers/referred?l=0&o=0',
    },
    { manual: true }
  );
  useEffect(() => {
    if (history.location.pathname === '/statistics') {
      history.push('/statistics/top-customers');
    }
  }, [history]);

  useEffect(() => {
    (async () => {
      const items = getStatsRoutes();

      let total = 0;
      try {
        const response = await referredCustomersReq();
        total = response?.data?.total;
      } catch (err) {
        console.log(err);
      }
      const headerItems = [
        ...items.filter(
          (route) =>
            route.customerRoute && (!route.referredCustomer || total > 0)
        ),
      ];

      const routes = items
        ? items.map((route, i) => {
            if (history.location.pathname.includes(route.path)) {
              setHeaderTitle(intl.formatMessage({ id: route.headerTitle }));
            }
            const Component = route.component;
            return (
              <Route
                key={`/statistics${route.path}`}
                path={`/statistics${route.path}`}
                component={() => <Component items={headerItems} />}
              />
            );
          })
        : null;
      routes.push(
        <Redirect key="redirect-to/dashboard" to="/my-account/dashboard" />
      );
      setItems(headerItems);
      setRoutes(routes);
    })();
  }, [history.location.pathname, intl, referredCustomersReq]);

  const componentCss = classNames('component-wrapper', {
    'component-wrapper-no-header-menu':
      history.location.pathname.includes('/game4score'),
  });

  return (
    <div className="page-wrapper">
      <Section title={headerTitle} className="component-section">
        <>
          {items && !history.location.pathname.includes('/game4score') ? (
            <HeaderMenu
              className="header-menu-wrapper"
              defaultPath={`/statistics`}
              items={items}
            />
          ) : null}
          <div className={componentCss}>
            <Switch>{routes}</Switch>
          </div>
        </>
      </Section>
    </div>
  );
}

export default CustomerStats;

