import './UserNote.scss';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import ButtonFieldWithErrors from '../../Forms/ButtonFieldWithErrors';
import Spinner from '../../Spinner/Spinner';

function UserNote({
  message,
  loading = false,
  noUser = false,
  error,
  change,
  invalid,
  cam,
  name,
}) {
  const { setFieldValue } = useFormikContext();
  const [requestError, setRequestError] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    setRequestError(error);
  }, [setRequestError, error]);

  useEffect(() => {
    setFieldValue('message', message);
  }, [setFieldValue, message]);

  const userTabNotesClass = classNames('user-tab-notes', {
    'no-cam': !cam,
  });

  return noUser ? null : loading ? (
    <div className="user-tab-notes-spinner">
      <Spinner />
    </div>
  ) : invalid ? (
    <div className="user-tab-notes-spinner">
      {intl.formatMessage({ id: 'usernote.errorGetNotes' })}
    </div>
  ) : (
    <>
      <div className={userTabNotesClass}>
        <h4 className="headline">{name}</h4>
        <ButtonFieldWithErrors
          as="textarea"
          id="usernotes" //do not change this, there is a check for this id in src\actions\window\keydown.js
          name="message"
          maxLength="1000"
          showRemainingChars={true}
          textarea={true}
          updateOptions={{
            change,
            isDataLoading: false,
            dataRequestError: requestError,
          }}
          invalidDataLabel="usernote.errorPutNotes"
        />
      </div>
    </>
  );
}

export default UserNote;
