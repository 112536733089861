import api from '../../utils/api';
import { getPortal } from '../../utils/portals';
import { UPDATE_DIALOG } from '../actions';

export default (partner) => async (dispatch, getState) => {
  if (
    !partner ||
    !getState().user?.account?.id ||
    getState().dialog.dialogs.includes((el) => el.partner === partner)
  ) {
    return;
  }

  const dialogProductIdArray = partner.split('@');
  const productId =
    dialogProductIdArray?.length === 2
      ? parseInt(dialogProductIdArray[0])
      : null;
  const id = partner;

  const profileMinimal = {
    info: {},
    turnover: {},
  };

  let portals = {};
  try {
    const response = await api.get(`/profile/${id}/minimal`, {
      skipAttachingAuthToken: true,
    });
    if (!response?.data?.info?.nickname) {
      return;
    }
    profileMinimal.info.nickname = response.data.info.nickname;
    if (productId) {
      try {
        portals = await getPortal(productId);
        profileMinimal.info.whitelabelLong = portals.whitelabelLong;
        profileMinimal.info.whitelabelShort = portals.whitelabelShort;
      } catch (err) {
        console.log('portal not found');
      }
    }

    if (
      !getState().user?.isLoggedIn ||
      getState().dialog.dialogs.includes((el) => el.partner === partner)
    ) {
      return;
    }

    const payload = {
      partner,
      profile: profileMinimal,
      name: profileMinimal?.info?.nickname,
      portals,
    };

    dispatch({
      type: UPDATE_DIALOG,
      payload,
    });
  } catch (error) {
    console.log({ addDialogError: error });
  }
};
